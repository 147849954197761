<template>
  <div class="body-wrap">
    <div class="container1">
      <div class="main-img"></div>
      <div class="centered">{{ $t('AboutUs') }}</div>
    </div>

    <!-- Small Thumbnail -->
    <div class="content-wrapper" v-if="langId == '0'">
      <h1>Who Is VSPORT?</h1>
      VSPORT is one of the world’s leading online gaming brands.
      <br />
      <br />

      VSPORT Sportsbook offers over 500 sporting events every week, with
      extensive coverage of all major soccer leagues and international sporting
      events. VSPORT also provides live soccer updates, immediate winnings
      confirmation, rapid payouts, easy access and fast online betting available
      around the clock. VSPORT Casino is a one-stop entertainment site offering
      an extensive range of casino games and the highly-rated Multi Player Live
      Dealer developed in conjunction with the o&shy;nline Gaming Systems of
      Australia to satisfy every player’s needs.
      <br />
      <br />

      In VSPORT Games, you can choose to play exclusive games such as
      scratchcards, keno, poker and roulette, all with unlimited plays and no
      download or installation required. VSPORT uses the latest technologies to
      deliver a fast and secure player environment. At VSPORT, we are committed
      to providing our customers with an outstanding gaming experience. With our
      wide selections of products and services, VSPORT caters for all sports
      bettors ranging from the casual punter through to VIP players and
      International Bookmakers.
      <br />
      <br />

      All of this on the widest range of sports, events and markets, in the
      comfort of your own home or anywhere in the world. When betting with
      VSPORT, you can feel confident knowing that you are dealing with a
      reputable o&shy;nline sportsbook whose management has many decades of
      experience in the gaming industry. Our friendly VSPORT Support Team is
      always available to assist in any way possible, including account
      enquiries, depositing or withdrawing of funds, or general betting
      assistance.
      <br />
      <br />

      Bet with VSPORT, the Sportsbook you can trust.
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="content-wrapper" v-if="langId == '1'">
      <h1>体育运动是谁?</h1>
      <p>V-Sport是世界领先的在线游戏品牌之一。</p><br />
      <p>
        V-Sport娱乐网是全球最知名的在线博彩品牌之一。
      </p><br />
      <p>
        V-Sport娱乐网体育博彩每周提供超过 500 个体育活动，囊括了所有重大足球联赛和国际体育赛事。V-Sport娱乐网还提供全天候的足球实时更新、即刻获奖确认、迅捷的支付、方便的网络访问和快速的在线投注。
      </p><br />
      <p>
        V-Sport娱乐网赌场是个一站式的娱乐网站，提供广泛的博彩游戏。为了满足玩家需要，V-Sport娱乐网赌场与澳洲 线上游戏软体商开发了“多人现场荷官” (Multi Player Live Dealer)，并获得很高评价。
      </p><br />
      <p>
        V-Sport娱乐网游戏将让您体验到我们独家游戏的精彩，比如刮奖卡，基诺以及卡牌游戏。所有的游戏都无须下载安装，就能免费无限试玩。
      </p><br />
      <p>
        V-Sport娱乐网利用最先进的技术为玩家创造快捷安全的娱乐环境。我们竭诚为用户提供卓越的娱乐体验。从消遣型的投注者到贵宾玩家，再到国际博彩公司，V-Sport娱乐网为所有体育投注者量身打造广泛的产品和服务。
      </p><br />
      <p>
        不论何时何地，您都可以轻松投注于我们所提供的广泛博彩项目。V-Sport娱乐网是一个有着良好信誉的在线体育投注商，通过V-Sport娱乐网下注，您可以放心的感受到我们在博彩业几十年的丰富经验带给您的信心。
      </p><br />
      <p>无论何时，我们亲切的客户服务部门会竭力为您提供各种服务，包括帐户查询、存取资金、以及常见的投注帮助。 </p><br />
      <p>
        敬请加入V-Sport娱乐网，您值得信赖的体育博彩。
      </p>
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="content-wrapper" v-if="langId == '2'">
      <h1>VSPORT คือใคร?</h1>
      <p>VSPORT เป็นหนึ่งในผู้รับวางพนันออนไลน์ชั้นนำของโลก</p>
      <p>
        สปอร์ตบุ๊ค VSPORT มีเกมส์การแข่งขันกีฬาต่างๆ ให้เลือกมากกว่า 500
        เกมส์ต่อสัปดาห์ ครอบคลุมรายการแข่งฟุตบอลใหญ่ๆ
        ทุกนัดและรายการกีฬานานาชาติต่างๆ ทั้งยังนำเสนอผลฟุตบอลสด,
        ยืนยันผลชนะทันที, จ่ายเงินรวดเร็ว และเข้าระบบได้สะดวกรวดเร็วตลอดเวลา
      </p>
      <p>
        VSPORT คาสิโน ให้บริการครบถ้วนเบ็ดเสร็จในที่เดียว
        ซึ่งเรานำเสนอเกมส์คาสิโนต่างๆหลากหลายและท่านสามารถร่วมเล่นกับผู้เล่นท่านอื่นๆ
        โดยมีผู้แจกไพ่สดแบบในคาสิโนจริงซึ่งเป็นระบบที่พัฒนาขึ้นร่วมกับระบบการเล่นเกมส์ออนไลน์ของประเทศออสเตรเลียซึ่งตอบโจ
        ทย์ทุกความต้องการของผู้เล่น
      </p>
      <p>
        ท่านสามารถเลือกเล่นเกมส์ต่างๆใน VSPORT เกมส์ อาทิเช่น บัตรขูด, keno,
        และเกมส์ไพ่
        ท่านสามารถเล่นได้ไม่จำกัดและไม่จำเป็นต้องดาวน์โหลดหรือติดตั้งโปรแกรม
      </p>
      <p>
        VSPORT
        ใช้เทคโนโลยีล่าสุดเพื่อให้บริการที่เร็วกว่าและปลอดภัยขึ้นสำหรับผู้เล่น
        VSPORT
        ให้คำมั่นสัญญากับลูกค้าว่าเราจะมอบประสบการณ์การเล่นเกมส์พนันที่เป็นเลิศ
        ด้วยผลิตภัณฑ์และบริการที่หลากหลายเหมาะกับผู้เล่นทุกความต้องการ
        ตั้งแต่ผู้เล่นทั่วไปถึงผู้เล่นวีไอพีและผู้วางพนันระดับนานาชาติ
      </p>
      <p>
        ท่านสามารถเพลิดเพลินกับเกมส์การแข่งขันกีฬารายการต่างๆทั่วทุกภูมิภาคของโลกได้อย่างสบายง่ายๆ
        ในบ้านของท่านหรือที่ใดก็ตามได้ทั่วโลก เมื่อท่านวางพนันกับ VSPORT
        ท่านสามารถมั่นใจได้ว่าท่านได้วางพนันกับสปอร์ตบุ๊คออนไลน์ที่มีชื่อเสียงไว้ใจได้
        และมีผู้บริหารงานที่มีประสบการณ์และความชำนาญนับหลายสิบปีในธุรกิจ
      </p>
      <p>
        เจ้าหน้าที่บริการลูกค้า VSPORT พร้อมให้ความช่วยเหลือท่าน
        รวมถึงพร้อมตอบทุกคำถามเกี่ยวกับบัญชี, การฝากหรือถอนเงิน
        และเรื่องการวางเดิมพันอื่นๆ ทั่วไป
      </p>
      <p>วางเดิมพันกับ VSPORT สปอร์ตบุ๊คที่ท่านเชื่อใจได้</p>
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="content-wrapper" v-if="langId == '3'">
      <h1>Ai là VSPORT?</h1>
      <p>
        VSPORT là một trong những thương hiệu cá cược qua mạng hàng đầu trên thế
        giới.
      </p>
      <p>
        VSPORT Sportsbook cung cấp hơn 500 sự kiện thể thao mỗi tuần, bao gồm
        tất cả các giải bóng đá chính và các sự kiện thể thao quốc tế. VSPORT
        cũng cung cấp thông tin cập nhật bóng đá trực tiếp, xác nhận thắng tức
        thì, thanh toán nhanh chóng, truy cập dễ dàng và cá cược qua mạng nhanh
        chóng.
      </p>
      <p>
        Sòng Bài VSPORT là một dịch vụ giải trí tổng hợp cung cấp hàng loại các
        lọai chơi bài và Người Chia Bài Trực Tiếp cho Nhiều Người Chơi hợp tác
        với Hệ Thống Chơi Bài qua mạng của Úc đáp ứng từng yêu cầu của người
        chơi.
      </p>
      <p>
        Trong Trò Chơi VSPORT, bạn có thể chọn chơi các trò chơi độc quyền như
        thẻ cào, keno, xì phé và Rulét, cho phép bạn chơi không giới hạn và
        không cần phải tải về hay lắp đặt chương trình nào.
      </p>
      <p>
        VSPORT sử dụng công nghệ tiên tiến nhất để mang lại một môi trường cá
        cược bảo mật và hiệu quả nhất. Tại VSPORT, chúng tôi cam kết cung cấp
        cho khách hàng một dịch vụ cá cược hoàn hảo. Với đa dạng sản phẩm và
        dịch vụ, VSPORT đáp ứng yêu cầu của tất cả khách hàng chơi cá cược thể
        thao từ người chơi không thường xuyên cho đến khách hàng VIP và Nhiều
        Nhà Cái Quốc Tế.
      </p>
      <p>
        Tất cả những điều này trên phạm vi rộng nhất của thể thao, sự kiện và thị trường, trong sự thoải mái tại nhà riêng của bạn hoặc bất cứ nơi nào trên thế giới. Khi đặt cược với V-Sport, bạn có thể cảm thấy tự tin khi biết rằng bạn đang giao dịch với một nhà cái thể thao trực tuyến uy tín có ban quản lý có nhiều thập kỷ kinh nghiệm trong ngành công nghiệp trò chơi.
      </p>
      <p>
        Bộ Phận Hỗ Trợ VSPORT của chúng tôi luôn luôn sẵn sàng trợ giúp trong
        bất cứ tình huống nào, bao gồm thắc mắc về tài khoản, gởi và rút tiền,
        hoặc trợ giúp chung về cá cược.
      </p>
      <p>Hãy cá cược với VSPORT, nhà cái bạn có thể tin tưởng.</p>
      <br />
      <br />
      <br />
      <br />
    </div>
    <div class="content-wrapper" v-if="langId == '4'">
      <h1>VSPORT ni nani?</h1>
      VSPORT ni mojawapo ya chapa zinazoongoza duniani za michezo ya kubahatisha mtandaoni.
      <br />
      <br />

      VSPORT Sportsbook hutoa zaidi ya matukio 500 ya michezo kila wiki, pamoja na
      chanjo ya kina ya ligi zote kuu za soka na michezo ya kimataifa
      matukio. VSPORT pia hutoa sasisho za moja kwa moja za soka, ushindi wa mara moja
      uthibitisho, malipo ya haraka, ufikiaji rahisi na kamari ya haraka mtandaoni inapatikana
      kote saa. VSPORT Casino ni toleo moja la tovuti ya burudani
      anuwai ya michezo ya kasino na Wachezaji Wengi Moja kwa Moja waliokadiriwa sana
      Muuzaji aliundwa kwa kushirikiana na Mifumo ya Michezo ya Kubahatisha mtandaoni ya
      Australia kukidhi mahitaji ya kila mchezaji.
      <br />
      <br />

      Katika Michezo ya VSPORT, unaweza kuchagua kucheza michezo ya kipekee kama vile
      kadi za mwanzo, keno, poker na roulette, zote zikiwa na michezo isiyo na kikomo na hapana
      pakua au usakinishaji unahitajika. VSPORT hutumia teknolojia za hivi punde
      toa mazingira ya haraka na salama ya mchezaji. Katika VSPORT, tumejitolea
      ili kuwapa wateja wetu uzoefu bora wa michezo ya kubahatisha. Pamoja na yetu
      uchaguzi mpana wa bidhaa na huduma, VSPORT inahudumia kwa michezo yote
      dau kuanzia kwa mchezaji wa kawaida hadi kwa wachezaji wa VIP na
      Watengenezaji wasiohalali wa Kimataifa.
      <br />
      <br />

      Yote haya kwenye anuwai kubwa ya michezo, hafla na masoko, kwenye
      faraja ya nyumba yako mwenyewe au popote duniani. Wakati wa kuweka kamari na
      VSPORT, unaweza kujiamini ukijua kuwa unashughulika na a
      kitabu cha michezo cha mtandaoni kinachoheshimika ambacho usimamizi wake una miongo mingi ya
      uzoefu katika tasnia ya michezo ya kubahatisha. Timu yetu ya kirafiki ya Usaidizi wa VSPORT ni
      inapatikana kila wakati kusaidia kwa njia yoyote iwezekanavyo, pamoja na akaunti
      maswali, kuweka au kutoa fedha, au kamari ya jumla
      msaada.
      <br />
      <br />

      Bet na VSPORT, Kitabu cha Michezo unachoweza kuamini.
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {
    return {
      selectedMenuIndex: 0,
      selectedGame: null,
    };
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
}

* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  color: white;
  margin-top: 0px;
  font-size: 20px;
}

h3 {
  font-size: 1em;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/about.jpg');
  background-size: cover;
  background-position: center;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: white;
  font-size: 15px;
  margin: 0 auto;
  max-width: 1200px;
  background: transparent;
  text-align: justify;
  padding: 30px;
  border-top: 1px solid #2c1901;
  border-bottom: 1px solid #2c1901;
  overflow-y: auto;
  max-height: 600px;
}
</style>
