//banner import
/*
import bannerEn1 from './../images/banner/banner-1-en.jpg';
import bannerTh1 from './../images/banner/banner-1-th.jpg';
import bannerEn2 from './../images/banner/banner-2-en.jpg';
import bannerTh2 from './../images/banner/banner-2-th.jpg';
import bannerEn3 from './../images/banner/banner-3-en.jpg';
import bannerTh3 from './../images/banner/banner-3-th.jpg';
import bannerEn4 from './../images/banner/banner-4-en.jpg';
import bannerTh4 from './../images/banner/banner-4-th.jpg';
import bannerEn5 from './../images/banner/banner-5-en.jpg';
import bannerTh5 from './../images/banner/banner-5-th.jpg';*/

import bannerEn6 from './../images/banner/banner-6-en.jpg';
import bannerTh6 from './../images/banner/banner-6-th.jpg';
//import bannerEn7 from './../images/banner/banner-7-en.jpg';
//import bannerEn8 from './../images/banner/banner-8-en.jpg';

import slot from './catalogMobile/slot';
//import live from './catalogMobile/live';
//import sb from './catalogMobile/sportsbook';
/*
import fish from './catalogMobile/fish';

import itp from './catalogMobile/itp';*/
var mobileBanner = {
  //banner  image import
  banner: [
  /*
    {
      imgUrl: [
        { langId: 0, src: bannerEn1 },
        { langId: 2, src: bannerTh1 },
      ],
      game: slot.items.find((x) => x.code === '42'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn2 },
        { langId: 2, src: bannerTh2 },
      ],
      game: slot.items.find((x) => x.code === '77'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn3 },
        { langId: 2, src: bannerTh3 },
      ],
      game: itp.items.find((x) => x.code === '67-105'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn4 },
        { langId: 2, src: bannerTh4 },
      ],
      game: live.items.find((x) => x.code === '67-114'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn5 },
        { langId: 2, src: bannerTh5 },
      ],
      game: fish.items.find((x) => x.code === '67-105'),
    },
    {
      imgUrl: [
        { langId: 0, src: bannerEn8 },
        { langId: 2, src: bannerEn8 },
      ],
      game: sb.items.find((x) => x.code === '3'),
    },
    
    {
      imgUrl: [
        { langId: 0, src: bannerEn7 },
        { langId: 2, src: bannerEn7 },
      ],
      game: live.items.find((x) => x.code === '80-202'),
    },*/
    {
      imgUrl: [
        { langId: 0, src: bannerEn6 },
        { langId: 2, src: bannerTh6 },
      ],
      game: slot.items.find((x) => x.code === '35'),
    },
  ],
};

export default mobileBanner;
