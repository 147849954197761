<template>
  <section>
    <section class="mobile">
      <img src="../assets/images/logo-back.png" class="logo-back"/>
      <MobileHome />
    </section>
    <section class="desktop">
      <DesktopHome />
    </section>
  </section>
</template>

<script>
// page component
import MobileHome from './mobile/MobileHome';
import DesktopHome from './desktop/DesktopHome';

export default {
  name: 'Home',
  components: {
    MobileHome,
    DesktopHome,
  },
  mounted() {
    // this.getToken();
  },
  methods: {
    // async getToken() {
    //   this.$http
    //     .get('/api/GetToken')
    //     .then((result) => {
    //       console.log(result);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },

  // beforeRouteEnter(to, from, next) {
  //   next()
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
