<template>
  <div class="card-body">
    <p class="card-text">
      V-Sport เป็นหนึ่งในผู้รับวางพนันออนไลน์ชั้นนำของโลก
    </p>
    <p class="card-text">
      สปอร์ตบุ๊ค V-Sport มีเกมส์การแข่งขันกีฬาต่างๆ ให้เลือกมากกว่า 500
      เกมส์ต่อสัปดาห์ ครอบคลุมรายการแข่งฟุตบอลใหญ่ๆ
      ทุกนัดและรายการกีฬานานาชาติต่างๆ ทั้งยังนำเสนอผลฟุตบอลสด,
      ยืนยันผลชนะทันที, จ่ายเงินรวดเร็ว และเข้าระบบได้สะดวกรวดเร็วตลอดเวลา
      <br />
      V-Sport คาสิโน ให้บริการครบถ้วนเบ็ดเสร็จในที่เดียว
      ซึ่งเรานำเสนอเกมส์คาสิโนต่างๆหลากหลายและท่านสามารถร่วมเล่นกับผู้เล่นท่านอื่นๆ
      โดยมีผู้แจกไพ่สดแบบในคาสิโนจริงซึ่งเป็นระบบที่พัฒนาขึ้นร่วมกับระบบการเล่นเกมส์ออนไลน์ของประเทศออสเตรเลียซึ่งตอบโจ
      ทย์ทุกความต้องการของผู้เล่น
    </p>
    <p class="card-text">
      ท่านสามารถเลือกเล่นเกมส์ต่างๆใน V-Sport เกมส์ อาทิเช่น บัตรขูด, keno,
      และเกมส์ไพ่
      ท่านสามารถเล่นได้ไม่จำกัดและไม่จำเป็นต้องดาวน์โหลดหรือติดตั้งโปรแกรม
      <br />
      V-Sport
      ใช้เทคโนโลยีล่าสุดเพื่อให้บริการที่เร็วกว่าและปลอดภัยขึ้นสำหรับผู้เล่น
      V-Sport
      ให้คำมั่นสัญญากับลูกค้าว่าเราจะมอบประสบการณ์การเล่นเกมส์พนันที่เป็นเลิศ
      ด้วยผลิตภัณฑ์และบริการที่หลากหลายเหมาะกับผู้เล่นทุกความต้องการ
      ตั้งแต่ผู้เล่นทั่วไปถึงผู้เล่นวีไอพีและผู้วางพนันระดับนานาชาติ
    </p>
    <p class="card-text">
      ท่านสามารถเพลิดเพลินกับเกมส์การแข่งขันกีฬารายการต่างๆทั่วทุกภูมิภาคของโลกได้อย่างสบายง่ายๆ
      ในบ้านของท่านหรือที่ใดก็ตามได้ทั่วโลก เมื่อท่านวางพนันกับ V-Sport
      ท่านสามารถมั่นใจได้ว่าท่านได้วางพนันกับสปอร์ตบุ๊คออนไลน์ที่มีชื่อเสียงไว้ใจได้
      และมีผู้บริหารงานที่มีประสบการณ์และความชำนาญนับหลายสิบปีในธุรกิจ
      <br />
      เจ้าหน้าที่บริการลูกค้า V-Sport พร้อมให้ความช่วยเหลือท่าน
      รวมถึงพร้อมตอบทุกคำถามเกี่ยวกับบัญชี, การฝากหรือถอนเงิน
      และเรื่องการวางเดิมพันอื่นๆ ทั่วไป
    </p>

    <p class="card-text">
      วางเดิมพันกับ V-Sport สปอร์ตบุ๊คที่ท่านเชื่อใจได้
    </p>
    <br><br><br>
  </div>
</template>

<style scoped>
.card-text {
  font-size: 16px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  letter-spacing: 0.2px;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
}
</style>
