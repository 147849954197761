<template>
  <div id="app">
    <!-- <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view> -->
    <router-view />
    <transition name="fadeIn">
      <div class="desktop" v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition>

    <transition name="fadeIn">
      <div class="mobile" v-if="showErrorAlert">
        <danger-alert-mobile :message="errorMessage"></danger-alert-mobile>
      </div>
    </transition>

    <!--floating button--->

    <div class="mobile">
      
      <a @click="onClickLine" class="float-btn" v-if="!isHideLine">
        <img
          :class="[isLoggedIn ? 'my-float2' : 'my-float']"
          src="./assets/icons/icn-line.png"
          width="60"
          height="60"
        />
      </a>
    </div>
    <div class="desktop">
      <a @click="onClickLine" class="float-btn" v-if="!isHideLine">
        <img
          class="my-float"
          src="./assets/icons/icn-line.png"
          width="60"
          height="60"
        />
      </a>
    </div>
  </div>
</template>

<script>
import CustomHttp from './CustomHttp';
import CustomStorage from './CustomStorage';
import debounce from 'lodash/debounce';
import i18n from './i18n';
const { t } = i18n.global;
// import CustomStorage from './CustomHttp';
const configTimer = process.env.VUE_APP_EXTEND_SESSION_SECONDS;
let lineUrl = process.env.VUE_APP_LINE_SUPPORT_URL;
export default {
  name: 'App',
  beforeMount() {
    //handle game event timeout language reset
    const langFlag = CustomStorage.getItem('timedOutLanguage');
    if (langFlag != null) {
      this.$store.dispatch('setLangId', langFlag);
      CustomStorage.removeItem('timedOutLanguage');
    }
  },
  mounted() {
    this.getSystemMaintenanceStatus();

    if (this.$store.getters.maintenanceRemark != '') {
      return;
    }
    if (CustomStorage.getItem('isTimeout') == 'true') {
      CustomStorage.removeItem('isTimeout');
      this.toggleDangerAlert(t('Session timed out. Please login again.'));
    }
    //for logout via mobile sidebar
    if (CustomStorage.getItem('mobileLogout') == 'true') {
      const logoutUser = CustomStorage.getItem('logoutUser');
      this.$store.dispatch('userLogoutWithApi2', logoutUser);
      CustomStorage.removeItem('mobileLogout');
      CustomStorage.removeItem('logoutUser');
    }
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerWidth;
    });
    // setTimeout(() => {}, 2500);
    this.resetTimer();
    this.resetIdleTimer();
    setInterval(() => {
      if (this.$store.getters.isLoggedIn) {
        this.timer -= 1;
      }
    }, 1000);

    setInterval(() => {
      if (this.$store.getters.isLoggedIn) {
        this.idleTimer -= 1;
      }
    }, 1000);
    this.getAutoWallet();
  },
  data() {
    return {
      // isSwalClose: false,
      showErrorAlert: false,
      innerWidth: window.innerWidth,
      handleDebouncedScroll: null,
      handleDebouncedMouseMove: null,
      timer: 10, // sec
      isUserIdle: false,
      idleTimer: 10, //sec
    };
  },
  methods: {
    async getAutoWallet() {
      let json = { username: this.$store.getters.username };
      let response = await CustomHttp.post("api/v1/member/get-preference", json);

      let responseData = response.data.data;

      // section for multi select
      this.$store.dispatch('setAutoTransferWallet', responseData.autoTransfer);

    },
    onClickLine() {
      window.open(lineUrl, '_blank');
    },
    resetTimer() {
      //  console.log("configTimer");
      // console.log(Number(configTimer));
      const tempTimer = Number(configTimer);
      this.timer = tempTimer;
    },
    resetIdleTimer() {
      //console.log("configTimer");
      // console.log(Number(configTimer));
      const tempTimer = Number(configTimer);
      this.idleTimer = tempTimer - 1;
    },
    async extendSession() {
      await CustomHttp.extendSessionAPI2();
    },
    showCustomSwal(msg) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-timeout',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire(msg);
    },
    async getSystemMaintenanceStatus() {
      const response = await CustomHttp.post(
        'api/v1/system/get-maintenance-status'
      );
      const isMaintenance = response.data.data.isMaintenance;
      if (isMaintenance) {
        this.$store.dispatch('userLogout');
        const remark = response.data.data.remark;
        this.$store.dispatch('setMaintenanceRemark', remark);
        this.$router.replace('/maintenance');
      } else {
        this.$store.dispatch('setMaintenanceRemark', '');
        CustomStorage.removeItem('maintenance');
        let currentPath = this.$router.currentRoute._value.path;
        if (currentPath == '/maintenance') {
          this.$router.replace('/');
        }
      }
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 2200);
    },
    logUserOut() {
      // this.showCustomSwal(t("Session timed out"));
      this.$store.dispatch('userLogout');
      this.$store.dispatch('setTimeoutFlag', false);
      this.$router.replace('/');
    },
    // eslint-disable-next-line
    userActive(event) {
      // Any code to be executed when the event is trigger
      // console.log(event);
      // console.log("userActive");
      this.resetIdleTimer();
      this.isUserIdle = false;
    },
  },
  created() {
    //for extend session
    this.resetTimer();
    this.handleDebouncedScroll = debounce(this.userActive, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
    this.handleDebouncedMouseMove = debounce(this.userActive, 100);
    window.addEventListener('mousemove', this.handleDebouncedMouseMove);
    window.addEventListener('keypress', this.userActive);
    // check if maintenance
    const maintenanceFlag = CustomStorage.getItem('maintenance');
    //console.log(maintenanceFlag);
    if (maintenanceFlag == null) {
      if (this.innerWidth >= 769) {
        this.$store.dispatch('tryLogin');
      } else {
        this.$store.dispatch('tryLoginMobile');
      }
    }

    this.$i18n.locale = this.$store.getters.langId.toString();
    //add listener to logout user when user logout in other tab
    window.addEventListener(
      'storage',
      (event) => {
        //console.log('event');
        //console.log(event);
        // this.toggleDangerAlert(
        //   'Logged out in another tab. Please login again.'
        // );
        if (event.key == 'event-sb-maintenance') {
          const message = localStorage.getItem('event-sb-maintenance');
          this.toggleDangerAlert(message);
          localStorage.removeItem('event-sb-maintenance');
        } else if (event.key == 'event-logout') {
          this.logUserOut();
        } else if (event.key == 'event-game-error') {
          CustomStorage.setItem('isTimeout', 'true');
          localStorage.removeItem('event-game-error');
          CustomStorage.setItem(
            'timedOutLanguage',
            this.$store.getters.langId.toString()
          );
          window.location.reload();
        }
      },
      false
    );
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
    window.removeEventListener('keypress', this.userActive);
    window.removeEventListener('mousemove', this.handleDebouncedMouseMove);
  },
  computed: {
    isTimeout() {
      return this.$store.getters.isTimeout;
    },
    langId() {
      return this.$store.getters.langId;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isHideLine() {
      return true;//disable line for vsport first
      //const relativePath = window.location.pathname + window.location.search;
      //return relativePath == '/agreement' || relativePath == '/initializing';
    },
  },
  watch: {
    /* eslint-disable */
    timer(newValue, oldValue) {
      // console.log("timer");
      //  console.log(newValue);
      if (newValue <= 0 && !this.isUserIdle) {
        this.resetTimer();
        this.extendSession();
      }
    },
    idleTimer(newValue, oldValue) {
      //  console.log("idleTimer");
      //  console.log(newValue);
      if (this.idleTimer <= 0 && !this.isUserIdle) {
        this.isUserIdle = true;
      }
    },
    langId(newLang, oldLang) {
      if (newLang.toString() == oldLang.toString()) {
        return;
      }
      // Our fancy notification (2).
      if (this.$store.getters.isLoggedIn) {
        //mobile dont need system message
        if (this.innerWidth >= 769) {
          this.$store.dispatch('getSystemMessage');
        }
        // window.location.reload();
      }
      this.$i18n.locale = this.$store.getters.langId.toString();
    },
    $route: {
      immediate: true,
      // eslint-disable-next-line
      handler(to, from) {
        // console.log(from);
        if (this.innerWidth >= 769) {
          document.title = to.meta.title || 'VSPORT';
        } else {
          document.title = to.meta.title || 'VSPORT Mobile - Home';
        }
      },
    },
    /* eslint-disable */
    isTimeout(newValue, oldValue) {
      if (newValue) {
        this.logUserOut();
        if (this.innerWidth <= 769) {
          CustomStorage.setItem('isTimeout', 'true');
          // this.$router.replace('/');
          window.location.reload();
        } else {
          this.toggleDangerAlert(t('Session timed out. Please login again.'));
        }
      }
      // console.log(`We have ${oldValue} at previous`);
      //this.$swal.fire('Session Timeout');
      //console.log(`We have ${newValue}  now, yay!`);
    },
    /* eslint-enable */
  },
};
// console.log(process.env.VUE_APP_TITLE);
</script>

<style>
/* mobile */
.float-btn {
  z-index: 2;
  }
@media (max-width: 769px) {
  .float-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 10px;
    /* background-color: #0c9; */
    color: #fff;
    /* border-radius: 50px; */
    text-align: center;
    /* box-shadow: 2px 2px 3px #999; */
    cursor: pointer;
  }
}

/* desktop */
@media (min-width: 769px) {
  .float-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 50px;
    right: 40px;
    /* background-color: #0c9; */
    color: #fff;
    /* border-radius: 50px; */
    text-align: center;
    /* box-shadow: 2px 2px 3px #999; */
    cursor: pointer;
  }

}
</style>
