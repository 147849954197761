<template>
  <div
    :class="{
      'padding-logged': isLoggedIn && enablePaymentGateway,
      'padding-unlogged': !isLoggedIn,
      'padding-logged-60': isLoggedIn && !enablePaymentGateway,
    }"
  >
    <div class="top-section mobile-body-font">
      <nav id="mobile-nav" class="navbar fixed-top navbar-expand-lg navbar-padding">
        <div class="col-sm-12">
          <div class="row d-flex" :class="(isLoggedIn) ? '' : 'align-items-center'">
            <div class="px-0 col">
              <div
                @click="toggleCollapse()"
                ref="myToggleBar"
                class="toggle-bar"
                data-toggle="collapse"
                data-target="#myNavbar"
              >
                <img src="../../../assets/icons/icn-main.png" width="40" />
              </div>
            </div>
            <div class="px-0 col text-white">
                <img
                  @click="setActiveComponent('screen-landing')"
                  src="../../../assets/images/logo.png"
                  alt="User Avatar"
                  class="brand-image"
                />
            </div>
            <div
              class="px-0 pt-1 text-white col-flex">
              <div class="text-right font-helvetica" v-if="isLoggedIn">
                <!-- <div class="b smaller text-uppercase">{{ username }}</div> -->
                <div style="font-size:8px">
                    {{ currentDate }} {{ currentTime }}
                </div>
                <div class="user-cash-container pointer" @click="onClickRefreshBalance">
                  <img src="../../../assets/icons/user-cash.png" width="12" height="12" style="position: absolute; left:0.5em"/>
                  <div class="w-100 text-right" v-if="isLoadingBalance"><i class="fas fa-spinner fa-pulse currency-spinner ml-5 mr-2"></i></div>
                  <div class="w-100 text-right" v-else>{{ $filters.currency(totalRemainingCreditLimit) }}</div>
                  
                </div>
              </div>
              <div class="smallest text-right mb-1" v-if="!isLoggedIn">{{currentDate}} {{ currentTime }}</div>
              <div class="btnlogin-top-container" v-if="!isLoggedIn">
                <img src="../../../assets/images/btn-login-top.png" class="btnlogin-top"/>
                <div
                  class="btn-login cursor-pointer float-right"
                  v-if="!isLoggedIn"
                  @click="toggleLogin()">

                  {{ $t('Login') }}
                </div>

              </div>

              <!-- <router-link to="/public/login">
              <div class="btn-login cursor-pointer float-right px-3 " @click="toggleLogin()">
                Login
              </div>
            </router-link> -->
            </div>
          </div>
        </div>
      </nav>
      <div class="navbar navbar-inverse">
        <div class="navbar-collapse collapse px-1 py-2" id="myNavbar" style="">
          <ul class="nav navbar-nav">
            <li>
              <a href="#" @click="setActiveComponent('screen-about-us')">
                {{ $t('AboutUs2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-betting-rules')">
                {{ $t('BettingRules2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-faq')">
                {{ $t('FAQ2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-why-us')">
                {{ $t('WhyUs2') }}
              </a>
            </li>
            <li>
              <a href="#" @click="setActiveComponent('screen-contact-us')">
                {{ $t('ContactUs2') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr class="divider" />
      <!--- credit bar--->
      <div
        id="credit"
        class="col-center spaced smaller credit-container"
        v-if="isLoggedIn && enablePaymentGateway"
      >
        <div class="credit-left">
          <img src="../../../assets/icons/icn-balance-checked.png" width="18" />
          <span class="credit-balance">
            <span v-if="isLoadingBalance">
              <i class="fas fa-spinner fa-pulse currency-spinner ml-5 mr-2"></i>
            </span>
            <span
              class="Positive cursor-pointer"
              v-if="!isLoadingBalance"
              @click="onClickRefreshBalance"
            >
              {{ $filters.currencyDecimal(totalRemainingCreditLimit) }}
            </span>
          </span>
        </div>
        <div class="col-center credit-right">
          <div
            v-show="!suspend"
            id="deposit"
            class="credit-item cursor-pointer"
            @click="onClickDeposit"
          >
            <div class="text-center">
              <img
                src="../../../assets/icons/icn-deposit-cd.png?v2"
                width="40"
              />
              <br />
              <div class="mt-1 lh">{{ $t('Btn_Deposit') }}</div>
            </div>
          </div>
          <div
            v-show="!suspend"
            id="withdraw"
            class="credit-item cursor-pointer"
            @click="onClickWithdrawal"
          >
            <div class="text-center">
              <img
                src="../../../assets/icons/icn-Withdrawal-cd.png?v2"
                width="40"
              />
              <br />
              <div class="mt-1 lh">{{ $t('Btn_Withdraw') }}</div>
            </div>
          </div>
          <div class="credit-item">
            <router-link to="/history">
              <div class="text-center">
                <img
                  src="../../../assets/icons/icn-history-cd.png?v2"
                  width="40"
                />
                <br />
                <div class="mt-1 lh">{{ $t('History') }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!---backdrop---->
      <transition name="fadeIn">
        <div
          class="dark-backdrop"
          @click="closeCollapse()"
          v-if="showCollapse"
        ></div>
      </transition>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import CustomEncrypt from '../../../CustomEncrypt';
const registerUrl = process.env.VUE_APP_USER_REGISTER_URL;
export default {
  props: ['showCollapse'],
  emits: ['toggle-login', 'toggle-collapse', 'set-component'],
  mounted() {
    this.updateCurrentTime();
    setInterval(() => {
      this.updateCurrentTime();
    }, 1000);

    const nav = $('#mobile-nav');

    function checkPositionForNav() {
      if(window.scrollY > 30){
        nav.addClass('nav-back');
      }else{
        nav.removeClass('nav-back');
      }
    }
    window.addEventListener('scroll', checkPositionForNav);

  },
  data() {
    return {
      currentDate: '',
      currentTime: '',
      isLoadingBalance: false,
      showSidebar : false,
    };
  },
  methods: {
    onClickRegister() {
      window.open(registerUrl, '_blank');
    },
    onClickDeposit() {
      this.goToPayment('D');
    },
    onClickWithdrawal() {
      this.goToPayment('W');
    },
    goToPayment(method) {
      let today = new Date();
      today.setHours(today.getHours() + 8);
      let currentUnixTime = moment(today).unix();
      let token = CustomEncrypt.SignAndHash(currentUnixTime);
      let routeData = this.$router.resolve({
        path: 'ValidationLogin',
        query: {
          username: this.$store.getters.username,
          timestamp: currentUnixTime,
          token: token,
          method: method,
          lang: CustomEncrypt.getPaymentLanguage(),
          landingPage: window.location.origin,
        },
      });
      window.open(CustomEncrypt.getPaymentUrl() + routeData.href, '_blank');
    },

    updateCurrentTime() {
      const today = new Date();
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const dateTime = date + ' ' + time;
      let tempDate = new Date(dateTime);

      let tempDateGmt = new Date();
      let gmt = Math.abs(tempDateGmt.getTimezoneOffset() / 60);

      tempDate = moment().format('DD-MM-yyyy');
      let tempDate2 =
        moment().format('hh:mm A') + ' (GMT+' + gmt.toString() + ')';
      this.currentDate = tempDate;
      this.currentTime = tempDate2;
    },
    toggleLogin() {
      this.$emit('toggle-login');
    },
    toggleCollapse() {
      const isMenuOpen = !$('#myNavbar').is(':visible');
      this.$emit('toggle-collapse', isMenuOpen);
    },
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
    closeCollapse() {
      this.$refs.myToggleBar.click();
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  }, //end methods
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    suspend() {
      return this.$store.getters.suspend;
    },
    username() {
      return this.$store.getters.username;
    },
    currency() {
      return this.$store.getters.currency;
    },
    totalRemainingCreditLimit() {
      // const current = new Date();
      return this.$store.getters.totalRemainingCreditLimit;
    },
    enablePaymentGateway() {
      return this.$store.getters.enablePaymentGateway;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.brand-image {
  /* width: 128px; */
  position: relative;
  height: 45px;
  object-fit: fill;
  top: 10px;
}

@media (max-width: 360px) {
  .brand-image {
    height: 35px;
  }
}

.btn-login {
  padding: 5px 10px;
  color: white;
  border-radius: 5em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  width: 100px;
  text-align: center;
}

.btn-register {
  margin-left: 3px;
  padding: 5px 10px;
  background: linear-gradient(to bottom, #7a86c9, #192460);
  color: black;
  border-radius: 5em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  top: 0.2rem;
}

.divider {
  border: opx;
  padding: 11px 0px;
}

.fixed-top {
  position: fixed !important;
}

.toggle-bar {
  padding: 0px;
  position: relative;
  margin-top: 0.5em;
}

.navbar-inverse {
  z-index: 1000;
  margin-top: 60px !important;
  padding: 0px;
}

.dark-backdrop {
  z-index: 10;
}

.padding-logged {
  padding-bottom: 131px;
}

.padding-logged-60 {
  padding-bottom: 60px;
}

@media (max-width: 360px) {
  .padding-logged {
    padding-top: 0px;
  }
}

@media (max-width: 370px) {
  .btn-register {
    top: 0.4rem;
    font-size: 0.65rem;
  }
/*
  .btn-login {
    top: 0.4rem;
    font-size: 0.65rem;
  }*/
}

.padding-unlogged {
  padding-bottom: 60px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}
.credit-left {
  padding-left: 17px !important;
}

.credit-balance {
  padding-left: 5px !important;
}

.credit-container {
  padding-top: 5px;
}

#timecontainer {
  white-space: nowrap;
}

.credit-item {
  padding: 0.75em 0.4em 0.75em 0.2em !important;
}

.credit-right {
  padding-right: 1rem !important;
}

.font-helvetica {
  font-family: 'Helvetica';
}

.navbar-padding {
  padding: 0.2rem 1rem 0.5rem 1rem;
}
.user-cash-container{
  position: relative;
  border: 2px solid #0059aa;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: small;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 5px;
}
.nav-back{
  background: #001e3c;
}
#myNavbar{
  background: black;
}
</style>
