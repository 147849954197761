<template>
  <div class="body-wrap">
    <div class="container1">
      <div class="main-img"></div>
      <div class="centered">{{ $t('FAQ') }}</div>
    </div>

    <!-- Small Thumbnail -->
    <div class="content-wrapper" v-if="langId == '0' || langId == '3'">
      <dl class="faq">
        <dt>How can I become a member?</dt>
        <dd>
          Please contact us to find out how to become a member. Welcome to join
          us!
        </dd>
        <dt>How can I place bet?</dt>
        <dd>
          You can place your bet with us via our website on the Internet or
          Mobile Wap Service and in accordance to our rules and regulations
          stated.
        </dd>
        <dt>When can I place bet?</dt>
        <dd>
          You are welcome to place your bet with us anytime at your convenience.
        </dd>
        <dt>What is the minimum and maximum bet amount?</dt>
        <dd>
          At VSPORT, the minimum and maximum varies for different users and
          different matches. You will see the acceptable bet range in the place
          bet panel.
        </dd>
        <dt>How do I deposit money into my VSPORT Account?</dt>
        <dd>
          We offer different methods for depositing money into your account at
          VSPORT. You may contact our Customer Service for further assistance.
        </dd>
        <dt>Can I cancel my bet?</dt>
        <dd>
          You cannot cancel a bet once it has been accepted by system. If you
          suspect any problem, please contact our customer service immediately.
        </dd>
        <dt>What if I cannot login?</dt>
        <dd>
          If you are certain that you cannot login using the correct username
          and password, your account may be suspended for various reasons.
          Please contact our VSPORT Customer Service to resolve the issue.
        </dd>
        <dt>What if I forgotten my password?</dt>
        <dd>
          If forget your username or password, please contact our VSPORT
          Customer Service for assistance.
        </dd>
        <dt>Can I let someone else bet with my account?</dt>
        <dd>
          For the security of your account and personal information, we suggest
          that you do NOT disclose your Username or Password to any other
          person.
        </dd>
        <dt>Is my personal information protected safely?</dt>
        <dd>
          Sure, all your personal information are securely protected with strict
          confidentiality which in accordance with our Privacy Policy.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '1'">
      <dl class="faq">
        <dt>How can I become a member?</dt>
        <dd>
          Please contact us to find out how to become a member. Welcome to join
          us!
        </dd>
        <dt>How can I place bet?</dt>
        <dd>
          You can place your bet with us via our website on the Internet or
          Mobile Wap Service and in accordance to our rules and regulations
          stated.
        </dd>
        <dt>When can I place bet?</dt>
        <dd>
          You are welcome to place your bet with us anytime at your convenience.
        </dd>
        <dt>What is the minimum and maximum bet amount?</dt>
        <dd>
          At VSPORT, the minimum and maximum varies for different users and
          different matches. You will see the acceptable bet range in the place
          bet panel.
        </dd>
        <dt>How do I deposit money into my VSPORT Account?</dt>
        <dd>
          We offer different methods for depositing money into your account at
          VSPORT. You may contact our Customer Service for further assistance.
        </dd>
        <dt>Can I cancel my bet?</dt>
        <dd>
          You cannot cancel a bet once it has been accepted by system. If you
          suspect any problem, please contact our customer service immediately.
        </dd>
        <dt>What if I cannot login?</dt>
        <dd>
          If you are certain that you cannot login using the correct username
          and password, your account may be suspended for various reasons.
          Please contact our VSPORT Customer Service to resolve the issue.
        </dd>
        <dt>What if I forgotten my password?</dt>
        <dd>
          If forget your username or password, please contact our VSPORT
          Customer Service for assistance.
        </dd>
        <dt>Can I let someone else bet with my account?</dt>
        <dd>
          For the security of your account and personal information, we suggest
          that you do NOT disclose your Username or Password to any other
          person.
        </dd>
        <dt>Is my personal information protected safely?</dt>
        <dd>
          Sure, all your personal information are securely protected with strict
          confidentiality which in accordance with our Privacy Policy.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '2'">
      <dl class="faq">
        <dt>ฉันจะวางเดิมพันได้อย่างไร ?</dt>
        <dd>
          คุณสามารถวางเดิมพันกับเราผ่านทางเวบไวค์โดยระบบอินเตอร์เนตอย่างเดียวเท่านั้นตามที่ระบุไว้ในกฎกติกา
        </dd>

        <dt>ฉันสามารถยกเลิกการเดิมพันได้หรือไม่ ?</dt>
        <dd>
          เมื่อเราได้ยอมรับรายการเดิมพันของคุณแล้ว คุณไม่สามารถยกเลิกได้
          กติกานี้ได้ถูกโพสต์ไว้ในกฎและกติกาของเวบไซค์เรียบร้อยแล้ว
          เป็นหน้าที่ของลูกค้าที่จะต้องทำความคุ้นเคยกับกติกาเหล่านี้
        </dd>

        <dt>ข้อมูลส่วนตัวของฉันปลอดภัยหรือเปล่าเมื่อเล่นกับเวบVSPORT.com ?</dt>
        <dd>
          ใช่คะ
          ข้อมูลส่วนตัวของคุณที่ให้ไว้กับทางเวบไซค์จะถูกเก็บไว้อย่างเป็นความลับคะ
        </dd>

        <dt>ฉันจะทำอย่างไรถ้าฉันลืมยูสเซอร์เนม หรือ พาสเวริ์ด ?</dt>
        <dd>
          กรุณาติดต่อฝ่ายบริการลูกค้าของเราโดยด่วนทาง อีเมลล์ โทรศัพท์ หรือ
          แฟกซ์
        </dd>

        <dt>ถ้าฉันต้องการเปลี่ยนแปลงข้อมูลส่วนตัวของฉัน ?</dt>
        <dd>
          กรุณาติดต่อฝ่ายบริการลูกค้าเพื่ออัพเดทข้อมูลปัจจุบันที่ต้องการเปลี่ยนแปลงโดยด่วน
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '4'">
<dl class="faq">
        <dt>Je, ninawezaje kuwa mwanachama?</dt>
        <dd>
          Tafadhali wasiliana nasi ili kujua jinsi ya kuwa mwanachama. Karibu ujiunge
          sisi!
        </dd>
        <dt>Ninawezaje kuweka dau?</dt>
        <dd>
          Unaweza kuweka dau lako nasi kupitia tovuti yetu kwenye mtandao au
          Huduma ya Wap ya Simu na kwa mujibu wa sheria na kanuni zetu
          alisema.
        </dd>
        <dt>Je, ninaweza kuweka dau lini?</dt>
        <dd>
          Unakaribishwa kuweka dau lako nasi wakati wowote kwa urahisi wako.
        </dd>
        <dt>Je, kiwango cha chini na cha juu zaidi cha dau ni kipi?</dt>
        <dd>
          Katika VSPORT, kiwango cha chini na cha juu kinatofautiana kwa watumiaji tofauti na
          mechi tofauti. Utaona safu inayokubalika ya dau mahali hapo
          dau la paneli.
        </dd>
        <dt>Je, ninawekaje pesa kwenye Akaunti yangu ya VSPORT?</dt>
        <dd>
          Tunatoa mbinu tofauti za kuweka pesa kwenye akaunti yako
          VSPORT. Unaweza kuwasiliana na Huduma yetu kwa Wateja kwa usaidizi zaidi.
        </dd>
        <dt>Je, ninaweza kughairi dau langu?</dt>
        <dd>
          Huwezi kughairi dau mara tu ikiwa imekubaliwa na mfumo. Kama wewe
          shuku tatizo lolote, tafadhali wasiliana na huduma kwa wateja wetu mara moja.
        </dd>
        <dt>Je kama siwezi kuingia?</dt>
        <dd>
          Ikiwa una hakika kuwa huwezi kuingia kwa kutumia jina la mtumiaji sahihi
          na nenosiri, akaunti yako inaweza kusimamishwa kwa sababu mbalimbali.
          Tafadhali wasiliana na Huduma yetu kwa Wateja wa VSPORT ili kutatua suala hilo.
        </dd>
        <dt>Je kama nilisahau nenosiri langu?</dt>
        <dd>
          Ukisahau jina lako la mtumiaji au nenosiri, tafadhali wasiliana na VSPORT yetu
          Huduma kwa Wateja kwa usaidizi.
        </dd>
        <dt>Je, ninaweza kuruhusu mtu mwingine kuweka dau na akaunti yangu?</dt>
        <dd>
          Kwa usalama wa akaunti yako na maelezo ya kibinafsi, tunapendekeza
          kwamba HUTOFULISHI Jina lako la Mtumiaji au Nenosiri kwa mtu mwingine yeyote
          mtu.
        </dd>
        <dt>Je, maelezo yangu ya kibinafsi yanalindwa kwa usalama?</dt>
        <dd>
          Hakika, maelezo yako yote ya kibinafsi yanalindwa kwa usalama na madhubuti
          usiri ambao kwa mujibu wa Sera yetu ya Faragha.
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {},
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  font-family: 'Montserrat';
  /* height: 823px; */
}
* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-top: 0px;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/faq.jpg');
  background-size: cover;
  background-position: center;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: white;
  font-size: 15px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: justify;
  padding: 30px;
  border-top: 1px solid #cbd8e7;
  border-bottom: 1px solid #cbd8e7;
  overflow-y: auto;
  max-height: 600px;
}

.faq {
  counter-reset: my-badass-counter;
}

.faq dt {
  color: white;
  position: relative;
  font-size: 18px;
  padding: 4px 0 5px 0;
  text-align: left;
}

.faq dt:before {
  color: white;
  content: counter(my-badass-counter);
  counter-increment: my-badass-counter;
  position: absolute;
  left: 0;
  top: 0;
  font: bold 50px/1 Sans-Serif;
}

.faq dd {
  margin: 0 0 30px 0;
}

.faq dt,
.faq dd {
  padding-left: 70px;
}
</style>
