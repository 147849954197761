<template>
  <div class="card left-card">
      <div class="card-body">
        <div class="col-12 p-0">
          <div class="row pb-4" v-for="f in faqList" :key="f.seq">
            <div>
              <div class="icn-round d-flex justify-content-center text-center">
                {{ f.seq }}.
              </div>
            </div>
            <div class="col-11">
              <div class="row">
                <span class="title"> {{ f.title }}</span>
              </div>
              <div class="row">
                <span class="desc">
                  {{ f.desc }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.setDataList();
  },
  data() {
    return {
      fullList: [
        {
          langId: '0', //english
          item: [
            {
              seq: '1',
              title: 'How can I place a bet?',
              desc:
                'You can ONLY place a bet with us via our website on the internet and in accordance with our Rules & Regulation',
            },
            {
              seq: '2',
              title: 'Can I cancel my bets?',
              desc:
                'No. Once we have accepted your bet, it cannot be cancelled and will be settled according to the Rules & Regulations governing our website. It is important that you are familiar with these rules.',
            },
            {
              seq: '3',
              title: 'Are my personal details safe and kept confidential with vsport.com?',
              desc:
                'Yes. All your personal details and the information you enter in the site will be kept in the strictest confidence.',
            },
            {
              seq: '4',
              title: 'What do I do if I have forgotten my User ID or Password?',
              desc:
                'Please contact our dedicated customer services team immediately either by Email, Telephone or Fax.',
            },
            {
              seq: '5',
              title: 'What if there are changes to my personal details?',
              desc:
                'Please contact our customer services team and update them on the changes immediately.',
            },
          ],
        },
{
          langId: '1', //chinese
          item: [
            {
              seq: '1',
              title: 'How can I place a bet?',
              desc:
                'You can ONLY place a bet with us via our website on the internet and in accordance with our Rules & Regulation',
            },
            {
              seq: '2',
              title: 'Can I cancel my bets?',
              desc:
                'No. Once we have accepted your bet, it cannot be cancelled and will be settled according to the Rules & Regulations governing our website. It is important that you are familiar with these rules.',
            },
            {
              seq: '3',
              title: 'Are my personal details safe and kept confidential with vsport.com?',
              desc:
                'Yes. All your personal details and the information you enter in the site will be kept in the strictest confidence.',
            },
            {
              seq: '4',
              title: 'What do I do if I have forgotten my User ID or Password?',
              desc:
                'Please contact our dedicated customer services team immediately either by Email, Telephone or Fax.',
            },
            {
              seq: '5',
              title: 'What if there are changes to my personal details?',
              desc:
                'Please contact our customer services team and update them on the changes immediately.',
            },
          ],
        },
        {
          langId: '2', //thai
          item: [
            {
              seq: '1',
              title: 'ฉันจะวางเดิมพันได้อย่างไร ?',
              desc:
                'คุณสามารถวางเดิมพันกับเราผ่านทางเวบไวค์โดยระบบอินเตอร์เนตอย่างเดียวเท่านั้นตามที่ระบุไว้ในกฎกติกา',
            },
            {
              seq: '2',
              title: 'ฉันสามารถยกเลิกการเดิมพันได้หรือไม่ ?',
              desc:
                'เมื่อเราได้ยอมรับรายการเดิมพันของคุณแล้ว คุณไม่สามารถยกเลิกได้ กติกานี้ได้ถูกโพสต์ไว้ในกฎและกติกาของเวบไซค์เรียบร้อยแล้ว เป็นหน้าที่ของลูกค้าที่จะต้องทำความคุ้นเคยกับกติกาเหล่านี้',
            },
            {
              seq: '3',
              title:
                'ข้อมูลส่วนตัวของฉันปลอดภัยหรือเปล่าเมื่อเล่นกับเวบ vsport.com ?',
              desc:
                'ใช่คะ ข้อมูลส่วนตัวของคุณที่ให้ไว้กับทางเวบไซค์จะถูกเก็บไว้อย่างเป็นความลับคะ',
            },
            {
              seq: '4',
              title: 'ฉันจะทำอย่างไรถ้าฉันลืมยูสเซอร์เนม หรือ พาสเวริ์ด ?',
              desc:
                'กรุณาติดต่อฝ่ายบริการลูกค้าของเราโดยด่วนทาง อีเมลล์ โทรศัพท์ หรือ แฟกซ์',
            },
            {
              seq: '5',
              title: 'ถ้าฉันต้องการเปลี่ยนแปลงข้อมูลส่วนตัวของฉัน ?',
              desc:
                'กรุณาติดต่อฝ่ายบริการลูกค้าเพื่ออัพเดทข้อมูลปัจจุบันที่ต้องการเปลี่ยนแปลงโดยด่วน',
            },
          ],
        },
        {
          langId: '3', //Vietnamese
          item: [
            {
              seq: '1',
              title: 'Làm thế nào tôi có thể đặt cược?',
              desc:
                'Bạn CHỈ có thể đặt cược với chúng tôi thông qua trang web của chúng tôi trên internet và tuân theo Quy tắc & Quy định của chúng tôi',
            },
            {
              seq: '2',
              title: 'Tôi có thể hủy cược của mình không?',
              desc:
                'Không. Khi chúng tôi đã chấp nhận đặt cược của bạn, nó không thể bị hủy bỏ và sẽ được giải quyết theo các Quy tắc & Quy định quản lý trang web của chúng tôi. Điều quan trọng là bạn phải quen thuộc với các quy tắc này.',
            },
            {
              seq: '3',
              title: 'Các chi tiết cá nhân của tôi có được an toàn và giữ bí mật với vsport.com không?',
              desc:
                'Vâng. Tất cả các chi tiết cá nhân của bạn và thông tin bạn nhập vào trang web sẽ được bảo mật một cách nghiêm ngặt nhất.',
            },
            {
              seq: '4',
              title: 'Tôi phải làm gì nếu quên ID người dùng hoặc mật khẩu của mình?',
              desc:
                'Vui lòng liên hệ ngay với nhóm dịch vụ khách hàng tận tâm của chúng tôi qua Email, Điện thoại hoặc Fax.',
            },
            {
              seq: '5',
              title: 'Điều gì sẽ xảy ra nếu có những thay đổi đối với chi tiết cá nhân của tôi?',
              desc:
                'Vui lòng liên hệ với nhóm dịch vụ khách hàng của chúng tôi và cập nhật họ về những thay đổi ngay lập tức.',
            },
          ],
        },
        {
          langId: '4', //english
          item: [
            {
              seq: '1',
              title: 'Ninawezaje kuweka dau?',
              desc:
                'Unaweza TU kuweka dau nasi kupitia tovuti yetu kwenye mtandao na kwa mujibu wa Sheria na Kanuni zetu',
            },
            {
              seq: '2',
              title: 'Je, ninaweza kughairi dau zangu?',
              desc:
                'Hapana. Tukishakubali dau lako, haliwezi kughairiwa na litatatuliwa kwa mujibu wa Sheria na Kanuni zinazosimamia tovuti yetu. Ni muhimu kuwa unazifahamu sheria hizi.',
            },
            {
              seq: '3',
              title: 'Je, maelezo yangu ya kibinafsi ni salama na yanahifadhiwa kwa siri na vsport.com?',
              desc:
                'Ndiyo. Maelezo yako yote ya kibinafsi na maelezo utakayoingiza kwenye tovuti yatawekwa kwa usiri mkubwa.',
            },
            {
              seq: '4',
              title: 'Nitafanya nini ikiwa nimesahau Kitambulisho changu cha Mtumiaji au Nenosiri?',
              desc:
                'Tafadhali wasiliana na timu yetu iliyojitolea ya huduma kwa wateja mara moja ama kwa Barua pepe, Simu au Faksi.',
            },
            {
              seq: '5',
              title: 'Je ikiwa kuna mabadiliko kwa maelezo yangu ya kibinafsi?',
              desc:
                'Tafadhali wasiliana na timu yetu ya huduma kwa wateja na uwasasishe kuhusu mabadiliko mara moja.',
            },
          ],
        }
      ],
      faqList: null,
    };
  },
  methods: {
    setDataList() {
      const tempList = this.fullList.find(
        (x) => x.langId.toString() == this.$store.getters.langId.toString()
      );
      if (tempList == undefined) {
        this.faqList = this.fullList[0].item;
      } else {
        this.faqList = tempList.item;
      }
    },
  },
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
    // eslint-disable-next-line
    langId(newCount, oldCount) {
      this.setDataList();
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  position: relative;
}
.card-body {
  padding: 1.85rem;
}
.left-card {
  border-radius: 5px;
  /* box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important; */
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #fff;
}

.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 0.85;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.icn-round {

  width: 30px;
  height: 30px;
  font-weight: bold;
  letter-spacing: 0.24px;
  text-align: left;
  color: #e9a0ff;
  white-space: nowrap;
}

.title {
  font-weight: 600;
  letter-spacing: 0.24px;
  text-align: left;
  color: #e9a0ff;
}
.desc {
  letter-spacing: 0.18px;
  text-align: left;
}
</style>
