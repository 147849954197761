<template>
  <section>
    <loading-spinner v-if="isLoading"></loading-spinner>
    <div class="flex">
      <div
        id="divStatement_LW"
        class="flex-item blue-border"
        :class="{ active: isSelectLastWeek }"
        @click="onClickLastWeek('last')"
      >
        {{ $t("LastWeek") }}
      </div>
      <div
        id="divStatement_TW"
        class="flex-item blue-border"
        :class="{ active: isSelectThisWeek }"
        @click="onClickThisWeek('this')"
      >
        {{ $t("ThisWeek") }}
      </div>
    </div>
    <!--- statement card--->
    <div v-if="showProgress">
      <div class="col-lg-12 p-1">
        <div class="card d-flex align-items-center">
          <white-progress-spinner></white-progress-spinner>
        </div>
      </div>
    </div>
    <template v-if="!showProgress">
      <template v-for="(statement, index) in statementList" :key="statement.date">
        <ul class="list-group" style="width: 92%; margin: auto; padding-top: 10px">
          <li class="list-group-item">
            <a
              class="btnCollapse collapsed"
              data-toggle="collapse"
              :href="'#collapse_0' + index"
              role="button"
              aria-expanded="false"
              aria-controls="collapse_0"
            >
              &nbsp;
              <span class="left">
                {{ $t(getWeekDay(statement.date)) }}
                {{ getDateString(statement.date) }}
                <!-- <span v-if="langId == '0'">
                  {{ $t(getWeekDay(statement.date)) }}
                </span> -->
              </span>
            </a>
            <!---multi-collapse container collapse show--->
            <div
              :id="'collapse_0' + index"
              class="multi-collapse container collapse"
              style="width: 95%"
            >
              <div class="row row_header">
                <div class="col blue-border text-center">{{ $t("Stake") }}</div>
                <div class="col blue-border text-center">{{ $t("W/L") }}</div>
                <div class="col blue-border text-center">{{ $t("Comm") }}</div>
                <div class="col blue-border text-center">{{ $t("Settled") }}</div>
              </div>
              <div class="row">
                <div class="col blue-border text-center">
                  <span class="Simple"> {{ $filters.currency(statement.stake) }}</span>
                </div>
                <div class="col blue-border text-center">
                  <span class="Hd9 Simple">
                    {{ $filters.currency(statement.winloseAmt) }}</span
                  >
                </div>
                <div class="col blue-border text-center">
                  <span class="Hd9 Simple"> {{ $filters.currency(statement.comm) }}</span>
                </div>
                <div class="col blue-border text-center">
                  <span class="Simple">
                    {{ $filters.currency(getAbsNumber(statement.settled)) }}</span
                  >
                </div>
              </div>
              <span class="right">
                {{ $t("Balance") }}:
                <span class="Negative"> {{ $filters.currency(statement.balance) }}</span>
              </span>
            </div>
          </li>
        </ul>
      </template>
    </template>
    <div style="height:100px">&nbsp;</div>
  </section>
</template>

<script>
import moment from "moment";
import CustomHttp from "../../../../CustomHttp";

// window.FontAwesomeConfig = {
//   searchPseudoElements: true,
// };
// page component

export default {
  data() {
    return {
      isLoading: false,
      showProgress: false,
      isSelectThisWeek: false,
      isSelectLastWeek: false,
      statementList: [],
    };
  },
  mounted() {
    this.onClickThisWeek();
  },
  methods: {
    getAbsNumber(num) {
      return Math.abs(num);
    },
    getDateString(date) {
      const tempDate = new Date(date);
      return moment(tempDate.getTime()).format("DD/MM/YYYY").toString();
    },
    getWeekDay(date) {
      let weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const tempDate = new Date(date);
      return weekday[tempDate.getDay()];
    },
    onClickThisWeek() {
      this.isSelectThisWeek = true;
      this.isSelectLastWeek = false;
      this.getMemberStatement();
    },
    onClickLastWeek() {
      this.isSelectThisWeek = false;
      this.isSelectLastWeek = true;
      this.getMemberStatement();
    },
    async getMemberStatement() {
      let startDate;
      let endDate;
      startDate = moment().startOf("week").toDate();
      endDate = moment().endOf("week").toDate();
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
      if (this.isSelectLastWeek) {
        startDate.setDate(startDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 7);
      }
      let fromDate = startDate;
      let toDate = endDate;
      const todayDate = new Date();
      if (toDate > todayDate) {
        toDate = new Date();
      }
      //handle sunday error
      if (todayDate.getDay() == 0 && this.isSelectThisWeek) {
        fromDate.setDate(fromDate.getDate() - 7);
        toDate = new Date();
      } else if (todayDate.getDay() == 0 && this.isSelectLastWeek) {
        fromDate.setDate(toDate.getDate() - 13);
        toDate = new Date();
        toDate.setDate(toDate.getDate() - 7);
      }

      const json = {
        username: this.$store.getters.username,
        fromDate: moment(fromDate.getTime()).format("YYYY-MM-DD"),
        toDate: moment(toDate.getTime()).format("YYYY-MM-DD"),
        //fromDate: '2021-10-14',
        // toDate: '2021-10-24',
      };
      this.showProgress = true;
      const response = await CustomHttp.post("api/v1/member/statement", json);
      if (response.data.data == null) {
        this.statementList = [];
      } else {
        this.statementList = response.data.data.memberStatementList;
      }
      this.showProgress = false;
    },
  }, //end methods
  computed: {
    // isTimeout() {
    //   return this.$store.getters.isTimeout;
    // },
    langId() {
      return this.$store.getters.langId;
    },
  }, //end computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-section {
  margin-top: 10px !important;
}
.content-section {
  color: inherit;
}

a.btnCollapse.collapsed:before {
  font-weight: 900;
  font-family: "Font Awesome\ 5 Free";
  content: "\f067";
  color: #ebc76e;
  padding-left: 7px;
}

a.btnCollapse:before {
  font-weight: 900;
  font-family: "Font Awesome\ 5 Free";
  content: "\f068";
  color: #ebc76e;
  padding-left: 7px;
}

.card {
  background-color: inherit !important;
}

.list-group {
  font-size: 13px !important;
}

.blue-border{
  border: 1px solid #0ad5f3;
}
</style>
