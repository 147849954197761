import liveCasino from './live.js';
import slot from './slot.js';
import gameImport from './../game';

import sb from './../../images/game2/hot/sb.png';
import sbmb from './../../images/game2/hot/sb-mobile.png';
import sbNew from './../../images/game2/hot/sb.png';
import sbNewTh from './../../images/game2/hot/sb.png';
import svpower from './../../images/game2/fishing/img-vpower-s.png';
import vpower from './../../images/game2/slot/img-vpower.png';

// import cards from './cards.js';
// import sb from './sportsbook.js';
var gameMenu = {
  name: 'Hot Games',
  items: [
    //   sb.items.find((x) => x.name.toUpperCase() === 'SPORTSBOOK'),
    {
      name: 'SPORTSBOOK2_HOT',
      isFullWidth: true,
      provider: '',
      icon: sbmb,
      code: '1',
      img: [
        { langId: 0, src: sbmb },
        { langId: 2, src: sbmb },
      ],
      img2: [
        { langId: 0, src: sbmb },
        { langId: 2, src: sbmb },
      ],
      isNew: true,
      labelName: '',
      parentName: '',
    },
    {
      name: 'SPORTSBOOK',
      isFullWidth: true,
      provider: '',
      icon: sb,
      code: '2',
      img: [
        { langId: 0, src: sbNew },
        { langId: 2, src: sbNewTh },
      ],
      img2: [
        { langId: 0, src: sbNew },
        { langId: 2, src: sbNewTh },
      ],
      isNew: true,
      labelName: '',
      parentName: '',
    },
    liveCasino.items.find((x) => x.code === '39-153'),
    liveCasino.items.find((x) => x.code == '39-101'),
    {
      name: 'VPOWER',
      provider: 'UFA Fishing',
      isFullWidth: false,
      icon: gameImport.find((x) => x.code.toString() === '72').icon,
      code: '72',
      img: [{ langId: 0, src: svpower }],
      img2: [{ langId: 0, src: svpower }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    slot.items.find((x) => x.code === '35'),
    slot.items.find((x) => x.code === '80-201'),
    {
      name: 'VPOWER2',
      provider: 'Vpower',
      icon: gameImport.find((x) => x.code.toString() === '72').icon,
      code: '72-101',
      img: [{ langId: 0, src: vpower }],
      img2: [{ langId: 0, src: vpower }],
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
