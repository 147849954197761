<template>
  <footer class="col-12 desktop-footer">
    <div class="container">
      <div class="row">
        <div class="col">
            <div>
              <div class="m-0 text-left text-white">
                <img
                  class="footer-logo"
                  alt="logo"
                  src="../../../assets/images/logo-footer.png"
                />
              </div>
            </div>
            <div class="text-dark-grey nowrap">
              <small>
                <span class="footer-text">
                  &nbsp; © Copyright 2023 V-Sport. All Rights Reserved.
                </span>
              </small>
            </div>
        </div>
        <div class="col-flex text-white pt-3 text-right">
          <small>
            <router-link to="/about">
              <span class="footer-menu-text cursor-pointer px-1">
                <a> {{ $t('AboutUs') }} </a>
              </span>
            </router-link>
            |
            <router-link to="/rules">
              <span class="footer-menu-text cursor-pointer px-1">
                {{ $t('BettingRules') }}
              </span>
            </router-link>
            |
            <router-link to="/faq">
              <span class="footer-menu-text cursor-pointer px-1">
                {{ $t('FAQ') }}
              </span>
            </router-link>
            |
            <router-link to="/why-us">
              <span class="footer-menu-text cursor-pointer px-1">
                {{ $t('WhyUs') }}
              </span>
            </router-link>
            |
            <router-link to="/contact">
              <span class="footer-menu-text cursor-pointer px-1">
                {{ $t('ContactUs') }}
              </span>
            </router-link>
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="css" src="../../../assets/css/desktop.css"></style>
