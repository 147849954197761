<template>
  <div class="table-wrapper">
    <table class="iframe-table">
      <tbody>
        <tr>
          <td>
            <a name="top" id="top"></a>
            <span style="color: Red;"
              >Attn: We are reserves the right to refuse/reject and suspend
              without prior notification any Customer who is suspected of
              cheating, hacking, attacking, manipulating or damaging the
              Operator's normal betting operations (including the Internet
              Betting Service). Any of the "abnormal bets" will be VOID without
              prior notification. The use of artificial intelligence or "bots"
              on the Internet Betting Service is strictly forbidden. We will
              take measures to prevent and detect the use of programs that are
              designed to enable artificial intelligence (non-humans) to utilize
              the Website. Any attempted or actual use of artificial
              intelligence by the Customer will lead to termination of their
              Account or Voiding the bets, at the discretion of us. Our company
              has the right to refrain from any relevant claims liability.</span
            >
            <div id="path">
              <span class="text_11_GR">Trang Chủ &gt; </span> Điều Khoản và Điều
              Kiện<br />
            </div>
            <div class="title_bg">&nbsp;&nbsp;Điều Khoản và Điều Kiện</div>
            <div class="information_text termsList">
              <p>
                <strong
                  >IMPORTANT: PLEASE READ THE INFORMATION SET OUT BELOW
                  CAREFULLY BEFORE ACCEPTING THESE TERMS AND CONDITIONS, THEN
                  PRINT AND STORE THESE TERMS AND CONDITIONS ALONG WITH ALL
                  TRANSACTION DATA, RULES AND REGULATIONS AND PAYMENT METHODS
                  RELEVANT TO YOUR USE OF THIS WEBSITE.</strong
                >
              </p>
              <h1>Introduction</h1>
              <p>
                www.vsport.com, (the “Company”) operates an online sports
                betting and gaming Website, regulated and licensed in compliance
                with the laws and regulations of the Philippines to conduct
                Remote Gambling. The Company is the operator of this Website
                ‘www.vsport.com ’ through which the Company provides its
                licensed Services.<br />
                <br />
                Notice and acceptance of these terms and conditions constitutes
                the making of an agreement, which you accept by selecting the
                “Agree” on this page and proceeding to access the Website. When
                you do so, a legally binding agreement in accordance with these
                terms and conditions is concluded between (a) you, the end user
                of the Website (the “Customer”), and (b) the Company.
              </p>
              <h1>General terms and conditions</h1>
              <p>
                The following are the terms and conditions governing the use of
                the Website. All Customer activities on the Website are subject
                to, and governed by these Terms and Conditions.
              </p>
              <h1>1. Definitions</h1>
              <!-- 1.1 -->
              <h2>
                <strong>1.1.</strong
                ><span
                  >The following terms shall have the following meanings:</span
                >
              </h2>
              <h3>
                <strong>1.1.1.</strong
                ><span
                  >Betting Information: Event names, locations, statistics,
                  scores, dates and/or times; Event-related information; team or
                  player names, statistics and related information thereto;
                  odds; prices; handicaps; bet types; stake; information on a
                  particular bet, bet type, or Event related to a wager or
                  bet;</span
                >
              </h3>
              <h3>
                <strong>1.1.2.</strong
                ><span
                  >Company Services: The betting, wagering and gaming services
                  through remote gambling devices available through the Website
                  and by the Company;</span
                >
              </h3>
              <h3>
                <strong>1.1.3.</strong
                ><span
                  >Documentation: These Terms and Conditions, the Privacy
                  Policy, the Betting Rules and Regulations;</span
                >
              </h3>
              <h3>
                <strong>1.1.4.</strong
                ><span
                  >Event: the event, incident, occurrence or happening on which
                  the Company is allowing betting through the Website and the
                  Company Services;</span
                >
              </h3>
              <h3>
                <strong>1.1.5.</strong
                ><span
                  >Illegal: shall include but not be limited to attempts to
                  circumvent the Documentation; betting limits; win limits;
                  hacking; unauthorized use or misuse of the Customer Account or
                  Login ID; attempts to circumvent or bypass any security
                  mechanisms available on the Website or the Company’s systems
                  or networks; wrongfully, attempt to transfer or transfer funds
                  from a third person’s Customer Account; any act or omission
                  through use of the Website of the Company Services cause any
                  harm to the Company or to any third person; provision of false
                  Customer ID; collusion, acting in concert or syndicate to
                  defraud the Company and/or, "chip dumping"; any actions and/or
                  omissions contrary to the law applicable to these Terms and
                  Conditions; and, any actions and/or omissions which the
                  Company reasonably deems to be a fraudulent and/or
                  wrongdoings;</span
                >
              </h3>
              <h3>
                <strong>1.1.6.</strong
                ><span
                  >Market: the bet types related to, or possibilities and/or
                  options to place bets on, a particular Event as offered by the
                  Company through the Website;</span
                >
              </h3>
              <h3>
                <strong>1.1.7.</strong
                ><span
                  >Personal Information: Shall have the same meaning as in the
                  Privacy Policy in addition to the Customer ID (as defined
                  herein);</span
                >
              </h3>
              <h3>
                <strong>1.1.8.</strong
                ><span
                  >Privacy Policy: The Company’s policy on privacy and
                  information handling with respect to Customers, the Website
                  and the Company Services, published by the Company from time
                  to time available at the Website;</span
                >
              </h3>
              <h3>
                <strong>1.1.9.</strong
                ><span
                  >Prohibited Jurisdictions: The United States of America; Hong
                  Kong Special Administrative Region of the People’s Republic of
                  China; Taiwan; Vietnam; Republic of Singapore, United Kingdom,
                  Australia and Republic of the Philippines.</span
                >
              </h3>
              <h3>
                <strong>1.1.10.</strong
                ><span
                  >Betting Rules and Regulations: The rules for betting on the
                  Website and using the Company Services, published by the
                  Company from time to time available in the Website;</span
                >
              </h3>
              <h3>
                <strong>1.1.11.</strong
                ><span
                  >Terms and Conditions: This document, the provisions included
                  herein and all documents referenced herein available in the
                  Website;</span
                >
              </h3>
              <h3>
                <strong>1.1.12.</strong
                ><span
                  >Website: This website located at the URL
                  www.vsport.com;</span
                >
              </h3>
              <!-- 1.2 -->
              <h1>2. Interpretation</h1>
              <h2>
                <strong>2.1.</strong
                ><span>
                  Unless otherwise specifically specified in writing, any
                  reference herein to:</span
                >
              </h2>
              <h3>
                <strong>2.1.1. </strong
                ><span
                  >a statute, enactment, or statutory provision includes a
                  reference to the statute, enactment, or statutory provision as
                  modified or re-enacted or both from time to time, and to any
                  subordinate legislation made under it;</span
                >
              </h3>
              <h3>
                <strong>2.1.2 </strong
                ><span
                  >a document is a reference to the document as from time to
                  time supplemented or varied;</span
                >
              </h3>
              <h3>
                <strong>2.1.3.</strong
                ><span
                  >the singular includes the plural and vice versa and the
                  masculine includes the feminine and the neuter genders and
                  vice versa;</span
                >
              </h3>
              <h3>
                <strong>2.1.4. </strong
                ><span>
                  a person includes natural persons, firms, partnerships,
                  companies, corporations, associations, organisations,
                  governments, states, governmental or state agencies,
                  foundations and trusts (in each case, whether or not having
                  separate legal personality);</span
                >
              </h3>
              <h3>
                <strong>2.1.5. </strong
                ><span>
                  years, months and weeks, are to calendar years, months and
                  weeks respectively; days are to Business days; and, times of
                  the day are to Greenwich Mean Time (GMT) plus eight hours,
                  where “Business Hours” are eight (8) hours to sixteen (16)
                  hours on a Business Day; and</span
                >
              </h3>
              <h3>
                <strong>2.1.6. </strong
                ><span
                  >writing includes fax transmission, but excludes email, text
                  messages (SMS) and other similar means of communication.</span
                >
              </h3>
              <h2>
                <strong>2.2.</strong
                ><span>
                  The Privacy Policy, Terms of Use, and Betting Rules and
                  Regulations form part of these Terms and Conditions. Reference
                  to these Terms and Conditions include the aforementioned
                  Documentation.</span
                >
              </h2>
              <h2>
                <strong>2.3</strong>.
                <span
                  >The headings and abbreviations used in the Documentation are
                  inserted for convenience only and shall not affect the
                  interpretation of the Documentation.</span
                >
              </h2>
              <h2>
                <strong>2.4.</strong
                ><span>
                  In the Documentation, any phrase introduced by the words
                  include, including, includes and such as are to be construed
                  as illustrative and shall not limit the sense of the words
                  preceding those words.</span
                >
              </h2>
              <h2>
                <strong>2.5.</strong
                ><span
                  >The Documentation has been originally written in the English
                  language. If the Documentation is translated into any other
                  language, the English version will prevail. If there is any
                  inconsistency between the Documentation any Supplementary
                  Documentation or any other document incorporated by reference,
                  these Terms and Conditions will at all times prevail.</span
                >
              </h2>
              <h1>3. Applicability and Acceptance</h1>
              <h2>
                <strong>3.1.</strong
                ><span
                  >The Customer is deemed to have accepted the Documentation by
                  selecting the “Agree” button on this page and proceeding to
                  access the Website or otherwise by opening an account with the
                  Company or using the Company Services. By agreeing to the
                  Documentation and/or because of the Customer’s use of the
                  Website or Company Services, the Customer is bound by the
                  Documentation.</span
                >
              </h2>
              <h2>
                <strong>3.2.</strong
                ><span
                  >The Company reserves the right to amend the Documentation as
                  it sees fit from time to time and at anytime without prior
                  notice to the Customer. However, the Company will make
                  reasonable efforts so that a prominent notice on the Website
                  displays any significant changes to the Documentation. The
                  most updated version of the Documentation may be found at the
                  Website.</span
                >
              </h2>
              <h2>
                <strong>3.3.</strong
                ><span
                  >It remains the Customer’s responsibility to periodically
                  check the Documentation on the Website every time the Website
                  is accessed to ensure that the Customer continues to agree
                  with it. The Customer’s continued use of the Website and/or
                  the Company Services will be deemed to be an unconditional and
                  irrevocable acceptance of the Documentation and any changes
                  made to it. Any bets received, accepted, recorded and settled
                  shall be governed by the existing Documentation published on
                  the Website and any changes thereto at the time when the bet
                  was received, accepted, recorded and settled by the
                  Company.</span
                >
              </h2>
              <h1>4. Customer Identification and Account</h1>
              <h2>
                <strong>4.1 </strong
                ><span
                  >To use the Company Services and the Website a person must
                  first open an account with the Company (“Customer Account”) by
                  providing at least the following true and accurate
                  information, (“Customer ID”) as determined by the Company in
                  its sole and absolute discretion from time to time and at
                  anytime:</span
                >
              </h2>
              <h3>
                <strong>4.1.1. </strong
                ><span
                  >Complete legal name as it appears in a government issued
                  identification document;</span
                >
              </h3>
              <h3>
                <strong>4.1.2. </strong
                ><span>
                  Determination of a government issued identification document
                  including its number;</span
                >
              </h3>
              <h3>
                <strong>4.1.3. </strong
                ><span>Complete and current usual residential address;</span>
              </h3>
              <h3><strong>4.1.4. </strong><span>Nationality;</span></h3>
              <h3><strong>4.1.5.</strong><span> Date of birth;</span></h3>
              <h3>
                <strong>4.1.6. </strong
                ><span>Current and valid electronic mail address;</span>
              </h3>
              <h3>
                <strong>4.1.7. </strong
                ><span>Current and valid phone number.</span>
              </h3>
              <h2>
                <strong>4.2 </strong
                ><span>
                  The Customer acknowledges having read and agreed with the
                  Privacy Policy and further agrees that:</span
                >
              </h2>
              <h3>
                <strong>4.2.1. </strong
                ><span
                  >consent to the processing and use of Personal Information, in
                  accordance with the Privacy Policy, is hereby granted;</span
                >
              </h3>
              <h3>
                <strong>4.2.2. </strong
                ><span>
                  the Company may disclose the Customer’s Personal Information
                  to any third persons without the Customer’s prior written
                  consent, in the event of a lawful court or governmental order
                  requesting such information; in the course of a money
                  laundering investigation by the Company or any third person;
                  and, when there is reasonable suspicion by the Company that
                  the Customer Account or the Customer are involved in an
                  Illegal transaction, even if unrelated to the Company;
                  and</span
                >
              </h3>
              <h3>
                <strong>4.2.3. </strong
                ><span>
                  the Company may process, use, transfer and dispose of the
                  Customer’s Personal Information worldwide in any way the
                  Company chooses.
                </span>
              </h3>
              <h2>
                <strong>4.3.</strong
                ><span
                  >Only upon, accepting the Documentation, opening a Customer
                  Account and using the Company Services, is a person deemed to
                  be a Customer of the Company. The Company reserves the right
                  of accepting or rejecting a Customer for any reason whatsoever
                  and at anytime. Any activity or transactions by a person not
                  deemed a Customer is considered null and void.</span
                >
              </h2>
              <h2>
                <strong>4.4.</strong
                ><span>
                  It is the Customer's responsibility to ensure that the
                  Customer ID is kept up-to-date with the Company. The Customer
                  must provide truthful information when opening or updating a
                  Customer Account, failure to do so will result in a breach of
                  these Terms and Conditions and immediate closure of the
                  Customer Account forfeiting all funds.</span
                >
              </h2>
              <h2>
                <strong>4.5.</strong
                ><span
                  >Upon a request by the Company the Customer agrees to
                  immediately provide the Company with any documentation to
                  verify the Customer ID information.</span
                >
              </h2>
              <h2>
                <strong>4.6.</strong
                ><span
                  >The Customer authorises the Company to use any lawful means
                  that it considers necessary to verify the Customer ID
                  information</span
                >.
              </h2>
              <h2>
                <strong>4.7.</strong
                ><span
                  >Upon provision by the Customer of the Customer ID Information
                  as set out in 4.1 herein, the Company will issue a Username
                  and Password (collectively “Login ID”), for which the
                  following terms and conditions shall apply:</span
                >
              </h2>
              <h3>
                <strong>4.7.1. </strong
                ><span
                  >Only the Company may issue a Username; it may not be modified
                  or changed at anytime;</span
                >
              </h3>
              <h3>
                <strong>4.7.2. </strong
                ><span
                  >The Login ID is personal to the Customer and may only be used
                  by the Customer. Safeguarding the secrecy or confidentiality
                  of the Login ID is the responsibility of the Customer. The
                  Customer shall be fully responsible for any activity and
                  transactions on the Website carried out with its Login ID as
                  well for any misuse by, and/or unauthorised disclosure of the
                  Login ID to, any person. The Customer must continuously change
                  the Password of the Login ID at least once a month to minimize
                  possibility of actual or suspected misuse, breach or
                  compromise of the Login ID.</span
                >
              </h3>
              <h3>
                <strong>4.7.3. </strong
                ><span>
                  The Customer must immediately notify the Company in writing of
                  any actual or suspected misuse, breach or compromise of the
                  Login ID. Until the Company has received said notice the
                  Customer shall continue to be responsible for all activity and
                  transactions carried out with the Customer’s Login ID. Upon
                  receipt of said notice, the Company shall forthwith suspend
                  the Customer Account. The Company will not be held responsible
                  or liable in any way whatsoever for any reasonable delay in
                  such suspension of the Customer Account. For the avoidance of
                  doubt, until said notice is received by the Company and the
                  Customer Account is suspended activity and transactions
                  through the Customer Account shall continue to be considered
                  valid; only after the receipt of said notice and the Customer
                  Account being suspended will activity and transactions through
                  the Customer Account shall considered to be void;</span
                >
              </h3>
              <h3>
                <strong>4.7.4. </strong
                ><span>
                  If the Company has reason to believe that there is likely to
                  be a breach of security or misuse of the Website or any actual
                  or suspected misuse, breach or compromise of Customer’s Login
                  ID, the Company at its sole and absolute discretion may
                  suspend the Customer Account and/or change the Password of the
                  Login ID notifying the Customer after the fact. The aforesaid
                  as a preventive measure only, without importing any liability
                  or obligation whatsoever for the Company should it choose not
                  to exercise this possibility.</span
                >
              </h3>
              <h3>
                <strong>4.7.5. </strong
                ><span>
                  The Company may perform random security checks on Customer
                  Accounts at any time or from time to time. The Customer hereby
                  accepts that the Company maintains the right to carry such
                  checks and demand additional information and/or documentation
                  from the Customer.</span
                >
              </h3>
              <h3>
                <strong>4.7.6. </strong
                ><span>
                  The Company reserves the right at the Company’s reasonable
                  sole and absolute discretion, without further explanation to
                  the Customer, with respect to a Customer Account:</span
                >
              </h3>
              <h4>
                <strong>4.7.6.1. </strong
                ><span>
                  to suspend it, if the Company has a reasonable belief that
                  continued use of the Customer Account would create unexpected
                  damage or loss of any kind to the Customer or to the
                  Company;</span
                >
              </h4>
              <h4>
                <strong>4.7.6.2. </strong
                ><span
                  >to suspend it, if during the investigation of a breach of the
                  Documentation;</span
                >
              </h4>
              <h4>
                <strong>4.7.6.3. </strong
                ><span>
                  to close it, if the Customer commits any breach of the
                  Documentation, as determined by the Company in the Company’s
                  sole and absolute discretion. In which case the Company
                  reserves all its rights and remedies against the Customer
                  under the Documentation and at law and reserves the right to
                  retain any funds from the Customer as a guarantee of the
                  Customer’s obligations hereunder and/or any liability arising
                  from the said breach of the Documentation by the
                  Customer;</span
                >
              </h4>
              <h4>
                <strong>4.7.6.4.</strong
                ><span>
                  to close it, if there is reasonable suspicion by the Company
                  of an Illegal transaction and/or the Customer being involved
                  in Illegal activities, even if unrelated to the Company;</span
                >
              </h4>
              <h4>
                <strong>4.7.6.5. </strong
                ><span>
                  to close it, if a Customer Account is inactive for three (3)
                  months consecutive or longer, in which case the Customer
                  agrees to forfeit any funds and all claims against the
                  Company;</span
                >
              </h4>
              <h4>
                <strong>4.7.6.6.</strong
                ><span>
                  to close it, if any Customer, person or Customers or group of
                  persons acting in concert or as a syndicate to collude and/or
                  defraud the Company;</span
                >
              </h4>
              <h4>
                <strong>4.7.6.7</strong
                ><span>
                  to close it or suspend it (at the Company’s choosing), at any
                  time with reason or for no reason. In this event, however,
                  outstanding transactions will be honoured (provided always
                  these transactions are in accordance with the Documentation)
                  and any funds due to the Customer will be refunded.</span
                >
              </h4>
              <h3>
                <strong>4.7.7. </strong
                ><span>
                  When a Customer Account is suspended, the Company reserves the
                  right without any liability of any kind for the Company
                  (including interest, loss of savings or contract) to freeze
                  any funds payable to the Customer; stop the use of the
                  Customer Account and any transactions associated with it; void
                  any and all bets pending to be accepted; use any information
                  available to the Company and any means of investigation the
                  Company elects (including contacting third persons, Government
                  offices and/or requesting documentation and information from
                  the Customer) to conduct an inquiry into the incident that
                  caused the suspension; all to which the Customer hereby
                  consents and agrees to fully cooperate. Lack of cooperation by
                  the Customer shall result in the immediate closure of the
                  Customer Account.</span
                >
              </h3>
              <h3>
                <strong>4.7.8. </strong
                ><span>
                  When a Customer Account is closed, the Company reserves the
                  right without any liability of any kind for the Company
                  (including interest, loss of savings or contract) to withhold
                  any funds payable to the Customer as guarantee of the
                  Customer’s obligations or liabilities hereunder or as suspect
                  of an Illegal activity; stop the use of the Customer Account
                  and any Transactions associated with it; void any and all bets
                  pending to be accepted; use any information available to the
                  Company and any means of investigation the Company elects
                  (including contacting third persons, Government offices and/or
                  requesting documentation and information from the Customer) to
                  conduct an inquiry into the incident that caused the closure,
                  to which the Customer hereby consents and agrees to fully
                  cooperate.</span
                >
              </h3>
              <h3>
                <strong>4.7.9. </strong
                ><span>
                  The Customer may close the Customer Account with the Company
                  at any time by notifying the Company in writing. Upon
                  submission of said notice the Customer must stop using the
                  Website immediately. Only after notification by the Company to
                  the Customer that their Customer Account has been closed, will
                  transactions made with the Customer Account will be deemed
                  void and the Customer Account considered closed. Customer will
                  continue to be liable for any activity and transaction until
                  the Customer Account is closed. Upon closure of the Customer
                  Account the Company will refund (if applicable) any funds due
                  to the Customer.
                </span>
              </h3>
              <h3>
                <strong>4.7.10. </strong
                ><span>
                  It is the Customer’s responsibility to actively maintain the
                  Customer Account. A Customer Account is deemed active if the
                  Customer has logged in and utilized the Company’s Services
                  using the Customer Account in any one (1) month period,
                  otherwise the Customer Account is deemed inactive.</span
                >
              </h3>
              <h2>
                <strong>4.8. </strong
                ><span
                  >A person may open or hold only one Customer Account. Any
                  additional Customer Accounts that are opened will be closed by
                  the Company and (if applicable) any funds due to the Customer
                  refunded or all such accounts may be treated as one joint
                  account and merged together by the Company, in either case at
                  the Company’s sole and absolute discretion. The Customer
                  agrees that the Company shall use any method it deems
                  reasonable to determine if two or more Customer Accounts
                  belong to the same Customer.</span
                >
              </h2>
              <h2>
                <strong>4.9. </strong
                ><span
                  >The Company may issue temporary Customer Accounts for
                  marketing or demonstration purposes. These accounts will
                  elapse in a predetermined time period not exceeding thirty
                  (30) days. After such period the temporary Customer Account
                  will either be closed by the Company or the Customer must meet
                  the criteria for, and open, a regular Customer Account.</span
                >
              </h2>
              <h1>5. Preliminary Customer Representations</h1>
              <h2>
                <strong>5.1.</strong
                ><span>
                  In addition to any other representation or warranty included
                  in the Documentation, the Customer irrevocably and
                  unconditionally accepts, represents and warrants and is bound
                  without reservation or limitation (as the case may be) that
                  the Customer:</span
                >
              </h2>
              <h3>
                <strong>5.1.1.</strong
                ><span>
                  is over the age of 18, or the age of consent, or age of
                  majority in the Customer’s jurisdiction of usual residence or
                  current location, whichever is higher;</span
                >
              </h3>
              <h3>
                <strong>5.1.2.</strong
                ><span>
                  has the mental capacity to take responsibility for the
                  Customer’s own actions and be bound by the Documentation,
                  without force or coercion;
                </span>
              </h3>
              <h3>
                <strong>5.1.3.</strong
                ><span>
                  understands that gambling over the Internet or other remote
                  communication devices or gambling in general, may be unlawful
                  in the jurisdiction where the Customer is located; the
                  Customer it is solely responsible to ensure compliance with
                  its applicable local or national laws before opening a
                  Customer Account, using the Company Services or accessing the
                  Website</span
                >
              </h3>
              <h3>
                <strong>5.1.4.</strong
                ><span>will not access the Website at any time:</span>
              </h3>
              <h4>
                <strong>5.1.4.1.</strong
                ><span
                  >from within a jurisdiction that prohibits gambling or remote
                  gambling;</span
                >
              </h4>
              <h4>
                <strong>5.1.4.2.</strong
                ><span>
                  if the Customer is a citizen of a nation state that prohibits
                  its citizens from participating in gambling or remote gambling
                  (regardless of their current location); and</span
                >
              </h4>
              <h4>
                <strong>5.1.4.3.</strong
                ><span>
                  if the Customer is a citizen or resident of, or is located
                  within, any of the Prohibited Jurisdictions, their
                  dependencies and territories.</span
                >
              </h4>
              <h3>
                <strong>5.1.5.</strong
                ><span
                  >that the Company is rendering the Company Services from the
                  jurisdiction and/or country stated herein, operating legally
                  and licensed for the provision of said Company Services, where
                  is legally permissible to do so; and that the Company has made
                  an effort not to solicit or undergo business from or in
                  Prohibited Jurisdictions or with Customers in, or from
                  Prohibited Jurisdictions (if the Customer is from or is in a
                  Prohibited Jurisdiction).</span
                >
              </h3>
              <h3>
                <strong>5.1.6.</strong
                ><span>
                  has not been solicited or been offered the Company Services by
                  the Company, and it is the Customer who has sought and
                  requested the Company Services from the Company in accordance
                  with the Documentation.</span
                >
              </h3>
              <h3>
                <strong>5.1.7.</strong
                ><span>
                  is not aware of any marketing or publicity by the Company in
                  its jurisdiction of usual residence or where it is located.
                </span>
              </h3>
              <h3>
                <strong>5.1.8.</strong
                ><span>
                  will use the Website at its sole risk, understanding that
                  money may be lost by use of the Company Services for which
                  accepts full responsibility for any such loss (which is deemed
                  forseable).</span
                >
              </h3>
              <h3>
                <strong>5.1.9.</strong
                ><span>
                  will not represent itself as an agent or affiliate of the
                  Company and in no way market, advertise, publicize, or promote
                  the Company or the Company Services without the previous
                  written consent of the Company.</span
                >
              </h3>
              <h3>
                <strong>5.1.10.</strong
                ><span>
                  will not (or even attempt to) attack, hack, undergo any
                  malicious activity, make unauthorised alterations to, or
                  introduce any kind of malicious code to, the Website. As such,
                  the Customer will not, including without limitation, (a)
                  reverse engineer or decompile (in whole or in part) any
                  software available through the Website; or (b) make copies,
                  modify, reproduce, transmit, alter, use or distribute all or
                  any part of the Website or any material or information
                  contained in it; or (c) make unauthorized use of, or disclose,
                  information in the Website and/or Customer Account to any
                  person; or (d) contravene any of the provisions of the
                  Documentation; or (e) cheat, deceive, trick, misinform or
                  defraud the Company in any way through use or abuse of the
                  Company Services or the Website.
                  <span class="style1"
                    >SHOULD THE COMPANY SUSPECT THAT THE CUSTOMER HAS FAILED TO
                    ABIDE BY THE TERMS OF THIS PROVISION, THE COMPANY SHALL
                    HAVE, IN ADDITION TO ANY OTHER REMEDY, THE RIGHT TO SUSPEND
                    THE CUSTOMER ACCOUNT IN ORDER TO CARRY OUT AN INVESTIGATION
                    WHICH IF CONFIRMS THE CUSTOMER’S FAILURE TO ABIDE BY THE
                    TERMS OF THIS PROVISION, IN THE COMPANY’S SOLE AND ABSOLUTE
                    DISCRETION, WILL RESULT IN THE CLOSURE OF THE CUSTOMER
                    ACCOUNT AND FORFEITURE BY THE COMPANY OF ANY FUNDS.
                  </span></span
                >
              </h3>
              <h3>
                <strong>5.1.11.</strong
                ><span
                  >will not allow or authorize any other person or third party
                  (including, without limitation, any minor) to use the Company
                  Services, use the Customer Account or accept any winnings on
                  their behalf.</span
                >
              </h3>
              <h3>
                <strong>5.1.12.</strong
                ><span>
                  will forthwith report any and all winnings to the proper
                  authorities if the Customer resides in a jurisdiction where
                  such winnings are taxable or are otherwise required by law to
                  be disclosed, and the Company shall not in anyway be liable
                  for the Customer’s failure to do so.</span
                >
              </h3>
              <h3>
                <strong>5.1.13.</strong
                ><span
                  >will fully indemnify, defend and hold the Company, and its
                  stockholders, directors, officers, employees, consultants,
                  advisors, agents, affiliates, and suppliers and other
                  representatives (the “Extended Parties”) harmless, immediately
                  on demand, from and against all claims, liabilities, damages,
                  losses, costs and expenses, including legal fees, (regardless
                  of theory of law) arising out of any breach of the
                  Documentation by the Customer or any other liabilities arising
                  out of the Documentation or Customer’s use of the Company
                  Services.</span
                >
              </h3>
              <h2>
                <strong>5.2.</strong
                ><span
                  >The Customer agrees that any and all warranties and
                  representations made herein are true and accurate and will
                  comply and maintain each and everyone, while it remains a
                  Customer of the Company with a Customer Account.
                </span>
              </h2>
              <h1>6. Terms of Transactions</h1>
              <h2>
                <strong>6.1.</strong
                ><span
                  >The Company is licensed to accept bets via remote
                  communication devices on a global basis under the Company’s
                  interactive gaming license issued by First Cagayan Leisure
                  Resorts Corporation and the Cagayan Economic Zone Authority on
                  behalf of the Government of Philippines. All bets are
                  considered to be placed, accepted and recorded within the
                  jurisdiction of the Cagayan Special Economic Zone and Freeport
                  in the Republic of the Philippines.</span
                >
              </h2>
              <h2>
                <strong>6.2.</strong
                ><span
                  >A bet is only deemed placed (“Placed Bet”) when it is made by
                  the Customer through the Internet via the Website. A bet is
                  deemed accepted and recorded (“Accepted Bet”) only when it has
                  been accepted and recorded in accordance to the Documentation
                  by the Company’s gaming server. A bet is deemed completed
                  (“Completed Bet”) when accepted and recorded and the Customer
                  has been notified (through an electronic acknowledgement sent
                  by the Company to the Customer via the Website) of said
                  acceptance and recordal.</span
                >
              </h2>
              <h2>
                <strong>6.3.</strong
                ><span
                  >A Customer Account must have an available Credit balance
                  equal to at least the minimum amount for a bet in an Event or
                  Market, for the Customer to be able to place a bet.</span
                >
              </h2>
              <h2>
                <strong>6.4.</strong
                ><span
                  >Customers are not allowed to cancel or change an Accepted
                  Bet, the Company is under no obligation to cancel Accepted
                  Bets.
                </span>
              </h2>
              <h2>
                <strong>6.5.</strong
                ><span>
                  Bets will be accepted up to the advertised deadline until when
                  bets are accepted for any given Event or Market.</span
                >
              </h2>
              <h2>
                <strong>6.6.</strong
                ><span
                  >The Company reserves the right to definitely or temporarily
                  suspend or prohibit further betting on an Event or Market at
                  anytime without prior notice to the Customer at the Company’s
                  sole and absolute discretion. Whereupon an Event or Market is
                  suspended or access to it prohibited, any attempt to place
                  bets by the Customer will be rejected.</span
                >
              </h2>
              <h2>
                <strong>6.7.</strong
                ><span> A bet will be deemed void (“Void Bet”) if:</span>
              </h2>
              <h3>
                <strong>6.7.1.</strong
                ><span>
                  it is not transmitted in full, including but not limited to
                  instances where the bet transmission has been disrupted or
                  interrupted due to technical or communication failure;</span
                >
              </h3>
              <h3>
                <strong>6.7.2.</strong
                ><span
                  >inadvertedly accepted by the Company in contradiction to the
                  Documentation;</span
                >
              </h3>
              <h3>
                <strong>6.7.3.</strong
                ><span>
                  the Company has reasonable belief that the Customer or any
                  person or group of persons are acting in concert or as a
                  syndicate to collude against, or defraud the Company;</span
                >
              </h3>
              <h3>
                <strong>6.7.4.</strong
                ><span>
                  the circumstances and/or assumptions considered by the Company
                  for a particular Event change;</span
                >
              </h3>
              <h3>
                <strong>6.7.5.</strong
                ><span>
                  a Market or Event is not suspended at the correct time, as
                  determined in the absolute sole discretion of the
                  Company;</span
                >
              </h3>
              <h3>
                <strong>6.7.6.</strong
                ><span>
                  any typographical, technical, human or palpable errors in
                  disclosing, posting or using Betting Information.
                </span>
              </h3>
              <h2>
                <strong>6.8.</strong
                ><span>
                  The Company reserves the right at its sole and absolute
                  discretion without the need for explanation or prior notice
                  given to the Customer to refuse any bet or part thereof.
                </span>
              </h2>
              <h2>
                <strong>6.9.</strong
                ><span>
                  All Betting Information is subject to variation but become
                  fixed at the time a Placed Bet becomes an Accepted Bet, except
                  for pool betting and totalisator bets on horse racing which
                  become fixed at the moment the respective pool or tote becomes
                  closed. The Company reserves the right at its sole and
                  absolute discretion without explanation or prior notice to the
                  Customer to change the Betting Information at any time.
                  Whenever by error, omission or mistake the incorrect Betting
                  information has been displayed, the Company reserves the right
                  at its sole discretion without prior notice to the Customer to
                  void, reject, or accept any affected bets and/or to correct
                  any error, omission or mistake. The Company will place a
                  prominent notice on the Website in such events.</span
                >
              </h2>
              <h2>
                <strong>6.10.</strong
                ><span>
                  The maximum and minimum bet amount that a Customer may place
                  on any Market or Event varies according to the specific, Event
                  or Market and is subject to change without prior notice at the
                  Company’s sole and absolute discretion. The Customer shall
                  comply with the posted minimum and maximum bet amounts and
                  limits.</span
                >
              </h2>
              <h2>
                <strong>6.11.</strong
                ><span>
                  Winnings will be credited to, and losses deducted from, the
                  Customer’s Customer Account, following confirmation of the
                  final result of the Event from the relevant, reasonably
                  appropriate, official source as determined by the Company in
                  its sole and absolute discretion.</span
                >
              </h2>
              <h2>
                <strong>6.12.</strong
                ><span
                  >Customer must check their Customer Account funds balance upon
                  each login. Should funds be credited to or debited from a
                  Customer Account in error, it is the Customer’s responsibility
                  to notify the Company of the error immediately without delay.
                  Any sums credited to a Customer Account due to the error will
                  be deemed invalid and must be returned to the Company. The
                  Customer may not dispose of funds credited to a Customer
                  Account in error and the Company reserves the right to void
                  any transaction (including bets) involving such funds. The
                  Customer agrees to indemnify the Company for the return of any
                  erroneously credited funds that the Customer has disposed
                  of.</span
                >
              </h2>
              <h2>
                <strong>6.13.</strong
                ><span>
                  The Company reserves the right in its sole and absolute
                  discretion, without incurring any liability of any kind, with
                  respect to any funds payable to Customers to:</span
                >
              </h2>
              <h2>
                <strong>6.14.</strong
                ><span>
                  freeze them in the event of the Customer Account being
                  suspended; suspicion of an Illegal transaction or breach of
                  the Documentation; pending the outcome of an investigation
                  from any Government authority or financial institution;
                  and/or,
                </span>
              </h2>
              <h2>
                <strong>6.15.</strong
                ><span
                  >withhold them in the event of the Customer Account being
                  closed for an Illegal transaction or breach of the
                  Documentation; and/or at the request of any Government
                  authority or financial institution.</span
                >
              </h2>
              <h2>
                <strong>6.16.</strong
                ><span
                  >Any and all payments made to the Customer include any tax,
                  including VAT or any chargeable, local sales tax at the rate
                  prevailing on the date of the corresponding transaction. Any
                  sums payable to the Company shall be paid in addition to any
                  deductions, withholdings, set offs or counterclaims, including
                  any deduction or withholding which may be required by law in
                  relation to tax. The Customer shall be responsible, where
                  applicable, for payment of any and all taxes, duties or levies
                  applicable to any transaction.</span
                >
              </h2>
              <h2>
                <strong>6.17.</strong
                ><span
                  >All transactions and communications between Customers and the
                  Company will be recorded (“Recordings”) by the Company in the
                  interests of Customers and the Company. Recordings will be
                  kept for a period of one (1) month from the date of the
                  transaction or communication, respectively; after said period,
                  the Recordings shall be discarded. Customers must notify any
                  disputes related to any transaction to the Company within one
                  (1) month from the date of the transaction. When a dispute
                  occurs which, cannot be resolved by the Company’s management
                  in good faith, the Recordings will be used as evidence in such
                  a dispute. The Customer and the Company agree that the
                  Recordings are true and accurate and will be the ultimate
                  authority in resolving such disputes as a last recourse. The
                  Company’s management shall make a final decision with respect
                  to the dispute and communicate it to the Customer.
                </span>
              </h2>
              <h1>7. Limitation of Liability</h1>
              <h2>
                <strong>7.1.</strong
                ><span
                  >The Company shall not be liable to the Customer or any other
                  person for any of the following types of loss or damage in
                  contract, tort, negligence, or otherwise (or any theory of
                  law) even in each case if the Company has been advised of the
                  possibility of such loss or damage, whether direct or
                  indirect, without limitation, that are deemed or alleged to
                  have resulted from or been caused by the Website; the
                  Company’s Services; transactions; and/or the Customer’s
                  relationship with the Company:</span
                >
              </h2>
              <h3>
                <strong>7.1.1.</strong
                ><span>
                  indirect, special or consequential damages or loss;</span
                >
              </h3>
              <h3>
                <strong>7.1.2.</strong
                ><span
                  >loss of profits, revenue, contracts or anticipated savings;
                  or</span
                >
              </h3>
              <h3>
                <strong>7.1.3.</strong
                ><span> loss or damage arising from:</span>
              </h3>
              <h4>
                <strong>7.1.3.1.</strong
                ><span> loss, damage or corruption of any data;</span>
              </h4>
              <h4>
                <strong>7.1.3.2.</strong
                ><span
                  >delay and/or interruption and/or failure from, or by
                  consequence of, any telecommunication services and/or devises;
                  hardware equipment; third person acts (including hacking and
                  DDOS); and/or</span
                >
              </h4>
              <h4>
                <strong>7.1.3.3.</strong
                ><span>
                  voiding and/or changing any transactions pursuant to the
                  Documentation;</span
                >
              </h4>
              <h4>
                <strong>7.1.3.4.</strong
                ><span
                  >suspension or closure of Customer Accounts pursuant to the
                  Documentation;</span
                >
              </h4>
              <h4>
                <strong>7.1.3.5.</strong
                ><span
                  >freezing or withholding of Customer funds pursuant to the
                  Documentation;</span
                >
              </h4>
              <h4>
                <strong>7.1.3.6.</strong
                ><span>
                  fulfilling actions and/or requests from any Government
                  authority or office and/or any financial institution;</span
                >
              </h4>
              <h4>
                <strong>7.1.3.7.</strong
                ><span>
                  any typographical, technical, human or palpable errors in
                  disclosing, posting or using Betting Information or any other
                  information on the Website,</span
                >
              </h4>
              <h4>
                <strong>7.1.3.8.</strong
                ><span>
                  events beyond the reasonable control of the Company (Force
                  Majeure).
                </span>
              </h4>
              <h2>
                <strong>7.2.</strong
                ><span
                  >The Customer without limiting any of the Company’s other
                  rights and remedies under these Terms and Conditions, the
                  Documentation and/or under applicable laws, warrants and
                  agrees to defend the Company and its Extended Parties against
                  all suits, claims or other actions, and to indemnify the
                  Company and its Extended Parties upon written demand, and to
                  hold them harmless from any damages, losses and expenses,
                  including reasonable attorneys fees, payable to a third person
                  by the Company or any of its Extended Parties, arising out of
                  any claim by a third person relating directly or indirectly to
                  fraud, money laundering or Illegal activities; a breach of the
                  Documentation; that are deemed or alleged to have resulted
                  from or been caused by the use of the Website; the Company
                  Services; and/or the Customer’s relationship with the
                  Company.</span
                >
              </h2>
              <h2>
                <strong>7.3.</strong
                ><span>
                  Without limiting its ability to seek alternative remedies, the
                  Company may restrict any Customer’s ability to use the Website
                  and/or the Company Services; suspend or terminate the Customer
                  Account; void any bets; and/or, withhold funds, in its
                  absolute discretion, if the Company has reason to believe or
                  suspicion that the Customer’s dealings with the Company
                  constitute Illegal activity. Customers acknowledge that if
                  their use of the Website is in breach of any local or national
                  laws with respect to fraud or money laundering, any payments
                  those Customers make to or by the Company may be subject to
                  forfeiture and/or may be withheld by the Company. To the
                  extent permitted by law, the Company shall not be liable to
                  the Customer for any such actions, nor shall it incur any
                  liability to any Customer where it is required to give
                  information or documentation relating to the Customer to any
                  relevant authority in such cases.
                </span>
              </h2>
              <h1>8. Use of the Website and Video Streaming</h1>
              <h2>
                <strong>8.1.</strong
                ><span
                  >The general use of the Website is governed in addition to
                  these Terms and Conditions, by the Terms of Use and Privacy
                  Policy.</span
                >
              </h2>
              <h2>
                <strong>8.2.</strong
                ><span
                  >Company reserves the right to withdraw the Website or
                  elements thereof at any time, and shall not be liable to the
                  Customer in any way as a result of any such action.</span
                >
              </h2>
              <h2>
                <strong>8.3.</strong
                ><span
                  >The Company’s offering of video streaming of Events over the
                  Internet, is proprietary, owned and copyrighted by the
                  rightful intellectual property owners (who reserve all rights)
                  and licensed to the Company. The Customer may only access the
                  offering of video streaming of Events over the Internet in
                  accordance with any specific rules, terms and conditions that
                  may apply for the particular offering as published separately
                  by the Company from time to time and with which the Customer
                  hereby accepts to fully comply with. At no time may the
                  Customer copy, re-distribute or make any un-authorized use of
                  the video feed offered, this would be considered a breach of
                  these Terms and Conditions and allow the Company for the
                  immediate closure of the Customer Account.</span
                >
              </h2>
              <h1>9. Promotions &amp; Special Offers</h1>
              <h2>
                <strong>9.1.</strong
                ><span
                  >From time to time the Company may issue promotions, special
                  offers or other incentives to the Customers. These have no
                  alternative cash value and are subject to the terms specified
                  in that particular promotion, special offer or incentive which
                  must be followed and complied with by the Customer. The
                  Company reserves the right without the need of prior notice to
                  the Customer, to withdraw or change a promotion, special offer
                  or incentive at any time at the Company’s sole
                  discretion.</span
                >
              </h2>
              <h1>10. General Rules and Specific Event Rules</h1>
              <h2>
                <strong>10.1.</strong
                ><span
                  >The Company Services, all transactions, bets, Events and
                  Betting Information is subject to these Terms and Conditions,
                  the Documentation, and Betting Rules and Regulations, to which
                  the Customer hereby acknowledges having read and accepted and
                  further agrees to abide by. In addition to these Terms and
                  Conditions and the Documentation, specifically the Betting
                  Rules and Regulations specify the betting rules applicable to
                  all Company Services, transactions, bets, Events, and Betting
                  Information, whereby the Specific Event and Betting Rules and
                  Regulations specify the betting rules particular to a specific
                  Event or bet type.</span
                >
              </h2>
              <h2>
                <strong>10.2.</strong
                ><span>
                  If there is an inconsistency, discrepancy or contradiction
                  between the Terms and Conditions and the Betting Rules and
                  Regulations the latter will prevail. If the Betting Rules and
                  Regulations are silent on a particular matter, the relevant
                  provision(s) of the Event’s Governing Body Authority will
                  apply by analogy or interpretation (where applicable).</span
                >
              </h2>
              <h2>
                <strong>10.3.</strong
                ><span
                  >For the Financial Betting, please refer to the financial
                  betting terms and conditions as found in the Betting Rules and
                  Regulations.</span
                >
              </h2>
              <h1>11. General</h1>
              <h2>
                <strong>11.1.</strong
                ><span
                  >The Website is not, and should not be regarded as an offer,
                  solicitation or invitation by the Company for the use of, or
                  subscription to, gambling services in any jurisdiction in
                  which such activities are prohibited by law.</span
                >
              </h2>
              <h2>
                <strong>11.2.</strong
                ><span
                  >All other statements or arrangements, whether written or
                  oral, express or implied (including any usage or custom and
                  any terms arising through any course of dealing), between the
                  Customer and the Company relating to the subject matter herein
                  shall be deemed to have been superseded, cancelled, null and
                  void.</span
                >
              </h2>
              <h2>
                <strong>11.3.</strong
                ><span>
                  Nothing in the Documentation shall be interpreted or construed
                  as creating or establishing any other type of relationship
                  between the Company and the Customer.</span
                >
              </h2>
              <h2>
                <strong>11.4.</strong
                ><span
                  >A failure or delay by the Company to exercise any right or
                  remedy under the Documentation shall not be construed or
                  operate as a waiver of that right or remedy nor shall any
                  single or partial exercise of any right or remedy preclude the
                  further exercise of that right or remedy.</span
                >
              </h2>
              <h2>
                <strong>11.5.</strong
                ><span>
                  Each of the provisions contained in the Documentation shall be
                  construed as independent of every other provision, so that if
                  any provision of the Documentation shall be determined by any
                  court or competent authority to be illegal, invalid and/or
                  unenforceable then such determination shall not affect any
                  other provision of the Documentation, all of which other
                  provisions shall remain in full force and effect.</span
                >
              </h2>
              <h2>
                <strong>11.6.</strong
                ><span
                  >The Customer acknowledges and warrants that it has
                  independently sought and received independent and competent
                  legal counsel with respect to the rights, obligations, terms,
                  conditions and consequences acquired by the Documentation, all
                  have been explained and have been completely understood.</span
                >
              </h2>
              <h2>
                <strong>11.7.</strong
                ><span
                  >These Terms and Conditions shall be governed by and construed
                  in accordance with Philippine law.</span
                >
              </h2>
              <h2>
                <strong>11.8.</strong
                ><span>
                  Any dispute, controversy or claim arising out of or in
                  connection with this Agreement shall be referred to and
                  determined by a tribunal comprised of one arbitrator to be
                  appointed by the Licensing Authority. The seat of arbitration
                  will be Santa Ana, Cagayan, Philippines, the location
                  determined by the Licensing Authority, the language of the
                  arbitration will be English and the regulations established by
                  the Licensing Authority shall govern the process. The
                  arbitration tribunal will have the right to issue injunctions
                  and its decision will be compulsory, final and strictly
                  mandatory to the Parties who in turn waive any other
                  jurisdiction or venue that may apply and agree to be subject
                  to the arbitral decision waiving any appeals of any
                  type.</span
                >
              </h2>
              <h2>
                <strong>11.9.</strong
                ><span>
                  Notices to the Customer or the Company relating to the
                  Documentation must be in writing and must be delivered
                  personally or sent by prepaid first class post, pre-paid air
                  mail post, electronic mail or facsimile transmission to the
                  address or fax number set out below:</span
                >
              </h2>
              <h3>
                <strong>11.9.1.</strong
                ><span>
                  To the Customer: To the information provided as the Customer
                  ID, should notice by any of the means included in the Customer
                  ID fail three consecutive times with one hour intervals, the
                  notice shall be deemed made after the third attempt.
                </span>
              </h3>
              <div id="1-3" class="page_top">
                <a href="#top" class="button"><span>ĐẦU TRANG</span></a>
              </div>
              <br />
              <br />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script scoped>
//Vue export
export default {};
</script>

<style scoped lang="css" src="../../../../assets/css/agreement.css"></style>
<style scoped>
.btn-dt {
  padding: 0.8em 2.4em;
  border-radius: 30px;
  text-align: center;
  font-family: 'Verdana';
  min-width: 65px;
  font-size: 12px;
  display: inline-block;
}

.CssBtnAgree-dt {
  cursor: pointer;
  color: white;
  margin-right: 1.5em;
  box-shadow: 0 4px 4px 0 rgb(136 108 44 / 45%);
  background-image: linear-gradient(to bottom, #ebdca6, #8a6f36 59%, #bc9f5b);
}

.CssBtnNotAgree-dt {
  cursor: pointer;
  color: #b19560;
  border: solid 1px #b19560;
}

@media (max-width: 700px) {
  table {
    background: #f4f4f4;
    width: 100%;
    height: 100%;
  }
  .body-wrap > div {
    height: 100% !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }
}

table {
  background: #f4f4f4;
  width: 100%;
  /* height: 100%; */
}
.body-wrap > div {
  height: 100% !important;
  overflow: auto !important;
}

.body-wrap {
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-bottom: 80px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  display: block;
  overflow: scroll;
}

section {
  flex: 1;
  display: flex;
}
.header {
  background: black;
  text-align: center;
  padding: 1em;
}
.col-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CssBtnAgree,
.CssBtnNotAgree {
  text-align: center;
  margin-right: 1.5em;
}

.agree-btn-container {
  /* margin: 1em 1em 1em 1em;  */
  /* Top, Right, Bottom, Left */
}

.agree-btn-top {
  margin-bottom: 1em;
  margin-top: 1em;
}
.agree-btn-bottom {
  /* margin-top: 0.5em !important;
  margin-bottom: 0em !important; */
  padding-bottom: 1.5rem !important;
  /* padding-top: 4rem !important; */
}

@media (max-height: 720px) {
  .agree-btn-bottom {
    /* padding-top: 6vh !important; */
    padding-top: 0vh !important;
  }
}

@media (min-height: 720px) {
  .agree-btn-bottom {
    /* padding-top: calc(10vh - 8px) !important; */
    margin-top: calc(9.5vh - 8px) !important;
  }
}

@media (min-height: 1000px) {
  .agree-btn-bottom {
    padding-top: calc(57vh - 8px) !important;
  }
}

.footer-mb {
  position: fixed;
  background: white;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-mb {
  padding: 1em 2.5em;
  border-radius: 30px;
  text-align: center;
  font-family: 'Verdana';
  min-width: 65px;
}
@media (max-width: 340px) {
  .btn-mb {
    padding: 1em 1.5em;
  }
}
.CssBtnAgree_mb {
  cursor: pointer;
  color: white;
  margin-right: 1.5em;
  box-shadow: 0 4px 4px 0 rgba(136, 108, 44, 0.45);
  background-image: linear-gradient(to bottom, #ebdca6, #8a6f36 59%, #bc9f5b);
}
.CssBtnNotAgree_mb {
  cursor: pointer;
  color: #bfbfbf;
  border: solid 1px #bfbfbf;
}
#termsFrame {
  width: 767px;
  height: 465px;
}
.d-none {
  display: none !important;
}

h1 {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.strong {
  font-weight: bold !important;
}

/* a:hover {
  color: inherit;
} */

.iframe-class {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
}

.CssBtnAgree {
  background: url(../../../../assets/icons/s28.jpg) no-repeat 0px 0px;
  background-position: 0px 0px;
  width: 78px;
  height: 20px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  line-height: 20px;
}
.CssBtnNotAgree {
  background: url(../../../../assets/icons/s12.jpg) no-repeat 0px 0px;
  background-position: 0px 0px;
  width: 78px;
  height: 20px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #000;
  text-decoration: none;
  line-height: 20px;
}

.iframe-table {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 500px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  -webkit-border-horizontal-spacing: 10px;
  -webkit-border-vertical-spacing: 10px;
  /* default style */
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

.iframe-table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.iframe-table td {
  display: table-cell;
  vertical-align: inherit;
  padding: 20px;
}

.header-logo {
  width: 213px;
  height: 64px;
  object-fit: contain;
}

#agreement-wraper {
  height: 100%;
  display: block;
}

p {
  /* margin: 10px 0 !important; */
  padding: 0;
}

/* desktop */
@media (min-width: 769px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .iframe-table td {
    line-height: 115%;
  }

  .bottom-container {
    width: 767px;
    margin: 0 auto;
    padding-bottom: 15px;
    border-top: 1px solid #d4d4d4;
    padding-top: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }
}

/* mobile */
@media (max-width: 769px) {
  .iframe-table td {
    background-color: #f4f4f4;
  }

  .iframe-table {
    background-color: #f4f4f4;
  }

  .header img {
    width: 200px;
    height: auto;
  }
  #termsFrame {
    width: 100%;
    height: calc(100vh - 70px);
  }
  .iframe-table td {
    line-height: 140%;
  }
}
.table-wrapper {
  overflow-x: hidden !important;
  overflow-y: scroll;
  display: block;
}

.clear-both {
  clear: both;
}

.content-body {
  background-color: #f7f7f7;
  min-height: 100% !important;
  display: block !important;
}

#Header {
  width: 100%;
}
#Content {
  height: 100% !important;
  min-height: 82vh !important;
  width: 100% !important;
  display: block !important;
  overflow-x: hidden !important;
}

.card-body {
  min-height: 35vh !important;
  width: 100% !important;
  display: block !important;
  overflow: auto;
  padding: 0rem !important;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.card-header {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.border-secondary {
  border-color: #b5b5b5 !important;
}

.card-footer {
  background-color: #fff !important;
  padding: 0.9rem 1.25rem;
}

.table-wrapper {
  font-family: Arial, Helvetica, sans-serif;
  height: 64.5vh;
  font-size: 12px;
  display: block;
  overflow: scroll;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

/* mobile */
@media (max-width: 769px) {
  .card-body {
    padding: 0px !important;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }
  .card-footer {
    padding: 0.6rem 1.25rem;
  }

  .table-wrapper {
    height: 78vh;
  }
}
</style>
