<template>
  <div class="col-lg-9 right-card-container">
    <div class="card right-card">
      <div class="info-text">
        {{ $t('AccountInfo') }}
      </div>
      <div class="py-1 mt-1">
        <div class="row">
          <div class="col-5 profile-label">{{ $t('UserNameLabel') }}:</div>
          <div class="col-7 profile-info">
            <span class="profile-spacer">:</span>
            {{ username }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-5 profile-label">{{ $t('CurrencyLabel') }}:</div>
          <div class="col-7 profile-info">
            <span class="profile-spacer">:</span>
            {{ currency }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-5 profile-label">{{ $t('CreditLabel') }}:</div>
          <div class="col-7 profile-info">
            <span class="profile-spacer">:</span>
            {{ $filters.currency(creditLimit) }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-5 profile-label">{{ $t('TotalBalanceLabel') }}:</div>
          <div
            class="col-7 profile-info"
            :class="[totalBalance >= 0 ? 'text-success' : 'text-danger']"
          >
            <span class="profile-spacer">:</span>
            {{ $filters.currency(totalBalance) }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-5 profile-label">
            {{ $t('RemainSportsbookLabel') }}
          </div>
          <div class="col-7 profile-info">
            <span class="profile-spacer">:</span>
            {{ $filters.currency(totalRemainingCreditLimit) }}
          </div>
        </div>
      </div>
      <div class="py-1">
        <div class="row">
          <div class="col-5 profile-label">{{ $t('MinMaxBetLabel') }}</div>
          <div class="col-7 profile-info">
            <span class="profile-spacer">:</span>
            {{ $filters.currencyDecimal(minBet) }} /
            {{ $filters.currencyDecimal(maxBet) }}
          </div>
        </div>
      </div>
      <div class="py-1 mb-5">
        <div class="row pb-2">
          <div class="col-5 profile-label">
            {{ $t('TotalOutstandingLabel') }}
          </div>
          <div class="col-7 profile-info">
            <span class="profile-spacer">:</span>
            {{ totalOutstanding }} ({{ totalOutstandingCount }}
            bets)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  computed: {
    username() {
      return this.$store.getters.username;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
    creditLimit() {
      return this.$store.getters.creditLimit;
    },
    minBet() {
      return this.$store.getters.minBet;
    },
    maxBet() {
      return this.$store.getters.maxBet;
    },
    totalOutstanding() {
      return this.$store.getters.totalOutstanding;
    },
    totalOutstandingCount() {
      return this.$store.getters.totalOutstandingCount;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    totalBalance() {
      return this.$store.getters.totalBalance;
    },
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>

<style scoped>
  .profile-spacer{
    margin-right: 4em;
    color: white;
  }
.info-text {
  font-size: xx-large;
  text-align: left;
  color: #c500ff;
  font-family: cclip;
  text-shadow: 0 0 8px #c500ff;
  -webkit-text-stroke: 0.75px #c500ff;
  text-transform: uppercase;
}

.profile-label {
  font-size: 14px;
}

.profile-info {
  text-align: left;
  font-size: 14px;
  font-weight: 900;
}

.right-card-container{
  padding: 7px;
  border-radius: 35px;
  border: solid 2px #6fecff;
  margin-top: 1em;
}
.right-card {
  border-radius: spx;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: transparent;
  padding: 20px 25px;
  border-radius: 25px;
  border: solid 2px #6fecff;
  min-height: 440px;
  overflow-y: auto;
}

.py-3 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 19px;
  margin: 11px 34px 11px 0px;
}
</style>
