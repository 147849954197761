<template>
  <div id="main" class="content-section2">
    <div v-if="showProgress">
      <div class="col-lg-12 p-1">
        <div class="card d-flex align-items-center">
          <white-progress-spinner></white-progress-spinner>
        </div>
      </div>
    </div>
    <ul
      class="list-group"
      style="width: 92%; margin: auto; padding-top: 10px;"
      v-if="!showProgress"
    >
      <li class="list-group-item">
        <span class="m_left">{{ $t('UserNameLabel') }}</span>
        <span class="m_right"> {{ username }}</span>
      </li>
      <li class="list-group-item">
        <span class="m_left">{{ $t('CurrencyLabel') }}</span>
        <span class="m_right"> {{ currency }}</span>
      </li>
      <li class="list-group-item">
        <span class="m_left">{{ $t('CreditLabel') }} </span>
        <span class="m_right Positive">
          {{ $filters.currency(creditLimit) }}</span
        >
      </li>
      <li class="list-group-item">
        <span class="m_left" v-html="$t('TotalBalanceLabel')"></span>
        <span class="m_right Positive">
          {{ $filters.currency(totalBalance) }}</span
        >
      </li>
      <li class="list-group-item">
        <span class="m_left">{{ $t('RemainSportsbookLabel') }}</span>
        <span class="m_right Positive">
          {{ $filters.currency(totalRemainingCreditLimit) }}</span
        >
      </li>
      <li class="list-group-item">
        <span class="m_left">{{ $t('MinMaxBetLabel') }}</span>
        <span class="m_right Positive">
          {{ $filters.currencyDecimal(minBet) }} /
          {{ $filters.currencyDecimal(maxBet) }}</span
        >
      </li>
      <li class="list-group-item">
        <span class="m_left"> {{ $t('TotalOutstandingLabel') }}</span>
        <span class="m_right">
          <span class="Positive"> {{ totalOutstanding }}</span> ({{
            totalOutstandingCount
          }}
          bets)
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
//Vue export
export default {
  mounted() {
    this.getMemberAllBalance();
  },
  data() {
    return { showProgress: false };
  },
  methods: {
    async getMemberAllBalance() {
      this.showProgress = true;
      try {
        await this.$store.dispatch('getMemberAllBalance');
        this.showProgress = false;
      } catch (error) {
        this.showProgress = false;
      }
    },
  },
  computed: {
    username() {
      return this.$store.getters.username;
    },
    nickname() {
      return this.$store.getters.nickname;
    },
    creditLimit() {
      return this.$store.getters.creditLimit;
    },
    minBet() {
      return this.$store.getters.minBet;
    },
    maxBet() {
      return this.$store.getters.maxBet;
    },
    totalOutstanding() {
      return this.$store.getters.totalOutstanding;
    },
    totalOutstandingCount() {
      return this.$store.getters.totalOutstandingCount;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    totalBalance() {
      return this.$store.getters.totalBalance;
    },
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>
<style scoped>
.content-section2 {
  margin-top: 1px !important;
}

.list-group-item {
  color: white;
}

.card {
  background-color: inherit !important;
}

.m_left {
  width: 200px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
</style>
