<template>
  <div class="card left-card">
      <EnAbout v-if="langId == '0'" />
      <CnAbout v-if="langId == '1'" />
      <ThAbout v-if="langId == '2'" />
      <VnAbout v-if="langId == '3'" />
      <SwAbout v-if="langId == '4'" />
    </div>
</template>

<script>
import EnAbout from './about/EnAbout.vue';
import ThAbout from './about/ThAbout.vue';
import VnAbout from './about/VnAbout.vue';
import CnAbout from './about/CnAbout.vue';
import SwAbout from './about/SwAbout.vue';
//Vue export
export default {
  components: { EnAbout, ThAbout, VnAbout, CnAbout, SwAbout },
  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  position: relative;
}

.card-body {
  padding: 1.85rem;
}

.left-card {
  border-radius: 5px;
  /* box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important; */
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16) !important;
}

</style>
