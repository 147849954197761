<template>
  <!-- Page Content -->
  <div class="wrapper">
    <loading-spinner v-if="isLoading"></loading-spinner>

    <!----pop out modal -->
    <transition name="fadeIn">
      <div class="transfer-backdrop" v-if="selectedGame != null"></div>
    </transition>
    <transition name="slideUp">
      <section class="transfer-wrap" v-if="selectedGame != null">
        <div class="transfer-backdrop2">&nbsp;</div>
        <div class="transfer-content">
          <img class="transfer-backdrop3" src="./../../../assets/images/starry.png">
          <div class="transfer-image">
            <img :src="getLangImg2(this.selectedGame)"/>
            
          </div>
          <h6 class="white larger d-none"></h6>
          <div class="row">
            <div class="col">
              <div class="full-center">
                <div class="text-right">
                  <div class="transfer-balance-header smaller">
                    
                  </div>
                  <h3 class="mt-3 smaller">
                    {{ $t("GameBalanceModal") }}:
                    <span v-if="showProgress">
                      <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-if="!showProgress">
                      {{ $filters.currency(gameBalance) }}
                    </span>
                  </h3>
                </div>
                </div>
              <div class="transfer-logo">
                <img :src="this.selectedGame.icon" />
              </div>

            </div>

          </div>
          <!----input area-->
          <div class="transfer-modal" v-if="!isErr">
            <div class="col-center spaced smaller">
              <div>{{ $t("TransferIn") }}</div>
              <div class="transfer-credit">
                {{ $t("CreditLabel") }}:
                {{ $filters.currency(totalRemainingCreditLimit) }}
              </div>
            </div>
            <input
              class="transfer-input large b"
              :class="{
                red: errorMessage != '',
              }"
              type="text"
              min="0"
              placeholder="100"
              v-model="inputAmount"
              @input="filterInput"
              :disabled="showProgress"
              v-on:input="onChangeAmount()"
            />
            <!-- oninput="this.value = Math.abs(this.value)" -->
            <div class="red smaller py-1">
              <span v-if="errorMessage == ''"> &nbsp;</span>
              {{ errorMessage }}
            </div>
            <button
              class="transfer-start cursor-pointer btn btn-default border-none"
              :disabled="showProgress"
              @click="onClickStartGame"
            >
              {{ $t("StartGame") }}
            </button>
          </div>
          <!---error area -->

          <div class="transfer-modal" v-if="isErr">
            <img
              class="mb-3"
              src="./../../../assets/icons/warning.png"
              alt="Game Error"
            /><span
              ><p class="smaller">
                {{ $t(errorMessage2) }}
              </p></span
            ><button
              class="transfer-start btn"
              @click="getGameDetails()"
              :disabled="timeoutFlag || suspend"
            >
              {{ $t("TRY AGAIN") }}
            </button>
          </div>

          <!--- button area--->
          <div class="transfer-modal2" v-show="!timeoutFlag">
            <a
              v-show="!suspend"
              class="transfer-cashout cursor-pointer"
              @click="setActiveComponent('screen-cash-out')"
            >
              <img class="icn-transfer mr-1" src="../../../assets/icons/cash-transfer.png" width="18" />
              {{ $t("CashOut") }}
            </a>
            &nbsp;

            <a class="transfer-cashout cursor-pointer" @click="onClickHistory()">
              <img
                class="icn-transfer mr-1"
                src="../../../assets/icons/history.png"
                width="18"
              />
              {{ $t("ViewHistory") }}
            </a>
          </div>
          <div
            class="transfer-close pointer"
            ref="closeBtn"
            @click="onClickCloseModal()"
          >
            <img src="../../../assets/icons/close.png" width="32" />
          </div>
          <!-- <div class="transfer-content">
              <div class="text-center transfer-load">Loading...</div>
            </div> -->
        </div>
      </section>
    </transition>
  </div>
  <!---- end pop out modal -->
</template>

<script>
import CustomHttp from "../../../CustomHttp";
import languageList from "../../../assets/script/languageList";
import i18n from "../../../i18n";
import CustomStorage from "../../../CustomStorage.js";
const { t } = i18n.global;
//Vue export
export default {
  name: "Landing",
  emits: ["toggle-login", "set-component", "set-selected-game", "reset-game"],
  components: {},
  props: ["selectedGame"],
  data() {
    return {
      //game modal
      isLoading: false,
      showProgress: false,
      gameBalance: 0,
      inputAmount: null,
      maxInputLenth: 26,
      errorMessage: "",
      isErr: false,
      errorMessage2: "",
      formSubmitted: false,
      timeoutFlag: false,
      languageList: languageList,
    };
  },
  methods: {
    setActiveComponent(componentName) {
      this.$emit("set-component", componentName);
    },
    onClickHistory() {
      sessionStorage.setItem("provider", this.selectedGame.code);
      this.$router.push("/history");
    },
    async goToSportsbook() {
      if (!this.$store.getters.isLoggedIn) {
        //no logged in
        this.loginPanelErr = "";
//        this.$refs.loginModal.showModal();
      } else {
        this.isLoading = true;
        const json = {
          username: this.$store.getters.username,
          langCode: this.languageList.find((l) => l.id == this.$store.getters.langId)
            .code,
          logout_url: window.location.origin,
        };
        const response = await CustomHttp.post("api/v1/member/login-old-site", json);
        const memberUrl = process.env.VUE_APP_MEMBER_SITE + response.data.data.url;
        window.location.href = memberUrl;
        //window.open(memberUrl, '_blank');
        this.isLoading = false;
      }
    },
    onClickCloseModal() {
      this.$emit("reset-game");
    },
    getLangImg(obj) {
      let imgSrc = obj.img[0].src;
      let tempSrc = obj.img.find((i) => i.langId == this.$store.getters.langId);
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    getLangImg2(obj) {
      if (obj.img2) {
        let imgSrc = obj.img2[0].src;
        let tempSrc = obj.img2.find((i) => i.langId == this.$store.getters.langId);
        if (tempSrc) {
          imgSrc = tempSrc.src;
        }
        return imgSrc;
      }
    },
    async getGameDetails() {
      this.showProgress = true;
      const gameCode = this.selectedGame.code;
      if (this.$store.getters.suspend == true) {
        this.gameBalance = 0;
        this.showProgress = false;
        this.isErr = true;
        this.errorMessage2 = t("AccountSuspended");
        return;
      }
      try {
        //update
        await this.$store.dispatch("getMemberBalanceMobileLanding");
        let tempAmt = Math.floor(Number(this.$store.getters.totalRemainingCreditLimit));
        this.inputAmount = this.processAmount(tempAmt.toString());
        const response = await CustomHttp.getOneGame(gameCode);
        this.isErr = false;
        this.errorMessage2 = "";
        this.gameBalance = response.credit[0];
        this.showProgress = false;
      } catch (err) {
        this.gameBalance = 0;
        this.showProgress = false;
        let tempMessage = err.message;
        tempMessage = tempMessage.replace("Error:", "");
        if (
          tempMessage.trim() == "Cannot read properties of undefined (reading 'data')"
        ) {
          tempMessage = "Session Time Out";
          this.$store.dispatch("userLogout");
          this.timeoutFlag = true;
        } else {
          this.timeoutFlag = false;
        }
        this.isErr = true;
        this.errorMessage2 = tempMessage;
        // if (tempMessage.trim() == 'Game maintenance!') {
        //   this.isErr = true;
        //   this.errorMessage2 = t('GameMaintenance');
        // } else {
        //   this.$swal.fire(tempMessage);
        //   this.$refs.closeBtn.click();
        // }

        //this.$store.dispatch('userLogout');
        // this.$swal.fire('Invalid session, please login again.');
      }
    },
    async getGameDetails2(game) {
      this.showProgress = true;
      //reset variable
      this.timeoutFlag = false;
      this.errorMessage = "";
      this.isErr = false;
      this.errorMessage2 = "";
      this.formSubmitted = false;
      this.inputAmount = null;

      this.$emit("set-selected-game", game);
      const gameCode = game.code;
      if (this.$store.getters.suspend == true) {
        this.gameBalance = 0;
        this.showProgress = false;
        this.isErr = true;
        this.errorMessage2 = t("AccountSuspended");
        return;
      }
      try {
        //update
        await this.$store.dispatch("getMemberBalanceMobileLanding");
        let tempAmt = Math.floor(Number(this.$store.getters.totalRemainingCreditLimit));
        this.inputAmount = this.processAmount(tempAmt.toString());
        const response = await CustomHttp.getOneGame(gameCode);
        this.isErr = false;
        this.errorMessage2 = "";
        this.gameBalance = response.credit[0];
        this.showProgress = false;
      } catch (err) {
        this.gameBalance = 0;
        this.showProgress = false;
        let tempMessage = err.message;
        tempMessage = tempMessage.replace("Error:", "");
        if (
          tempMessage.trim() == "Cannot read properties of undefined (reading 'data')"
        ) {
          tempMessage = "Session Time Out";
          this.$store.dispatch("userLogout");
          this.timeoutFlag = true;
        } else {
          this.timeoutFlag = false;
        }
        this.isErr = true;
        this.errorMessage2 = tempMessage;
        // if (tempMessage.trim() == 'Game maintenance!') {
        //   this.isErr = true;
        //   this.errorMessage2 = t('GameMaintenance');
        // } else {
        //   this.$swal.fire(tempMessage);
        //   this.$refs.closeBtn.click();
        // }

        //this.$store.dispatch('userLogout');
        // this.$swal.fire('Invalid session, please login again.');
      }
    },
    onChangeAmount() {
      if (this.formSubmitted) {
        let tempAmount = (" " + this.inputAmount.toString()).slice(1);
        this.formSubmitted = true;
        tempAmount = tempAmount.replace(/[^0-9]+/g, "");
        let tempAmount2 = (
          " " + this.$store.getters.totalRemainingCreditLimit.toString()
        ).slice(1);
        if (Number(tempAmount) > Number(tempAmount2)) {
          this.errorMessage =
            "Insufficient Balance. Please adjust your amount or reload to start the game.";
          let oldAmount = Math.floor(Number(tempAmount));
          this.inputAmount = this.processAmount(oldAmount.toString());
        } else {
          this.errorMessage = "";
        }
      }
    },
    filterInput(e) {
      //remove symbol
      e.target.value = this.processAmount(e.target.value);
    },
    processAmount(value) {
      let result = value;

      result = result.replace(/\/r/, "/");
      //only number, need change
      result = result.replace(/[^0-9]+/g, "");
      //e.target.value = e.target.value.toString();
      // e.target.value = e.target.value.replaceAll('[^a-zA-Z0-9]', '');
      //  e.target.value = Number(e.target.value);
      // e.target.value = Math.abs(e.target.value);
      result = result.toString();
      if (result == "00") {
        result = 0;
      } else if (result.length > 1) {
        result = result.replace(/^0+/, "");
      }
      // add  comma
      result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (result.length > this.maxInputLenth) {
        result = result.slice(0, this.maxInputLenth);
      }
      return result;
    },
    async onClickStartGame() {
      let tempAmount = (" " + this.inputAmount.toString()).slice(1);
      this.formSubmitted = true;
      //remove comma
      tempAmount = tempAmount.replace(/[^0-9]+/g, "");
      //amount validation
      let tempAmount2 = (
        " " + this.$store.getters.totalRemainingCreditLimit.toString()
      ).slice(1);
      if (Number(tempAmount) > Number(tempAmount2)) {
        this.errorMessage =
          "Insufficient Balance. Please adjust your amount or reload to start the game.";
        return;
      }
      let oldAmount = Math.floor(Number(tempAmount));
      this.inputAmount = this.processAmount(oldAmount.toString());
      this.isLoading = true;
      const gameCode = this.selectedGame.code;
      // var windowReference = window.open("/initializing");
      CustomStorage.setItem("gameCode", gameCode);
      CustomStorage.setItem("tempAmount", tempAmount);
      this.$refs.closeBtn.click();
      this.isLoading = false;
      window.open("/initializing");
    }, // end onClickStartGame
  },
  computed: {
    suspend() {
      return this.$store.getters.suspend;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    totalRemainingCreditLimit() {
      // const current = new Date();
      return this.$store.getters.totalRemainingCreditLimit;
    },
  },
};
</script>

<style scoped lang="css" src="../../../assets/css/landing.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.transfer-wrap {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
</style>
