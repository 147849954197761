var preferenceImport = {
  Language: [
    { desc: 'EN-US', value: '0', remark: 'ENGLISH', gameLang: 'en-US' },
    { desc: 'ZH-CN', value: '1', remark: '中文（简体)', gameLang: 'zh-CN' },
    //{ desc: 'EN-GB', value: '2', remark: 'ภาษาไทย', gameLang: 'th-TH' },
    { desc: 'EN-IE', value: '3', remark: 'Tiếng Việt', gameLang: 'vi-VN' },
    { desc: 'SW', value: '4', remark: 'Kiswahili', gameLang: 'en-US' },
    //{ desc: 'EN-AU', value: '4', remark: 'INDONESIAN', gameLang: 'id-ID' },
    //{ desc: 'JA-JP', value: '5', remark: '日本語', gameLang: 'ja-JP' },
    //{ desc: 'EN-TT', value: '6', remark: 'KOREAN', gameLang: 'en-US' },
    //{ desc: 'ES-PA', value: '7', remark: 'Español', gameLang: 'en-US' },
    //{ desc: 'EN-PH', value: '8', remark: 'Português', gameLang: 'en-US' },
    //{ desc: 'EN-JM', value: '9', remark: 'FRENCH', gameLang: 'en-US' },
    //{ desc: 'EN-BZ', value: 'A', remark: 'MYANMAR', gameLang: 'en-US' },
    //{ desc: 'ES-PR', value: 'B', remark: 'LAOS', gameLang: 'en-US' },
  ],
  OddsType: [
    { desc: 'MY', value: '0' },
    { desc: 'HK', value: '1' },
    { desc: 'ID', value: '2' },
    { desc: 'EU', value: '3' },
  ],
  OddsView: [
    { desc: 'Single', value: '0' },
    { desc: 'Double', value: '1' },
    { desc: 'Simple', value: '2' },
  ],
  DefaultStake: [
    { desc: 'Disable', value: '0' },
    { desc: 'Enable', value: '1' },
  ],
  AcceptBetterOdds: [
    { desc: 'No', value: '0' },
    { desc: 'Yes', value: '1' },
  ],
  SortBy: [
    { desc: 'League', value: '0' },
    { desc: 'Time', value: '1' },
  ],
  TimeZone: [
    { value: '1', desc: '(GMT -12:00) Eniwetok, Kwajalein' },
    { value: '2', desc: '(GMT -11:00) Midway Island, Samoa' },
    { value: '3', desc: '(GMT -10:00) Hawaii' },
    { value: '4', desc: '(GMT -9:30) Taiohae' },
    { value: '5', desc: '(GMT -9:00) Alaska' },
    { value: '6', desc: '(GMT -8:00) Pacific Time (US, Canada)' },
    { value: '7', desc: '(GMT -7:00) Mountain Time (US, Canada)' },
    { value: '8', desc: '(GMT -6:00) Central Time (US, Canada), Mexico City' },
    { value: '9', desc: '(GMT -5:00) Eastern Time (US, Canada), Bogota, Lima' },
    { value: '10', desc: '(GMT -4:30) Caracas' },
    {
      value: '11',
      desc: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    },
    { value: '12', desc: '(GMT -3:30) Newfoundland' },
    { value: '13', desc: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { value: '14', desc: '(GMT -2:00) Mid-Atlantic' },
    { value: '15', desc: '(GMT -1:00) Azores, Cape Verde Islands' },
    {
      value: '16',
      desc: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    },
    { value: '17', desc: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
    { value: '18', desc: '(GMT +2:00) Kaliningrad, South Africa' },
    {
      value: '19',
      desc: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    },
    { value: '20', desc: '(GMT +3:30) Tehran' },
    { value: '21', desc: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { value: '22', desc: '(GMT +4:30) Kabul' },
    {
      value: '23',
      desc: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    },
    { value: '24', desc: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
    { value: '25', desc: '(GMT +5:45) Kathmandu, Pokhar' },
    { value: '26', desc: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { value: '27', desc: '(GMT +6:30) Yangon, Mandalay' },
    { value: '28', desc: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    {
      value: '29',
      desc: '(GMT +8:00) Beijing, Hong Kong, Kuala Lumpur, Singapore',
    },
    { value: '30', desc: '(GMT +8:45) Eucla' },
    { value: '31', desc: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { value: '32', desc: '(GMT +9:30) Adelaide, Darwin' },
    { value: '33', desc: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
    { value: '34', desc: '(GMT +10:30) Lord Howe Island' },
    {
      value: '35',
      desc: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    },
    { value: '36', desc: '(GMT +11:30) Norfolk Island' },
    { value: '37', desc: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
    { value: '38', desc: '(GMT +12:45) Chatham Islands' },
    { value: '39', desc: '(GMT +13:00) Apia, Nukualofa' },
    { value: '40', desc: '(GMT +14:00) Line Islands, Tokelau' },
  ],
};

export default preferenceImport;
