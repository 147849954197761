<template>
  <div id="main" class="content-section aboveBackground">
    <nav class="nav nav-pills nav-justified">
      <a
        class="nav-item nav-link"
        :class="{ active: selectedTab == 'bet' }"
        @click="onclickTab('bet')"
        href="#"
        style="border-right: 1px #0fcdf0 solid;"
      >
        {{ $t('BetList') }}
      </a>
      <a
        class="nav-item nav-link"
        :class="{ active: selectedTab == 'statement' }"
        @click="onclickTab('statement')"
        href="#"
        style="border-right: 1px #0fcdf0 solid;"
      >
        {{ $t('Statement3') }}
      </a>
      <a
        class="nav-item nav-link"
        :class="{ active: selectedTab == 'result' }"
        @click="onclickTab('result')"
        href="#"
      >
        {{ $t('Result') }}
      </a>
    </nav>
    <!------bet list---->
    <section v-if="selectedTab == 'bet'">
      <BetComponent />
    </section>
    <!---statement -->
    <section v-if="selectedTab == 'statement'">
      <StatementDetailsComponent />
    </section>

    <!---result -->
    <section v-if="selectedTab == 'result'">
      <ResultComponent />
    </section>
  </div>
</template>

<script>
import BetComponent from './StatementPage/Bet.vue';
import StatementDetailsComponent from './StatementPage/StatementDetails.vue';
import ResultComponent from './StatementPage/Result.vue';
//Vue export
export default {
  components: {
    BetComponent,
    StatementDetailsComponent,
    ResultComponent,
  },
  data() {
    return {
      selectedTab: 'result',
    };
  },
  methods: {
    onclickTab(tabName) {
      this.selectedTab = tabName;
    },
  },
};
</script>
<style scoped>
.content-section {
  margin-top: 10px !important;
}
.content-section {
  color: inherit;
}
</style>
