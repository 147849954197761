<template>
  <div id="home-body">
    <div class="glow-backdrop"></div>
    <div class="row mx-0 pt-5">
      <desktop-header></desktop-header>
      <!--- page content --->
      <div class="py-4 col-12 px-0 py-5">
        <div class="container clear-both px-0 container-min-height">
          <div class="row px-0">
            <!--- page header --->
            <!--- end page header --->
            <!----card section --->
            <div class="col-12">
              <div class="row">
                <div class="col-lg-3">
                  <div class="left-card">
                    <template v-if="!showFirstLaodingProgress">
                    <h3 class="statement-title">{{$t('Welcome')}} !</h3>
                    <div class="col-12 px-0 d-flex justify-content-center">
                      <img
                        src="../../../../assets/icons/avatar.png"
                        class="img-avatar py-2"
                      />
                    </div>

                    
                      <div class="col-12 pt-2 pb-0 px-0 d-flex justify-content-center">
                        <span class="username-text"> {{ username }}</span>
                      </div>
                      <div class="col-12 pt-1 pb-0 px-0 d-flex justify-content-center">
                        <div
                          class="img-separator"
                        ></div>
                      </div>

                      <div
                        class="col-12 pt-1 pb-0 px-0 d-flex justify-content-center"
                      >
                      <div>
                        <span class="credit-text">
                          <span v-if="!isLoadingBalance">
                            {{ currency }} {{ $filters.currency(totalRemainingCreditLimit) }}
                          </span>

                          <!-- <span v-if="isLoadingBalance">
                            <i class="fas fa-spinner fa-pulse"></i>
                          </span> -->
                        </span>
                        
                      </div>


                        <span v-if="!isLoadingBalance">

                          <img
                            src="../../../../assets/icons/icn-refresh.png"
                            class="img-responsive mt-1 ml-2 cursor-pointer"
                            alt="Responsive image"
                            width="22"
                            height="22"
                            @click="onClickRefreshBalance"
                          />
                        </span>
                        <span v-if="isLoadingBalance">
                          <i
                            class="fas fa-spinner fa-pulse fa-lg text-dark my-2 font-weight-bold"
                          ></i>
                        </span>
                      </div>
                      <div class="smaller text-center">CURRENT BALANCE</div>

                    </template>
                    <template v-if="showFirstLaodingProgress">
                      <div class="col-lg-12 p-1">
                        <div class="d-flex align-items-center">
                          <progress-spinner></progress-spinner>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <!----main statement content---->
                <div class="col-lg-9 right-card-container">
                  <div class="right-card">
                    <template v-if="showProgress">
                      <div class="col-lg-12 p-1">
                        <div class="d-flex align-items-center">
                          <progress-spinner></progress-spinner>
                        </div>
                      </div>
                    </template>
                    <template v-if="!showProgress">
                      <div>

                        <div class="col-spaced mb-4">
                          <h3 class="neon text-uppercase">{{$t('Statement')}}</h3>
                          <div class="float-right">


                            <button
                              class="btn btn-default mr-3 p-2"
                              :class="[isSelectThisWeek ? 'btn-inactive' : 'btn-active']"
                              @click="onClickLastWeek"
                            >
                              {{ $t("LastWeek") }}
                            </button>
                            <button
                              class="btn btn-default p-2"
                              :class="[isSelectLastWeek ? 'btn-inactive' : 'btn-active']"
                              @click="onClickThisWeek"
                            >
                              {{ $t("ThisWeek") }}
                            </button>


                          </div>
                        </div>

                        <table class="mb-0">
                          <thead>
                            <tr>
                              <th class="radius-top-left" scope="col" style="width: 25%">
                                {{ $t("Date") }}
                              </th>
                              <th class="text-right" scope="col" style="width: 12%">
                                {{ $t("Stake") }}
                              </th>
                              <th class="text-right" scope="col" style="width: 18%">
                                {{ $t("W/L") }}
                              </th>
                              <th class="text-right" scope="col" style="width: 12%">
                                {{ $t("Comm") }}
                              </th>
                              <th class="text-right" scope="col" style="width: 15%">
                                {{ $t("Settled") }}
                              </th>
                              <th
                                class="text-right radius-top-right"
                                scope="col"
                                style="width: 18%"
                              >
                                {{ $t("Balance") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="statementList.length >= 1">
                            <tr
                              v-for="(statement, index) in statementList"
                              :key="statement.date"
                            >
                              <td
                              class="nowrap"
                                :class="{
                                  'radius-bottom-left ':
                                    statementList.length - 1 == index,
                                  'border-top-none': index == 0,
                                }"
                              >
                                <span class="b" v-if="langId != '0'">
                                  {{ $t(getWeekDay(statement.date)) }} &nbsp;
                                </span>
                                <span class="date-color">
                                  {{ getDateString(statement.date) }}
                                </span>
                                &nbsp;
                                <span class="b" v-if="langId == '0'">
                                  {{ $t(getWeekDay(statement.date)) }}
                                </span>
                              </td>
                              <td
                                class="b text-right"
                                :class="{
                                  'border-top-none': index == 0,
                                }"
                              >
                                {{ $filters.currency(statement.stake) }}
                              </td>
                              <td
                                class="b text-right"
                                :class="{
                                  'border-top-none': index == 0,
                                }"
                              >
                                {{ $filters.currency(statement.winloseAmt) }}
                              </td>
                              <td
                                class="b text-right"
                                :class="{
                                  'border-top-none': index == 0,
                                }"
                              >
                                {{ $filters.currency(statement.comm) }}
                              </td>
                              <td
                                class="b text-right"
                                :class="{
                                  'border-top-none': index == 0,
                                }"
                              >
                                {{ $filters.currency(getAbsNumber(statement.settled)) }}
                              </td>
                              <td
                                :class="{
                                  'radius-bottom-right':
                                    statementList.length - 1 == index,
                                  'border-top-none': index == 0,
                                }"
                                class="font-weight-bold text-right"
                              >
                                {{ $filters.currency(statement.balance) }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="statementList.length == 0">
                            <tr>
                              <td colspan="6">
                                <div class="col-12 px-0 d-flex justify-content-center">
                                  <img
                                    src="../../../../assets/icons/search-empty.png"
                                    class="img-not-found mx-auto d-block"
                                  />
                                </div>
                                <div class="col-12 py-3 d-flex justify-content-center">
                                  <span class="text-muted">
                                    no statement record found
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!---end card section -->
          </div>
        </div>
      </div>

      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CustomHttp from "../../../../CustomHttp";

//Vue export
export default {
  components: {},
  mounted() {
    this.showProgress = false;
    this.onClickThisWeek();
  },
  data() {
    return {
      isLoadingBalance: false,
      showFirstLaodingProgress: null,
      showProgress: false,
      isSelectThisWeek: false,
      isSelectLastWeek: false,
      statementList: [],
    };
  },
  methods: {
    getAbsNumber(num) {
      return Math.abs(num);
    },
    getDateString(date) {
      const tempDate = new Date(date);
      return moment(tempDate.getTime()).format("DD/MM/YYYY").toString();
    },
    getWeekDay(date) {
      let weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const tempDate = new Date(date);
      return weekday[tempDate.getDay()];
    },
    onClickThisWeek() {
      this.isSelectThisWeek = true;
      this.isSelectLastWeek = false;
      this.getMemberStatement();
    },
    onClickLastWeek() {
      this.isSelectThisWeek = false;
      this.isSelectLastWeek = true;
      this.getMemberStatement();
    },
    async getMemberStatement() {
      let startDate;
      let endDate;

      startDate = moment().startOf("week").toDate();
      endDate = moment().endOf("week").toDate();

      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);

      if (this.isSelectLastWeek) {
        startDate.setDate(startDate.getDate() - 7);
        endDate.setDate(endDate.getDate() - 7);
      }

      let fromDate = startDate;
      let toDate = endDate;

      const todayDate = new Date();

      if (toDate > todayDate) {
        toDate = new Date();
      }

      //handle sunday error
      if (todayDate.getDay() == 0 && this.isSelectThisWeek) {
        fromDate.setDate(fromDate.getDate() - 7);
        toDate = new Date();
      } else if (todayDate.getDay() == 0 && this.isSelectLastWeek) {
        fromDate.setDate(toDate.getDate() - 13);
        toDate = new Date();
        toDate.setDate(toDate.getDate() - 7);
      }

      const json = {
        username: this.$store.getters.username,
        fromDate: moment(fromDate.getTime()).format("YYYY-MM-DD"),
        toDate: moment(toDate.getTime()).format("YYYY-MM-DD"),
        //fromDate: '2021-10-14',
        // toDate: '2021-10-24',
      };
      this.showProgress = true;
      if (this.showFirstLaodingProgress == null) {
        this.showFirstLaodingProgress = true;
      }
      const response = await CustomHttp.post("api/v1/member/statement", json);

      if (response.data.data == null) {
        this.statementList = [];
        // this.statementList = [
        //   {
        //     date: '2021-10-25T00:00:00',
        //     stake: 38837,
        //     winloseAmt: 0,
        //     comm: 0,
        //     settled: -3573.1,
        //     balance: 231924,
        //   },
        // ];
      } else {
        this.statementList = response.data.data.memberStatementList;
      }
      // const latestBalance = this.statementList[this.statementList.length - 1]
      //   .balance;
      // const latestStake = this.statementList[this.statementList.length - 1]
      //   .stake;
      // for (
      //   var d = new Date(fromDate);
      //   d <= toDate;
      //   d.setDate(d.getDate() + 1)
      // ) {
      //   const existFlag = this.statementList.find(
      //     (s) => s.date.getTime() == d.getTime()
      //   );
      //   if (!existFlag) {
      //     this.statementList.push({
      //       date: d,
      //       stake: latestStake,
      //       winloseAmt: 0,
      //       comm: 0,
      //       settled: 0,
      //       balance: latestBalance,
      //     });
      //   }
      // }

      this.showFirstLaodingProgress = false;
      this.showProgress = false;
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch("getMemberBalance");
        this.isLoadingBalance = false;
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    username() {
      return this.$store.getters.username;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    currency() {
      return this.$store.getters.currency;
    },
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>

<style scoped>
.nowrap{
  white-space: nowrap;
}
.statement-title{
  font-family: cclip;
  text-align: center;
  color: #00edff;
  margin: 0.25em 0 1em;
  -webkit-text-stroke: 0.5px #00edff;
  text-transform: uppercase;
}
#home-body{
  background: url('../../../../assets/images/desktop-bg.jpg') 0 0 / cover;
}
.card {
  border: 0px;
}
.bg-light-grey {
  background-color: #f7f7f7;
}

.bg-light-gold-gradient {
  background: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
  border-radius: 5px;
}

.menu-text {
  margin-top: 4px;
  font-size: 11px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  border-radius: 5px;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background:  #005dff44;
  border: 1px solid white;
  color: white;
  border-radius: 18px;
  height: min(300px,100%);
  padding: 1em 1em 3em 1em;
}

.right-card {
  border-radius: 5px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: transparent !important;
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}

.img-separator {
  object-fit: contain;
  width: 60%;
  border-top: 1px solid #00edff;
  margin: 0 auto;
  margin: 5px 0;
}
.username-text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #00edff;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
}

.credit-text {
  font-size: 20px;
  font-weight: 600;
}

.account-tab-active {
  background-image: linear-gradient(to bottom, #36342f, #5c4812);
}

.account-tab {
  background-color: #fff;
}

/* .account-tab-active:hover,
.account-tab:hover {
  opacity: 1;
} */

.tab-label-active {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.tab-label {
  color: #908d84;
  font-size: 14px;
  font-weight: 600;
}

.img-tab {
  width: 22px;
  height: 22px;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.btn-inactive {
  font-size: 12px;
  font-weight: 600;
  color: black;
  /* font-family: SegoeUI; */
  border: solid 1px #ddd;
  background: linear-gradient(to bottom, #00edff, #22a4dd);
  border-radius: 8px;
  opacity: 0.65;
  border: none;
}

.btn-active {
  /* font-family: SegoeUI; */
  font-size: 12px;
  font-weight: 600;
  color: black;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, #00edff, #22a4dd);
}

.border-radius-19 {
  border-radius: 19px !important;
}

tr th {
    background: rgba(0, 67, 72, 0.9);
}

tr th {
  letter-spacing: 0.18px;
  text-align: left;
  color: #fff;
  font-weight: bold;
}

tr td {
  width: 58px;
  height: 16px;
  margin: 0 12px 0.2px 0;
  /* font-family: SegoeUI; */
  font-size: 14px;
  text-align: left;
}

.date-color {
  font-weight: 700;
}

.img-not-found {
  width: 60px;
  margin-top: 2em;
}

.container-min-height {
  min-height: 85vh;
}


tr:nth-child(even){
  background: rgba(0, 67, 72, 0.7);
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}

th,
td {
  padding: 10px;
  border: solid 1px #95ffb166;
}

.radius-top-left {
  border-radius: 5px 0 0 0;
}

.radius-top-right {
  border-radius: 0 5px 0 0;
}

.radius-bottom-left {
  border-radius: 0 0 0 5px !important;
}

.radius-bottom-right {
  border-radius: 0 0 5px 0 !important;
}

table {
  border-collapse: separate !important;
}

.table td,
.table th {
  padding-right: 0.75rem !important;
}

.border-top-none {
  border-top: 0px !important;
}
.right-card-container{
  padding: 7px;
  border-radius: 35px;
  border: solid 2px #6fecff;
}
.right-card {
  border-radius: spx;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: transparent;
  padding: 20px 25px;
  border-radius: 25px;
  border: solid 2px #6fecff;
  min-height: 440px;
  overflow-y: auto;
} 
</style>
