import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home';

import History from '../components/mobile/pages/History.vue';
import Agreement from '../components/global/pages/Agreement/TermAndCondition.vue';
import ChangePassword from '../components/global/pages/ForceChangePassword.vue';
import Account from '../components/desktop/pages/account/Account.vue';
import Statement from '../components/desktop/pages/statement/Statement.vue';
import CreditHistory from '../components/desktop/pages/history/CreditHistory.vue';
import SystemMessage from '../components/desktop/pages/message/SystemMessage.vue';
import CashOut from '../components/global/pages/CashOut.vue';
import Maintenance from '../components/global/pages/Maintenance.vue';
//game page import
import Slot from '../components/desktop/pages/games/Slot.vue';
import LiveCasino from '../components/desktop/pages/games/LiveCasino.vue';
import Itp from '../components/desktop/pages/games/Itp.vue';
import Cards from '../components/desktop/pages/games/Cards.vue';
import Fishing from '../components/desktop/pages/games/Fishing.vue';
import Lotto from '../components/desktop/pages/games/Lotto.vue';
import CockFt from '../components/desktop/pages/games/CockFt.vue';
import Sportsbook from '../components/desktop/pages/games/Sportsbook.vue';
//static page
import Info from '../components/desktop/pages/infor/Infor.vue';
import Info2 from '../components/desktop/pages/infor/Infor.vue';
import InitialGame from '../components/global/pages/InitialGame.vue';

import store from './../store/index';
import { loadImage } from './loadImage';
const router = createRouter({
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      children: [],
      // eslint-disable-next-line
      beforeEnter(to, from, next) {
        loadImage();
        next();
      },
    },
    {
      path: '/history',
      name: 'History',
      meta: { needToken: true },
      component: History,
      // beforeEnter(to, from, next) {
      //   next()
      // },
    },
    {
      path: '/agreement',
      meta: { needToken: true },
      name: 'Agreement',
      component: Agreement,
    },
    {
      path: '/account',
      meta: { needToken: true },
      name: 'Account',
      component: Account,
    },
    {
      path: '/change-password',
      meta: { reqChgPw: true },
      name: 'ChangePassword',
      component: ChangePassword,
    },
    {
      path: '/statement',
      meta: { needToken: true },
      name: 'Statement',
      component: Statement,
    },
    {
      path: '/message',
      meta: { needToken: true },
      name: 'Message',
      component: SystemMessage,
    },
    {
      path: '/credit-history',
      meta: { needToken: true },
      name: 'CreditHistory',
      component: CreditHistory,
    },
    {
      path: '/cash-out',
      meta: { title: 'Cash Out', needToken: true },
      name: 'CashOut',
      component: CashOut,
    },
    {
      path: '/slot',
      name: 'Slot',
      component: Slot,
    },
    //desktop static page
    {
      path: '/about',
      name: 'Info',
      component: Info,
    },
    {
      path: '/rules',
      name: 'rules',
      component: Info2,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Info,
    },
    {
      path: '/faq',
      name: 'faq',
      component: Info,
    },
    {
      path: '/why-us',
      name: 'why-us',
      component: Info,
    },
    //end static page
    {
      path: '/live-casino',
      name: 'LiveCasino',
      component: LiveCasino,
    },
    {
      path: '/itp',
      name: 'Itp',
      component: Itp,
    },
    {
      path: '/cards',
      name: 'Cards',
      component: Cards,
    },
    {
      path: '/fishing',
      name: 'Fishing',
      component: Fishing,
    },
    {
      path: '/lotto',
      name: 'Lotto',
      component: Lotto,
    },
    {
      path: '/cockft',
      name: 'CockFt',
      component: CockFt,
    },
    {
      path: '/sportsbook',
      name: 'Sportsbook',
      component: Sportsbook,
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: Maintenance,
    },
    {
      path: '/initializing',
      meta: { title: 'Loading...', needToken: true },
      name: 'InitialGame',
      component: InitialGame,
    },
    //404 should come last
    { path: '/:notFound(.*)', name: '404', redirect: '/' },
    //  { path: '/Home', name: 'Home', component: Home },
  ],
});

router.beforeEach(function (to, from, next) {
  // console.log(from);
  //console.log(to);
  // console.log(store.getters.reqChgPw);

  if (
    to.fullPath == '/' &&
    store.getters.token &&
    store.getters.isLoggedIn == 'false'
  ) {
    next('/agreement');
  } else if (
    to.fullPath == '/agreement' &&
    store.getters.token &&
    store.getters.isLoggedIn == 'false'
  ) {
    next();
  } else if (to.fullPath == '/' && store.getters.reqChgPw == 'true') {
    next('/change-password');
  } else if (to.fullPath == '/change-password' && !store.getters.token) {//remove this 
    next('/');
  } else if (to.name == 'ChangePassword' && store.getters.reqChgPw == 'false') {//and this to access force pwd change page
    next('/');
  } else if (to.meta.needToken && store.getters.reqChgPw == 'true') {
    next('/change-password');
  } else if (to.meta.needToken && !store.getters.token) {
    next('/');
  } else {
    next();
  }
});
// router.replace({ path: '/', redirect: '/' })
export default router;
