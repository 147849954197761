<template>
  <div class="body-wrap">
    <div class="container1">
      <div class="main-img"></div>
      <div class="centered">{{ $t("ContactUs") }}</div>
    </div>

    <!-- Small Thumbnail -->
    <div class="content-wrapper">
      <h1>Smart Betting</h1>
      <br />
      <a href="https://smart.vsport.com" target="_blank">smart.vsport.com</a>
      <br />
      <br />

      <h1>Customer Service</h1>
      <br />
      <a href="mailto:support@vsport.com" target="_top">support@vsport.com</a>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  /* height: 823px; */
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url("../../../../assets/images/contact.jpg");
  background-size: cover;
  background-position: center;
}
* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #2c1901;
  display: inline-block;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: white;
  font-size: 15px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
  padding: 60px;
  border-top: 1px solid #2c1901;
  border-bottom: 1px solid #2c1901;
  height: 435 px;
  overflow-y: auto;
  max-height: 600px;
}

a {
  color: #4ac8e2;
  text-decoration: none;
}
a:focus,
a:hover {
  color: #4ac8e2;
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}
</style>
