//banner import
import banner1 from './../images/promo1.png';
import banner2 from './../images/promo2.png';
import banner3 from './../images/promo3.png';
//icon import
import icnHot from './../icons/menu/icn-hot.png';
import icnHotActive from './../icons/menu/icn-hot-active.png';
import sb from './../icons/menu/sb.png';
import sbActive from './../icons/menu/sb-active.png';
import lc from './../icons/menu/lc.png';
import lcActive from './../icons/menu/lc-active.png';
import slot from './../icons/menu/slot.png';
import slotActive from './../icons/menu/slot-active.png';
import itp from './../icons/menu/itp.png';
import itpActive from './../icons/menu/itp-active.png';
import cards from './../icons/menu/cards.png';
import cardsActive from './../icons/menu/cards-active.png';
import fishing from './../icons/menu/fish.png';
import fishingActive from './../icons/menu/fish-active.png';
import lotto from './../icons/menu/lotto.png';
import lottoActive from './../icons/menu/lotto-active.png';
import cockft from './../icons/menu/cockft.png';
import cockftActive from './../icons/menu/cockft-active.png';
// game menu image
import gameSportsbook from './../images/game/sportsbook.png';
import gameSportsbookTh from './../images/game/sportsbook-th.png';

//game card lg
import gameLgFishing from './../images/game-lg/fishing.png';
import gameLgCasino from './../images/game-lg/casino.png';
import gameLgCard from './../images/game-lg/card.png';
//game card sm
import miniHabanero from './../images/game-sm/habanero.png';
import miniFachai from './../images/game-sm/fachai.png';
import miniPtS from './../images/game-sm/pts.png';
import miniSpade from './../images/game-sm/spade.png';
import miniJoker from './../images/game-sm/joker.png';
import miniJili from './../images/game-sm/jili.png';
import miniRedTiger from './../images/game-sm/redtiger.png';
import miniPlayAndGo from './../images/game-sm/playngo.png';
import miniNextSpin from './../images/game-sm/nextspin.png';
import miniKing from './../images/game-sm/king.png';
import miniCQ9 from './../images/game-sm/cq9.png';
import miniAEG from './../images/game-sm/aegaming.png';
import miniGioco from './../images/game-sm/gioco.png';
//  page
import miniPocket from './../images/game-sm/pocket.png';
import miniPlay8 from './../images/game-sm/play8.png';
import miniPrag from './../images/game-sm/pragmatic.png';
import miniGenesis from './../images/game-sm/genesis.png';
var desktopImport = {
  //banner  image import
  bannerUrl1: banner1,
  bannerUrl2: banner2,
  bannerUrl3: banner3,
  //icon import
  icnHot,
  icnHotActive,
  sb,
  sbActive,
  lc,
  lcActive,
  slot,
  slotActive,
  itp,
  itpActive,
  cards,
  cardsActive,
  fishing,
  fishingActive,
  lotto,
  lottoActive,
  cockft,
  cockftActive,
  // game image import
  gameSportsbook,
  gameSportsbookTh,
  //card lg
  gameLgFishing,
  gameLgCasino,
  gameLgCard,
  //game sm
  miniHabanero,
  miniFachai,
  miniPtS,
  miniSpade,
  miniJoker,
  miniJili,
  miniRedTiger,
  miniPlayAndGo,
  miniNextSpin,
  miniKing,
  miniCQ9,
  miniAEG,
  miniGioco,
  miniPocket,
  miniPlay8,
  miniPrag,
  miniGenesis,
};

export default desktopImport;
