<template>
  <div class="col-lg-9 right-card-container">
    <div class="card right-card">
      <div class="info-text col-12">{{ $t('ChangePassword') }}</div>

      <div class="password-wrap">
        <div class="row">
          <div class="col-3 pl-0 profile-label d-flex align-items-center">
            {{ $t('CurrentPassword') }}
          </div>
          <div class="col-4 pl-0">
            <input
              type="password"
              class="form-control"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              v-model.trim="inputCurrent"
              :placeholder="$t('CurrentPassword')"
              v-on:input="onChangeNewPassword()"
            />
          </div>
        </div>
      </div>
      <div class="password-wrap">
        <div class="row">
          <div class="col-3 pl-0 profile-label d-flex align-items-center">
            {{ $t('NewPassword') }}
          </div>
          <div class="col-4 pl-0">
            <input
              type="password"
              class="form-control"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              v-model.trim="inputNew"
              :placeholder="$t('NewPassword')"
              v-on:input="onChangeNewPassword()"
            />
          </div>
        </div>
      </div>
      <div class="password-wrap">
        <div class="row">
          <div class="col-3 pl-0 profile-label d-flex align-items-center">
            {{ $t('ConfirmPassword') }}
          </div>
          <div class="col-4 pl-0">
            <input
              type="password"
              class="form-control"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              :placeholder="$t('ConfirmPassword')"
              v-model.trim="inputConfirm"
              v-on:input="onChangeNewPassword()"
            />
          </div>
        </div>
      </div>
      <div class="validate-wrap mt-4">
        <div
          class="row d-flex align-items-center"
          :class="[validateFlag1 ? 'text-success' : 'text-muted']"
        >
          <i class="fas fa-check-circle"></i>
          <span class="password-info pl-2">
            {{ $t('Password_RemarkLength') }}
          </span>
        </div>
      </div>
      <div class="validate-wrap">
        <div
          class="row d-flex align-items-center"
          :class="[validateFlag5 ? 'text-success' : 'text-muted']"
        >
          <i class="fas fa-check-circle"></i>
          <span class="password-info pl-2">
            {{ $t('NewPasswordCannotBeSameAsOldPassword') }}
          </span>
        </div>
      </div>
      <div class="validate-wrap">
        <div
          class="row d-flex align-items-center"
          :class="[validateFlag2 ? 'text-success' : 'text-muted']"
        >
          <i class="fas fa-check-circle"></i>
          <span class="password-info pl-2">
            {{ $t('Password_RemarkInclude') }}
          </span>
        </div>
      </div>
      <div class="validate-wrap">
        <div
          class="row d-flex align-items-center"
          :class="[validateFlag3 ? 'text-success' : 'text-muted']"
        >
          <i class="fas fa-check-circle"></i>
          <span class="password-info pl-2">
            {{ $t('Password_RemarkExclude') }}
          </span>
        </div>
      </div>
      <div class="validate-wrap">
        <div
          class="row d-flex align-items-center"
          :class="[validateFlag4 ? 'text-success' : 'text-muted']"
        >
          <i class="fas fa-check-circle"></i>
          <span class="password-info pl-2">
            {{ $t('ConfirmPasswordMustSame') }}
          </span>
        </div>
      </div>
      <div class="save-btn-wrap mt-5">
          <div class="col-3 profile-label">
            <button
              class="btn btn-secondary btn-save px-4"
              :disabled="
                !validateFlag1 ||
                !validateFlag2 ||
                !validateFlag3 ||
                !validateFlag4 ||
                !validateFlag5
              "
              @click="onClickSave"
            >
              &nbsp; {{ $t('SaveBtn') }}&nbsp;
            </button>
          </div>
      </div>
    </div>

    <loading-spinner v-if="isLoading"></loading-spinner>
    <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition>
  </div>
</template>

<script>
import CustomHttp from '../../../../CustomHttp';
import CustomEncrypt from '../../../../CustomEncrypt';
import i18n from './../../../../i18n';
const { t } = i18n.global;
//Vue export
export default {
  data() {
    return {
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
      //input parameter
      inputCurrent: '',
      inputNew: '',
      inputConfirm: '',
      // validation flag
      validateFlag1: false,
      validateFlag2: false,
      validateFlag3: false,
      validateFlag4: false,
      validateFlag5: false,
      formSubmitted: false,
    };
  },
  methods: {
    async onClickSave() {
      this.formSubmitted = true;
      // if (this.inputNew != this.inputConfirm) {
      //   this.toggleDangerAlert(t('PasswordMustMatch'));
      //   return;
      // }
      const oldPassword = CustomEncrypt.encrypt(this.inputCurrent).toString();
      const newPassword = CustomEncrypt.encrypt(this.inputNew).toString();
      let json = {
        username: this.$store.getters.username,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      this.isLoading = true;
      let response;
      try {
        response = await CustomHttp.post('/api/v1/member/update-pw', json);
      } catch (error) {
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace('Error:', '').trim();
        tempError = t(tempError);
        this.toggleDangerAlert(tempError.toString());
        return;
      }

      this.formSubmitted = false;
      this.isLoading = false;
      const responseData = response.data;
      if (responseData.error == null) {
        this.toggleSuccessAlert(t('Passwordupdatedsuccessfully'));
        this.validateFlag1 = false;
        this.validateFlag2 = false;
        this.validateFlag3 = false;
        this.validateFlag4 = false;
        this.validateFlag5 = false;
        this.inputCurrent = '';
        this.inputConfirm = '';
        this.inputNew = '';
      } else {
        this.toggleDangerAlert(responseData.error.message);
      }
    },
    onChangeNewPassword() {
      this.validateFlag1 = false;
      this.validateFlag2 = false;
      this.validateFlag3 = false;
      this.validateFlag4 = false;
      this.validateFlag5 = false;
      if (this.inputNew.length >= 8 && this.inputNew.length <= 15) {
        this.validateFlag1 = true;
      }
      // eslint-disable-next-line
      // const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      //format.test(this.inputNew) &&
      // if (/\d/.test(this.inputNew)) {
      //   this.validateFlag2 = true;
      // }
      // eslint-disable-next-line
      if (/^(?=.*[A-Za-z])(?=.*\d)/.test(this.inputNew)) {
        this.validateFlag2 = true;
      }

      const username = this.$store.getters.username;
      // const nickname = this.$store.getters.nickname;
      if (
        !this.inputNew.toLowerCase().includes(username.toLowerCase()) &&
        !this.inputNew.toLowerCase().includes(username.toLowerCase())
      ) {
        this.validateFlag3 = true;
      }

      if (this.inputNew == this.inputConfirm) {
        this.validateFlag4 = true;
      }
      if (this.inputCurrent != this.inputNew) {
        this.validateFlag5 = true;
      }
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1900);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1900);
    },
  },
};
</script>

<style scoped>
.info-text {
  font-size: xx-large;
  text-align: left;
  color: #c500ff;
  font-family: cclip;
  text-shadow: 0 0 8px #c500ff;
  -webkit-text-stroke: 0.75px #c500ff;
  text-transform: uppercase;
}
.profile-label {
  color: white;
  text-align: left;
}

.profile-info {
  text-align: left;
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

.right-card-container{
  padding: 7px;
  border-radius: 35px;
  border: solid 2px #6fecff;
  margin-top: 1em;
}
.right-card {
  border-radius: spx;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: transparent;
  padding: 20px 25px;
  border-radius: 25px;
  border: solid 2px #6fecff;
  min-height: 440px;
  overflow-y: auto;
}

.btn-save {
  border: none;
  font-weight: bold;
  color: black;
  border-radius: 5px !important;
  background-image: linear-gradient(to bottom, #00edff, #22a4dd);
}

.btn-save:disabled {
  border: none;
  font-weight: bold;
  color: black;
  border-radius: 5px !important;
  opacity: 0.5;
}

.form-control {
  border-radius: 7px;
  border: solid 1px white;
  background-color: rgba(0, 67, 72, 0.45);
  color: white;
}

input:placeholder-shown {
  opacity: 0.7;
  color: #000;
}

.password-info {
  /* font-family: Helvetica; */
  font-size: xx-small;
  text-align: left;
  color: #e9a0ff;
  font-weight: 600;
}

.text-muted {
  opacity: 0.95;
  color: #c3c9ce !important;
}

.password-wrap {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
}

.validate-wrap {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: #929298;
  padding-left: 2em;
}

.fa-check-circle {
  font-size: 13px !important;
}

.save-btn-wrap {
  padding-top: 0.1rem;
  padding-bottom: 1.5rem;
}

.form-control {
  font-size: 14px;
}
.form-control:focus{
  box-shadow: 0 0 8px #ffffff;
  opacity: 1;
}
</style>
