<template>
  <div class="card left-card">
      <div class="card-body">
        <div class="col-12 p-0">
          <h2 class="cclip neon mb-4">WE'RE HERE FOR YOU</h2>
          <div class="row pb-4" v-for="d in dataList" :key="d.seq">
            <div class="col-1">
              <div class="d-flex justify-content-center text-center">
                <img v-bind:src="d.seq" width="42"/>
              </div>
            </div>
            <div class="col-11 pl-4">
              <div class="row">
                <span class="title"> {{ d.title }}</span>
              </div>
              <div class="row">
                <span class="desc">
                  <a :href="d.link" :target="d.target"> {{ d.desc }} </a>
                </span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import icnSmart from "./../../../../assets/icons/information/smart.png";
import icnService from "./../../../../assets/icons/information/service.png";

export default {
  components: {},
  data() {
    return {
      dataList: [
        {
          seq: icnSmart,
          title: "Smart Betting",
          desc: "smart.vsport.com",
          link: "https://smart.vsport.com",
          target: "_blank",
        },
        {
          seq: icnService,
          title: "Customer Service",
          desc: "support@vsport.com",
          link: "mailto:support@vsport.com",
          target: "_top",
        },
      ],
    };
  },
  methods: {},
  computed: {
    computedLangId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  position: relative;
}
.card-body {
  padding: 1.85rem;
}
.left-card {
  border-radius: 5px;
  /* box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important; */
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #fff;
}

.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 0.85;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.icn-round {
  width: 30px;
  height: 30px;
  padding: 5px 10px 5px 10px;
  border-radius: 28px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.24px;
  text-align: left;
  color: #735505;
  white-space: nowrap;
}

.title {
  font-weight: 600;
  letter-spacing: 0.24px;
  text-align: left;
  color: #4ac8e2;
}
.desc {
  letter-spacing: 0.18px;
  text-align: left;
}

a {
  color: white !important;
  font-weight: 900;

}

a:focus,
a:hover {
  text-decoration: underline;
}

a:active,
a:hover {
  outline: 0;
}
</style>
