<template>
  <div class="card left-card">
      <EnRules v-if="langId == '0'" />
      <CnRules v-if="langId == '1'" />
      <ThRules v-if="langId == '2'" />
      <VnRules v-if="langId == '3'" />
      <EnRules v-if="langId == '4'" />
  </div>
</template>

<script>
import EnRules from './Rules/EnRules.vue';
import ThRules from './Rules/ThRules.vue';
import VnRules from './Rules/VnRules.vue';
import CnRules from './Rules/CnRules.vue';
//Vue export
export default {
  components: { EnRules, ThRules, VnRules, CnRules},

  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  position: relative;
}

.left-card {
  border-radius: 5px;
  /* box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important; */
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #fff;
}

.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 1;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  font-family: 'Ubuntu';
  background-color: #000;
  /* height: 823px; */
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-top: 0px;
}

h2 {
  font-weight: 200;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-weight: 200;
  font-size: 14px;
  font-weight: normal;
}

h4 {
  font-weight: 200;
  font-size: 14px;
  font-weight: normal;
}

h5 {
  font-weight: 200;
  font-size: 14px;
  font-weight: normal;
}

.button span {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  background-color: #907339;
  border-radius: 5px;
  line-height: 20px;
  width: 105px;
  height: 20px;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}

.button:hover span {
  color: #000;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/rules.jpg');
  background-size: cover;
  background-position: center;
}
b,
strong {
  font-weight: 700 !important;
}

/*Scrollbar for Vsport */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 0px;
  background: blue;
  background: linear-gradient(#4a4a4a, #4a4a4a) no-repeat center/2px 100%;
  margin-top: 50px;
  margin-bottom: 150px;
}
::-webkit-scrollbar-thumb {
  width: 3px;
  height: 77px;
  border-radius: 10px;
  background-color: #4a4a4a;
}
::-webkit-scrollbar-thumb:window-inactive {
  width: 3px;
  height: 77px;
  border-radius: 10px;
  background-color: #4a4a4a;
}
</style>
