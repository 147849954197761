<template>
  <div id="home-body">
    <div class="glow-backdrop"></div>
    <div class="row mx-0 pt-5">
      <desktop-header></desktop-header>
      <!--- page content --->
        <div class="container clear-both container-min-height">
          <div class="row px-0">
            <!--- page header --->
            <!--- end page header --->
            <!----card section --->
            <div class="col-lg-3 py-3 px-0 px-lg-3">
              <div class="card left-card">
                <h3 class="acc-welcome">WELCOME !</h3>
                <div class="full-center">
                  <img
                    src="../../../../assets/icons/avatar.png"
                    class="img-avatar py-2"
                  />
                </div>

                <template v-if="!showProgress">
                  <div
                    class="pt-2 pb-0 px-0 full-center"
                  >
                    <span class="username-text"> {{ username }}</span>
                  </div>
                  <div
                    class="pt-1 pb-0 px-0 full-center"
                  >
                    <div
                      class="img-separator"
                    ></div>
                  </div>

                  <div
                    class="pt-1 pb-0 px-0 text-center"
                  >
                    <span class="credit-text">
                      <span v-if="!isLoadingBalance">
                        {{ currency }} {{ $filters.currency(totalRemainingCreditLimit) }}
                      </span>
                      <!-- <span v-if="isLoadingBalance">
                        <i class="fas fa-spinner fa-pulse"></i>
                      </span> -->
                    </span>
                    </div>
                    <div class="text-center mb-3">
                      <span class="smaller">CURRENT BALANCE</span>
                    <span v-if="!isLoadingBalance">
                      <img
                        src="../../../../assets/icons/icn-refresh.png"
                        class="img-responsive mt-1 ml-2 cursor-pointer"
                        alt="Responsive image"
                        width="22"
                        height="22"
                        @click="onClickRefreshBalance"
                      />
                    </span>
                    <span v-if="isLoadingBalance">
                      <i
                        class="fas fa-spinner fa-pulse fa-lg text-dark my-2 font-weight-bold"
                      ></i>
                    </span>
                    </div>
                  
                </template>
                <template v-if="showProgress">
                  <div class="full-center">
                    <progress-spinner></progress-spinner>
                  </div>
                </template>

                <template v-for="(tab, index) in tabList" :key="tab.name">
                  <div
                    class="cursor-pointer"
                    @click="onClickTab(index)"
                    :class="[ selectedTabIndex == index ? 'account-tab-active' : 'account-tab']"
                  >
                    <div :class="[selectedTabIndex == index ? 'tab-label-active' : 'tab-label']">
                      <img
                      :src="selectedTabIndex == index ? tab.iconUrlActive : tab.iconUrl "
                      class="img-tab mx-1" />
                      {{ $t(tab.name) }}
                    </div>
                  </div>
                </template>
                <div class="pt-4"> </div>
              </div>
            </div>
              <template v-if="selectedTabIndex == 0 && !showProgress">
                <user-info></user-info>
              </template>
              <template v-if="selectedTabIndex == 1 && !showProgress">
                <user-preference></user-preference>
              </template>
              <template v-if="selectedTabIndex == 2 && !showProgress">
                <change-password></change-password>
              </template>
            <template v-if="showProgress">
              <div class="col-lg-8 p-3">
                <div class="card left-card py-5">
                  <progress-spinner></progress-spinner>
                </div>
              </div>
            </template>

            <!---end card section -->
          </div>
        </div>

      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>

    <!--globa  element component  --->
    <loading-spinner v-if="isLoading"></loading-spinner>
    <!-- <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition> -->
  </div>
</template>

<script>
import accIcn from '../../../../assets/icons/icn-account.png';
import accIcnChecked from '../../../../assets/icons/icn-account.png';

import icnPw from '../../../../assets/icons/icn-changepw.png';
import icnPwChecked from '../../../../assets/icons/icn-changepw.png';

import icnPreference from '../../../../assets/icons/icn-preference.png';
import icnPreferenceChecked from '../../../../assets/icons/icn-preference.png';
// import CustomHttp from '../../../CustomHttp';
import UserInfo from './Info';
import ChangePassword from './ChangePassword';
import UserPreference from './Preference';
//Vue export
export default {
  components: {
    UserInfo,
    UserPreference,
    ChangePassword,
  },
  mounted() {
    this.isLoading = false;
    this.selectedTabIndex = 0;
    this.getMemberAllBalance();
  },
  data() {
    return {
      showProgress: false,
      isLoading: false,
      isLoadingBalance: false,
      showErrorAlert: false,
      showSuccessAlert: false,
      successMessage: false,
      errorMessage: false,
      selectedTabIndex: 0,
      tabList: [
        {
          name: 'AccountInfo2',
          iconUrl: accIcn,
          iconUrlActive: accIcnChecked,
        },
        {
          name: 'Preferences',
          iconUrl: icnPreference,
          iconUrlActive: icnPreferenceChecked,
        },
        {
          name: 'ChangePassword',
          iconUrl: icnPw,
          iconUrlActive: icnPwChecked,
        },
      ],
    };
  },
  methods: {
    onClickTab(idx) {
      this.selectedTabIndex = idx;
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    async getMemberAllBalance() {
      // this.isLoading = true;
      this.showProgress = true;
      try {
        await this.$store.dispatch('getMemberAllBalance');
        //   this.isLoading = false;
        this.showProgress = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        // this.isLoading = false;
        this.showProgress = false;
        this.toggleDangerAlert(error);
      }
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    username() {
      return this.$store.getters.username;
    },
    totalRemainingCreditLimit() {
      return this.$store.getters.totalRemainingCreditLimit;
    },
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>

<style scoped>
.bg-bricks{
  background: url('../../../../assets/images/desktop-bg.jpg') 0 0 / cover;
}
.acc-welcome{
  font-family: cclip;
  text-align: center;
  color: #00edff;
  margin-top: 0.5em;
  -webkit-text-stroke: 0.5px #00edff;
}

.card {
  border: 0px;
}
.bg-light-grey {
  background-color: #f7f7f7;
}

.bg-light-gold-gradient {
  background: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
  border-radius: 5px;
}

.menu-text {
  margin-top: 4px;
  font-size: 11px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}

.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  border-radius: 5px;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background:  #005dff44;
  border: 1px solid white;
  color: white;
  border-radius: 18px;
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}

.img-separator {
  object-fit: contain;
  width: 60%;
  border-top: 1px solid #00edff;
  margin: 0 auto;
  margin: 5px 0;
}
.username-text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #00edff;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
}

.credit-text {
  font-size: 20px;
  font-weight: 600;
}

.tab-label-active {
  background: #52b7d5;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 10px;
  width: 90%;
  border: 1px solid white;
  border-radius: 5px;
  margin: 0 auto;
}

.tab-label {
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  padding: 10px;
  width: 90%;
}

.img-tab {
  width: 22px;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.container-min-height {
  min-height: 65vh;
}
#home-body{
  background: url('../../../../assets/images/desktop-bg.jpg') 0 0 / cover;
}
</style>
