import gameImport from './../game';

import king from './../../images/game2/cards/img-thaihilo.png';
import sicbo from './../../images/game2/cards/img-sicbo.png';
import sking from './../../images/game2/cards/img-thaihilo-s.png';
import ssicbo from './../../images/game2/cards/img-sicbo-s.png';


var gameMenu = {
  name: 'CARDS',
  items: [
    {
      name: 'THAI LI HO',
      isFullWidth: true,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: king }],
      img2: [{ langId: 0, src: sking }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'SIC BO',
      isFullWidth: true,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: sicbo }],
      img2: [{ langId: 0, src: ssicbo }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
