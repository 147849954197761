<template>
  <!-------bottom menu bar--->
  <section v-if="isLoggedIn">
    <hr class="divider" />
    <nav class="navbar bottom-nav col-spaced smaller">
        <div class="full-center">
          <div class="col px-0 text-center">
            <div
              class="cursor-pointer"
              @click="setActiveComponent('screen-landing')"
            >
              <img src="../../../assets/icons/home.png" width="32" />
              <br />
              <div class="lh footer-menu-text">{{ $t('Home') }}</div>
            </div>
          </div>

          <div class="col px-0 text-center">
            <div
              class="cursor-pointer"
              @click="setActiveComponent('screen-statement')"
            >
              <img src="../../../assets/icons/statement.png" width="32" />
              <br />
              <div class="lh footer-menu-text">{{ $t('Statement') }}</div>
            </div>
          </div>
          <div class="col px-0 text-center">
            <div
              class="cursor-pointer"
              @click="setActiveComponent('screen-cash-out')"
            >
              <img src="../../../assets/icons/cash.png" width="32" />
              <br />
              <div class="lh footer-menu-text">{{ $t('CashOut') }}</div>
            </div>
          </div>
          <div class="col px-0 text-center">
            <div class="cursor-pointer" @click="toggleSidebar()">
              <img src="../../../assets/icons/more.png" width="32" />
              <br />
              <div class="lh footer-menu-text">{{ $t('More') }}</div>
            </div>
          </div>
        </div>
    </nav>
  </section>
  <!-----end bottom nav bar ---->
</template>

<script>
export default {
  emits: ['toggle-sidebar', 'set-component'],
  methods: {
    toggleSidebar() {
      this.$emit('toggle-sidebar');
    },
    setActiveComponent(componentName) {
      this.$emit('set-component', componentName);
    },
  }, //end methods
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    suspend() {
      return this.$store.getters.suspend;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bottom-nav {
  background: black;
  padding-top: 0.5em;
  flex: 0;
  justify-content: space-around;
  padding: 0.75em 0.5em;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  box-shadow: 3px -4px 10px rgb(0 0 0 / 30%);
}

.bottom-nav img {
  width: 25px;
  height: 25px;
}

.col-center {
  display: flex;
  align-items: center;
}

.smaller {
  font-size: 0.75rem;
}

.divider {
  border: opx;
  padding: 11px 0px;
}
</style>
