<template>
  <div class="content-wrapper" @scroll="onScroll">
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <img
        class="returnTop"
        src="./../../../../../assets/icons/top.png"
        @click="scrollTop()"
        v-show="showTop"
      />
                    <tr>
                        <td>&nbsp;</td>
                        <td width="50">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <span class="df_grenralRules">
                                <span style="color: #e9a0ff; font-size: 13px;">請注意：本公司有權擱置或拒絕疑用欺騙手段如通過攻擊、操縱等破壞操作系統（包括綫上投注服務）所進行的投注。任何不尋常的投注将被取消，而不需另行通知。特此聲明，人工智能或軟件（機器人"bots"）于綫上投注服務的使用是被嚴禁的行爲，本公司必採取防備措施，並且查究相關的程序或軟件；而任何試圖或利用該方式的投注將被取消，相關賬號亦會被關閉。本公司有权不负上任何相关理赔责任。</span>
                                <br><br>
                                <div class="title_bg" style="color: #e9a0ff;">投注规则与规定</div>
                                <div class="information_text termsList">
                                    <p>某些赛事和赌盘的规则有所不同，本网站中每项特定赛事或赌盘/投注类型的规则已在下面的“特定赛事/赌盘投注规则”中列出。 下列一般规则适用于所有赛事与赌盘/投注类型，在投注中必须完全遵守。 在适用的情况下，网站所公布的“条件与条款”中设定的规定和定义应适用于本“投注规则与规定”。</p>
                                    <h1><a name="R1" id="R1"></a>1. 投注总则</h1>
                                    <!-- 1.1 -->
                                    <h2><a name="R11" id="R11"></a>1.1. 投注总则的适用范围——建议会员阅读以下投注总则，这些规则一般适用于所有赛事、交易和产品，除非另有特定规则适用。公司保留修改、更新或更改这些规则的权利，修改后的规则在网站上发布后立即生效并具有约束力。</h2>
                                    <h3>
                                        <strong>1.1.1.</strong><span>
                                            为了服务会员，公司应努力提供有用的投注信息，并确保交易的公平性和透明度。但是，公司不保证投注信息的及时性、准确性和完整性，如日期、场地、赔率、结果、得分、匹配状态和其他投注信息。会员有责任在下注前核实此类信息。公司不对此类投注信息的任何错误或遗漏承担责任。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.2.</strong><span>
                                            网站上赛事或参赛队的英文名称可能与非英文名称有所不同。如果名称之间存在任何不一致，以英文名称为准。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.3.</strong><span>
                                            由于时间延迟和其他原因，网站中的某些投注信息（例如当前得分、比赛进行时间和第三方供应商提供的其他数据馈送）可能不准确。会员认同公司不保证数据的及时性、准确性和完整性，在此方面，会员接受依赖数据和下注的所有风险。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.4.</strong><span>
                                            一旦会员下注并由公司确认，会员就不能撤回、更改或取消该投注。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.5.</strong><span>
                                            在下列情况下，无需事先通知，公司将全权酌情决定取消投注、宣布任何下注无效和/或暂停会员账户：
                      会员以违反规则的方式下注；
                      会员下注超过允许的最高限额；
                      会员的下注有误且未完成；
                      会员下注时，因人为错误或传输错误而导致博彩数据或显示的赔率有误；
                      损失或彩金似乎过高；
                      出现博彩平台异常或不正常使用的情况；
                      公司有理由相信下注涉及非法赌博活动；
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.6.</strong><span>
                                            公司有绝对酌情权决定其游戏或产品的最高彩金金额或更改现有金额，该决定或更改对会员具有约束力，无需事先通知。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.7.</strong><span>
                                            在赛事直播期间所下的即时投注有效，并将在赛事完成和赛果已知时结算，即使赛事直播因任何原因断开连接。但是，如果关于特定游戏或产品断开连接的特定规则涵盖此情况，则以该特定规则为准。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.8.</strong><span>
                                            如果游戏或特定产品的公平性存在任何差错或受到攻击，公司有权行使绝对酌情权，暂停或关闭该游戏或特定产品，以维护其公平性。如果会员怀疑系统有任何错误，会员必须停止下注并与公司的会员支持部门沟通，该部门有绝对酌情权决定是否解决问题。否则的话，继续下注的会员将被视为已接受与其投注结果相关的所有风险。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.9.</strong><span>
                                            在日常业务过程中，如果公司理性地认为比赛或赛事已被操纵，或者奖池已被操纵，公司保留推迟结算或拒绝支付的权利。就此而言，公司可能根据其任何或所有投注渠道下注的规模、数量或模式的理性分析作出判断。如果对这些规则的解释存在任何争议，则以公司的解释为准。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.1.10.</strong><span>
                                            公司应合理行使其绝对酌情权，限制会员加入游戏、拒绝会员加入游戏和/或将会员逐出游戏，无需事先通知或进一步解释。
                                        </span>
                                    </h3>
                                    <!-- 1.2 -->
                                    <h2><a name="R12" id="R12"></a>1.2. 软件和游戏</h2>
                                    <h3>
                                        <strong>1.2.1.</strong><span>
                                            公司不保证软件的质量或安全性，也不表示软件或服务器没有错误、缺陷或病毒。公司不承担因使用软件或因通信或系统错误而产生的任何责任。公司有权为调试、更正错误或出于任何其他目的而从软件中删除所有游戏。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.2.2.</strong><span>
                                            公司应授予会员不可转让的个人权利使用该软件，以进行博彩和下注，但并没有给予或转让软件的所有权或知识产权。会员应对使用软件下注承担全部责任和风险，公司对与此相关的任何损失或损害概不负责。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.2.3.</strong><span>
                                            严禁会员在使用软件时：
                      复制或拷贝软件；转让、转授、出租或让与使用软件的权利；
                      在其它设备上安装软件或提供软件给他人访问；
                      覆盖、模糊或删除软件中的任何通知，如版权、商标、专有标记或类似通知；
                      泄露可能在软件上显示的有关公司或软件提供商的任何保密信息；
                      在软件、软件的任何部分或软件的任何复制、改编、转录或合并部分的基础上进行解码、反汇编、反编译、反向工程、修改或创建衍生作品，或转换软件或其任何部分，或尝试发现软件的源代码；
                      尝试绕过、访问或进入网站或公司的安全系统，或以任何方式干扰软件、游戏或网站。
                                        </span>
                                    </h3>
                  
                                    <!-- 1.3 -->
                                    <h2><a name="R13" id="R13"></a>1.3. 预定时间的变更</h2>
                                    <h3>
                                        <strong>1.3.1.</strong><span>
                      如果比赛或赛事在预定时间之前开始，那么只有在赛事开始之前进行的投注（不包括指定的现场投注）将被视为有效。如果交易未在正确时间关闭或中止，则公司有权取消在实际开始时间之后的所有下注（不包括指定的现场投注）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.3.2.</strong><span>
                                            如果比赛或赛事推迟至不同于预定开始日期的日期且未在原定完成日期内完成，那么所有投注将无效，那些已无条件确定的交易除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.3.3.</strong><span>
                                            如果比赛或赛事开始但后来被放弃且未在原定完成日期内完成，则所有投注无效，那些已无条件确定的交易除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.3.4.</strong><span>
                                            如果比赛或赛事未在原定完成时间内完成且赛果由特定赛事的相关管理机构宣布，则公司保留决定比赛是否有效的权利。公司在这方面的决定是最终决定并具有约束力。
                                        </span>
                                    </h3>
                  
                                    <!-- 1.4 -->
                                    <h2><a name="R14" id="R14"></a>1.4. 场地的变更</h2>
                                    <h3>
                                        <strong>1.4.1.</strong><span>
                                            如果比赛原计划在主队场地比赛，但最终在客队场地比赛，所有投注将被视为无效。如果主队和客队名称被错误地颠倒，投注也将被视为无效。如果比赛计划在中立场地进行，但结果在非中立场地进行，或者情况相反，除非另有说明，否则所有投注仍将被视为有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.4.2.</strong><span>
                                            如果交易之后场地发生变化，所有投注仍将被视为有效。这适用于所有非参赛队的赛事。
                                        </span>
                                    </h3>
                  
                                    <!-- 1.5 -->
                                    <h2><a name="R15" id="R15"></a>1.5. 赛事时长</h2>
                                    <h3>
                                        <strong>1.5.1.</strong><span>
                                            如果比赛或赛事的预定赛事时长发生变化，则所有投注仍将被视为有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.5.2.</strong><span>
                                            在伤停补时阶段发生的任何事件均被视为在常规时间结束时发生（例如：足球比赛的上半场伤停补时阶段的进球得分被视为第45分钟时的得分）。
                                        </span>
                                    </h3>

                  <!-- 1.6 -->
                                    <h2><a name="R16" id="R16"></a>1.6. 赛果</h2>
                                    <h3>
                                        <strong>1.6.1.</strong><span>
                                            就交易结算而言，颁奖仪式的位次将视为正式赛果，不论之后是否出现取消成绩或更改赛果的情况。如果没有颁奖仪式，赛果将以交易结算时相关管理机构公布的官方结果为准，不论之后是否出现取消成绩或更改赛果的情况。如果没有官方结果，将参考交易结算时已知的现有证据确定赛果。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.6.2.</strong><span>
                                            一般来说，交易在赛事结束后不久结算。如果交易结算有误，公司保留撤销结算的权利。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.6.3.</strong><span>
                                            如果无法确定比赛或赛事的结果，公司保留暂停交易结算的权利，直至赛果最终被确定。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.6.4.</strong><span>
                                            如果赛事开始七十二（72）小时后赛事结果、参赛队名称或任何其它赛事详情发生修改或变化，或投注已经结算，公司不会取消或退还已结算的投注，不存在的比赛除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.6.5.</strong><span>
                                            如果官方赛果与公司网站上公布的赛果存在冲突，应参考公司对该赛事的视频录像，以确定正确结果。如果没有视频录像，则在确定正确赛果时，应考虑特定赛事相关管理机构的官方网站上公布的官方赛果。如果官方网站未公布赛果或赛果明显错误，公司有权作出决定/修改以确定最终赛果。在这方面，公司的决定具有最终效力和约束力。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.6.6.</strong><span>
                                            为了确定中奖的投注，在赛事结束时确定赛事的获胜者，无论事后是否推翻决定或任何抗议或申诉的结果如何。
                                        </span>
                                    </h3>

                  <!-- 1.7 -->
                                    <h2><a name="R17" id="R17"></a>1.7. 倒数计时器的接受</h2>
                                    <h3>
                                        <strong>1.7.1.</strong><span>
                                            仅在特定赛事提供该功能。如有该功能，会员可选择菜单上的“Timer Accept”按钮来下注。使用计时器接受功能进行的每次下注在特定时间段内将拥有自己的倒计时。如果没有出现中断，则在计时器倒数结束时已下的注将被接受。如果在计时器倒数结束前发生任何中断，则根据本节规定，已下的注将被视为无效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.7.2.</strong><span>
                                            以下情况应视为中断：
                      可能或确实领得一张红牌；
                      可能或确实被判罚一个点球；
                      任何球队可能进球或取得进球；
                      偶发事件包括但不限于，影响正确下注、接受投注、记录或通知投注的任何设备或通讯故障，操作或传输延误或中断，通讯线路故障等。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.7.3.</strong><span>
                                            会员认同网站上提供的当前分数、所用时间及其它数据虽然是由第三方“现场”实时提供，但仍存在时间延迟以及/或者可能不准确的情况，并且会员根据这些数据做任何投注时，应自行承担所有风险。公司按原样提供这些数据，但不保证其准确性、完整性和及时性，并且不对会员因依赖这些数据而遭受的任何损失（直接或间接损失）负责。
                                        </span>
                                    </h3>

                  <!-- 1.8 -->
                                    <h2><a name="R18" id="R18"></a>1.8. 混合过关的最高彩金</h2>
                                    <h3>
                                        <strong>1.8.1.</strong><span>
                                            如果混合过关下注涉及不同最高彩金限额的各种运动，则其中金额最低的最高限额适用。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.8.2.</strong><span>
                                            所有最高彩金限额同样适用于一起下注或作为有组织的团体/联合组织进行下注的会员群体，他们对同一选择组合下注，包括在数天内使用不同账户以多种价位进行一系列投注。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.8.3.</strong><span>
                                            如果公司有任何理由相信会员按照前一段所述的方式进行多次投注，则所有这些投注的总彩金将被限制为单次最高彩金。该单次最高彩金将记入在一系列投注中首次投注的会员账户。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>1.8.4.</strong><span>
                                            最高彩金单位为马币。如果以其它货币进行投注，彩金将使用系统固定的货币兑换率进行转换。
                                        </span>
                                    </h3>
                  
                                    <h1><a name="R2" id="R2"></a>2. 赌盘（投注类型）规则通则</h1>
                                    <!-- 1.1 -->
                                    <h2><a name="R21" id="R21"></a>2.1. 一般规定</h2>
                                    <h3>
                                        <strong>2.1.1.</strong><span>
                                            优胜冠军/冠军投注
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.1.1.</strong><span>
                                            优胜冠军/冠军投注是指投注于赛事、比赛或锦标赛的赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.1.2.</strong><span>
                                            优胜冠军/冠军投注排名是指投注于参赛者在赛事、比赛或锦标赛中获得的指定名次。 赢得排名投注的数量将在赌盘名称中公布。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.1.3.</strong><span>
                                            如果参赛者/球员没有出场参加赛事、比赛或锦标赛，则所有投注于该参赛者/球员的“优胜冠军/冠军”投注将作废，除非在特定的投注规则中另有说明。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.1.4.</strong><span>
                                            如果任何“优胜冠军/冠军投注”中宣布的结果为“不分胜负”，则所赢的金额除以获胜方数目，然后与投注金额一起派还。如果超过两名参赛者“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h4>
                                    <h4><span>以下為例子: 關於客戶的詢問, 若英格蘭超級聯賽- 最佳射手有兩位, 公司如何計算派彩額? </span></h4>
                                    <h4>派彩如下: 本金 / 贏家 X (賠率-1) = 派彩額, [若最佳射手超過兩人則= 本金/ (人數)]<br /></h4>
                                    <h5 class="style3">
                                        例如:<br />
                                        <strong>英格蘭超級聯賽 - 最佳射手</strong><br />
                                        迪米塔-貝巴托夫  <strong>1.40</strong><br />
                                        迪維斯 <strong>3.50</strong><br />
                                        如果我投注100於 <strong>迪米塔-貝巴托夫</strong> 賠率 1.40.<br />
                                        若最佳射手有兩個人.<br />
                                        那麼我的派彩為: = 本金 100/2 X (賠率 - 1) = 20<br />
                                        如果我投注100於<strong>迪維斯</strong>賠率 3.50<br />
                                        若最佳射手有兩個人.<br />
                                        那麼我的派彩為: = 本金 100/2 x (賠率 - 1) = 125<br />
                                    </h5>
                                    <h4>
                                        <strong>2.1.1.5.</strong><span>
                                            术语“任何其他选手”（任何其他队伍等）是指所有在赌盘中未提及的参赛者。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.2.</strong><span>
                                            胜负盘
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.2.1.</strong><span>
                                            胜负盘是指投注于一方参赛者或队伍将在赛事中击败另一方参赛者/队伍，或者在配对赛中获得较高的排名。 其它“胜负盘”规则请参阅“特定赛事投注规则”。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.2.2.</strong><span>
                                            术语“场上选手”是指除了在配对赛胜负盘中被提及的参赛者之外的所有参赛者。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.3.</strong><span>
                                            让球盘 (HDP) 和上半场让球盘 (FH HDP)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.3.1.</strong><span>
                                            让球盘是指将假定的让分数预先加给其中一方参赛者或队伍（在赛事开始前该参赛者或队伍便以该受让分数领先）并据此进行投注。 将让分数加上实际比赛结果后，得分较高的参赛者或队伍即为赢家。 其它“让球盘”规则请参阅“特定赛事投注规则”。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.3.2.</strong><span>
                                            上半场让球盘是指投注于上半场将让分数加上实际比赛结果后，得分较高的参赛者或队伍即为赢家。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.4.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.4.1.</strong><span>
                                            大小盘是指投注结果由赛事最后结果的总分（入球数、得分情况等）来决定。 如果总分大于“大小盘”预先指定的总分，则投注“大盘”的为赢家；如果总分小于“大小盘”预先指定的总分，则投注“小盘”的为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.4.2.</strong><span>
                                            上半场大小盘是指投注结果由上半场赛事的总分来决定。 如果总分大于“大小盘”预先指定的总分，则投注“大盘”的为赢家；如果总分小于“大小盘”预先指定的总分，则投注“小盘”的为赢家。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.5.</strong><span>
                                            单双盘 (OE) 和上半场单双盘 (FH OE)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.5.1.</strong><span>
                                            单双盘是指投注结果由赛事最后结果的总分（入球数、得分情况等）来决定。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.5.2.</strong><span>
                                            上半场单双盘是指投注结果由上半场赛事的总分来决定。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.5.3.</strong><span>
                                            如果赛事在上半场结束之前中止，全部上半场的投注将视为无效。相反的，如果赛事在上半场结束之后中止，则全部上半场的投注将视为有效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.6.</strong><span>
                                            单队单双盘(OE)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.6.1.</strong><span>
                                            单队单双盘(OE)是指投注预测特定球队在全场比赛中的结果会是单盘或双盘。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.6.2.</strong><span>
                                            比赛中的任何额外加时赛将不被计算在特定球队的全场比分结果中。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.7.</strong><span>
                                            比赛半场/全场单双盘(O/E)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.7.1.</strong><span>
                                            比赛半场/全场单双盘(O/E)是指投注预测半场结果和全场结果中的赛事是单单盘，单双盘，双单盘或双双盘。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.7.2.</strong><span>
                                            共四种投注选项:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>单/单</li>
                                            <li>单/双</li>
                                            <li>双/单</li>
                                            <li>双/双</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.1.7.3.</strong><span>
                                            比赛中的任何额外加时赛将不被计算在此投注类型的全场比分结果中。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.1.8.</strong><span>
                                            混合过关
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.1.8.1.</strong><span>
                                            “混合过关”乃指以两个或以上投注为组合的博彩方式。若全部选择均赢，则该混合过关投注为赢, 并将获赔两个投注的组合赔率。若一个 (或多个) 选择均输, 则 “过关” 投注为输。若一个 (或多个) 选择被推迟, 则该选择的赔率将恢复为1.00的赔率。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.2.</strong><span>
                                            在混合过关投注中任何因"现场"选项而导致被取消之注单，則以 [1.00] 計算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.3.</strong><span>
                                            有关更多详情，请参阅“混合过关”投注页面上的“帮助”。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.4.</strong><span>
                                            三串四投注是由不同赛事的3个选项组成的4个不同投注，即包括3个孖宝以及1个三宝投注。任何两个选项获胜﹐您将获得彩金回报。若您所选的选项中有2项胜出， 则一注孖宝投注将赢得彩金回报。若您所选的3个选项均胜出，则3个孖宝投注及三宝投注将赢得彩金回报。如果其中一个（或以上）的选项因比赛延期或取消则该选项之赔率将以1.00计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.5.</strong><span>
                                            四串十一投注是由不同赛事的4个选项组成的11个不同投注﹐即包括6个孖宝，4个三宝及1个四串一投注。任何两个选项获胜﹐您将获得彩金回报。若您所选的选项中有2项胜出，则其中一注孖宝将赢得彩金回报。若有3个选项胜出,，则有三注孖宝及一注三宝投注将赢得彩金回报。若4个选项全部获胜， 则所有6个孖宝，4个三宝以及四串一投注将赢得彩金回报。如果其中一个（或以上）的选项因比赛延期或取消则该选项之赔率将以1.00计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.6.</strong><span>
                                            五串二十六投注是由不同赛事的5个选项组成的26个不同投注﹐即包括10个孖宝﹐10个三宝﹐5个四串一以及1个五串一投注。任何两个选项胜出﹐您都将赢得彩金回报。若您所选的选项中有2项胜出, 则其中一注孖宝将赢得彩金回报。若有4个选项胜出﹐则有六注孖宝，四注三宝以及1个四串一投注将赢得彩金回报。若5个选项全部获胜﹐则所有10个孖宝，10个三宝，5个四串一以及1个五串一投注将赢得彩金回报。如果其中一个（或以上）的选项因比赛延期或取消则该选项之赔率将以1.00计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.7.</strong><span>
                                            六串五十七投注是由不同赛事的6个选项组成的57个不同投注，即包括15个孖宝﹐20个三宝﹐15个四串一﹐6个五串一以及1个六串一投注。任何两个选项胜出﹐您将赢得彩金回报。若您所选的选项中有2项胜出，则其中一注孖宝将赢得彩金回报。若有5个选项胜出﹐则有10注孖宝，10注三宝，5个四串一以及1个五串一投注将赢得彩金回报。若6个选项全部获胜﹐则所有15个孖宝, 20个三宝，15个四串一，6个五串一以及1个六串一投注将赢得彩金回报。如果其中一个（或以上）的选项因比赛延期或取消则该选项之赔率将以1.00计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.8.</strong><span>
                                            七串一百二十投注是由不同赛事的7个选项组成的120个不同投注，即包括21个孖宝﹐35个三宝﹐35个四串一﹐21个五串一﹐7个六串一以及1个七串一投注。任何两个选项胜出﹐您将赢得彩金回报。若您所选的选项中有2项胜出，则其中一注孖宝将赢得彩金回报。若有6个选项胜出﹐则有15注孖宝，20注三宝，15个四串一，6个五串一以及1个六串一投注将赢得彩金回报。若7个选项全部获胜﹐则所有21个孖宝，35个三宝，35个四串一，21个五串一，7个六串一以及1个七串一投注将赢得彩金回报。如果其中一个（或以上）的选项因比赛延期或取消则该选项之赔率将以1.00计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.9.</strong><span>
                                            八串二百四十七投注是由不同赛事的8个选项组成的247个不同投注﹐即包括28个孖宝﹐56个三宝﹐70个四串一﹐56个五串一﹐28个六串一﹐8个七串一以及1个八串一投注。任何两个选项胜出﹐您将赢得彩金回报。若您所选的选项中有2项胜出，则其中一注孖宝将赢得彩金回报。若有7个选项胜出﹐则有21注孖宝，35注三宝，35个四串一，21个五串一，7个六串一以及1个七串一投注将赢得彩金回报。若8个选项全部获胜﹐则所有28个孖宝，56个三宝，70个四串一，56个五串一，28个六串一，8个七串一以及1个八串一投注将赢得彩金回报。如果其中一个（或以上）的选项因比赛延期或取消则该选项之赔率将以1.00计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.10.</strong><span>
                                            滚球投注将不提供三串四投注，四串十一投注，五串二十六投注，六串五十七投注， 七串一百二十投注和八串二百四十七投注等选项。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.1.8.11.</strong><span>
                                            请参考混合过关投注介面 “ ” 以瞭解详细的投注方法。
                                        </span>
                                    </h4>
                                    <h2><a name="R22" id="R22"></a>2.2. 足球投注类型</h2>
                                    <h3>
                                        <strong>2.2.1.</strong><span>
                                            除非另有说明，足球投注类型的成绩是指以比赛正赛时间结束时的比分（包括裁判决定的受伤补时）。 加时赛不计算在内。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>2.2.2.</strong><span>
                                            1X2
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.2.1.</strong><span>
                                            1X2 是指在单一赛事中投注于三种可能的胜利结果中的任何一种。 1 表示首先被提及的队伍（通常是主队）；X 代表比赛结果平手；2 代表第二个被提及的队伍（通常是客队）。
                                        </span>
                                    </h4>
                  
                  <h3>
                    <strong>2.2.3.</strong><span>
                      波胆 & 波胆滚球
                    </span>
                  </h3>
                  <h4>
                    <strong>2.2.3.1.</strong><span>
                      波胆是指投注预测完整比赛时间结束后的最终比分。
                    </span>
                  </h4>

                  <h4>
                    <strong>2.2.3.2.</strong><span>
                      投注"波胆"时，如果要赢得"AOS”，表示投注者选择的队伍必须以没有列入投注选项的成绩获胜。
                    </span>
                  </h4>

                  <h4>
                    <strong>2.2.3.3.</strong><span>
                      投注"波胆滚球"时，如果要赢得全场"AOS”，表示投注者选择的队伍必须以没有列入投注选项的成绩获胜。(最终比分为主队或客队的入球数额外多 “5” 个入球或以上，不包括当前比分)
                    </span>
                  </h4>

                  <h4>
                    <strong>2.2.3.4.</strong><span>
                      投注"波胆滚球"时，如果要赢得半场"AOS”，表示投注者选择的队伍必须以没有列入投注选项的成绩获胜。(最终比分为主队或客队的入球数额外多 “4” 个入球或以上，不包括当前比分)
                    </span>
                  </h4>
                  
                  <h5>
                    <strong>举例全场AOS:</strong><br />
                    <span>
                      全场AOS 赛果：即时比分 1-2 (不包括: 2-2, 3-2, 3-3, 4-2, 4-3, 4-4, 5-2, 5-3, 5-4, 5-5, 1-2, 2-3, 3-4, 4-5, 5-6, 1-3, 1-4, 2-4, 1-5, 2-5, 3-5, 1-6, 2-6, 3-6, 4-6)<br />
                      全场AOS 赛果：即时比分 3-2 (不包括: 4-2, 5-2, 5-3, 6-2, 6-3, 6-4, 7-2, 7-3, 7-4, 7-5, 3-2, 4-3, 5-4, 6-5, 7-6, 3-3, 3-4, 4-4, 3-5, 4-5, 5-5, 3-6, 4-6, 5-6, 6-6)
                    </span>
                  </h5>
                  
                  <h5>
                    <strong>举例半场AOS:</strong><br />
                    <span>
                      半场AOS 赛果：即时比分 1-2 (不包括: 2-2, 3-2, 3-3, 4-2, 4-3, 4-4, 1-2, 2-3, 3-4, 4-5, 1-3, 1-4, 2-4, 1-5, 2-5, 3-5)<br />
                      半场AOS 赛果：即时比分 3-2 (不包括: 4-2, 5-2, 5-3, 6-2, 6-3, 6-4, 3-2, 4-3, 5-4, 6-5, 3-3, 3-4, 4-4, 3-5, 4-5, 5-5)
                    </span>
                  </h5>

                                    <h3>
                                        <strong>2.2.4.</strong><span>
                                            总进球数和上半场总进球数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.4.1.</strong><span>
                                            总进球数是指由赛事中的总进球数决定投注结果。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.4.2.</strong><span>
                                            上半场总进球数是指由上半场赛事中的总进球数决定投注结果。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.4.3.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.5.</strong><span>
                                            半场/全场(HT.FT)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.5.1.</strong><span>
                                            半场/全场是指投注预测赛事的半场结果和全场结果（加时赛不计算在内）。 下面介绍与此赌盘相关的意义：H 表示首先被提及的队伍（通常是主队）；D 表示平手；A 表示第二个被提及的队伍（通常是客队）。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.5.2.</strong><span>
                                            举例 – HA 表示首先被提及的队伍（主队）半场得分会领先，而第二个被提及的队伍（客队）全场得分会领先。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.6.</strong><span>
                                            最先进球/最后进球和上半场最先进球/最后进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.6.1.</strong><span>
                                            最先进球/最后进球是指投注哪个队伍会在比赛中率先进球或射进最后一球。 下面介绍与此赌盘相关的意义：HF 表示首先被提及的队伍（通常是主队）会率先进球。 HL 表示首先被提及的队伍会射进最后一球。 AF 表示第二个被提及的队伍（通常是客队）会率先进球。 AL 表示第二个被提及的队伍会射进最后一球。 NG 表示赛事中没有队伍射门得分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.6.2.</strong><span>
                                            上半场最先进球/最后进球是指投注哪个队伍会在上半场比赛中率先进球或射进最后一球。 下面介绍与此赌盘相关的意义：HF 表示首先被提及的队伍（通常是主队）会率先进球。 HL 表示首先被提及的队伍会射进最后一球。 AF 表示第二个被提及的队伍（通常是客队）会率先进球。 AL 表示第二个被提及的队伍会射进最后一球。 NG 表示赛事中没有队伍射门得分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.6.3.</strong><span>
                                            乌龙球算作进球队伍的得分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.6.4.</strong><span>
                                            如果在得分后赛事中止，“率先进球”（以及“无进球”）的投注仍有效，但“最后进球”的投注均作废。 如果在没有进球的情况下赛事中止，则“率先进球”、“最后进球”和“无进球”的投注均作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.7.</strong><span>
                                            开球队伍
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.7.1.</strong><span>
                                            开球队伍是指投注比赛中首先开球的队伍。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.7.2.</strong><span>
                                            如果开球后赛事中止，所有投注仍然有效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.8.</strong><span>
                                            主队总球数与客队总球数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.8.1.</strong><span>
                                            主队总进球数与客队总进球数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.8.1.1.</strong><span>
                                            主队总进球数与客队总进球数是指投注预测在特定日期的特定联赛中，主队的总进球数与客队的总进球数。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.8.2.</strong><span>
                                            主队总角球数与客队总角球数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.8.2.1.</strong><span>
                                            主队总角球数与客队总角球数是指投注预测在特定日期的特定联赛中，主队的总角球数与客队的总角球数。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.8.3.</strong><span>
                                            主队表示首先被提及的队伍，客队表示第二个被提及的队伍。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.8.4.</strong><span>
                                            如果一场（或更多）的赛事延期或中止，则投注作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.9.</strong><span>
                                            单队大小盘和单队上半场大小盘
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.9.1.</strong><span>
                                            单队大盘/小盘是指投注特定队伍在该场赛事中的得分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.9.2.</strong><span>
                                            单队上半场大盘/小盘是指投注预测特定队伍在该场上半场赛事中的得分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.9.3.</strong><span>
                                            如果总进球数大于“大小盘”预先指定的总进球数，则投注“大盘”的为赢家；如果总进球数少于“大小盘”预先指定的总进球数，则投注“小盘”的为赢家。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.10.</strong><span>
                                            角球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.10.1.</strong><span>
                                            经判罚后而未开出之角球将不计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.10.2.</strong><span>
                                            角球数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.10.2.1.</strong><span>
                                            让球和上半场让球
                                        </span>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.1.1.</strong><span>
                                                让球是指投注比赛中角球数最多的队伍，包含任何让球数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.1.2.</strong><span>
                                                上半场让球是指投注上半场比赛中角球数最多的队伍，包含任何让球数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.1.3.</strong><span>
                                                如果赛事在上半场期间中止，全部上半场让球的投注将视为无效。相反的，如果赛事在下半场期间中止，则全部上半场让球的投注将视为有效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.10.2.2.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.2.1.</strong><span>
                                                大小盘是指投注比赛中双方所开出角球的总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.2.2.</strong><span>
                                                上半场大小盘是指投注上半场比赛中双方所开出角球的总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.2.3.</strong><span>
                                                如果总角球数大于“大小盘”预先指定的总角球数，则投注“大盘”的为赢家；如果总角球数少于“大小盘”预先指定的总角球数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.10.2.2.4.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.10.3.</strong><span>
                                            第一个角球和下半场第一个角球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.10.3.1.</strong><span>
                                            第一个角球是指投注比赛中获得第一个角球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.10.3.2.</strong><span>
                                            下半场第一个角球是指投注下半场比赛中获得第一个角球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.10.3.3.</strong><span>
                                            如果在开出第一个角球后赛事中止，所有投注仍有效。 如果在开出第一个角球前赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.10.4.</strong><span>
                                            最后一个角球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.10.4.1.</strong><span>
                                            最后一个角球是指投注比赛中获得最后一个角球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.10.4.2.</strong><span>
                                            如果赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.10.5.</strong><span>
                                            下一个角球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.10.5.1.</strong><span>
                                            下一个角球: 为投注比赛中取得下一个角球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.10.5.2.</strong><span>
                                            如果投注的角球已经开出，注单将视为有效。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.11.</strong><span>
                                            罚牌
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.11.1.</strong><span>
                                            一張黃牌一分，一張紅牌兩分。單場賽事中每位球員最高得分為三分 (黃牌一分，紅牌兩分，第二張黃牌不列入計算)。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.11.2.</strong><span>
                                            非场上球员(球团经理、教练、替补球员)收到的牌不列入计算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.11.3.</strong><span>
                                            罚牌数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.11.3.1.</strong><span>
                                            让分和上半场让分
                                        </span>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.1.1.</strong><span>
                                                让分：投注比赛中收到最多罚牌的队伍，包含任何让分。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.1.2.</strong><span>
                                                上半场让分是指投注上半场比赛中收到最多罚牌的队伍，包含任何让分。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.1.3.</strong><span>
                                                如果赛事在上半场期间中止，全部上半场让分的投注将视为无效。相反的，如果赛事在下半场期间中止，则全部上半场让分的投注将视为有效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.3.2.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.2.1.</strong><span>
                                                大小盘：投注比赛中两队收到的罚牌总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.2.2.</strong><span>
                                                上半场大小盘是指投注上半场比赛中两队收到的罚牌总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.2.3.</strong><span>
                                                如果总罚牌数大于“大小盘”预先指定的总罚牌数，则投注“大盘”的为赢家；如果总罚牌数少于“大小盘”预先指定的总罚牌数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.11.3.2.4.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.11.4.</strong><span>
                                            第一张罚牌和下半场第一张罚牌
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.11.4.1.</strong><span>
                                            第一张罚牌：投注比赛中收到第一张罚牌 (黄牌或红牌) 的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.4.2.</strong><span>
                                            下半场第一张罚牌是指投注下半场比赛中收到第一张罚牌 (黄牌或红牌) 的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.4.3.</strong><span>
                                            若两名以上的球员因为同一个事件收到罚牌，则以裁判首先亮牌的球员为赢家。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.4.4.</strong><span>
                                            若在第一张罚牌发出后赛事中止，所有投注仍有效。若在第一张罚牌发出前赛事中止，所有投注皆作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.11.5.</strong><span>
                                            最后一张罚牌
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.11.5.1.</strong><span>
                                            最后一张罚牌：投注比赛中收到最后一张罚牌(黄牌或红牌)的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.5.2.</strong><span>
                                            若两名以上的球员因为同一个事件收到罚牌，则以裁判最后亮牌的球员为赢家。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.5.3.</strong><span>
                                            若赛事中止，则所有投注作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.11.6.</strong><span>
                                            下一张罚牌
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.11.6.1.</strong><span>
                                            下一张罚牌是指投注预测取得下一张罚牌 (黄牌或红牌) 的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.11.6.2.</strong><span>
                                            如果投注的罚牌已经发出，注单将视为有效。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.12.</strong><span>
                                            越位
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.12.1.</strong><span>
                                            越位次数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.12.1.1.</strong><span>
                                            让分和上半场让分
                                        </span>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.1.1.</strong><span>
                                                让分是指投注比赛中越位次数最多的队伍，包含任何让分。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.1.2.</strong><span>
                                                上半场让分是指投注上半场比赛中越位次数最多的队伍，包含任何让分。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.1.3.</strong><span>
                                                如果赛事在上半场期间中止，全部上半场让分的投注将视为无效。相反的，如果赛事在下半场期间中止，则全部上半场让分的投注将视为有效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.12.1.2.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.2.1.</strong><span>
                                                大于/小于是指投注比赛中越位判罚的总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.2.2.</strong><span>
                                                上半场大于/小于是指投注上半场比赛中越位判罚的总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.2.3.</strong><span>
                                                如果总越位次数大于“大小盘”预先指定的总越位次数，则投注“大盘”的为赢家；如果总越位次数少于“大小盘”预先指定的总越位次数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.12.1.2.4.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.12.2.</strong><span>
                                            第一次越位和下半场第一次越位
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.12.2.1.</strong><span>
                                            第一次越位是指投注最先被判越位的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.12.2.2.</strong><span>
                                            下半场第一次越位是指投注下半场最先被判越位的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.12.2.3.</strong><span>
                                            如果在第一次越位判罚后赛事中止，所有投注均有效。 如果在第一次越位判罚前赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.12.3.</strong><span>
                                            最后一次越位
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.12.3.1.</strong><span>
                                            最后一次越位是指投注最后被判越位的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.12.3.2.</strong><span>
                                            如果赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.12.4.</strong><span>
                                            下一次越位
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.12.4.1.</strong><span>
                                            下一次越位是指投注预测下一个被判越位的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.12.4.2.</strong><span>
                                            如果投注的越位已经判出，注单将视为有效。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.13.</strong><span>
                                            换人
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.13.1.</strong><span>
                                            换人次数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.13.1.1.</strong><span>
                                            让分
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.13.1.1.1.</strong><span>
                                                让分是指投注赛事中换人次数最多的队伍，包含任何让分。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.13.1.1.2.</strong><span>
                                                如果赛事中止，所有投注均作废。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.13.1.2.</strong><span>
                                            大小盘
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.13.1.2.1.</strong><span>
                                                大小盘是指投注赛事中换人次数的总数。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.13.1.2.2.</strong><span>
                                                如果总换人次数大于“大小盘”预先指定的总换人次数，则投注“大盘”的为赢家；如果总换人次数少于“大小盘”预先指定的总换人次数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.13.1.2.3.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.13.2.</strong><span>
                                            第一次换人
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.13.2.1.</strong><span>
                                            第一次换人是指投注比赛中最先换人的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.13.2.2.</strong><span>
                                            如果同时替换两名以上球员，则以第四裁判最先出示的球员编号为赢家。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.13.2.3.</strong><span>
                                            如果在第一次换人后赛事中止，所有投注仍有效。 如果在第一次换人前赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.13.3.</strong><span>
                                            最后一次换人
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.13.3.1.</strong><span>
                                            最后一次换人是指投注比赛中最后换人的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.13.3.2.</strong><span>
                                            如果同时替换两名以上球员，则以第四裁判最后出示的球员编号为赢家。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.13.3.3.</strong><span>
                                            如果赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.14.</strong><span>
                                            零失球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.14.1.</strong><span>
                                            投注在比赛中零失球” 是“ （也就是没让敌方攻入任何一球）或 投注在比赛中零失球” 没有“ （也就是让敌方攻入任何一球）。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.14.2.</strong><span>
                                            如果赛事在只有主场进球之后中止， 则零失球投注选项: “客是” 和“客否” 的投注将有效，但零失球投注选项:“主是” 和“主否” 的投注均视为无效。如果只有客场进球，则零失球投注选项:“主是” 和“主否” 的投注将有效，但零失球投注选项: “客是” 和“客否” 的投注均视为无效。如果主客场均有进球，则全部投注选项视为有效。如果赛事在没有任何进球的情况下中止，则全部投注选项视为无效。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>例子1：</strong><span>
                                            <br />
                                            主场得分≥ 1，客场得分= 0 (1, 2…: 0)<br />
                                            主场是– 退还 客场是– 输<br />
                                            主场否– 退还 客场否– 赢<br />
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>例子2：</strong><span>
                                            <br />
                                            主场得分≥ 1，客场得分≥ 1 (1, 2…: 1, 2…)<br />
                                            主场是– 输  客场是– 输<br />
                                            主场否– 赢  客场否– 赢<br />
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>例子3：</strong><span>
                                            <br />
                                            主场得分= 0，客场得分≥ 1 (0 : 1, 2…)<br />
                                            主场是– 输 客场是– 退还<br />
                                            主场否– 赢 客场否– 退还<br />
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.15.</strong><span>
                                            点球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.15.1.</strong><span>
                                            点球是指投注比赛中判罚的点球次数。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.15.2.</strong><span>
                                            如果在第一次判罚点球后赛事中止，所有投注仍有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.15.3.</strong><span>
                                            如果在第一次判罚点球前赛事中止，所有投注均作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.16.</strong><span>
                                            点球决胜
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.16.1.</strong><span>
                                            点球决胜: 是指投注哪一队会在点球大战中胜出。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.16.2.</strong><span>
                                            在让分赌盘中，结果包含点球决胜中所有得分的罚球数，包括突然死亡。 在“大小盘”赌盘中，结果仅包含点球决胜中正规十个罚球，不包括突然死亡。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.17.</strong><span>
                                            梦幻对垒
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.17.1.</strong><span>
                                            梦幻对垒是将不同赛事中的队伍配对比赛。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.17.2.</strong><span>
                                            所有场地仅限于参考之用。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.18.</strong><span>
                                            特定15分钟大小盘分段投注
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.18.1.</strong><span>
                                            特定15分钟分段投注（OU）的意思是，在每场比赛中的每15分钟[中场休息]结束时，根据总点数投注（进球、角球、比赛、掏牌等）。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.18.2.</strong><span>
                                            如果总越位次数大于“大小盘”预先指定的总越位次数，则投注“大盘”的为赢家；如果总越位次数少于“大小盘”预先指定的总越位次数，则投注“小盘”的为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.18.3.</strong><span>
                                            例如：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <strong>第15分钟OU</strong><br />
                                        预测从00:00 – 15:00分钟的总进球数大于或小于所预设的大小盘,必须在15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第30分钟OU</strong><br />
                                        预测从15:01 – 30:00分钟的总进球数大于或小于所预设的大小盘,必须在15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第45分钟OU</strong><br />
                                        预测从30:01- 45:00分钟的总进球数大于或小于所预设的大小盘,必须在15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第60分钟OU</strong><br />
                                        预测从45:01 – 60:00分钟的总进球数大于或小于所预设的大小盘,必须在15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第75分钟OU</strong><br />
                                        预测从60:01 – 75:00分钟的总进球数大于或小于所预设的大小盘,必须在15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第90分钟OU</strong><br />
                                        预测从75:01- 90:00分钟的总进球数大于或小于所预设的大小盘,必须在15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h4>
                                        <strong>2.2.18.4.</strong><span>
                                            对于特定的15分钟OU，根据现场转播显示的时间，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.18.5.</strong><span>
                                            如果暂停或放弃比赛，那么在未结束的这一特定15分钟内的投注将被视为无效。如果指定的特定15分钟OU结束，那么投注有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.18.6.</strong><span>
                                            对于在任意特定15分钟大小盘的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：进球；点球和红牌。
                                        </span>
                                    </h4>

                                    <h4>
                                        <strong>2.2.18.7.</strong><span>
                                            对于在任意特定15分钟角球数的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：前场危险任意球；攻方球员在前场持球；点球。
                                        </span>
                                    </h4>

                                    <h4>
                                        <strong>2.2.18.8.</strong><span>
                                            对于在任意特定15分钟罚牌数的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：前场危险任意球；点球；进球；角球；球员受伤倒地原因不明；球员争吵和打架。
                                        </span>
                                    </h4>

                                    <h4>
                                        <strong>2.2.18.9.</strong><span>
                                            对于30:01-45:00和75:01 - 90:00这两个时段，根据现场转播显示的时间，但不包括加时和伤停补时，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.19.</strong><span>
                                            任意球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.19.1.</strong><span>
                                            经判罚后而未开出之任意球将不计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.19.2.</strong><span>
                                            任意球包括直接任意球和间接任意球。(点球除外)。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.19.3.</strong><span>
                                            任意球数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.19.3.1.</strong><span>
                                            让分和上半场让分
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.1.1.</strong><span>
                                                让分是指投注比赛中任意球数最多的队伍，包含任何让分数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.1.2.</strong><span>
                                                上半场让分是指投注上半场比赛中任意球数最多的队伍，包含任何让分数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.1.3.</strong><span>
                                                如果赛事在上半场期间中止，全部上半场让分的投注将视为无效。相反的，如果赛事在下半场期间中止，则全部上半场让分的投注将视为有效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.19.3.2.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.2.1.</strong><span>
                                                大小盘是指投注比赛中双方所开出任意球的总数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.2.2.</strong><span>
                                                上半场大小盘是指投注上半场比赛中双方所开出任意球的总数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.2.3.</strong><span>
                                                如果总任意球数大于“大小盘”预先指定的总任意球数，则投注“大盘”的为赢家；如果总进球数少于“大小盘”预先指定的总任意球数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.19.3.2.4.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.19.4.</strong><span>
                                            第一个任意球和下半场第一个任意球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.19.4.1.</strong><span>
                                            第一个任意球是指投注比赛中获得第一个任意球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.19.4.2.</strong><span>
                                            下半场第一个任意球是指投注下半场比赛中获得第一个任意球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.19.4.3.</strong><span>
                                            如果在开出第一个任意球后赛事中止，所有投注仍有效。 如果在开出第一个任意球前赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.19.5.</strong><span>
                                            最后一个任意球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.19.5.1.</strong><span>
                                            最后一个任意球是指投注比赛中获得最后一个任意球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.19.5.2.</strong><span>
                                            如果赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.19.6.</strong><span>
                                            下一个任意球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.19.6.1.</strong><span>
                                            下一个任意球是指投注预测下一个获得任意球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.19.6.2.</strong><span>
                                            如果投注的任意球已经开出，注单将视为有效。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.20.</strong><span>
                                            第一个龙门球和下半场第一个龙门球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.20.1.</strong><span>
                                            当球越出龙门线而最后触球的球员为攻方，那么守方球队将获得龙门球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.20.2.</strong><span>
                                            下半场第一个龙门球是指投注下半场比赛中获得第一个龙门球的队伍。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.20.3.</strong><span>
                                            由守门员接球后开出的龙门球不计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.20.3.</strong><span>
                                            龙门球数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.20.3.1.</strong><span>
                                            让分和上半场让分
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.1.1.</strong><span>
                                                让分是指投注比赛中球龙球数最多的队伍，包含任何让分数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.1.2.</strong><span>
                                                上半场让分是指投注上半场比赛中龙门球数最多的队伍，包含任何让分数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.1.3.</strong><span>
                                                如果赛事在上半场期间中止，全部上半场让分的投注将视为无效。相反的，如果赛事在下半场期间中止，则全部上半场让分的投注将视为有效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.20.3.2.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.2.1.</strong><span>
                                                大小盘是指投注比赛中双方所开出龙门球的总数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.2.2.</strong><span>
                                                上半场大小盘是指投注上半场比赛中双方所开出龙门球的总数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.2.3.</strong><span>
                                                如果总龙门球数大于“大小盘”预先指定的总龙门球数，则投注“大盘”的为赢家；如果总龙门球数少于“大小盘”预先指定的总龙门球数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.20.3.2.4.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.20.4.</strong><span>
                                            第一个龙门球和下半场第一个龙门球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.20.4.1.</strong><span>
                                            第一个龙门球是指投注比赛中获得第一个龙门球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.20.4.2.</strong><span>
                                            下半场第一个龙门球是指投注下半场比赛中获得第一个龙门球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.20.4.3.</strong><span>
                                            如果在开出第一个龙门球后赛事中止，所有投注仍有效。 如果在开出第一个龙门球前赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.20.5.</strong><span>
                                            最后一个龙门球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.20.5.1.</strong><span>
                                            最后一个龙门球是指投注比赛中获得最后一个龙门球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.20.5.2.</strong><span>
                                            如果赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.20.6.</strong><span>
                                            下一个龙门球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.20.6.1.</strong><span>
                                            下一个龙门球是指投注预测下一个获得龙门球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.20.6.2.</strong><span>
                                            如果投注的龙门球已经开出，注单将视为有效。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.21.</strong><span>
                                            界外球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.21.1.</strong><span>
                                            当球越出边线而最后触球的球员的对方将获得界外球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.21.2.</strong><span>
                                            界外球数
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.21.2.1.</strong><span>
                                            让分和上半场让分
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.1.1.</strong><span>
                                                让分是指投注比赛中界外球数最多的队伍，包含任何让分数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.1.2.</strong><span>
                                                上半场让分是指投注上半场比赛中界外球数最多的队伍，包含任何让分数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.1.3.</strong><span>
                                                如果赛事在上半场期间中止，全部上半场让分的投注将视为无效。相反的，如果赛事在下半场期间中止，则全部上半场让分的投注将视为有效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <strong>2.2.21.2.2.</strong><span>
                                            大小盘和上半场大小盘
                                        </span>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.2.1.</strong><span>
                                                大小盘是指投注比赛中双方所开出界外球的总数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.2.2.</strong><span>
                                                上半场大小盘是指投注上半场比赛中双方所开出界外球的总数。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.2.3.</strong><span>
                                                如果总界外球数大于“大小盘”预先指定的总界外球数，则投注“大盘”的为赢家；如果总界外球数少于“大小盘”预先指定的总界外球数，则投注“小盘”的为赢家。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">2.2.21.2.2.4.</strong><span>
                                                如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>2.2.21.3.</strong><span>
                                            第一个界外球和下半场第一个界外球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.21.3.1.</strong><span>
                                            第一个界外球是指投注比赛中获得第一个界外球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.21.3.2.</strong><span>
                                            下半场第一个界外球是指投注下半场比赛中获得第一个界外球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.21.3.3.</strong><span>
                                            如果在开出第一个界外球后赛事中止，所有投注仍有效。 如果在开出第一个界外球前赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.21.4.</strong><span>
                                            最后一个界外球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.21.4.1.</strong><span>
                                            最后一个界外球是指投注比赛中获得最后一个界外球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.21.4.2.</strong><span>
                                            如果赛事中止，所有投注均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.21.5.</strong><span>
                                            下一个界外球
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.21.5.1.</strong><span>
                                            下一个界外球是指投注预测下一个获得界外球的队伍。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.21.5.2.</strong><span>
                                            如果投注的界外球已经抛出，注单将视为有效。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.22.</strong><span>
                                            特定10分钟大小盘分段投注 (O/U)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.22.1.</strong><span>
                                            特定10分钟分段投注（OU）的意思是，在每场比赛中的每10分钟[中场休息]结束时，根据总点数投注（进球、角球、比赛、掏牌等）。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.22.2.</strong><span>
                                            如果总点数超过预设的高/低线，那么买高的赢；如果总点数小于预设的高/低线，那么买低的赢。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.22.3.</strong><span>
                                            例如：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <strong>第10分钟OU</strong><br />
                                        00:00 – 10:00 O/U:可得分的总点数从00:00至10:00。<br />
                                        必须在第10分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第20分钟OU</strong><br />
                                        10:01 – 20:00 O/U:可得分的总点数从10:01至20:00。<br />
                                        必须在第20分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第30分钟OU</strong><br />
                                        20:01 – 30:00 O/U:可得分的总点数从20:01至30:00。<br />
                                        必须在第30分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第40分钟OU</strong><br />
                                        30:01 – 40:00 O/U:可得分的总点数从30:01至40:00。<br />
                                        必须在第40分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第60分钟OU</strong><br />
                                        50:01 – 60:00 O/U:可得分的总点数从50:01至60:00。<br />
                                        必须在第60分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第70分钟OU</strong><br />
                                        60:01 – 70:00 O/U:可得分的总点数从60:01至70:00。<br />
                                        必须在第70分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第80分钟OU</strong><br />
                                        70:01 – 80:00 O/U:可得分的总点数从70:01至80:00。<br />
                                        必须在第80分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第90分钟OU</strong><br />
                                        80:01 - 90:00 O/U:可得分的总点数从80:01至90:00。<br />
                                        必须在第90分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h4>
                                        <strong>2.2.22.4.</strong><span>
                                            对于特定的10分钟OU，根据现场转播显示的时间，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.22.5.</strong><span>
                                            如果暂停或放弃比赛，那么在未结束的这一特定10分钟内的投注将被视为无效。如果指定的特定10分钟O/U结束，那么投注有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.22.6.</strong><span>
                                            对于在任意特定10分钟大小盘的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：进球；点球和红牌。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.22.7.</strong><span>
                                            对于80:01-90:00这个时段，根据现场转播显示的时间，但不包括加时和伤停补时，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.23.</strong><span>
                                            室内足球规则
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.23.1.</strong><span>
                                            所有赌注根据球赛预定完场时间<strong>40分钟(2 x 20分钟)</strong>为准，加时赛或点球则<strong>不计算</strong>在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.23.2.</strong><span>
                                            如果比赛被延期或者暂停并未能于当天复赛，则所有投注将被视为作废。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.23.3.</strong><span>
                                            如果赛事被预先时间进行，所有票在预定时间前投注则为有效，若赛事开始之后投注将被视为作废，除了滾球投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.24.</strong><span>
                                            进球最多之球员
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.24.1.</strong><span>
                                            联赛最佳射脚
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.24.1.1.</strong><span>
                                            联赛最佳射脚是指以投注来预测哪个球员将会在一个常规联赛中入球较多。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.1.2.</strong><span>
                                            若一个球员在赛季中期加入同一联赛中的另一个球队， 所有在不同球队的进球将计算在总进球内。但任何在联赛之外的比赛之进球将不予以计算。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.1.3.</strong><span>
                                            任何在附加赛和点球决胜中的进球将不予以计算。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.1.4.</strong><span>
                                            乌龙球将不予以计算及不列入投注目的。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.1.5.</strong><span>
                                            非首发球员的投注仍有效。非首发球员是指任何球员在本赛季联赛没有被列入首发阵容名单。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.1.6.</strong><span>
                                            若一个球员在本赛季开始之前退出本赛季联赛或是转移到不同联赛的球队，所有投注于该球员的“最佳射脚”投注将退还。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.1.7.</strong><span>
                                            若宣布的结果为“不分胜负”，则一半赌金将分配给完全赔率，而另一半赌金将全部丧失。若超过两名参赛者“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.24.2.</strong><span>
                                            杯赛 (非联赛) 最佳射脚
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.24.2.1.</strong><span>
                                            杯赛 (非联赛) 最佳射脚是指以投注来预测哪个球员将会在一个常规杯赛或比赛中入球较多。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.2.2.</strong><span>
                                            乌龙球和点球决胜中的进球将不予以计算及不列入投注目的。然而，任何在加时赛的进球将计算在总进球内。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.2.3.</strong><span>
                                            非首发球员的投注仍有效。非首发球员是指任何球员在本杯赛或比赛没有被列入首发阵容名单。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.2.4.</strong><span>
                                            若一个球员在本赛季开始之前退出本杯赛或比赛或是转移到不同杯赛的球队，所有投注于该球员的“最佳射脚”投注将退还。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.2.5.</strong><span>
                                            若宣布的结果为“不分胜负”，则一半赌金将分配给完全赔率，而另一半赌金将全部丧失。若超过两名参赛者“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.24.3.</strong><span>
                                            球员一对一赛事进球最多之球员
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.24.3.1.</strong><span>
                                            乌龙球和点球决胜中之进球将不予以计算。加时赛将不予以计算及不列入投注目的。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.3.2.</strong><span>
                                            如果球员没开始/进行赛事， 所有投注将视为无效。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.24.3.3.</strong><span>
                                            所有结果将依照相关管理机构在比赛结束时所宣布的官方结果为准。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.25.</strong><span>
                                            伤停补时
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.25.1.</strong><span>
                                            上/下半场结束前受伤延长补时是弥补上半场或下半场比赛球员受伤所花费的时间。补时将分为:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <strong>无补时</strong><br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>1分钟</strong><br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>2分钟</strong><br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>3分钟</strong><br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>4 分钟或以上</strong><br />
                                    </h5>
                                    <h4>
                                        <strong>2.2.25.2.</strong><span>
                                            上半场伤停补时
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.25.2.1.</strong><span>
                                            所有注单是以上半场球赛结束前第四裁判员所判给的受伤延长补时结算。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.25.2.2.</strong><span>
                                            如果赛事在上半场中止，所有注单投注于上半场伤停补时均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.25.3.</strong><span>
                                            下半场伤停补时
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.25.3.1.</strong><span>
                                            所有注单是以下半场球赛结束前第四裁判员所判给的受伤延长补时结算。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.25.3.2.</strong><span>
                                            如果赛事在下半场中止，所有注单投注于下半场伤停补时均作废。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.25.4.</strong><span>
                                            本公司将根据主办比赛的官方成績来结算投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.26.</strong><span>
                                            双重机会和上半场双重机会
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.26.1.</strong><span>
                                            下列选项包括：
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>*</strong><span>
                                            1或X -如果球赛结果是主胜或和，则投注于此选项的为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>*</strong><span>
                                            X或2 - 如果球赛结果是和或客胜，则投注于此选项的为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>*</strong><span>
                                            1或2 - 如果球赛结果是主胜或客胜，则投注于此选项的为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>*</strong><span>
                                            如果比赛是在中立场进行﹐排在上面/左边的球队将被视为主场。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.27.</strong><span>
                                            获胜球队（平局退款）
                                        </span>
                                    </h3>

                                    <h4>
                                        <strong>2.2.27.1.</strong><span>
                                            预测该赛事之最终成绩为主或客胜，如果球赛在常规时间结束后之成绩为平局, 则所有注單將被退回。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.28.</strong><span>
                                            双方/一方/两者皆不得分
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>*</strong><span>
                                            双方=双方都得分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>*</strong><span>
                                            一方=其中一方得。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>*</strong><span>
                                            两者皆不=双方都不得 分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.28.1.</strong><span>
                                            如果赛事在只有主场进球之后中止， 则“两者皆不”的投注将有效，但“双方 ”和“一方”的投注均视为无效 。如果只有客场进球之后中止，则“两者皆不”的投注将有效，但“双方”和“一方”的投注均视为无效。如果主客场都有进球之后中止，则全部投注视为有效。如果赛事在没有任何进球的情况下中止，则全部投注视为无效。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>规则1：</strong><span>
                                            <br />
                                            主场得分≥ 1，客场得分= 0 (1, 2…: 0)<br />
                                            双方 – 退还<br />
                                            一方 – 退还<br />
                                            两者皆不 – 输<br />
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>规则2：</strong><span>
                                            <br />
                                            主场得分≥ 1，客场得分≥ 1 (1, 2…: 1, 2…)<br />
                                            双方 – 赢<br />
                                            一方 – 输<br />
                                            两者皆不 – 输<br />
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>规则3：</strong><span>
                                            <br />
                                            主场得分= 0，客场得分≥ 1 (0 : 1, 2…)<br />
                                            双方 – 退还<br />
                                            一方 – 退还<br />
                                            两者皆不 – 输<br />
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.29.</strong><span>
                                            零失球的胜方
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.29.1.</strong><span>
                                            预测于完场后获胜而又不失球的一方，加时赛和点球决胜将不计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.29.2.</strong><span>
                                            如果赛事在只有主场进球之后中止， 则“客场”的投注将有效，但“主场”的投注均视为无效。如果只有客场进球，则“主场”的投注将有效，但“客场”的投注均视为无效。如果主客场都有进球，则全部投注视为有效。如果赛事在没有任何进球的情况下中止，则全部投注视为无效。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>规则1：</strong><span>
                                            <br />
                                            主场得分≥ 1，客场得分= 0 (1, 2…: 0)<br />
                                            主场 – 退还  客场 – 输<br />
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>规则2：</strong><span>
                                            <br />
                                            主场得分≥ 1，客场得分≥ 1 (1, 2…: 1, 2…)<br />
                                            主场是– 输  客场是– 输<br />
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>规则3：</strong><span>
                                            <br />
                                            主场得分= 0，客场得分≥ 1 (0 : 1, 2…)<br />
                                            主场 – 输  客场 – 退还<br />
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.30.</strong><span>
                                            三项让分投注
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.30.1.</strong><span>
                                            让分投注的结算是以让分赔率为准，按让分后的赛果来计算的。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.31.</strong><span>
                                            胜出其中一个半场
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.31.1.</strong><span>
                                            预测所投注的一方在其中一个半场的进球数多于对手。
                                        </span>
                                    </h4>

                                    <h4><strong>2.2.31.2.</strong><span> 主队胜出其中一个半场</span></h4>
                                    <h4><strong>2.2.31.2.1.</strong><span> 主队胜出其中一个半场是指投注预测主队在其中一个半场的进球数多于对手。</span></h4>
                                    <h4><strong>2.2.31.2.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>
                                    <h4><strong>2.2.31.3.</strong><span> 客队胜出其中一个半场</span></h4>
                                    <h4><strong>2.2.31.3.1.</strong><span> 客队胜出其中一个半场是指投注预测客队在其中一个半场的进球数多于对手。</span></h4>
                                    <h4><strong>2.2.31.3.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>

                                    <h3>
                                        <strong>2.2.32.</strong><span>
                                            胜出两个半场（包括上/下半场投注）
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.32.1.</strong><span>
                                            预测所投注的一方在两个半场中的进球数都多于对手。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>举例：</strong><span>
                                            所投注的一方在上半场领先1-0而完场比分亦为1-0，虽然上半场以1-0胜出，但下半场的实际比分为0-0的和局如出现此状况，则只有上半场被视为“胜出”，因此此注单将判为输。
                                        </span>
                                    </h5>

                                    <h4><strong>2.2.32.2.</strong><span> 主队胜出两个半场 (包括上/下半场投注)</span></h4>
                                    <h4><strong>2.2.32.2.1.</strong><span> 主队胜出两个半场是指投注预测主队在两个半场中的进球数都多于对手。</span></h4>
                                    <h4><strong>2.2.32.2.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>
                                    <h4><strong>2.2.32.3.</strong><span> 客队胜出两个半场 (包括上/下半场投注)</span></h4>
                                    <h4><strong>2.2.32.3.1.</strong><span> 客队胜出两个半场是指投注预测客队在两个半场中的进球数都多于对手。</span></h4>
                                    <h4><strong>2.2.32.3.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>

                                    <h3>
                                        <strong>2.2.33.</strong><span>
                                            进球最多的球队
                                        </span>
                                    </h3>
                                    <h4><strong>2.2.33.1.</strong><span>进球最多的球队就是赢家。</span></h4>
                                    <h4><strong>2.2.33.2.</strong><span>不计算净胜球。</span></h4>
                                    <h4><strong>2.2.33.3.</strong><span>如果出现两支或以上并列最多进球的球队，则按“不分胜负”的投注规则来分配赌金。</span></h4>
                                    <h5>
                                        <strong>例子 :</strong><br />
                                        <span>曼市进4 球 ，史云斯进4球</span><br />
                                        <span>利物浦进4 球 ，桑德兰进1球</span><br />
                                        <span>那利物浦，曼市和史云斯都是赢家。</span>
                                    </h5>
                                    <h4><strong>2.2.33.4.</strong><span>中止或延期 </span></h4>
                                    <h5><strong>2.2.33.4.1.</strong><span>若投注的球队之赛事因事故中止或延期 (超出本公司所预定的赛程) ，所有投注于该球队的投注将视为无效。</span></h5>
                                    <h4><strong>2.2.33.5.</strong><span>点球决胜中的进球将不予以计算。 </span></h4>
                                    <h4><strong>2.2.33.6.</strong><span>针对于杯赛 (非联赛) 的进球最多的球队，任何在加时赛的进球将予以计算。 </span></h4>
                                    <h4><strong>2.2.33.7.</strong><span>针对于特定日期内的进球最多的球队，任何在加时赛的进球将不予以计算。 </span></h4>
                                    <h3>
                                        <strong>2.2.34.</strong><span>
                                            准确的上半場总进球:
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.34.1.</strong><span>
                                            准确的上半場总进球是指准确地投注预测两队在上半場总进球数。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.34.2.</strong><span>
                                            若在上半場結束前賽事中止，所有投注视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.35.</strong><span>
                                            准确的分組賽积分：
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.35.1.</strong><span>
                                            准确的分組賽积分是指准确地投注预测某队在分組賽结束后的总积分。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.36.</strong><span>
                                            进球最多的组合
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.36.1.</strong><span>
                                            在特定的期间内, 准确地投注进球最多的组合。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.36.2.</strong><span>
                                            如果出现两支或以上并列最多进球的组合，则按“不分胜负”的投注规则来分配赌金。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>例子:</strong><br />
                                        A组:<br />
                                        <span>波兰 -vs- 希腊</span><span>2 - 1 = 3球</span><br />
                                        <span>俄罗斯 -vs- 捷克</span><span>3 - 2 = 5球</span><br />
                                        A 组总进球: 8球<br />
                                        B 组总进球: 7球<br />
                                        C 组总进球: 7球<br />
                                        D 组总进球: 6球<br />
                                        <strong>A组</strong>是赢家<br />
                                    </h4>
                                    <h3>
                                        <strong>2.2.37.</strong><span>
                                            球队小组积分
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.37.1.</strong><span>
                                            预测某队在分组赛结束后的总积分是属于哪个特定选项（“低于”，“包括”， 和“高于”）。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>例子：</strong><br />
                                        X 队在 A 组 的总积分是 5 分<br />
                                        <span>低于 3 分</span><span>==> 输</span><br />
                                        <span>包括 3 - 4 分</span><span>==> 输</span><br />
                                        <span>高于 4 分</span><span>==> 赢</span><br />
                                    </h4>
                                    <h3>
                                        <strong>2.2.38.</strong><span>
                                            小组正向连嬴预测
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.38.1.</strong><span>
                                            预测小组赛第一 / 第二 的正确排名。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.39.</strong><span>
                                            伤停补时大小盘
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.39.1.</strong><span>
                                            上半场伤停补时大小盘
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.39.1.1.</strong><span>
                                            投注上半场伤停补时大小盘意味着上半场结束前因伤停而延长的加时。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.39.1.2.</strong><span>
                                            如果总分大于“大小盘”预先指定的球头，则投注“大盘”的为赢家；如果总分小于“大小盘”预先指定的球头，则投注“小盘”的为赢家。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.39.1.3.</strong><span>
                                            注单是以上半场球赛结束前第四裁判员所判的伤停补时结算。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.39.2.</strong><span>
                                            下半场伤停补时大小盘
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.39.2.1.</strong><span>
                                            投注下半场伤停补时大小盘意味着下半场结束前因伤停而延长的加时。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.39.2.2.</strong><span>
                                            如果总分大于“大小盘”预先指定的球头，则投注“大盘”的为赢家；如果总分小于“大小盘”预先指定的球头，则投注“小盘”的为赢家。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.39.2.3.</strong><span>
                                            注单是以下半场球赛结束前第四裁判员所判的伤停补时结算。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>2.2.40.</strong><span>
                                            第一个进球方式
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.40.1.</strong><span>
                                            预测比赛任何一队的第一个进球方式。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>2.2.40.1.1.</strong><span>
                                            任意球 - 进球必须是在任意球时直接射进。在出现“折射”的情况下，如果任意球射手被判获得进球得分，则该球将被计算在内。此外，还包括角球直接进球。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.40.1.2.</strong><span>
                                            点球 - 进球必须是在点球时射进，且进行点球球员将为进球得分手。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.40.1.3.</strong><span>
                                            乌龙球 - 如果该球被射进自己球队球门为乌龙球。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.40.1.4.</strong><span>
                                            头球 - 射手最后接触该进球的身体部分必须是头部。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.40.1.5.</strong><span>
                                            射门 - 所有以上不包括在内的其它进球类型。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>2.2.40.1.6.</strong><span>
                                            无进球
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>2.2.40.2.</strong><span>
                                            公司投注结算会根据足球权威组织的官方赛果为准。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.41.</strong><span>
                                            点球决胜 – 点球是否取得入球？
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.41.1.</strong><span>
                                            预测球队所指定点球入球或错失。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.41.2.</strong><span>
                                            如果球队所指定的点球并没有进行，所有票根将视为作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.42.</strong><span>
                                            双重预测
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.42.1.</strong><span>
                                            预测所投注的两支球队，在赛事结束后之名次必须为第一名或第二名。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.43.</strong><span>
                                            准确预测
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.43.1.</strong><span>
                                            预测所投注的两支球队, 在赛事结束后之名次必须与该投注所预测的名次相同。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.44.</strong><span>
                                            最佳升级球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.44.1.</strong><span>
                                            预测哪支新升级球队在球季结束时得到最高名次。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.44.2.</strong><span>
                                            新升级球队即获得升级资格参加一个项目或锦标赛的球队。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.45.</strong><span>
                                            区域冠军
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.45.1.</strong><span>
                                            区域冠军即投注预测哪支球队在赛事结束后在该区域排名第一。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.45.2.</strong><span>
                                            所有结果将依照相关管理机构在比赛结束时所宣布的官方结果为准。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.46.</strong><span>
                                            主队 (不获胜球队)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.46.1.</strong><span>
                                            预测该赛事之最终成绩为和球或客胜，如果球赛在常规时间结束后之成绩为主胜,则所有注單將被退回。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.47.</strong><span>
                                            客队 (不获胜球队)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.47.1.</strong><span>
                                            预测该赛事之最终成绩为和球或主胜，如果球赛在常规时间结束后之成绩为客胜,则所有注单将被退回。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.48.</strong><span>
                                            平局/不是平局
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.48.1.</strong><span>
                                            预测该赛事在常规时间结束后之最终成绩是否平局。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.49.</strong><span>
                                            上半场波胆
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.49.1.</strong><span>
                                            上半场波胆：预测投注在上半场比赛时间结束后的最后比分。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.49.2.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.50.</strong><span>
                                            赛果 / 总进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.50.1.</strong><span>
                                            准确预测全场赛果是主队赢，客队赢或打和以及总进球数在特定球数是大或小。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.50.2.</strong><span>
                                            六种投注选项为：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>主队 & 大 ： 主队赢以及总进球大过特定球数</li>
                                            <li>主队 & 小 ： 主队赢以及总进球小过特定球数</li>
                                            <li>打和 & 大 ： 打和以及总进球大过特定球数</li>
                                            <li>打和 & 小 ： 打和以及总进球小过特定球数</li>
                                            <li>客队 & 大 ： 客队赢以及总进球大过特定球数</li>
                                            <li>客队 & 小 ： 客队赢以及总进球小过特定球数</li>
                                        </ol>
                                    </h5>
                                    <h3>
                                        <strong>2.2.51.</strong><span>
                                            落后反超获胜的球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.51.1.</strong><span>
                                            落后反超获胜的球队”，所投注的球队必须首先在比赛中某一阶段落后，但后来反超获胜（90分钟内），投注才算赢。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.52.</strong><span>
                                            第一射手
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.52.1.</strong><span>
                                            "第一射手"是指预测在比赛中第一个进球的球员的投注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.52.2.</strong><span>
                                            对未参赛球员的投注将视为无效并退还。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.52.3.</strong><span>
                                            对仅在第一个进球后才作为替补上场的球员的投注将视为无效并退还。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.52.4.</strong><span>
                                            乌龙球不计为第一个进球。在这种情况下，下个进球的球员将视为第一射手。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.52.5.</strong><span>
                                            对第一个进球后选上的球员的投注将视为无效，除非第一个进球为乌龙球，此时的投注将有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.52.6.</strong><span>
                                            对第一个进球前被替补或离场的球员的投注将会输掉。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.52.7.</strong><span>
                                            如果比赛中没有球员进球，则投注"无进球"者将赢。如果乌龙球是比赛中唯一的进球，则投注"无进球"者将赢。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.53.</strong><span>
                                            最先进两个球的球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.53.1.</strong><span>
                                            最先进两个球的球队。三种投注选项为：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>主队</li>
                                            <li>客队</li>
                                            <li>两队皆不</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.53.2.</strong><span>
                                            如果赛事已经有一队进两个球后才终止或延期，所有投注依然有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.53.3.</strong><span>
                                            如果赛事在没有任何一队进两个球之前终止或延期，所有投注视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.54.</strong><span>
                                            最先进三个球的球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.54.1.</strong><span>
                                            投注最先进三个球的球队。三种投注选项为：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>主队</li>
                                            <li>客队</li>
                                            <li>两队皆不</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.54.2.</strong><span>
                                            如果赛事已经有一队进两个球后才终止或延期，所有投注依然有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.54.3.</strong><span>
                                            如果赛事在没有任何一队进两个球之前终止或延期，所有投注视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.55.</strong><span>
                                            预测第一个进球时段
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.55.1.</strong><span>
                                            第一个进球在哪个时段发生。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.55.2.</strong><span>
                                            十种投注选项为：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>00:00 - 10:00</li>
                                            <li>10:01 - 20:00</li>
                                            <li>20:01 - 30:00</li>
                                            <li>30:01 - 40:00</li>
                                            <li>40:01 - 50:00</li>
                                            <li>50:01 - 60:00</li>
                                            <li>60:01 - 70:00</li>
                                            <li>70:01 - 80:00</li>
                                            <li>80:01 – 全场</li>
                                            <li>无进球</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.55.3.</strong><span>
                                            如果赛事已经进了第一个球后才终止或延期，所有投注依然有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.55.4.</strong><span>
                                            如果赛事在没有进第一个球之前终止或延期，所有投注视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.56.</strong><span>
                                            预测进第一个球的半场
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.56.1.</strong><span>
                                            哪个半场进第一个球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.56.2.</strong><span>
                                            三种投注选项为:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>上半场</li>
                                            <li>下半场</li>
                                            <li>无进球</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.56.3.</strong><span>
                                            如果赛事已经进了第一个球后才终止或延期，所有投注依然有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.56.4.</strong><span>
                                            如果赛事在没有进第一个球之前终止或延期，所有投注视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.57.</strong><span>
                                            两队皆进球/赛果和两队皆进球/上半场赛果
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.57.1.</strong><span>
                                            准确预测全場的赛果是主队赢，客队赢或打和 以及是否两队皆有进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.57.2.</strong><span>
                                            三种投注选项为：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>是 & 主队 - 两队皆进球以及主队赢</li>
                                            <li>是 & 客队 - 两队皆进球以及客队赢</li>
                                            <li>是 & 打和 - 两队皆进球以及打和</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.57.3.</strong><span>
                                            两队皆进球/上半场赛果是指投注预测赛事的上半场赛果以及两队是否都在上半场皆有进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.57.4.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.58.</strong><span>
                                            比赛半场/全场单双盘(O/E)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.58.1.</strong><span>
                                            比赛半场/全场单双盘(O/E)是指投注预测半场结果和全场结果中的赛事是单单盘，单双盘，双单盘或双双盘。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.58.2.</strong><span>
                                            共四种投注选项:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>单/单</li>
                                            <li>单/双</li>
                                            <li>双/单</li>
                                            <li>双/双</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.58.3.</strong><span>
                                            比赛中的任何额外加时赛将不被计算在此投注类型的全场比分结果中。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.58.4.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.59.</strong><span>
                                            客队于哪个半场进球最多:
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.59.1.</strong><span>
                                            准确预测客队在哪个半场进球最多
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.59.2.</strong><span>
                                            3种投注选项为:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>上半场</li>
                                            <li>下半场</li>
                                            <li>打和</li>
                                        </ol>
                                    </h5>
                                    <h3>
                                        <strong>2.2.60.</strong><span>
                                            主队于哪个半场先进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.60.1.</strong><span>
                                            准确预测主队在哪个半场先取得进球
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.60.2.</strong><span>
                                            3种投注选项为:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>上半场</li>
                                            <li>下半场</li>
                                            <li>无进球</li>
                                        </ol>
                                    </h5>
                                    <h3>
                                        <strong>2.2.61.</strong><span>
                                            客队于哪个半场先进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.61.1.</strong><span>
                                            准确预测客队在哪个半场先取得进球
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.61.2.</strong><span>
                                            3种投注选项为:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>上半场</li>
                                            <li>下半场</li>
                                            <li>无进球</li>
                                        </ol>
                                    </h5>
                                    <h3>
                                        <strong>2.2.62.</strong><span>
                                            特定15分钟让球分段投注 (HDP)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.62.1.</strong><span>
                                            特定15分钟分段投注（HDP）的意思是，在每场比赛中的每15分钟[中场休息]结束时,将假定的让分数预先加给其中一方参赛者或队伍并据此进行投注。将让分数加上每15分钟比赛结果后，得分较高的参赛者或队伍即为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.62.2.</strong><span>
                                            供于参考:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <strong>第15分钟HDP</strong><br />
                                        预测从00:00至15:00得分较高的参赛者或队伍，必须在第15分钟结束时或在这一刻之前投注。<br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第30分钟HDP</strong><br />
                                        预测从15:01 – 30:00得分较高的参赛者或队伍，必须在第15分钟结束时或在这一刻之前投注。<br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第45分钟HDP</strong><br />
                                        预测从30:01- 45:00得分较高的参赛者或队伍，必须在第15分钟结束时或在这一刻之前投注。<br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第60分钟HDP</strong><br />
                                        预测从45:01 – 60:00得分较高的参赛者或队伍，必须在第15分钟结束时或在这一刻之前投注。<br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第75分钟HDP</strong><br />
                                        预测从60:01 – 75:00得分较高的参赛者或队伍，必须在第15分钟结束时或在这一刻之前投注。<br />
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第90分钟HDP</strong><br />
                                        预测从75:01 - 90:00得分较高的参赛者或队伍，必须在第15分钟结束时或在这一刻之前投注。<br />
                                    </h5>
                                    <h4>
                                        <strong>2.2.62.3.</strong><span>
                                            对于特定的15分钟HDP，根据现场转播显示的时间，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.62.4.</strong><span>
                                            如果暂停或放弃比赛，那么在未结束的这一特定15分钟内的投注将被视为无效。如果指定的特定15分钟HDP结束，那么投注有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.62.5.</strong><span>
                                            对于在任意特定15分钟让球盘的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：进球；点球和红牌。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.62.6.</strong><span>
                                            对于30:01-45:00和75:01 - 90:00这两个时段，根据现场转播显示的时间，但不包括加时和伤停补时，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.63.</strong><span>
                                            梦幻对垒：第一个进球的球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.63.1.</strong><span>
                                            梦幻对垒：第一个进球的球队是指投注预测哪个球队会在梦幻对垒比赛中率先进球。
                                        </span>
                                    </h4>
                                    <h4><strong>例如：</strong></h4>
                                    <h5 class="style1">
                                        赛事：曼城迎战利物浦，切尔西迎战曼联<br />
                                        梦幻对垒：曼城迎战切尔西<br />
                                        曼城在第25:10打进了第一个进球<br />
                                        切尔西在第25:48打进了第一个进球<br />
                                        中奖投注：曼城。<br />
                                    </h5>
                                    <h4>
                                        <strong>2.2.63.2.</strong><span>
                                            如果两场比赛进球得分是在同一时间（分钟和秒）或者比赛完场都没有进球得分，这样梦幻对垒将被视为平局。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.63.3.</strong><span>
                                            如果其中一场比赛进球得分之后，而另一场赛事才被推迟或取消，所有投注仍然有效。反之，如果其中一场比赛进球得分之前，另一场赛事就已经被推迟或取消，那么所有投注都将视为无效。
                                        </span>
                                    </h4>
                                    <h4><strong>例如：</strong></h4>
                                    <h5 class="style1">
                                        赛事：曼城迎战利物浦，切尔西迎战曼联<br />
                                        梦幻对垒：曼城迎战切尔西<br />
                                        曼城在第25:10打进了第一个进球<br />
                                        切尔西在第25:10之前发生比赛推迟或赛事取消。<br />
                                        结果：所有投注都将视为无效。<br />
                                    </h5>
                                    <h4>
                                        <strong>2.2.63.4.</strong><span>
                                            如果两场比赛在没有进球得分就被取消，所有投注将被作废。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.63.5.</strong><span>
                                            加时赛的进球将不被计算在内。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>2.2.64.</strong><span>
                                            欧洲最高名次球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.64.1.</strong><span>
                                            欧洲最高名次球队是指投注预测哪支欧洲球队在国际足联世界杯赛事得到最高名次。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.64.2.</strong><span>
                                            如果两个欧洲球队在总决赛或季军争夺赛对决，本场球赛的获胜者将被视为欧洲最高名次球队。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.64.3.</strong><span>
                                            在其他情况下，如果多个欧洲球队在同一轮赛事终止，则应用“不分胜负”的投注规则，一半赌金将分配给完全赔率，而另一半赌金将全部丧失。若超过两名参赛者“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.64.4.</strong><span>
                                            所有欧洲最高名次球队的投注将根据国际足联世界杯官方公告的成绩来结算投注。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.65.</strong><span>
                                            南美洲最高名次球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.65.1.</strong><span>
                                            南美洲最高名次球队是指投注预测哪支南美洲球队在国际足联世界杯赛事得到最高名次。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.65.2.</strong><span>
                                            如果两个南美洲球队在总决赛或季军争夺赛对决，本场球赛的获胜者将被视为南美洲最高名次球队。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.65.3.</strong><span>
                                            在其他情况下，如果多个南美洲球队在同一轮赛事终止，则应用“不分胜负”的投注规则，一半赌金将分配给完全赔率，而另一半赌金将全部丧失。若超过两名参赛者“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.65.4.</strong><span>
                                            所有南美洲最高名次球队的投注将根据国际足联世界杯官方公告的成绩来结算投注。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.66.</strong><span>
                                            非洲最高名次球队
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.66.1.</strong><span>
                                            非洲最高名次球队是指投注预测哪支非洲球队在国际足联世界杯赛事得到最高名次。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.66.2.</strong><span>
                                            如果两个非洲球队在总决赛或季军争夺赛对决，本场球赛的获胜者将被视为非洲最高名次球队。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.66.3.</strong><span>
                                            在其他情况下，如果多个非洲球队在同一轮赛事终止，则应用“不分胜负”的投注规则，一半赌金将分配给完全赔率，而另一半赌金将全部丧失。若超过两名参赛者“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.66.4.</strong><span>
                                            所有非洲最高名次球队的投注将根据国际足联世界杯官方公告的成绩来结算投注。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.67.</strong><span>
                                            两队皆进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.67.1.</strong><span>
                                            两队皆进球是指投注预测两队是否都在赛事中皆有进球。
                                        </span>
                                    </h4>

                                    <h4><strong>2.2.67.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>

                                    <h3>
                                        <strong>2.2.68.</strong><span>
                                            上半场两队皆进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.68.1.</strong><span>
                                            上半场两队皆进球是指投注预测两队是否都在赛事中的上半场皆有进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.68.2.</strong><span>
                                            如果赛事当主客队都有在上半场进球后中止，“是”的投注将视为胜方，“不是”的投注并视为负方。如果赛事在上半场之前并没有任何进球的情况下中止，则全部投注视为无效。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.69.</strong><span>
                                            下半场两队皆进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.69.1.</strong><span>
                                            下半场两队皆进球是指投注预测两队是否都在赛事中的下半场皆有进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.69.2.</strong><span>
                                            如果赛事当主客队都有在下半场进球后中止，“是”的投注将视为胜方，“不是”的投注并视为负方。如果赛事在没有任何进球的情况下中止，则全部投注视为无效。
                                        </span>
                                    </h4>


                                    <h3>
                                        <strong>2.2.70.</strong><span>
                                            两队皆进球 上半场/下半场
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.70.1.</strong><span>
                                            两队皆进球，上半场/下半场是指投注预测两队是否在赛事中的上半场皆有进球以及两队是否在赛事中的下半场皆有进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.70.2.</strong><span>
                                            如果赛事中止，除非主客队都有在上半场和下半场进球，否则投注将视为无效。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.71.</strong><span>
                                            两队皆进球/总进球数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.71.1.</strong><span>
                                            两队皆进球/总进球数是指投注预测两队在赛事中的总进球数以及两队是否都在赛事中进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.71.2.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.72.</strong><span>
                                            上半场赛果/总进球数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.72.1.</strong><span>
                                            上半场赛果/总进球数是指投注预测赛事的上半场赛果以及两队在上半场的总进球数。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.72.2.</strong><span>
                                            如果赛事在上半场期间中止，所有投注将视为无效。如果赛事在下半场期间中止，则所有投注将视为有效。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.73.</strong><span>
                                            主队均进球 上半场/下半场
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.73.1.</strong><span>
                                            主队均进球，上半场/下半场是指投注预测主队是否均在上半场和下半场都进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.73.2.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.74.</strong><span>
                                            客队均进球 上半场/下半场
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.74.1.</strong><span>
                                            客队均进球，上半场/下半场是指投注预测客队是否均在上半场和下半场都进球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.74.2.</strong><span>
                                            如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。
                                        </span>
                                    </h4>

                                    <h3>
                                        <strong>2.2.75.</strong><span>
                                            特定15分钟分段投注（1X2）
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.75.1.</strong><span>
                                            特定15分钟分段投注（1X2）是指投注在每场比赛中的每15分钟（中场休息）结束时，预测三种可能的胜利结果中的任何一种。1表示首先被提及的队伍（通常是主队）；X代表比赛结果平手；2代表第二个被提及的队伍（通常是客队）。将以每15分钟比赛结果后决定胜负。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.75.2.</strong><span>
                                            例如:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <strong>第15分钟1X2</strong><br />
                                        预测从00:00 – 15:00三种可能的胜利结果中的任何一种，必须在第15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第30分钟1X2</strong><br />
                                        预测从15:01 – 30:00三种可能的胜利结果中的任何一种，必须在第15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第45分钟1X2</strong><br />
                                        预测从30:01 – 45:00三种可能的胜利结果中的任何一种，必须在第15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第60分钟1X2</strong><br />
                                        预测从45:01 – 60:00三种可能的胜利结果中的任何一种，必须在第15分钟结束时或在这一刻之前投注。.
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第75分钟1X2</strong><br />
                                        预测从60:01 – 75:00三种可能的胜利结果中的任何一种，必须在第15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第90分钟1X2</strong><br />
                                        预测从75:01 – 90:00三种可能的胜利结果中的任何一种，必须在第15分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h4>
                                        <strong>2.2.75.3.</strong><span>
                                            对于特定的15分钟1X2，根据现场转播显示的时间，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.75.4.</strong><span>
                                            如果暂停或放弃比赛，那么在未结束的这一特定15分钟内的投注将被视为无效。如果指定的特定15分钟1X2结束，那么投注将视为有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.75.5.</strong><span>
                                            对于在任意特定15分钟1X2盘的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：进球；点球和红牌。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.75.6.</strong><span>
                                            对于30:01 – 45:00和75:01 – 90:00这两个时段，根据现场转播显示的时间，但不包括加时和伤停补时，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.76. </strong><span>
                                            哪支球队晋级
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong> 2.2.76.1. </strong><span>
                                            哪支球队晋级是指投注预测哪支球队将会晋级下一轮赛事。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.77. </strong><span>
                                            特定10分钟让球分段投注 (HDP)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.77.1. </strong><span>
                                            特定10分钟分段投注 (HDP) 的意思是，在每场比赛中的每10分钟 [中场休息] 结束时，将假定的让分数预先加给其中一方参赛者或队伍并据此进行投注。将让分数加上每10分钟比赛结果后，得分较高的参赛者或队伍即为赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.77.2. </strong><span>
                                            供于参考:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <strong>第10分钟HDP</strong><br />
                                        预测从00:00至10:00得分较高的参赛者或队伍，必须在第10分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第20分钟HDP</strong><br />
                                        预测从10:01至20:00得分较高的参赛者或队伍，必须在第20分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第30分钟HDP</strong><br />
                                        预测从20:01至30:00得分较高的参赛者或队伍，必须在第30分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第40分钟HDP</strong><br />
                                        预测从30:01至40:00得分较高的参赛者或队伍，必须在第40分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第60分钟HDP</strong><br />
                                        预测从50:01至60:00得分较高的参赛者或队伍，必须在第60分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第70分钟HDP</strong><br />
                                        预测从60:01至70:00得分较高的参赛者或队伍，必须在第70分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第80分钟HDP</strong><br />
                                        预测从70:01至80:00得分较高的参赛者或队伍，必须在第80分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h5 class="style1">
                                        <strong>第90分钟HDP</strong><br />
                                        预测从80:01至90:00得分较高的参赛者或队伍，必须在第90分钟结束时或在这一刻之前投注。
                                    </h5>
                                    <h4>
                                        <strong>2.2.77.3. </strong><span>
                                            对于特定的10分钟HDP，根据现场转播显示的时间，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.77.4. </strong><span>
                                            如果暂停或放弃比赛，那么在未结束的这一特定10分钟内的投注将被视为无效。如果指定的特定10分钟HDP结束，那么投注有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.77.5. </strong><span>
                                            对于在任意特定10分钟让球盘的最后两（2）分钟内的即时投注，除了以下提到的，任何其他行动都将被视为安全玩法，因此所有未决投注将是可接受的：进球；点球和红牌。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.77.6. </strong><span>
                                            对于80:01 – 90:00这个时段，根据现场转播显示的时间，但不包括加时和伤停补时，在确定得分（球超过球门线）、角球数（获得角球）和总红黄牌数（由官方裁判掏牌）时结算投注。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.78. </strong><span>
                                            第一个点球进或不进
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.78.1. </strong><span>
                                            第一个点球进或不进是指投注预测该比赛中的第一个点球将会进或错失点球。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.79. </strong><span>
                                            最高名次球队的球衣赞助商
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.79.1. </strong><span>
                                            最高名次球队的球衣赞助商是指投注预测哪个球衣赞助商的球队会赢得冠军头衔。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.80. </strong><span>
                                            冠军队教练的洲籍
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.80.1. </strong><span>
                                            冠军队教练的洲籍是指投注预测冠军队主教练的原籍大洲。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.81. </strong><span>
                                            净胜球数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.81.1. </strong><span>
                                            净胜球数是指投注预测主队和客队之间的胜方和输赢比数。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.81.2. </strong><span>
                                            这种投注类型的选项例如有:
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>主队以1球取胜</li>
                                            <li>主队以2球取胜</li>
                                            <li>主队以3球或以上取胜</li>
                                            <li>任何平局的比数</li>
                                            <li>客队以1球取胜</li>
                                            <li>客队以2球取胜</li>
                                            <li>客队以3球或以上取胜</li>
                                        </ol>
                                    </h5>
                                    <h4>
                                        <strong>2.2.81.3. </strong><span>
                                            任何的加时赛将不包括在判定净胜球数的投注类型。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.82. </strong><span>
                                            下一个进球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.82.1. </strong><span>
                                            下一个进球是指投注预测该比赛下一个进球的球队。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong> 2.2.83. </strong><span>
                                            判罚点球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>2.2.83.1. </strong><span>
                                            判罚点球是指投注预测是否在该赛事中有判罚点球。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>2.2.83.2. </strong><span>
                                            以下为投注选项：
                                        </span>
                                    </h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>有</li>
                                            <li>没有</li>
                                        </ol>
                                    </h5>

                                    <h3><strong>2.2.84.</strong><span> 进球最多的半场</span></h3>
                                    <h4><strong>2.2.84.1.</strong><span> 进球最多的半场是指投注预测比赛中的哪一个半场进球最多。</span></h4>
                                    <h4><strong>2.2.84.2.</strong><span> 投注选项包括：</span></h4>
                                    <h5 class="style1">
                                        <ol>
                                            <li>上半场</li>
                                            <li>下半场</li>
                                            <li>打和</li>
                                        </ol>
                                    </h5>

                                    <h4><strong>2.2.84.3.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>
                                    <h4><strong>2.2.84.4.</strong><span> 主队进球最多的半场</span></h4>
                                    <h4><strong>2.2.84.4.1.</strong><span> 主队进球最多的半场是指投注预测主队在比赛中的哪一个半场进球最多。</span></h4>
                                    <h4><strong>2.2.84.4.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>
                                    <h4><strong>2.2.84.5.</strong><span> 客队进球最多的半场</span></h4>
                                    <h4><strong>2.2.84.5.1.</strong><span> 客队进球最多的半场是指投注预测客队在比赛中的哪一个半场进球最多。</span></h4>
                                    <h4><strong>2.2.84.5.2.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>

                                    <h3><strong>2.2.85.</strong><span> 上半场/下半场波胆</span></h3>
                                    <h4><strong>2.2.85.1.</strong><span> 上半场/下半场波胆是指投注均预测上半场的比分和下半场结束后的比分。</span></h4>
                                    <h4><strong>2.2.85.2.</strong><span> 上半场/下半场波胆里的“4+”投注选项是指赛事的总进球要至少4球才能赢得赌注。</span></h4>
                                    <h4><strong>2.2.85.3.</strong><span> 如果赛事中止，除非投注结算已可被确认，否则投注将视为无效。</span></h4>


                                    <h1><a name="R3" id="R3"></a>3. 特定赛事投注规则</h1>
                                    <!-- 3.1 -->
                                    <h2><a name="R31" id="R31"></a>3.1. 足球规则</h2>
                                    <h3>
                                        <strong>3.1.1.</strong><span>
                                            若球赛原定的比赛时间异于常规时间（如各种锦标赛或友谊赛的特别比赛时间），所有投注将依照原定的比赛时间来结算。
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.1.1.1.</strong><span>
                                            若比赛时间少于常规时间，本公司有权搁置所有投注的结算直到官方成绩公布。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.1.1.2.</strong><span>
                                            关于不正规的比赛时间，除非于比赛前已公布在网站上，否则投注将被作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.1.2.</strong><span>
                                            如果足球比赛延期，中止或暂停，而且没有在同一天恢复比赛，则注单作废，如果比赛在十二小时期限前复赛，投注才被视作有效。不管是否有官方决定承认比赛结果，公司都会宣布比赛作废。所有对中止/暂停赛事的投注结果，由公司全权决定。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.3.</strong><span>
                                            上半场投注 (1H) 仅适用于上半场赛事。 如果在上半场结束前赛事中止，所有投注作废。 如果在下半场结束前赛事中止，所有上半场投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.4.</strong><span>
                                            本公司所提供之信息（例：红牌，计时，统计信息，日期，生效日期等等）服务，并不担负责任确保其准确性客户有责任确认任何比赛之相关信息的准确性。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.5.</strong><span>
                                            除非另有说明，如果场地从主场变成中立场地，或从之前提及的中立场地变成主场，所有投注仍然有效。如果场地从主场变更为原来的客场，则投注作废。如果主场和客场的名称被错误颠倒，则投注也作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.6.</strong><span>
                                            分数会现场更新，以供足球现场投注参考，现场交易时显示的赌盘则表示下注时显示的分数。 计时和红牌公告均仅供参考。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.7.</strong><span>
                                            对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.8.</strong><span>
                                            对于现场投注，在大部分的比赛中，投注者可于第九十分钟或官方法定时间的最后一分钟以及任何全场比赛的伤停时间内下注；任何操作（本节 3.1.8中提及的操作除外）都将视作“安全比赛”，因此公司将接受任何待定的投注：在大禁区中或附近进行比赛、罚球以及被公司视为危险（可能得分）的任意球。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.9.</strong><span>
                                            对于现场投注，只要裁判宣布半场或全场比赛时间结束，公司会自动拒绝所有待定的投注。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.10.</strong><span>
                                            对于罚点球时，所有待定投注将会处于等待直到点球结果而做出以下决定：如果判定点球得分，公司会取消所有待定的投注;反之，如果点球不进，公司将会在认为处于安全的区域方接受所有待定的投注。以上动作不包括现场特定15分钟大小盘，特定10分钟大小盘以及特定15分钟让球盘的最后两（2）分钟。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.1.11.</strong><span>
                                            梦幻对垒现场投注，在大部分的比赛中，投注者可于第九十分钟或官方法定时间的最后一分钟以及任何全场比赛的伤停时间内下注；但是，从比赛开始（00:00），直到比赛（90分钟）结束前，除了以下的数种情况：在大禁区中或附近进行比赛、罚球以及被公司视为危险（可能得分）的任意球。 任何操作都将视作「安全比賽」，因此本公司将接受任何待定的投注。
                                        </span>
                                    </h3>
                                    <h2><a name="R32" id="R32"></a>3.2. 篮球</h2>
                                    <h3>
                                        <strong>3.2.1.</strong><span>
                                            所有全场赌盘（包含实时投注）的结果均以最终比赛结果进行结算，包含延时（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.2.</strong><span>
                                            如果比赛未于原定开始时间开赛，所有投注均作废（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.3.</strong><span>
                                            如果比赛开始，但在原定开始时间的十二小时内暂停或中止，若 NBA 比赛至少完成了四十三 (43) 分钟，任何其他篮球比赛至少完成了三十五 (35) 分钟，则全场投注仍然有效。 如果由相关管理机构宣布官方结果，投注仍然有效。 否则，暂停或中止赛事的投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.4.</strong><span>
                                            上半场结果为第一节和第二节的分数总和。 下半场结果为第三节和第四节的分数总和，<strong>包含</strong>任何比赛延时。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.5.</strong><span>
                                            第四节的结果<strong>不</strong>包含任何延时。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.6.</strong><span>
                                            如果比赛暂停或中止，所有未完成半场或小节的投注均作废。 如果指定的半场或小节已完赛，则投注有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.7.</strong><span>
                                            篮球实时投注的分数<strong>不会</strong>更新，实时交易时显示的让分表示比赛开始时的分数，即 0-0。所显示的时间和比分，仅供参考。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.8.</strong><span>
                                            “首先得分队伍”的赌盘结果是以率先得分的队伍进行结算。 如果有队伍率先得分后赛事暂停或中止，投注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.9.</strong><span>
                                            “最后得分队伍”盘口结果，是以比赛中(包括加时赛)最后得分的队伍进行结算. 然而，[ 单节及半场的最后得分队伍] 盘口结果是以比赛中(不包括加时赛)最后得分的队伍进行结算。如果赛事暂停或中止，所有投注均作废。无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.10.</strong><span>
                                            如果双方球员都参加比赛，特殊赌盘（包括分数、篮板球、助攻、三分球、罚球等）均有效。 如果一方或双方球员未参加比赛，所有投注均作废。 特殊赌盘的结果包含延时（除非另有说明）。 管理机构（NBA.com、FIBA.com 等）在比赛结束公布官方结果后，公司即认定这些赛果，任何后续的数据变更均不适用于投注目的。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.11.</strong><span>
                                            NCAA 比赛的主场/客场信息仅供参考。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.12.</strong><span>
                                            梦幻对垒篮球赛事  (包括即时投注)  是将不同赛事中的队伍进行配对及组合的赌盘。在梦幻赛事中, 两队须在同一天进行比赛, 否则投注均视为作废。梦幻对垒赌盘的场地仅供参考。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.2.13.</strong><span>
                                            赢得最多节数的赌盘是以在整场篮球比赛中赢得最多节数的队伍进行结算。如果单节比分相同，那么任一队都不能赢得该节。此赌盘中不包含延时。如果赛事暂停或中止，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h2><a name="R33" id="R33"></a>3.3. 美式足球</h2>
                                    <h3>
                                        <strong>3.3.1. </strong><span>
                                            所有全场赌盘（包含实时投注）的结果都以最后比赛结果进行结算，包含延时（除非规则中另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.2.</strong><span>
                                            如果比赛未于原定开始时间开赛，所有投注均作废（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.3.</strong><span>
                                            如果比赛开始，但在原定开始时间的十二小时内暂停或中止，而比赛已完成五十五 (55) 分钟，则全场的投注仍然有效。 如果由相关管理机构宣布官方结果，投注仍然有效。 否则，暂停或中止赛事的投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.4.</strong><span>
                                            上半场结果为第一节和第二节的分数总和。 下半场结果为第三节和第四节的分数总和，<strong>包含</strong>任何比赛延时。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.5.</strong><span>
                                            第四节的结果<strong>不</strong>包含任何延时。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.6.</strong><span>
                                            如果比赛暂停或中止，所有未完成半场或小节的投注均作废。 如果指定的半场或小节已完赛，则投注有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.7.</strong><span>
                                            美式足球实时投注的分数会实时更新，实时交易时显示的赌盘则表示下注时显示的分数。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.8.</strong><span>
                                            “首先得分队伍”的赌盘结果是以率先得分的队伍进行结算。 如果有队伍率先得分后赛事暂停或中止，投注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.9.</strong><span>
                                            “最后得分队伍”赌盘结果是以比赛中（包括延时）最后得分的队伍进行结算。 如果赛事暂停或中止，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.10.</strong><span>
                                            NCAA 比赛的主场/客场信息仅供参考。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.3.11.</strong><span>
                                            对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。
                                        </span>
                                    </h3>
                                    <h2><a name="R34" id="R34"></a>3.4. 棒球</h2>
                                    <h3>
                                        <strong>3.4.1.</strong><span>
                                            投手姓名仅限参考。 无论先发投手是谁，所有棒球投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.2.</strong><span>
                                            所有全场赌盘（包含实时投注）的结果都以最后比赛结果进行结算，<strong>包含</strong>加赛局数（除非规则中另有说明）。 日本棒球可以宣布平手，如果出现此类情况，“胜负盘”赌金<strong>会</strong>全数归还。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.3.</strong><span>
                                            如果比赛未于原定开始时间开赛，所有投注均作废（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.4.</strong><span>
                                            比赛必须进行 9（九）局（如果主队领先则为 8.5 局），棒球投注才有效。 如果比赛暂停，并在隔天继续完成，则所有赌注（无条件决定投注结果的赌盘除外）均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.5.</strong><span>
                                            如果比赛暂停或进入延长赛，分数会在完整局数后决定，除非主队在局数下半场打成平手或领先，在此类情况下，分数将在比赛停止时决定。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.6.</strong><span>
                                            前五局投注是根据前五局结束后的结果进行结算。 如果不论任何原因未打完五局，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.7.</strong><span>
                                            棒球实时投注的分数会实时更新，实时交易时显示的赌盘则表示下注时显示的分数。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.8.</strong><span>
                                            根据世界棒球经典赛规定，如果另一队打击至少七局，领先队伍领先十分以上，或者另一队至少打击五局，领先队伍领先十五分以上，比赛可以提前结束。 如果出现此类情况，所有投注均有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.9.</strong><span>
                                            国际棒球比赛（例如奥运棒球比赛）可以提前结束，但至少需打完 6.5 局，投注才有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.4.10.</strong><span>
                                            对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。
                                        </span>
                                    </h3>
                                    <h2><a name="R35" id="R35"></a>3.5. 冰上曲棍球</h2>
                                    <h3><strong>3.5.1</strong>.<span>全局赌盘将提供 "不包括加时赛与点球赛 "或 " 包括加时赛与点球赛 " 或两者兼有。客户应始终参照赌盘标题。由罚球决胜赛确定赛果的赛事 , 将添加一个得分至优胜队伍的分数来确定最终的结果。</span> </h3>
                                    <h3><strong>3.5.2.</strong> <span>如果比赛未于原定开始时间开赛，所有投注均作废（除非另有说明）。</span></h3>
                                    <h3><strong>3.5.3.</strong> <span>如果比赛开始，但在原定开始时间的十二小时内暂停或中止，而比赛已完成五十五 (55) 分钟，则全场的投注仍然有效。 如果由相关管理机构宣布官方结果，投注仍然有效。 否则，暂停或中止赛事的投注均作废，无条件决定投注结果的赌盘除外。 </span></h3>
                                    <h3><strong>3.5.4.</strong> <span>对于赛局投注，必须完成一局，投注才有效。 </span></h3>
                                    <h3><strong>3.5.5.</strong> <span>第三局结果<strong>不</strong>包含任何可能进行的延时或决胜赛。 </span></h3>
                                    <h3><strong>3.5.6.</strong> <span>“冰上曲棍球实时投注”是以正规时间（三局）结束时的结果进行结算。 延时和决胜赛结果<strong>不</strong>计算在内。 </span></h3>
                                    <h3><strong>3.5.7.</strong> <span>冰上曲棍球实时投注的分数会实时更新，实时交易时显示的赌盘则表示下注时显示的分数。</span> </h3>
                                    <h3><strong>3.5.8.</strong> <span>对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。</span> </h3>
                                    <h2><a name="R36" id="R36"></a>3.6. 网球</h2>
                                    <h3><strong>3.6.1.</strong> <span>胜负盘是指比赛或指定分组的赢家。 让分盘是根据分组或比赛进行（请参阅赌盘名称）；“大于/小于”以及“奇数/偶数”是根据比赛决定（除非另有说明）。 </span></h3>
                                    <h3><strong>3.6.2.</strong> <span>如果选手没有参加锦标赛或比赛，投在该名选手身上的赌金均作废。 </span></h3>
                                    <h3>
                                        <strong>3.6.3.</strong><span>
                                            如果选手（或双人搭档）在比赛时退出或丧失资格，所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.4.</strong><span>
                                            如果比赛延期或暂停后，赛事仍然完整结束，所有赌注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.5.</strong><span>
                                            无论场地或球场表面是否有任何变更（包括从户外移往室内球场进行比赛，反之亦然），所有赌注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.6.</strong><span>
                                            如果原定的取胜局数有所改变，所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.7.</strong><span>
                                            第一局赢家（第二、第三局等）表示指定局的赌盘结果。 如果指定局未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.8.</strong><span>
                                            网球实时投注的结果是根据比赛（或指定局）结果进行结算。 网球实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.9.</strong><span>
                                            “最多发球得分”（双误等）赌盘是根据最后比赛统计结果结算。 如果球员在比赛结束前退出或丧失比赛资格，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.10.</strong><span>
                                            “首个发球得分”（双误等）赌盘根据官方的比赛统计结果进行结算。即使由于退赛或取消资格的原因造成比赛未能完成，只要首个发球得分（双误等）赌盘已判定结果，则所有投注仍然有效。如果在发生退赛/取消资格的事件时，比赛中尚未发球得分（双误等），则所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.11.</strong><span>
                                            “比赛获胜者赌盘针对的是特定比赛中的获胜者，例如，1 局 1 赛、1 局 2 赛等等。如果是针对加赛设局，则赌盘将被指定为 1 局 TB、2 局 TB 等等。如果比赛未结束之前出现了退赛/取消资格的情况，则所有投注均作废。如果比赛在裁判授意的“罚球赛”之后结束，则该场比赛的所有投注均作废（倘若比赛在“罚球点”之后结束，则所有投注仍然有效）。 如果比赛暂停后，赛事仍然完整结束，所有赌注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.12.</strong><span>
                                            盘数波胆是指每个选手于一场比赛所赢得的准确盘数。如果比赛未完成，则所有投注将被视为无效。如果比赛原订获胜所需盘数更改，则所有投注将被视为无效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.6.13.</strong><span>
                                            第X盘波胆是指每个选手于一场比赛在指定盘所赢得的准确局数。如果所指定盘未完成，则所有投注将被视为无效。如果指定盘原定获胜局数更改，则所有投注将被视为无效。
                                        </span>
                                    </h3>
                                    <h2><a name="R37" id="R37"></a>3.7. 羽毛球</h2>
                                    <h3>
                                        <strong>3.7.1.</strong><span>
                                            胜负盘是指比赛或指定分组的赢家。 让分盘是根据分组或得分而决定（请参阅赌盘名称）；“大于/小于”以及“奇数/偶数”是根据得分而决定（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.7.2.</strong><span>
                                            如果选手没有参加锦标赛或比赛，投在该名选手身上的赌金均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.7.3.</strong><span>
                                            如果选手（或双人搭档）在比赛时退出或丧失资格，所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.7.4.</strong><span>
                                            如果比赛延期或暂停，并且比赛在十二小时的期限前恢复，所有投注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.7.5.</strong><span>
                                            第一局赢家（第二、第三局等）表示指定局的赌盘结果。 如果指定局未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.7.6. </strong><span>
                                            羽毛球实时投注的结果是根据比赛（或指定分组）结果进行结算。 羽毛球实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h2><a name="R38" id="R38"></a>3.8. 乒乓球</h2>
                                    <h3>
                                        <strong>3.8.1.</strong><span>
                                            胜负盘是指比赛或指定分组的赢家。 让分盘是根据分组或得分而决定（请参阅赌盘名称）；“大于/小于”以及“奇数/偶数”是根据得分而决定（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.8.2.</strong><span>
                                            如果选手没有参加锦标赛或比赛，投在该名选手身上的赌金均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.8.3.</strong><span>
                                            如果选手（或双人搭档）在比赛时退出或丧失资格，所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.8.4. </strong><span>
                                            如果比赛延期或暂停，并且比赛在十二小时的期限前恢复，所有投注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.8.5.</strong><span>
                                            第一局赢家（第二、第三局等）表示指定局的赌盘结果。 如果指定局未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3><strong>3.8.6.</strong> <span>乒乓球实时投注的结果是根据比赛（或指定分组）结果进行结算。 乒乓球实时投注的分数不会更新。</span></h3>
                                    <h2><a name="R39" id="R39"></a>3.9. 排球及沙滩排球</h2>
                                    <h3>
                                        <strong>3.9.1.</strong><span>
                                            胜负盘是指比赛或指定分组的赢家。 让分盘是根据分组或得分而决定（请参阅赌盘名称）；“大于/小于”以及“奇数/偶数”是根据得分而决定（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3><strong>3.9.2.</strong> <span>如果队伍没有参加锦标赛或比赛，投在该队伍身上的赌金均作废。</span></h3>
                                    <h3>
                                        <strong>3.9.3. </strong><span>
                                            如果队伍在比赛时退出或丧失资格，所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.9.4.</strong><span>
                                            如果比赛延期或暂停，并且比赛在十二小时的期限前恢复，所有投注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.9.5.</strong><span>
                                            第一局赢家（第二、第三局等）表示指定局的赌盘结果。 如果指定局未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.9.6.</strong><span>
                                            排球实时投注的结果是根据比赛（或指定局）结果进行结算。 排球实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h2><a name="R310" id="R310"></a>3.10. 曲棍球</h2>
                                    <h3>
                                        <strong>3.10.1.</strong><span>
                                            所有全场赌盘（包含实时投注）是以正规时间结束时的结果进行结算。 加时赛、金球和罚球决胜赛不计入全场赌盘的计算结果 （加时赛 (ET) 和罚球决胜赛 (PEN) 的特定赌盘可能会接受下注）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.10.2.</strong><span>
                                            如果比赛延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废，无条件决定投注结果的赌盘除外。 如果由相关管理机构宣布官方结果，投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.10.3. </strong><span>
                                            “上半场”赌盘是指上半场的比赛结果。 如果指定半场未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.10.4. </strong><span>
                                            “曲棍球实时投注”是以正规时间结束时的结果进行结算。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.10.5.</strong><span>
                                            曲棍球实时投注的分数会实时更新，实时交易时显示的赌盘则表示下注时显示的分数。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.10.6.</strong><span>
                                            对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。
                                        </span>
                                    </h3>
                                    <h2><a name="R311" id="R311"></a>3.11. 台球</h2>
                                    <h3>
                                        <strong>3.11.1.</strong><span>
                                            胜负盘及让分盘是指比赛的赢家。 “大于/小于”及“奇数/偶数”是根据盘数/三角框数而定（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.2.</strong><span>
                                            如果选手没有参加锦标赛或比赛，投在该名选手身上的赌金均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.3.</strong><span>
                                            如果比赛已开始但未结束，则所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.4.</strong><span>
                                            “台球实时投注”的结果是根据比赛（或指定盘/三角框）结果进行结算。 台球实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.5.</strong><span>
                                            单局 “大/小盘” 及“单/双盘” 的盘口是根据那一局的总得分而决定。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.6.</strong><span>
                                            哪位选手将击入第一颗红球？交易指的是选手在一场比赛或某一局中击入第一个有效红球，即在没有犯规的情况下击入第一个红球。（如果一局里需要将球重摆，那么原局的所有赌注仍然有效。）
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.7.</strong><span>
                                            哪位选手首先得到30分？交易指的是哪位选手将在某一局里首先得到三十分。（如果一局里需要将球重摆，那么原局的所有赌注仍然有效。）
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.8.</strong><span>
                                            单杆最高得分指的是在某一局、某场比赛或锦标赛里由某一选手或某些选手“单杆”的最高得分。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.11.9.</strong><span>
                                            单杆过百总数指的是在某一局或某一锦标赛里“单杆”超过100分的总次数。
                                        </span>
                                    </h3>
                                    <h2><a name="R312" id="R312"></a>3.12. 高尔夫球</h2>
                                    <h3>
                                        <strong>3.12.1.</strong><span>
                                            所有高尔夫球投注是根据官方锦标赛结果进行结算。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.12.2.</strong><span>
                                            若选手没有参加锦标赛或特定回合，投在该名选手身上的赌金皆作废。若高尔夫球选手在锦标赛或特定回合中退出或丧失资格，投在该名选手身上的所有赌注全输。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.12.3.</strong><span>
                                            如果锦标赛或特定回合因故延期或暂停，所有投注在开赛时间后的四十八小时内有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.12.4.</strong><span>
                                            总冠军(锦标赛获胜者)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.12.4.1.</strong><span>
                                            “任何其他选手”或“场上选手”是指总冠军赌盘中未提及的高尔夫球选手。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.4.2.</strong><span>
                                            总冠军赌盘是根据锦标赛赢家进行结算，任何延长赛的结果均计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.4.3.</strong><span>
                                            如果官方缩短锦标赛赛程（即没有完成原定洞数），如果官方公布正式赢家，则所有投注均有效。 但是，如果在下注后并无后续球赛，则该投注作废。 如果官方没有宣布正式赢家，所有投注均作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.12.5.</strong><span>
                                            锦标赛杆数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.12.5.1.</strong><span>
                                            锦标赛杆数是指锦标赛（通常是 72 洞）中打出最低杆数的高尔夫球选手。 如果比赛的洞数比原定少，并且官方公布锦标赛结果，则所有投注仍有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.5.2.</strong><span>
                                            两名高尔夫球选手必须发球，投注才有效。 完成最多洞数（不包括延长赛）的高尔夫球选手是赢家。 如果高尔夫球选手完成相同洞数（同样不包括延长赛），则杆数最低的选手是赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.5.3.</strong><span>
                                            如果高尔夫球选手在开球后退出或丧失资格，则另一名选手为赢家。 但是，如果选手是在另一名选手已遭淘汰后才退出比赛或丧失资格，则完成最多洞数的高尔夫球选手仍然是赢家。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.5.4.</strong><span>
                                            如果两名高尔夫球选手在同一回合中退出比赛或丧失资格，无论每位选手完成多少洞数，所有投注均作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.12.6.</strong><span>
                                            回合杆数
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.12.6.1.</strong><span>
                                            回合杆数是指在指定的 18 洞比赛中打出最低杆数的高尔夫球选手。 延长赛<strong>不</strong>计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.6.2.</strong><span>
                                            两名高尔夫球选手必须发球，投注才有效。 如果高尔夫球选手在开球后退出或丧失资格，则另一名选手为赢家。 但是，如果丧失资格的选手已经开始下一回合，则原始杆数仍然有效。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.12.7.</strong><span>
                                            回合大于/小于
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.12.7.1.</strong><span>
                                            “回合大于/小于”赌盘是指高尔夫球选手在指定的 18 洞比赛中打出的杆数。 延长赛不计算在内。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.7.2.</strong><span>
                                            高尔夫球选手必须发球，投注才有效。 如果一名高尔夫球选手无法完成指定的 18 洞，所有投注均作废。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.12.8.</strong><span>
                                            单洞大于/小于
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.12.8.1.</strong><span>
                                            “单洞大于/小于”赌盘是指高尔夫球选手在特定回合中在某一个洞上打出的杆数。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.12.8.2.</strong><span>
                                            如果高尔夫球选手未完成该洞，所有投注均作废。
                                        </span>
                                    </h4>
                                    <h2><a name="R313" id="R313"></a>3.13. 赛车</h2>
                                    <h3>
                                        <strong>3.13.1.</strong><span>
                                            赛车包含但不限于一级方程式 (F1)、世界摩托车大奖赛 (Moto GP) 和世界杯汽车大奖赛 (A1GP)。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.2.</strong><span>
                                            无论后来是否出现取消资格或更改结果的情况，颁奖仪式的排名即为正式结果。 如果没有颁奖仪式，赌盘结果即为比赛结束后相关管理机构所公布的结果。排位赛赌盘是根据排位赛结束后管理机构立即宣布的最终资格排名为准。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.3.</strong><span>
                                            如果原定场地变更，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.4.</strong><span>
                                            如果比赛延期，并且比赛在四十八小时期限内开赛，则所有赌注均有效，其它情况下所有赌注均作废。在比赛已开始但未结束的情况下，如果宣布了官方结果，则所有投注均有效。如果没有宣布官方结果，则所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.5.</strong><span>
                                            发出讯号开始热身圈时，即表示赛车比赛开始。 如果参赛者没有从起跑位置（或维修道）准备就绪起跑，则投注在该名赛车手上的赌金均作废。 如果参赛者没有从官方认定合格的赛程开始比赛，投注在该名赛车手上的杆位赌金均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.6. </strong><span>
                                            在“对决”赌盘中，两名赛车手必须开始比赛（或官方资格赛），投注才有效。 最终排名领先的赛车手就是赢家。如果两名赛车手都无法完成比赛，则完成圈数最多的参赛者是赢家。如果两名赛车手完成的圈数相同， 所有投注均作废，除非主办单位公布官方结果。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.7.</strong><span>
                                            “最快单圈”赌盘是投注比赛中赛取得最快单圈成绩的选手或队伍。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.8.</strong><span>
                                            “分类到达终点车手数量”赌盘是根据管理机构宣布的官方结果而定。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.9.</strong><span>
                                            “赛车实时投注”是根据指定比赛的官方结果进行结算。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.10.</strong><span>
                                            单/双盘口是根据指定比赛的官方结果进行结算。举例，如果赛车手A以第一名胜出比赛，那么成绩为单；如果赛车手B以第二名胜出比赛，那么成绩为双。如果赛车手没得到任何官方资格，那所有投注于该赛车手的注单将被视为作废和退款。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.13.11.</strong><span>
                                            胜负差数盘口 是根据指定赛车手所取得的比赛时间结果的差幅(秒数)进行结算。大于为取得时间高于盘口所显示的时间。小于为取得时间低于盘口所显示的时间。如果赛果时间和盘口所显示的时间一致，那所有注单将被视为作废和退款。
                                        </span>
                                    </h3>
                                    <h2><a name="R314" id="R314"></a>3.14. 手球</h2>
                                    <h3>
                                        <strong>3.14.1.</strong><span>
                                            所有全场赌盘（包含实时投注）是以正规时间结束时的结果进行结算。 加时赛和罚球决胜赛<strong>不计入</strong>全场赌盘的计算结果。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.14.2.</strong><span>
                                            如果比赛延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废，无条件决定投注结果的赌盘除外。 如果由相关管理机构宣布官方结果，投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.14.3.</strong><span>
                                            “手球实时投注”是以正规时间结束时的结果进行结算。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.14.4.</strong><span>
                                            手球实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h2><a name="R315" id="R315"></a>3.15. 水球</h2>
                                    <h3>
                                        <strong>3.15.1.</strong><span>
                                            所有全场赌盘（包含实时投注）是以正规时间（四小节）结束时的结果进行结算。 加时赛和决胜赛<strong>不计入</strong>全场赌盘的计算结果。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.15.2.</strong><span>
                                            如果比赛延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废，无条件决定投注结果的赌盘除外。 如果由相关管理机构宣布官方结果，投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.15.3.</strong><span>
                                            “上半场”赌盘是指第一节和第二节的比赛结果。 如果指定半场未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.15.4.</strong><span>
                                            “水球实时投注”是以正规时间结束时的结果进行结算。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.15.5.</strong><span>
                                            水球实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h2><a name="R316" id="R316"></a>3.16. 拳击</h2>
                                    <h3>
                                        <strong>3.16.1.</strong><span>
                                            如果比赛延期，但在原定开始日期的十四天内举行，投注在延期比赛中的赌金仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.16.2.</strong><span>
                                            如果比赛结果为平手，胜负盘（不提供平手的投注选项）的赌金均作废。 如果公司提供平手的赔率作为第三种投注选项，且比赛以平手收场，则平手选项的投注就是赢家，投注在两名拳击手上的赌金全输。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.16.3.</strong><span>
                                            第一回合开始的钟响后，比赛就算正式开始。 如果一名（或两名）拳击手无法开始比赛，所有赌注均作废。 如果拳击手无法对开始钟响做出任何回应，或在下回合开始前被判丧失资格，其对手就算获得上回合的胜利。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.16.4.</strong><span>
                                            所有赌注将以比赛后官方判决进行结算。 后续的结果变更不适用于投注目的。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.16.5.</strong><span>
                                            击倒 (KO) 胜利也包含技术击倒 (TKO)，或因对手丧失资格 (DSQ) 而得胜。 得分判罚包含技术判罚 (TD)，平手包含技术平手。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.16.6.</strong><span>
                                            “大于/小于”投注代表拳赛中举行的回合数，例如，“小于 9.5”代表比赛在第 9 回合的一分三十秒前结束；“大于 9.5”代表比赛在第 9 回合的一分三十秒后结束（包括得分判罚）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.16.7.</strong><span>
                                            UFC
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.16.7.1.</strong><span>
                                            UFC 规则也适用于其他综合武术 (MMA) 组织所推广的比赛。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.16.7.2.</strong><span>
                                            如果比赛延期，但在原定开始日期的十四天内举行，投注在延期比赛中的赌金仍然有效。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.16.7.3.</strong><span>
                                            如果比赛平手，所有投注均作废。
                                        </span>
                                    </h4>
                                    <h2><a name="R317" id="R317"></a>3.17. 飞镖</h2>
                                    <h3>
                                        <strong>3.17.1.</strong><span>
                                            胜负盘和赌盘是指比赛的赢家。 让分盘和“大于/小于”赌盘是根据分组的数量而定（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.17.2.</strong><span>
                                            如果比赛延期，并且未于原定开始时间的十二小时内开始，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.17.3.</strong><span>
                                            如果选手没有参加锦标赛或比赛，投在该名选手身上的赌金均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.17.4.</strong><span>
                                            如果比赛开始但未完整结束，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.17.5.</strong><span>
                                            “飞镖实时投注”是根据比赛结果进行结算。 飞镖实时投注的分数不会更新。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.17.6.</strong><span>
                                            “180” 盘口是指比赛選手的任何一方獲得最多"180分"的次數而定。
                                        </span>
                                    </h3>
                                    <h2><a name="R318" id="R25"></a>3.18. 板球</h2>
                                    <h3>
                                        <strong>3.18.1.</strong><span>
                                            在“限制投球轮”（包含 ODI 及 Twenty20）比赛中，所有投注均根据比赛规则进行，并且依官方结果进行结算。但如果结果由大逆转、投球、丢钱币等因素决定，则所有比赛投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.2.</strong><span>
                                            如果官方结果是“无结果”，或比赛规则没有宣布胜利方，则所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.3.</strong><span>
                                            如果比赛延期或暂停，并且比赛在 48 小时的期限内恢复，所有投注仍有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.4.</strong><span>
                                            测试比赛赌盘的结果将以1X2 的格式提供。1 表示首先被提及的队伍（通常是主队）；X 代表比赛结果平手；2 代表第二个被提及的队伍（通常是客队）。如果测试比赛以“平局”告终（以示与“平手”区别开来），所有比赛投注均作废。如果赛事由于外界干扰而中止，则所有投注均宣布作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.5.</strong><span>
                                            最多跑动得分盘指的是在一场比赛或具体击球局中得到大部分跑动得分的球员。 两名球员必须在击球球道至少击出一球，投注才能生效。 如果正式比赛“无结果”，则该盘的所有投注将失效（盘上无条件确定的除外）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.6.</strong><span>
                                            最高门柱盘指的是在一场比赛或具体击球局中得到大部分门柱得分的球员。两名球员必须至少击中一球，投注才能生效。如果正式比赛“无结果”，则该盘的所有投注将失效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.7.</strong><span>
                                            最高六分打盘指的是在一场比赛或具体击球局中得到最多六分打得分的球队。如果宣布了官方结果，投注有效。如果正式比赛“无结果”，则该盘的所有投注将失效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.8.</strong><span>
                                            出局赌盘表示在一场比赛或特定局中最先出局的选手。 如果一名选手退出，则投注作废。 如果没有选手出局，则所有投注均作废。 即使官方比赛结果是“无结果”，已判定结果的赌盘的所有投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.18.9.</strong><span>
                                            总跑动得分盘指在一场比赛或具体击球局中一名球员得到的总跑动得分。一名球员必须在击球球道至少击出一球，投注才能生效。如果正式比赛“无结果”，则该盘的所有投注将失效。
                                        </span>
                                    </h3>

                                    <h2><a name="R319" id="R319"></a>3.19. 15人橄榄球</h2>
                                    <h3>
                                        <strong>3.19.1.</strong><span>
                                            所有全场赌盘（包含实时投注）是以正规时间（80 分钟）结束时的结果进行结算。 加时赛不计入全场赌盘的计算结果。 7人橄榄球的赌盘是以正规赛结束时的结果来进行结算 (通常是14或20分钟) 。加时赛将不计算在全场7人橄榄球的赌盘内。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.19.2.</strong><span>
                                            如果比赛延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废，无条件决定投注结果的赌盘除外。 如果由相关管理机构宣布官方结果，投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.19.3.</strong><span>
                                            “上半场”赌盘仅限上半场的比赛结果。 如果指定半场未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.19.4.</strong><span>
                                            “15 人橄榄球实时投注”是以正规时间结束时的结果进行结算。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.19.5.</strong><span>
                                            15 人橄榄球实时投注的分数会实时更新，实时交易时显示的赌盘则代表下注时显示的分数。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.19.6.</strong><span>
                                            对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。
                                        </span>
                                    </h3>
                                    <h2><a name="R320" id="R320"></a>3.20. 橄榄球联赛</h2>
                                    <h3>
                                        <strong>3.20.1.</strong><span>
                                            所有全场赌盘（包含实时投注）的结果，是以比赛结束时的结果进行结算，<strong>包含</strong>任何进行的加时赛。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.20.2.</strong><span>
                                            如果比赛延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废，无条件决定投注结果的赌盘除外。 如果由相关管理机构宣布官方结果，投注仍然有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.20.3.</strong><span>
                                            “上半场”赌盘仅限上半场的比赛结果。 如果指定半场未完成，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.20.4.</strong><span>
                                            橄榄球联赛实时投注的结果是根据比赛结果进行结算，包括任何可能进行的加时赛。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.20.5.</strong><span>
                                            橄榄球联赛实时投注的分数会实时更新，实时交易时显示的赌盘则代表下注时显示的分数。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.20.6.</strong><span>
                                            对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。
                                        </span>
                                    </h3>
                                    <h2><a name="R321" id="R321"></a>3.21. 田径</h2>
                                    <h3>
                                        <strong>3.21.1.</strong><span>
                                            总冠军（赢得金牌）赌盘表示每项体育竞赛的胜者。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.21.2.</strong><span>
                                            胜负盘表示竞赛（或指定赛次）中名次最高的参赛者。 如果只有一名选手进入决赛，投注在该名选手上的赌注即可赢得赌金。 如果两名选手都无法进入决赛，则在资格赛时分组成绩较好的参赛者就是赢家。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.21.3.</strong><span>
                                            颁奖仪式的排名就是所有投注结算的正式结果。 任何后来取消资格或修改结果的情况均不适用于投注目的。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.21.4.</strong><span>
                                            如果赛事延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.21.5.</strong><span>
                                            如果选手无法参加比赛，所有投注均作废。 如果选手参加比赛并获得正式成绩或分组（包括丧失资格 (DSQ) 但不包括未参赛 (DNS)），则选手将被视作已参加比赛。
                                        </span>
                                    </h3>
                                    <h2><a name="R322" id="R322"></a>3.22. 游泳</h2>
                                    <h3>
                                        <strong>3.22.1.</strong><span>
                                            总冠军（赢得金牌）赌盘表示每项游泳竞赛的优胜者。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.22.2.</strong><span>
                                            胜负盘表示竞赛（或指定赛次）中名次最高的参赛者。 如果只有一名游泳选手进入决赛，投注在该名选手上的赌注即可赢得赌金。 如果两名游泳选手都无法进入决赛，则在资格赛时分组成绩较好的参赛者就是赢家。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.22.3.</strong><span>
                                            颁奖仪式的排名就是所有投注结算的正式结果。 任何后来取消资格或修改结果的情况均不适用于投注目的。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.22.4.</strong><span>
                                            如果赛事延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.22.5.</strong><span>
                                            如果游泳选手无法参加比赛，所有投注均作废。
                                        </span>
                                    </h3>
                                    <h2><a name="R323" id="R323"></a>3.23. 沙滩足球</h2>
                                    <h3><strong>3.23.1.</strong> <span>所有赛事赔率是根据常规三回合（每场各 12 分钟）的比赛结果而定，包含所有伤停时间。 除非另行规定。</span></h3>
                                    <h3><strong>3.23.2.</strong> <span>如果赛事于预定时间前开始，则只有在赛事（不包括特定实时投注）开始前投注的项目方为有效。 如果赌盘没有于正确时间关闭或中止，则公司保留避免投注者于实际开始时间（不包括特定实时投注）后投注的权利。</span></h3>
                                    <h3><strong>3.23.3.</strong> <span>加时赛和罚球决胜赛不计入全场赌盘的计算结果，除非另行规定。</span></h3>
                                    <h3><strong>3.23.4.</strong> <span>如果比赛延期、暂停或中止，并且未于原定开始时间的十二小时内恢复比赛，所有投注均作废，无条件决定投注结果的赌盘除外。 如果由相关管理机构宣布官方结果，投注仍然有效。</span></h3>
                                    <h3><strong>3.23.5.</strong> <span>公司提供场地信息（例如中立场地）服务，并不担负责任确保中立场地的正确性。 客户有责任自己确认任何比赛的正确场地信息。</span></h3>
                                    <h3><strong>3.23.6.</strong> <span>对于现场投注，在比赛过程中，公司可全权决定是否存在可能影响分数、结果、某队或某球员表现的危险操作；或者授权更改赔率/赌金与赢 款的差额，以及赌盘或投注信息（称为“危险比赛”），公司保留暂停接受投注的权利，并且可以于“危险比赛”后接受或拒绝投注。 赛事中的其他操作均视作“安全比赛”，仍可接受投注。</span></h3>
                                    <h3><strong>3.23.7.</strong> <span>对于实时投注，只要裁判宣布半场或全场比赛时间结束，公司会自动拒绝所有待定的投注。</span></h3>
                                    <h3><strong>3.23.8.</strong> <span>沙滩足球投注种类 </span></h3>
                                    <h4>
                                        <strong>3.23.8.1.</strong><span>
                                            公司所提供的投注种类:
                                        </span>
                                    </h4>
                                    <h5><strong>3.23.8.1.1.</strong> <span>非现场投注</span></h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">3.23.8.1.1.1.</strong><span>
                                                亚洲盘 -让分盘是指将假定的让分数预先加给其中一方参赛者或队伍（在赛事开始前该参赛者或队伍便以该让分数领先）并据此进行投注。 将让分数加上实际比赛结果后，得分较高的参赛者或队伍即为赢家。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">3.23.8.1.1.2.</strong><span>
                                                奇数/偶数(OE) -奇数/偶数是指投注结果是根据常规三回合（每场各 12 分钟）的比赛结果而定。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">3.23.8.1.1.3.</strong><span>
                                                大于/小于 - 是指投注结果由赛事是根据常规三回合（每场各 12 分钟）最后结果的总分（射门分数、得分情况等）来决定。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5><strong>3.23.8.1.2.</strong> <span> 现场投注</span></h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">3.23.8.1.2.1.</strong><span>
                                                亚洲盘 -让分盘是指将假定的让分数预先加给其中一方参赛者或队伍（在赛事开始前该参赛者或队伍便以该让分数领先）并据此进行投注。 将让分数加上实际比赛结果后，得分较高的参赛者或队伍即为赢家。
                                            </span>
                                        </p>
                                    </h5>
                                    <h5>
                                        <p class="h7">
                                            <strong style="color:#333333">3.23.8.1.2.2.</strong><span>
                                                大于/小于 - 是指投注结果由赛事是根据常规三回合（每场各 12 分钟）最后结果的总分（射门分数、得分情况等）来决定。
                                            </span>
                                        </p>
                                    </h5>
                                    <h4>
                                        <strong>3.23.8.2.</strong><span>
                                            有关更多投注种类详情，请参阅“2.2. 足球投注类型”。
                                        </span>
                                    </h4>
                                    <h2><a name="R324" id="A2"></a>3.24.   壁球</h2>
                                    <h3><strong>3.24.1.</strong> <span>胜负盘是指比赛或指定分组的赢家。 让分盘是根据分组或得分而决定（请参阅赌盘名称）；“大于/小于”以及“奇数/偶数”是根据得分而决定（除非另有说明）。 </span></h3>
                                    <h3><strong>3.24.2.</strong> <span>如果选手没有参加锦标赛或比赛，投在该名选手身上的赌金均作废。</span></h3>
                                    <h3><strong>3.24.3.</strong> <span>如果选手（或双人搭档）在比赛时退出或丧失资格，所有投注均作废，无条件决定投注结果的赌盘除外。 </span></h3>
                                    <h3><strong>3.24.4.</strong> <span>如果比赛延期或暂停，并且比赛在十二小时的期限前恢复，所有投注仍有效。</span></h3>
                                    <h3><strong>3.24.5.</strong> <span>第一赛赢家（第二、第三赛等）表示指定局的赌盘结果。 如果指定比赛未完成，所有投注均作废。</span></h3>
                                    <h3><strong>3.24.6.</strong> <span>壁球实时投注的结果是根据比赛（或指定比赛）结果进行结算。 壁球实时投注的分数不会更新。</span></h3>
                                    <h2><a name="R325" id="A1"></a>3.25.  赛马特别投注</h2>
                                    <h3>
                                        <strong>3.25.1.</strong><span>
                                            将为墨尔本杯等大型赛事提供赛马特别投注。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.25.2.</strong><span>
                                            总冠军
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.25.2.1.</strong><span>
                                            “总冠军”是指投注于赛马比赛的赢家。赛马场宣布“全部清除”、“已过秤”或“正确过秤”，并宣布“正式结果”时，代表比赛已经有正式结果。任何后来取消资格或修改结果的情况均不适用于投注目的。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.25.2.2.</strong><span>
                                            “总冠军排名”是指投注于在比赛中获得指定名次的马匹。赢得排名投注的数量将在赌盘名称中公布。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.25.2.3.</strong><span>
                                            马匹除名/未出赛 –只要马匹在等待发令员鸣枪起跑之前或之后退出比赛（或一个以上的起跑门未开启，从而导致起跑不公平），马匹则被视为未出赛/除名，投注在该匹马上的赌金将全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.25.2.4.</strong><span>
                                            如果任何“总冠军赌盘”中宣布的结果为“不分胜负”，则一半赌金将分配给完全赔率，而另一半赌金将全部丧失。如果超过两匹赛马“不分胜负”，则按比例分配赌金。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.25.3.</strong><span>
                                            对决
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.25.3.1.</strong><span>
                                            “对决”是指投注于某匹参赛马匹在比赛中的名次排在另一匹参赛马匹前面。如果其中一匹（或两匹）参赛马匹被除名/未出赛，则所有投注均作废并全数退回。如果两匹参赛马匹的成绩不分胜负，则所有投注均作废。
                                        </span>
                                    </h4>

                                    <h2><a name="R326" id="R326"></a>3.26.冬季奥林匹克运动会规则与规定</h2>
                                    <h3>
                                        <strong>3.26.1.</strong><span>
                                            一般规定
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.26.1.1.</strong><span>
                                            所有赌盘将按照 IOC 宣布的官方结果进行结算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.1.2.</strong><span>
                                            对于所有投注，结算均采用最初的颁奖仪式成绩作为官方结果。 任何后来取消资格或修改结果的情况均不适用于投注目的。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.1.3.</strong><span>
                                            如果比赛或赛事是在奥林匹克运动会正式举行期间结束，无论其实际开始时间为何时，所有投注均有效。 如果比赛或赛事未在有效时段内结束，且没有得出官方结果，则所有投注均作废并全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.1.4.</strong><span>
                                            如果公司在赛事实际开始之后错误接受了投注（实时投注除外），则该投注将被视为作废并全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.1.5.</strong><span>
                                            如果有队伍或参赛者没有参赛，则对应项目【包括总冠军（赢得金牌）赌盘】上的所有投注将被视为作废并全数退回。 如果胜负盘（对决）中涉及的队伍或参赛者没有参赛，则该赌盘上的所有投注将被视为作废并全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.1.6.</strong><span>
                                            如果在总冠军（赢得金牌）赌盘中出现“不分胜负”的情况，则一半赌金将分配给完全赔率，而另一半赌金将全部丧失。 如果在胜负盘（对决）中出现“不分胜负”的情况，结果将被视为平局，压在双方参赛者身上的投注均会全数退回。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.26.2.</strong><span>
                                            奥运会奖牌
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.26.2.1.</strong><span>
                                            赌盘将以奥林匹克运动会举行期间个人或国家所赢得的奖牌数作为投注对象进行提供。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.2.2.</strong><span>
                                            这些赌盘既可以针对金牌数量，也可以针对奖牌总数（包括金、银、铜牌）。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.2.3.</strong><span>
                                            所有赌盘将按照 IOC 在奥林匹克运动会结束后随即发布的官方奖牌榜结果进行结算。 任何对奖牌榜的后续变更均不适用于投注目的。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.26.3.</strong><span>
                                            胜负盘（对决）
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.26.3.1.</strong><span>
                                            哪一位参赛者或哪一支队伍将赢得比赛或在赛事中取得更高的名次？
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>3.26.3.1.1.</strong><span>
                                            最终的名次取决于在赛事中晋级最高的参赛者。 如果双方参赛者在同一赛段被淘汰出局，则官方排名较高的参赛者将被宣布为赢家。 如果双方参赛者在同一赛段被淘汰出局，但均未取得官方排名，则投注作废。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>3.26.4.</strong><span>
                                            冰球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.26.4.1.</strong><span>
                                            奥运会冰球比赛投注将按照正常冰球比赛规则 (3.5.) 的规定进行。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.4.2.</strong><span>
                                            但 3.5.3 规则将由以下规则取代 - 如果比赛是在奥林匹克运动会正式举行期间结束，无论其实际开始时间为何时，所有投注均有效。 如果由 IOC 宣布官方结果，投注仍然有效。 如果比赛未在有效时段内结束，且没有得出官方结果，则所有投注均被视为作废并全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.26.4.3.</strong><span>
                                            公司可能会提供附加赌盘（例如，包括加时赛的结果或点球大战的获胜方），赌盘名称中会明确注明这类赌盘的条款。
                                        </span>
                                    </h4>
                                    <p class="h7">
                                        <strong style="color:#333333">*</strong><span>
                                            请注意：分组预选赛期间不会进行加时赛，因此可能会出现平局。
                                        </span>
                                    </p>

                                    <!--3.28
                                    <h2><a name="R328" id="R328"></a>3.28.金融投注规则</h2>
                                    <h3><strong>3.28.1.</strong><span>
                                    投注标价</span></h3>
                                    <h4><strong>3.28.1.1.</strong><span>
                                    您同意投注价格由公司计算，并按 www.maxbet.com上公布的价格提供投注价格。在决定投注价格时，公司应出于对市场波动、资产价格、无风险利率、运输成本、协议价格以及其它因素的考虑而采用一种包括各种假设的方式。
                                    </span></h4>
                                    <h4><strong>3.28.1.2.</strong><span>
                                    如果已公布对股票进行彩金付款，那么公司有权根据该股票的所有公开投注按一定数额上调或下调协议价格，但是此数额不能超过彩金的数额。 </span></h4>
                                    <h4><strong>3.28.1.3.</strong><span>
                                    如果出现价格错误，公司不承担任何责任。除非另外指明，在网站上刊登广告的所有标价都是最终标价。</span></h4>
                                    <h3><strong>3.28.2.</strong><span>
                                    投注有效期限</span></h3>
                                    <h4><strong>3.28.2.1.</strong><span>
                                    使用我们的投注设备，表示您接受根据系统中的到期投注制定的时间表由我们全权管理，并会不定期变更。</span></h4>
                                    <h4><strong>3.28.2.2.</strong><span>
                                    如果当天投注的有效期限早于我们赌盘的最后时间，那么此投注将在投注界面上已选定的分钟数后过期。例如，假如我们的交易日还剩余 15 分钟，那么一个有效期为 15 分钟的投注将在 15 分钟后过期。此规则同样适用于所有当天的 60 分钟投注。
                                    </span></h4>
                                    <h4><strong>3.28.2.3.</strong><span>
                                    如果我们的交易日剩余时间不够，投注的有效期限在赌盘关闭后到期，那么该投注将作废。 </span></h4>
                                    <h4><strong>3.28.2.4.</strong><span>
                                    有效期限将从我们的服务器接受或记录投注的那一刻算起。 显示在我们网站上的有效期限仅供参考。 </span></h4>
                                    <h4><strong>3.28.2.5.</strong><span>
                                    任何交易时段的最初 5 分钟和最后 5 分钟都不计入任何投注。例如，对于一个将在 16:30 关闭的赌盘，16:25 后的数据将不计入任何投注。赌盘开启和关闭的时间表将在我们的网站上公布。 </span></h4>
                                    <h3><strong>3.28.3.</strong><span>
                                    付款与安全</span></h3>
                                    <h4><strong>3.28.3.1.</strong><span>
                                    结算条款与条件应与公司制定的《一般条款与条件》一致。 </span></h4>
                                    <h4><strong>3.28.3.2  </strong><span>
                                    结算投注后，您的信用额度将根据金融投注的盈亏相应增加或减少。</span></h4>
                                    <h3><strong>3.28.4.</strong><span>
                                    投注結算</span></h3>
                                    <h4><strong>3.28.4.1  </strong><span>
                                    交易日中投注有效期限为 5 分钟、15 分钟、30 分钟和 60 分钟的投|注将在规定到期时间后 60 秒内被结算。 </span></h4>
                                    <h4><strong>3.28.4.2  </strong><span>
                                    所提供指数的投注结算是以指数的官方结算值为基础，而官方结算值是根据赌盘关闭后特定指数的官方值计算得出。官方结算值通常在交
                                    易日结束后大约 5 分钟内公布。因此，于 16:30 结束的赌盘的官方结算值可能与 16:40 时特定指数的值不同。
                                    </span></h4>
                                    <h4><strong>3.28.4.3  </strong><span>
                                    在我们网站上公布的指数值仅供参考，公司对有关值和结算值信息的因故延期概不承担任何责任。结算值被定义为交易日结束时交易的平
                                    均加权标价，也称作“结算价格”。 </span></h4>
                                    <h3><strong>3.28.5.</strong><span>
                                    金融投注的条款与条件</span></h3>
                                    <h4><strong>3.28.5.1.</strong><span>
                                    如果因技术错误而按反映了对我们定价模式的错误输入的标价下注/接受投注，您要承认此次交易将作废。</span></h4>
                                    <h4><strong>3.28.5.2.</strong><span>
                                    公司将确定（如在我们的网站上公布）接受金融投注的营业时间；这可能不包括每个赌盘开启和关闭的时间段，这段时间通常约为 30 分
                                    钟，有些赌盘会暂停一会留有午餐时间。 赌盘交易时间的最初和最后 5 分钟将不计入下注。 </span></h4>
                                    <h4><strong>3.28.5.3.</strong><span>
                                    超过我们设定接受投注的个别赌盘营业时间的下注将被拒绝或作废。</span></h4>
                                    <h4><strong>3.28.5.4.</strong><span>
                                    无论赌盘因何原因意外关闭，所有投注均将作废而赌金将被退还。 </span></h4>
                                    <h4><strong>3.28.5.5.</strong><span>
                                    最低赌注设定为会员帐户所用货币的 1.00 单位。</span></h4>
                                    <h4><strong>3.28.5.6.</strong><span>
                                    金融投注的最高赌金将取决于已提供的赔率投注类型和指数。帐户持有人将被告知提供的特定赔率的最高赌金。 </span></h4>
                                    <h4><strong>3.28.5.7.</strong><span>
                                    如果您能够准确预测产品结算价格相对于您下注时的市场价格是升还是降（“大小盘”投注类型），或者能够准确预测您下注时目前指数的最后一位数是单还是双（“单双盘”投注类型），那么您将赢得投注。如果目前指数是以小数表示，则所有小数部分将被忽略，也不进行四舍五入。出于大小盘的投注结算目的，将使用所投注的赌盘在关闭时间所更新的指数。如果指数在关闭时间那一秒更新，就以当时的指数结算。如果指数更新在n秒后出现，将于n秒后结算。出于单双盘的投注结算目的，将使用小数点前最后一个数字来确定是单还是双。如果产品的结算价格与您投注时的完全一样，就出现“平局”。赌金将被退还，未记入盈亏。</span></h4>
                                    <h4><strong>3.28.5.8.</strong><span>
                                    公司将采取一些适当措施，以确保所提供的信息准确无误。我们对网站上所公布信息的任何可能的轻微延迟概不承担任何责任。如果我们从第三方供应商收到不一致的数据条款，为了防止提供给我们的条件或赌盘条款中存在错误，无论错误是人为造成或因技术原因造成的，我们均保留作废投注或以我们确定的正确标价重新结算投注的权利。 </span></h4>
                                    <h4><strong>3.28.5.9.</strong><span>
                                    如果出现疑似操纵、内部人员交易以及暗中赌盘下注，我们将保留关闭您的帐户的权利。</span></h4>
                                    <div id="1-2"align="right"><a href="#top" class="button"><span>最顶</span></a></div>
                                    -->
                                    <!-- BEGIN Betting Rules -->
                                    <!--3.29 new-->
                                    <h2><a name="R327" id="A5"></a>3.27. 篮网球</h2>
                                    <h3>
                                        <strong>3.27.1.</strong><span>
                                            所有全场赌盘（包含实时投注）的结果均以最终比赛结果进行结算，包含延时（除非另有说明）。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.27.2.</strong><span>
                                            如果比赛未于原定开始时间开赛，所有投注均作废（除非另有说明）。 如果比赛暂停或中止，所有投注均作废，无条件决定投注结果的赌盘除外。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.27.3.</strong><span>
                                            上半场结果为第一节和第二节的分数总和。 下半场结果为第三节和第四节的分数总和，包含任何比赛延时。第四节的结果不包含任何延时。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.27.4.</strong><span>
                                            如果比赛暂停或中止，所有未完成半场或小节的投注均作废。 如果指定的半场或小节已完赛，则投注有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.27.5.</strong><span>
                                            篮网球即时投注的分数不会更新，即时交易时显示的让分表示比赛开始时的分数，即 0-0。 显示的时间和分数仅供参考。
                                        </span>
                                    </h3>
                                    <h2><a name="R328" id="A6"></a>3.28.百练赛游戏规则</h2>
                                    <h3>
                                        <strong>3.28.1.</strong><span>
                                            百练赛
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.1.1.</strong><span>
                                            此游戏是应用宾果机进行。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.1.2.</strong><span>
                                            本公司将提供关于实时赔率。您可以投 注在多种类型赔率，如下所述。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.28.2.</strong><span>
                                            游戏类型
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.2.1.</strong><span>
                                            一般来说，数字游戏的投注可分为两种类型
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>(A)</strong><span>
                                            游戏开始前 - 玩家可以在游戏开始前投注，一旦游戏开始所有投注将不允许。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong>(B)</strong><span>
                                            走地游戏 -   玩家可以在游戏开始后，投注予每一个不同的赔率球。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>3.28.3.</strong><span>
                                            游戏开始前
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.3.1.</strong><span>
                                            以下为游戏开始前的玩法：
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>(A)</strong><span>
                                            第一球或最后一球大小。 （第一或最后抽到的球）
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>(B)</strong><span>
                                            第一球或最后一球单双。 （第一或最后抽到的球）
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>(C)</strong><span>
                                            全场单双（把所有抽到的三粒球的号码加上）
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong></strong><span>
                                            最后一球被定义为最后一粒百练赛游戏抽取的球，如果因为任何故障的情况下而令宾果机抽取不到最后一粒球，所有在'最后一粒球单双'的赌注将被退还。
                                        </span>
                                    </h5>
                                    <h5>
                                        <strong></strong><span>
                                            所有的奖金派发是根据完整完成的游戏。完整完成的游戏是指，最后一粒百练赛游戏抽取的球是在没有遇到任何的错误情况下。如果此游戏没有完整完成，所有投注均作废。公司保留更正任何明显错误的权利，并会采取所有合理的措施来确保以诚信透明的方式管理赌盘。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>3.28.3.2.</strong><span>
                                            第一球或最后一球大小 （第一或最后抽到的球）
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            操作员会开出第一球或最后一球大小的盘口。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            小：当球点数介于1至37.
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            大: 当球点数介于38至75.
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong></strong><span>
                                            例如：最后被抽到的是30号球，那么投注最后一球“小” 者中奖。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>3.28.3.3.</strong><span>
                                            第一球或最后一球单双 （第一或最后抽到的球）
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            操作员会开出第一球或最后一球单双的盘口。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            单- 单数的球为“单”。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            双- 双数的球为“双”。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong></strong><span>
                                            例如：最先被抽到的是30号球，那么投注最先一球“双” 者中奖。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>3.28.3.4.</strong><span>
                                            全场单双（把所有抽到的三粒球的号码加上）
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            游戏前操作员会开出全场单双盘口。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            全场单-开出三粒球的号码总和为奇数，则为总单。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong></strong><span>
                                            全场双-开出三粒球的号码总和为偶数，则为总双。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong></strong><span>
                                            例如：当任所有抽到的三粒球的号码（07，13 和20)，以上全部号码总计为40。40是个双数，那么全场“双”获胜。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>3.28.4.</strong><span>
                                            走地游戏
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.4.1.</strong><span>
                                            有六 (6) 种投注类型适用于运行投注类型：
                                        </span>
                                    </h4>
                                    <h5>
                                        <span>
                                            A. 单/双走地游戏<br />
                                            B. 大/小走地游戏<br />
                                            C. 高/低走地游戏<br />
                                            D. 斗士，比对第二颗 或第三颗球 <br />
                                            E. 下一个组合<br />
                                            F. 数盘
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>3.28.4.2.</strong><span>
                                            单/双走地游戏
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>3.28.4.2.1.</strong><span>
                                            每抽出一粒球后，操作员将提供新的盘口。玩家必须预测下一粒球是单或双。<br />
                                            单-单数的球为“单”。 <br />
                                            双-双数的球为“双”。 <br />
                                            单/双走地游戏结束于如下:<br />
                                            1. 本公司自行决定何时不提供盘口。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>3.28.4.3.</strong><span>
                                            大/小走地游戏
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>3.28.4.3.1.</strong><span>
                                            每抽出一粒球后, 操作员将提供新的盘口。玩家必须预测下一粒球是大或小。<br />
                                            小：当宾果球点数介于1到37<br />
                                            大: 当宾果球点数介于38到75 <br />
                                            大/小走地游戏结束于如下:<br />
                                            1. 本公司自行决定何时不提供盘口。
                                        </span>
                                    </h5>
                                    <h4>
                                        <strong>3.28.4.4.</strong><span>
                                            高/低走地游戏
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>3.28.4.4.1.</strong><span>
                                            每抽出一粒球后, 操作员将提供新的盘口。玩家必须预测下一粒球是高或低。<br />
                                            例如: 如果你下注“高“而下一粒球的点数比前一粒球的点数高。你将会赢的赌注。<br />
                                            高/低走地游戏結束於如下:<br />
                                            1. 本公司自行决定何时不提供盘口。
                                        </span>
                                    </h5>
                                    <h3>
                                        <strong>3.28.5.</strong><span>
                                            斗士，比对第二颗 或第三颗球
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.5.1.</strong><span>
                                            此游戏必须抽出三颗球。游戏将在抽出第一颗球后开始第二颗与第三颗球的号码对比，号码最大的那颗将获胜。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>3.28.5.1.1.</strong><span>
                                            1号将是最小而75号是最大。
                                        </span><br />
                                    </h5>
                                    <h5>
                                        <strong>3.28.5.1.2.</strong><span>
                                            公司将在第一颗球抽出之前提供此类盘口。玩家可以在第一颗球抽出之前随意下注。
                                        </span><br />
                                    </h5>
                                    <h5><span>例子：假如第二颗抽到的球是45号而第三颗抽到的是60号，那么下注第三颗球的玩家获胜。</span></h5>
                                    <h3>
                                        <strong>3.28.6.</strong><span>
                                            下一球组合
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.6.1.</strong><span>
                                            在每一颗球抽出后，公司将会提供下一球组合的盘口有 大/单，小/单，大/双，小/双。玩家可以在游戏开始前随意下注。本公司将无条件的，任意的在某些号码球抽出后结束游戏。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>例子1：</strong><span>
                                            抽到的球是30号球，那么“下一组合小/双”获胜。
                                        </span><br />
                                    </h5>
                                    <h5>
                                        <strong>例子2：</strong><span>
                                            抽到的球是47号球，那么“下一组合大/单”获胜。
                                        </span><br />
                                    </h5>
                                    <h3>
                                        <strong>3.28.7.</strong><span>
                                            数 盘
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.7.1.</strong><span>
                                            游戏玩法：可选一个号码或一组号码下注。公司将会在抽出第一个球之前提供赔率。玩家可在比赛开始后随时下注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.7.2.</strong><span>
                                            玩家也可选多个号码或多组号码下注。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.7.3.</strong><span>
                                            玩家可看到每个球号旁单个号码的赔率。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.7.4.</strong><span>
                                            玩家可以投注多个数盘 ：
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>3.28.7.4.1.</strong><span>
                                            五 (5) 个号码的数盘投注类型
                                        </span>
                                    </h5>
                                    <h5><span>为投注 5 个号码的数盘提供的赔率以水平方向成行排列。5 个号码数盘 显示如下：</span> </h5>
                                    <h5><span>数盘1-1（号码 1-5）、数盘 1-2（号码 6-10)）、数盘 1-3（号码 11-15）、数盘  1-4（号码 16-20）、数盘  1-5（号码 21-25）、数盘 1-6（号码 26-30）、数盘1-7（号码 31-35）、数盘  1-8（号码 36-40）、数盘  1-9（号码 41-45）、数盘  1-10（号码 46-50)、数盘  1-11（号码 51-55）、数盘 1-12（号码 56-60）、数盘 1-13（号码 61-65）、数盘 1-14（号码 66-70）以及数盘  1-15（号码 71-75）。</span> </h5>
                                    <h5>
                                        <strong>3.28.7.4.2.</strong><span>
                                            十五 (15) 个号码的数盘投注类型
                                        </span>
                                    </h5>
                                    <h5><span>为投注 15 个号码的数盘提供的赔率成行或成列排列。</span> </h5>
                                    <h5>
                                        <strong>3.28.7.4.2.1</strong><span>
                                            15 个号码的数盘投注类型成行排列，显示如下：
                                        </span>
                                    </h5>
                                    <h5><span>数盘2-1（号码 1-15）、  数盘2-2（号码 16-30）、  数盘 2-3（号码 31-45）、数盘2-4（号码 46-60）以及 数盘 2-5（号码 61-75）。</span> </h5>
                                    <h5>
                                        <strong>3.28.7.4.2.2</strong><span>
                                            另外，15 个号码的数盘投注类型成列排列，显示如下：
                                        </span>
                                    </h5>
                                    <h5><span>数盘 4-1（号码 1、6、11、16、21、26、31、36、41、46、51、56、61、66、71）、数盘4-2（号码 2、7、12、17、22、27、32、37、42、47、52、57、62、67、72）、数盘 4-3（号码 3、8、13、18、23、28、33、38、43、48、53、58、63、68、73）、数盘 4-4（号码 4、9、14、19、24、29、34、39、44、49、54、59、64、69、74）以及数盘 4-5（号码 5、10、15、20、25、30、35、40、45、50、55、60、65、70、75）。</span> </h5>
                                    <h5>
                                        <strong>3.28.7.4.3.</strong><span>
                                            二十五 (25) 个号码的数盘投注类型
                                        </span>
                                    </h5>
                                    <h5><span>为投注 25 个号码的数盘提供的赔率。25 个号码的数盘显示如下</span> </h5>
                                    <h5><span>数盘 3-1（号码 1-25）、数盘 3-2（号码 26-50）、以及数盘3-3（号码 51-75）。</span> </h5>
                                    <h3>
                                        <strong>3.28.8.</strong><span>
                                            其他规则
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.8.1.</strong><span>
                                            球是通过宾果机的自动扫描 仪读出。 公司采取一切合理的步骤和注意事项来确保扫描仪的精度度。在这些步骤和注意事项，您接受的是，自动化扫描仪产生立竿见影的效果，是非常快节奏的游戏。因此，在远程视频和自动扫描所显示的读取球号码球数差异情况下，公司保留权 利，依靠自动扫描仪作为最终结果。您接受本公司的决定是最终决定和约束 。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.8.2.</strong><span>
                                            所有走地游戏, 当每颗球抽取并确定结果后将会结帐。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.8.3.</strong><span>
                                            本公司有 绝对的权利，更改，取消，暂停，删除，修改或重新启动任何游戏，或拒绝或取消任何投注由偶然事件的原因，战争行为，自然灾害，停电，人为错误或故障或公司员工的工作疏忽，软件故障，以及任何其他类似事件。本公司的决定是最终决定并具有约束力。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.28.9.</strong><span>
                                            投注计时器
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.9.1.</strong><span>
                                            投注计时器显示了玩家可在某段时间内进行投注的时间范围。投注计时器将在当前赛事中以图表形式显示，并且投注计时器的时长将作为公司作出决定的唯一标准。在投注计时器计时结束之前进行的任何投注均可接受且视作有效。玩家不可在超过投注计时器期限后进行投注。超过投注时长期限后进行的投注将视作无效或作废。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.9.2.</strong><span>
                                            玩家确认虽然公司始终尽力确保投注计时器的精确度时，但投注计时器仍可能出现中断、连接滞后以及其它技术问题。使用投注计时器进行的任何投注均由玩家自行承担风险，并且公司拥有接受或拒绝此类投注的最终决定权。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.28.10.</strong><span>
                                            记分卡功能
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.28.10.1.</strong><span>
                                            百练赛 游戏记分卡是现场直播中的一个可用功能，由记分卡按钮指定。 在现场直播中，百练赛游戏记分卡亦可替代游戏结果的功能。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.28.10.2.</strong><span>
                                            此百练赛游戏记分卡将显示从机器中抽出的每个球的结果，以及在记分卡中每个投注类型的结果。 对于最后一球O/U、O/E 和 warrior 或 FT O/E 的结果，将会在第三个球抽出后显示。在鼠标悬停情况下，“U” 或“O” 的每个结果皆以号码作为显示的结果。显示被抽出的球号码和游戏编号，並以逗号隔开。
                                        </span>
                                    </h4>
                                    <h5>
                                        <strong>示例：</strong><span>
                                            “44,01234” ， “44” 代表被抽出号码，“01234”代表游戏编号。
                                        </span>
                                    </h5>
                                    <h4><span>备注： 此功能只供玩家作为参考，帮助玩家根据上一颗球抽出的结果来推测下一颗球有可能抽出的结果。 </span> </h4>


                                    <!--
                                    <h2><a name="R329" id="R329"></a>3.29. 宾果游戏规则</h2>
                                    <h3><strong>3.29.1.</strong><span>
                                    宾果卡简介</span></h3>
                                    <h4><strong>3.29.1.1.</strong><span>
                                    传统宾果游戏是以宾果卡的方式进行，每一张宾果卡含有 24 个数目字，从 1-75 不定。</span></h4>
                                    <h4><strong>3.29.1.2.</strong><span>
                                    宾果卡是 5X5 的格式，方格的最上面印着 B、I、N、G、O 的英文字，每个英文字母的下面印着五个数字从 1 到 75。"B" 列里含有数字从 1 到 15 ，"I" 列里含有数字从 16 到 30 ，"N" 列里含有数字从 31 到 45，"G" 列里含有数字从 46 到 60，"O" 列里含有数字从 61 到 75。 </span></h4>
                                    <h4><strong>3.29.1.3.</strong><span>
                                    在特殊的 N 列里的中间列有一个空格这个空格是属于免费格，可以以四个数字及一个空格组成一 B、I、N、G、O 直线。</span></h4>
                                    <h4><strong>3.29.1.4.</strong><span>
                                    每一轮的游戏将有 100 张宾果卡。</span></h4>
                                    <h3><strong>3.29.2.</strong><span>
                                    玩法</span></h3>
                                    <h4><strong>3.29.2.1.</strong><span>
                                    宾果机会随机自动抽取机里的从 1 到 75 号码球。当抽出的号码球被宣布后，球将被置放在另一边直到游戏结束以确保同样的号码球不会再次被抽取。</span></h4>
                                    <h4><strong>3.29.2.2.</strong><span>
                                    获胜条件是宾果卡上被涂抹的位置形成一定的图案。</span></h4>
                                    <h4><strong>3.29.2.3.</strong><span>
                                    第一位成功正确地完成一个卡片上赢得特定图案者将获胜。</span></h4>
                                    <h3><strong>3.29.3.</strong><span>
                                    关于获胜模式</span></h3>
                                    <h4><strong>3.29.3.1.</strong><span>
                                    线上七十五球宾果游戏有许多不同的获胜模式。最基本的有：横线、直线、全盖以及对角线。 </span></h4>
                                    <h4><strong>3.29.3.2.</strong><span>
                                    每一局游戏开始时，宾果游戏板上将公布该局必须连成的获胜模式。</span></h4>
                                    <h3><strong>3.29.4.</strong><span>
                                    倍率</span></h3>
                                    <h4><strong>3.29.4.1.</strong><span>
                                    胜出的宾果卡的奖金将以赌额乘以 90 倍。如有超过一张以上宾果卡胜出，那奖金会基于 90 倍平分于胜出的宾果卡的总数。例如：</span></h4>
                                    <h5><strong>*</strong><span>
                                    玩家 A 持有宾果卡一号，玩家 B 持有宾果卡五号各在一局游戏里同时获得 "Bingo"，那么九十将被依照以下方式计算：90/2 = 45</span></h5>
                                    <h5><strong>*</strong><span>
                                    玩家 A 以 $10 购买宾果卡一号，获胜奖金等于 $10 x 45 = $450</span></h5>
                                    <h5><strong>*</strong><span>
                                    玩家 B 以 $5 购买宾果卡五号，获胜奖金等于 $5 x 45 = $225</span></h5>
                                    <h4><strong>3.29.4.2.</strong><span>
                                    奖金派发是根据完整完成的游戏。完整完成的游戏是指任何一张宾果卡获得 "BINGO" 是在没有遇到任何的错误情况下。如果此游戏没有完整完成，所有投注均作废。</span></h4>
                                    <h4><a name="R334.43" id="R334.43"></a><strong>3.29.4.3.</strong><span>
                                    宾果特别奖金</span></h4>
                                    <h5><strong>3.29.4.3.1.</strong><span>
                                    玩家有机会赢取$ 50,000美元宾果特别奖金。 </span></h5>
                                    <h5><strong>3.29.4.3.2.</strong><span>
                                    玩家的宾果卡, 必须在40 颗球以下 获的"BINGO"</span></h5>
                                    <h5><strong>3.29.4.3.3.</strong><span>
                                    宾果特别奖金仅适用于"COVERALL"模式宾果游戏。</span></h5>
                                    <h5><strong>3.29.4.3.4.</strong><span>
                                    如果有一个以上的获奖卡，特别奖金将平分</span></h5>
                                    <h3><strong>3.29.5.</strong><span>
                                    宾果机和自动扫描仪</span></h3>
                                    <h4><strong>3.29.5.1.</strong><span>
                                    球是通过宾果机的自动扫描仪读出。公司采取一切合理的步骤和注意事项来确保扫描仪的精度。在这些步骤和注意事项，您接受的是自动化扫描仪产生立竿见影的效果，是非常快节奏的游戏。因此，在远程视频和自动扫描所显示的读取球号码球数差异情况下公司保留权利，依靠自动扫描仪作为最终结果。您接受本公司的决定是最终决定和约束。 </span></h4>
                                    <h4><strong>3.29.5.2.</strong><span>
                                    在营业日结束时本公司的游戏系统，将会自动每日结账。</span></h4>
                                    <h4><strong>3.29.5.3.</strong><span>
                                    如您超出了活跃时限，本公司的游戏系统将自动把你登出宾果游戏。</span></h4>
                                    <h4><strong>3.29.5.4.</strong><span>
                                    本公司有绝对的权利、更改、取消、暂停、删除、修改或重新启动任何游戏，或拒绝或取消任何投注由偶然事件的原因、战争行为、自然灾害、停电、人为错误或故障或公司员工的工作疏忽、软件故障、以及任何其他类似事件。本公司的决定是最终决定并具有约束力。</span></h4>
                                    <div id="Div3"align="right"><a href="#top" class="button"><span>最顶</span></a></div>   -->


                                    <h2><a name="R329" id="R329"></a>3.29. 奥运会比赛</h2>
                                    <h3>
                                        <strong>3.29.1.</strong><span>
                                            一般规定
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.29.1.1.</strong><span>
                                            所有盘口将按照IOC 宣布的官方结果进行结算。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.1.2.</strong><span>
                                            原定颁奖仪式的官方结果将是结算所有投注的依据。后续任何取消资格或修改成绩之事宜，不适用于投注目的。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.1.3.</strong><span>
                                            只要赛事在官方奥运会比赛期内完成，则无论任何实际的开赛时间，所有投注皆为有效。若比赛或赛事未完成，或没有公布官方结果，则所有投注皆作废，并归还赌金。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.1.4.</strong><span>
                                            赛事实际開赛后所不慎接受的投注（现场投注除外）将作废并全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.1.5.</strong><span>
                                            如果有队伍或参赛者没有参赛，则对应项目【包括优胜冠军（赢得金牌）盘口】上的所有投注将被视为作废并全数退回。如果独赢盘（对决）中涉及的队伍或参赛者没有参赛，则该盘口上的所有投注将被视为作废并全数退回。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.1.6.</strong><span>
                                            如果在优胜冠军（赢得金牌）盘口中出现"不分胜负"的情况，则一半投注金额将分配给完全赔率，而另一半投注金额将全部丧失。如果在独赢盘（对决）中出现"不分胜负"的情况，结果将被视为平局，并归还投注在双方参赛者身上的金额。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.29.2.</strong><span>
                                            奥运奖牌
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.29.2.1.</strong><span>
                                            盘口将提供2012奥林匹克运动会举行期间的个人或国家所赢得的奖牌数以供参考。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.2.2.</strong><span>
                                            这些盘口既可以针对金牌数量，也可以针对奖牌总数（包括金、银、铜牌)。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.2.3.</strong><span>
                                            所有盘口将按照IOC 在奥林匹克运动会结束后随即发布的官方奖牌榜结果进行结算。任何对奖牌榜的后续成绩变更均不适用于投注目的。
                                        </span>
                                    </h4>
                                    <h3>
                                        <strong>3.29.3.</strong><span>
                                            独赢盘（对决）
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.29.3.1.</strong><span>
                                            哪个参赛者或队伍将赢得比赛，或在赛事中获得较高的排名？
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.29.3.2.</strong><span>
                                            最终排名是依赛事中最高排行的参赛者而判定。若参赛者双方在相同的阶段遭到淘汰，则将由官方排名较高的参赛者获胜。若参赛者双方在相同的阶段遭到淘汰，但并未公布参赛者的官方排名，则投注将作废。
                                        </span>
                                    </h4>
                                    <h2><a name="R330" id="R330"></a>3.30. 政治 </h2>
                                    <h3>
                                        <strong>3.30.1.</strong><span>
                                            美国总统大选
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.30.1.1.</strong><span>
                                            哪一个政党将会赢得2012 美国总统大选？参选人之名称只供参考。 如果共和党和民主党没能获胜，所有投注将被视为无效及投注金额将被退还。
                                        </span>
                                    </h4>
                                    <h2><a name="R331" id="R331"></a>3.31. 帆船赛</h2>
                                    <h3>
                                        <strong>3.31.1.</strong><span>
                                            美洲杯 (包括路易威登杯)
                                        </span>
                                    </h3>
                                    <h4>
                                        <strong>3.31.1.1.</strong><span>
                                            所有交易都将根据每场比赛后官方网站宣布的正式结果进行结算。不会因投注的原因而随后对结果进行任何修改或取消资格。
                                        </span>
                                    </h4>
                                    <h4>
                                        <strong>3.31.1.2.</strong><span>
                                            如果某场比赛推迟或取消，但如果在48 小时内恢复了比赛，则所有投注均视为有效。
                                        </span>
                                    </h4>
                                    <h2><a name="R332" id="A332"></a>3.32. 自行车赛</h2>
                                    <h3>
                                        <strong>3.32.1.</strong><span>
                                            所有交易都将根据每场比赛后官方网站宣布的正式结果进行结算。不会因投注的原因而随后对结果进行任何修改或取消资格。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.32.2.</strong><span>
                                            如果推迟或取消某场比赛，但如果在24 小时内恢复了比赛，则所有投注均都视为有效。
                                        </span>
                                    </h3>
                                    <h3>
                                        <strong>3.32.3.</strong><span>
                                            在一对一的比赛中，必须有两位选手开始进行比赛投注方为有效。终点位置更佳者将判为获胜者。如果两位选手均未完成，则投注将宣布为无效并将退还。
                                        </span>
                                    </h3>
                                    <h2><a name="R333" id="A333"></a>3.33. 藤球</h2>
                                    <h3><strong>3.33.1.</strong> <span>胜负盘指比赛或指定局的赢家。让球盘基于任何一局或比分（请参见盘口）；上/下盘与奇/偶比赔率取决于比分（另行说明除外）。</span></h3>
                                    <h3><strong>3.33.2.</strong> <span>比赛期间，如果某队弃权或被取消资格，则所有赌注将被取消（盘上无条件确定的除外）。</span></h3>
                                    <h3><strong>3.33.3.</strong> <span>如果比赛被推后或暂停后在12小时有效期内恢复比赛，则所有赌注仍然有效。</span></h3>
                                    <h3><strong>3.33.4.</strong> <span>头盘赢家（第二盘、第三盘赢家等）与指定局结果关联。如果指定局未完成，则所有赌注将失效。</span></h3>
                                    <h3><strong>3.33.5.</strong> <span>藤球滚球投注比分不会更新。</span></h3>

                                    <h2><a name="R335" id="A335"></a>3.35. 电子竞技</h2>
                                    <h3><strong>3.35.1.</strong> <span> 胜负盘是指投注于一方参赛队伍或个人赛所击败对方或在赛事中取得更高的排名。比赛获胜者盘口指的是地图规定的数目。</span></h3>
                                    <h3><strong>3.35.2.</strong> <span> 现场投注盘口指的是官方开赛后的赛事时间，玩家还未进入比赛/游戏地图的时间。所有现场投注于玩家挑选英雄入伍期间的投注将视为有效。即时投注盘口指的是所有征召英雄入伍后和玩家进入比赛地图的时间。</span></h3>
                                    <h3><strong>3.35.3.</strong> <span> 总冠军 (锦标赛获胜者) 是指投注于电子竞技的赛事、比赛或锦标赛的优胜组或个人赛获胜者。</span></h3>
                                    <h3><strong>3.35.4.</strong> <span> 所有赌盘将按照电子竞技相关的管理协会或组织团体在官方网站公布结果时进行结算。任何后来取消资格或修改结果的情况均不适用于投注目的。</span></h3>
                                    <h3><strong>3.35.5.</strong> <span> 如果比赛延期，并且比赛在 12 小时的期限内恢复，所有投注仍视为有效。</span></h3>
                                    <h3><strong>3.35.6.</strong> <span> 如果比赛已经开始而官方成绩已被宣告，所有投注即将有效，无论参赛者无法进行比赛，因网络掉线或供电中断。如果比赛无法进行或已进行但是无法完成而官方成绩没被公布，所有投注将为作废。除非投注结算已可被确认，否则投注将视为无效。</span></h3>
                                    <h3><strong>3.35.7.</strong> <span> 对于现场投注，如果赛事重赛但玩家仍然保留同样的选秀英雄（重赛），所有投注于即时盘口的主弹将被视为无效，但所有现场投注于征召英雄期间仍然视为有效。如果赛事重新开赛，和重新挑选新英雄（重新开赛），则所有现场注单和即时盘口的投注将被视为无效，除非投注结算已可被确认，否则投注将视为无效。</span></h3>
                                    <h3><strong>3.35.8.</strong> <span> 如果比赛的回合数/地图规定的数目不符合盘口标题所显示的赛制数目，除胜负盘外，则所有投注 (让分盘/大小盘) 将视为无效。</span></h3>
                                    <h3><strong>3.35.9.</strong> <span> 第一滴血/最先击杀 5，10，15，20数目投注盘口是指以哪一个队伍先杀死对方英雄来决定，仅被对方队伍/玩家杀死方可计算为此盘。</span></h3>
                                    <h3><strong>3.35.10.</strong> <span> 首座防御塔/炮塔/兵营投注盘口指的是以哪一个队伍先拿下第一层首座防御塔/炮塔或首座兵营。若其一方队伍反补，或被对方小兵攻破，全部投注仍视为有效。</span></h3>
                                    <h3><strong>3.35.11.</strong> <span> 赛事时间盘口指的是赛后比数板上所显示的赛事进行所用的时间（分钟）。至于刀塔2 ， 第一阵小兵还未开始的时间不被计算在此盘。</span></h3>
                                    <h3><strong>3.35.12.</strong> <span> 第一个肉山大魔王/男爵/巨龙 投注盘是指预测哪个队伍最先击杀最强大的野怪。</span></h3>
                                    <h3><strong>3.35.13.</strong> <span> 杀敌盘口（胜负盘，让分盘，大小盘，单双盘）是由两方（队伍或个人）在正式比赛当中互相厮杀所得的分数。如果某一队伍在比赛还没完毕时宣布弃权，弃权后所发生的厮杀数目也将被计算在内。包括被塔杀，被敌方小兵杀将计算在最终官方的比数板上。另外自杀，队友反扑，被野怪杀不会计算在内。</span></h3>
                                    <h3><strong>3.35.14.</strong> <span> 回合获胜盘口指的是回合或回合组的官方赢家。这盘口将施用在于比赛的地图由回合来划分而已。</span></h3>
                                    <h3><strong>3.35.15.</strong> <span> 玩家杀敌盘口指的是个别玩家在指定比赛，地图或回合所厮杀的总数。</span></h3>
                                    <h3><strong>3.35.16.</strong> <span> 反恐精英中的盘口（胜负盘/让分盘/大小盘/单双盘）指的是获胜回合的数目同时将会以官方成绩包括加时进行计算 （除非另有说明）。</span></h3>

                                    <h2><a name="R336" id="A336"></a>3.36. 泰拳</h2>
                                    <h3><strong>3.36.1.</strong> <span> 拳手颜色（红或蓝）仅供于参考。尽管拳手的颜色有所更改，所有投注仍然视为有效。</span></h3>
                                    <h3><strong>3.36.2.</strong> <span> 让分盘口是指拳赛的获胜者。如果赛事结果为平局时，所有投注于让分盘口的注单将会被视为作废。</span></h3>
                                    <h3><strong>3.36.3.</strong> <span> “大于/小于” 盘口是代表拳赛中所完成的回合总数。因此，如果拳赛未在第五回合完整结束，该回合总数将以完整结束的四个回合进行结算。除非该拳赛在第五回合完整结束后由裁判判決，那么回合总数将以五个回合进行结算。</span></h3>
                                    <h3><strong>3.36.4.</strong> <span> 如果其中一名或两名拳手被裁判取消参赛资格，则所有投注将会被视为作废。</span></h3>
                                    <h3><strong>3.36.5.</strong> <span> 如果拳赛延期并且未于原定开赛时间的十二小时内开始，所有投注均作废。</span></h3>
                                    <h3><strong>3.36.6.</strong> <span> 当第一回合的钟声响后，拳赛就视为正式开始。如果一名或两名拳手无法开始比赛，则所有投注均作废。</span></h3>
                                    <h3><strong>3.36.7.</strong> <span> 所有投注将会以赛场上钟声响后的官方决定宣布赛果为准进行结算。后续的赛果更改将不会影响结算。</span></h3>

                                    <h2><a name="R337" id="A337"></a>3.37. 娱乐</h2>
                                    <h3><strong>3.37.1.</strong> <span> 中国新歌声 - 晋级最远的队伍/ 参赛者</span></h3>
                                    <h3> <span> 两支队伍/ 两个参赛者将配对作为投注选项，属于不同组的队伍/ 参赛者也将会配对作为投注选项。</span></h3>
                                    <h3> <span> 如果配对的两支队伍/ 两个参赛者都晋级下一轮赛事，所有该队伍/ 参赛者的投注将带到随后的阶段。</span></h3>
                                    <h3> <span> 如果配对的两支队伍/ 两个参赛者都在同一轮赛事淘汰，所有该队伍/ 参赛者的投注将被视为和局。</span></h3>
                                    <!-- End Entertainment -->

                                </div>
                            </span>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </table>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      showTop: false,
    };
  },
  methods: {
    // eslint-disable-next-line
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop != 0) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
      // console.log(scrollTop);
      // console.log(clientHeight);
      // console.log(scrollHeight);
      // if (scrollTop + clientHeight >= scrollHeight) {
      //   console.log("scrollHeight");

      // }
    },
    scrollTop() {
      document.getElementById('containerDiv').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    },
  },
};
</script>

<style scoped>
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  line-height: 1.025rem;
  margin-bottom: 0.75rem !important;
  letter-spacing: 0.18px;
  text-align: left;
  color: white;
}

h1 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  letter-spacing: 0.18px;
}

h2 {
  font-weight: 200;
  font-size: 14px;
  font-weight: bold;
  padding-left: 25px;
  letter-spacing: 0.18px;
}

h3 {
  font-weight: 200;
  font-size: 12px;
  font-weight: normal;
  padding-left: 50px;
  letter-spacing: 0.18px;
}

h4 {
  font-weight: 200;
  font-size: 12px;
  font-weight: normal;
  padding-left: 50px;
  letter-spacing: 0.18px;
}

h5 {
  font-weight: 200;
  font-size: 12px;
  font-weight: normal;
  padding-left: 50px;
  letter-spacing: 0.18px;
}

.button span {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  background-color: #907339;
  border-radius: 5px;
  line-height: 20px;
  width: 105px;
  height: 20px;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}

.button:hover span {
  color: #000;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: #191002;
  margin: 0 auto;
  max-width: 1200px;
  max-height: 36rem;
  text-align: justify;
  padding: 20px;
  overflow: auto;
  overflow-y: scroll;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.18px;
  text-align: left;
  margin-right: 20px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../../assets/images/rules.jpg');
  background-size: cover;
  background-position: center;
}
b,
strong {
  font-weight: 700 !important;
}
span {
  font-weight: 700 !important;
}

.termsList {
  margin-top: 1.25rem;
}

.returnTop {
  position: absolute;
  width: 40px;
  right: 20px;
  top: 0;
  cursor: pointer;
}
</style>
