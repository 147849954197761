import gameImport from './../game';

import fachai from './../../images/game/fishing/img-fachai-fish-d.png';
import jili from './../../images/game/fishing/img-jili-fish-d.png';
import spadefish from './../../images/game/fishing/img-spadegaming-fish-d.png';
import play8 from './../../images/game/fishing/img-play8-fish-d.png';
import sfachai from './../../images/game/fishing/img-fachai-fish-s.png';
import sjili from './../../images/game/fishing/img-jili-fish-s.png';
import sspadefish from './../../images/game/fishing/img-spadegaming-fish-s.png';
import splay8 from './../../images/game/fishing/img-play8-fish-s.png';
import vpower from './../../images/game/fishing/img-vpower-d.png';
import svpower from './../../images/game/fishing/img-vpower-s.png';

var gameMenu = {
  name: 'FISHING',
  items: [
    {
      name: 'VPOWER',
      provider: 'UFA Fishing',
      icon: gameImport.find((x) => x.code.toString() === '72').icon,
      code: '72',
      img: [{ langId: 0, src: vpower }],
      img2: [{ langId: 0, src: svpower }],
    },
    {
      name: 'SPADE GAMING FISHING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-302',
      img: [{ langId: 0, src: spadefish }],
      img2: [{ langId: 0, src: sspadefish }],
    },
    {
      name: 'JILI FISHING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-305',
      img: [{ langId: 0, src: jili }],
      img2: [{ langId: 0, src: sjili }],
    },
    {
      name: 'FA CHAI FISHING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-304',
      img: [{ langId: 0, src: fachai }],
      img2: [{ langId: 0, src: sfachai }],
    },
    {
      name: 'PLAY8 FISHING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-307',
      img: [{ langId: 0, src: play8 }],
      img2: [{ langId: 0, src: splay8 }],
    },
  ],
};

export default gameMenu;
