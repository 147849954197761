import sagaming from './../../images/game2/live/img-joker.png';
import sexyba from './../../images/game2/live/img-spadegaming.png';
//import ebet from './../../images/game2/live/img-fachai.png';
//import venus from './../../images/game2/live/img-jili.png';
import biggame from './../../images/game2/live/img-kingmaker.png';
//import pgs from './../../images/game2/live/img-pgslive.png';
import hotroad from './../../images/game2/live/img-hotrod-d.png';

//import worldent from './../../images/game2/live/img-nanyang-d.png';
//import pp from './../../images/game2/live/v-sport-lc-pplive-s.png';

import gameImport from './../game';

var gameMenu = {
  name: 'LIVE CASINO',
  items: [

    // {
    //   name: 'PEGASUS',
    //   isFullWidth: false,
    //   provider: 'pegasus',
    //   icon: gameImport.find((x) => x.code.toString() === '80').icon,
    //   code: '80-202',
    //   img: [{ langId: 0, src: pgs }],
    //   img2: [{ langId: 0, src: pgs }],
    //   isNew: false,
    //   labelName: '',
    //   parentName: '',
    // },
    {
      name: 'SA GAMING',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '31').icon,
      code: '31',
      img: [{ langId: 0, src: sagaming }],
      img2: [{ langId: 0, src: sagaming }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'SEXY BACCARAT',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-101',
      img: [{ langId: 0, src: sexyba }],
      img2: [{ langId: 0, src: sexyba }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    // {
    //    name: 'EBET',
    //    isFullWidth: false,
    //    provider: 'ebet',
    //    icon: gameImport.find((x) => x.code.toString() === '64').icon,
    //    code: '64',
    //    img: [{ langId: 0, src: ebet }],
    //    img2: [{ langId: 0, src: ebet }],
    //    isNew: false,
    //    labelName: '',
    //    parentName: '',
    //  },
    // {
    //   name: 'VENUS CASINO',
    //   isFullWidth: false,
    //   provider: 'game hall',
    //   icon: gameImport.find((x) => x.code.toString() === '39').icon,
    //   code: '39-102',
    //   img: [{ langId: 0, src: venus }],
    //   img2: [{ langId: 0, src: venus }],
    //   isNew: false,
    //   labelName: '',
    //   parentName: '',
    // },
    {
      name: 'BIGGAMING',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-108',
      img: [{ langId: 0, src: biggame }],
      img2: [{ langId: 0, src: biggame }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    // {
    //   name: 'WORLD. ENT',
    //   isFullWidth: false,
    //   provider: 'game hall',
    //   icon: gameImport.find((x) => x.code.toString() === '81').icon,
    //   code: '81',
    //   img: [{ langId: 0, src: worldent }],
    //   img2: [{ langId: 0, src: worldent }],
    //   isNew: false,
    //   labelName: '',
    //   parentName: '',
    // },
    //{
    //  name: 'Pragmatic Play',
    //  isFullWidth: false,
    //  provider: 'game hall',
    //  icon: gameImport.find((x) => x.code.toString() === '39').icon,
    //  code: '39-132',
    //  img: [{ langId: 0, src: pp }],
    //  img2: [{ langId: 0, src: pp }],
    //  isNew: false,
    //  labelName: '',
    //  parentName: '',
    //},
    {
      name: 'Hot Road Gaming',
      isFullWidth: false,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-153',
      img: [{ langId: 0, src: hotroad }],
      img2: [{ langId: 0, src: hotroad }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
