<template>
  <div class="backdrop">
   
  <br/>
  <nav
    class="navbar navbar-expand-lg navbar-dark fixed-top d-flex justify-content-center row col-12 gx-4"
    id="mainNav"
  >
    <div class="container px-0">
          <div
            class="col-5"
          >
            <div class="pull-left position-relative">
              <img
                class="header-logo cursor-pointer"
                alt="logo"
                src="../../../assets/images/logo.png"
                @click="onClickMenuIcon(0)"
              />
              <img class="logo-glow" src="../../../assets/images/logo-glow.png"/>
            </div>
          </div>
    </div>
  </nav>

    <div class="container clear-both px-0 container-min-height">
      <div class="row px-0">
        <div class="col-3 d-none d-lg-block">
          <img class="lamp" src="../../../assets/images/img-lamp.png" width="250" />
        </div>
        <div class="col-lg-9 col-md-12 right-card-container">
          <div class="right-card">

            <h2 class="cclip force-password-header">{{ $t('PleaseChangePassword') }}</h2>

              <div class="row mt-3">
                <div
                  class="col-md-3 col-sm-12 pl-4 profile-label d-flex align-items-center"
                >
                  {{ $t('UserNameLabel') }}
                </div>
                <div class="col-md-4 col-sm-12 pl-1">
                  <input
                    type="text"
                    class="form-control border-none"
                    aria-label="Default"
                    disabled="true"
                    aria-describedby="inputGroup-sizing-default"
                    v-model.trim="inputUsername"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div
                  class="col-md-3 col-sm-12 pl-4 profile-label d-flex align-items-center"
                >
                  {{ $t('CurrentPassword') }}
                </div>
                <div class="col-md-4 col-sm-12">
                  <input
                    type="password"
                    class="form-control password-field"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model.trim="inputCurrent"
                    :placeholder="$t('CurrentPassword')"
                    v-on:input="onChangeNewPassword()"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div
                  class="col-md-3 col-sm-12 pl-4 profile-label d-flex align-items-center"
                >
                  {{ $t('NewPassword') }}
                </div>
                <div class="col-md-4 col-sm-12">
                  <input
                    type="password"
                    class="form-control password-field"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model.trim="inputNew"
                    :placeholder="$t('NewPassword')"
                    v-on:input="onChangeNewPassword()"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div
                  class="col-md-3 col-sm-12 pl-4 profile-label d-flex align-items-center"
                >
                  {{ $t('ConfirmPassword') }}
                </div>
                <div class="col-md-4">
                  <input
                    type="password"
                    class="form-control password-field"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    :placeholder="$t('ConfirmPassword')"
                    v-model.trim="inputConfirm"
                    v-on:input="onChangeNewPassword()"
                  />
                </div>
              </div>
            <!-----desktop--->
            <div class="desktop">
              <div class="validate-wrap mt-4">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag1 ? 'text-success' : 'text-muted']"
                >
                  <i class="fas fa-check"></i>
                  <span class="password-info pl-2">
                    {{ $t('Password_RemarkLength') }}
                  </span>
                </div>
              </div>
              <div class="validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag5 ? 'text-success' : 'text-muted']"
                >
                  <i class="fas fa-check"></i>
                  <span class="password-info pl-2">
                    {{ $t('NewPasswordCannotBeSameAsOldPassword') }}
                  </span>
                </div>
              </div>
              <div class="validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag2 ? 'text-success' : 'text-muted']"
                >
                  <i class="fas fa-check"></i>
                  <span class="password-info pl-2">
                    {{ $t('Password_RemarkInclude') }}
                  </span>
                </div>
              </div>
              <div class="validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag3 ? 'text-success' : 'text-muted']"
                >
                  <i class="fas fa-check"></i>
                  <span class="password-info pl-2">
                    {{ $t('Password_RemarkExclude') }}
                  </span>
                </div>
              </div>
              <div class="validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag4 ? 'text-success' : 'text-muted']"
                >
                  <i class="fas fa-check"></i>
                  <span class="password-info pl-2">
                    {{ $t('ConfirmPasswordMustSame') }}
                  </span>
                </div>
              </div>
            </div>

            <!-----mobile--->
            <div class="mobile">
              <div class="col-12 validate-wrap mt-4">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag1 ? 'text-success' : 'text-muted']"
                >
                  <span class="pl-2">
                    <i class="fas fa-check-circle"></i>

                    <span class="password-info">
                      &nbsp; {{ $t('Password_RemarkLength') }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-12 validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag5 ? 'text-success' : 'text-muted']"
                >
                  <span class="pl-2">
                    <i class="fas fa-check-circle"></i>

                    <span class="password-info">
                      &nbsp; {{ $t('NewPasswordCannotBeSameAsOldPassword') }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-12 validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag2 ? 'text-success' : 'text-muted']"
                >
                  <span class="pl-2">
                    <i class="fas fa-check-circle"></i>
                    <span class="password-info">
                      &nbsp; {{ $t('Password_RemarkInclude') }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-12 validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag3 ? 'text-success' : 'text-muted']"
                >
                  <span class="pl-2">
                    <i class="fas fa-check-circle"></i>

                    <span class="password-info">
                      &nbsp; {{ $t('Password_RemarkExclude') }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-12 validate-wrap">
                <div
                  class="row d-flex align-items-center"
                  :class="[validateFlag4 ? 'text-success' : 'text-muted']"
                >
                  <span class="pl-2">
                    <i class="fas fa-check-circle"></i>
                    <span class="password-info">
                      &nbsp; {{ $t('ConfirmPasswordMustSame') }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="save-btn-wrap mt-5 pb-4">
                <div class="profile-label">
                  <button
                    class="btn btn-secondary btn-save px-4"
                    :disabled="
                      !validateFlag1 ||
                        !validateFlag2 ||
                        !validateFlag3 ||
                        !validateFlag4 ||
                        !validateFlag5
                    "
                    @click="onClickSave"
                  >
                    &nbsp; {{ $t('SaveBtn') }}&nbsp;
                  </button>
                </div>
            </div>
          </div>

          <loading-spinner v-if="isLoading"></loading-spinner>
          <transition name="fadeIn">
            <div class="desktop" v-if="showSuccessAlert">
              <success-alert :message="successMessage"></success-alert>
            </div>
          </transition>
          <transition name="fadeIn">
            <div class="mobile" v-if="showSuccessAlert">
              <success-alert-mobile
                :message="successMessage"
              ></success-alert-mobile>
            </div>
          </transition>

          <transition name="fadeIn">
            <div class="desktop" v-if="showErrorAlert">
              <danger-alert :message="errorMessage"></danger-alert>
            </div>
          </transition>

          <transition name="fadeIn">
            <div class="mobile" v-if="showErrorAlert">
              <danger-alert-mobile
                :message="errorMessage"
              ></danger-alert-mobile>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
  <footer class="col-12 desktop-footer">
    <div class="container">
      <div class="row">
        <div class="col">
            <div>
              <div class="m-0 text-left text-white">
                <img
                  class="footer-logo"
                  alt="logo"
                  src="../../../assets/images/logo-footer.png"
                />
              </div>
            </div>
            <div class="text-dark-grey nowrap">
              <small>
                <span class="footer-text">
                  &nbsp; © Copyright 2023 V-Sport. All Rights Reserved.
                </span>
              </small>
            </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped lang="css" src="../../../assets/css/desktop.css"></style>
<script>
import CustomHttp from '../../../CustomHttp';
import CustomEncrypt from '../../../CustomEncrypt';
import moment from 'moment';
import languageList from '../../../assets/script/languageList';

import i18n from './../../../i18n';
const { t } = i18n.global;
//Vue export
export default {
  mounted() {
    this.updateCurrentTime();
    // this.onClickMenuIcon(0);

    this.isLoading = false;
    setInterval(() => {
      this.updateCurrentTime();
    }, 1000);

    //check default language and fallback to english
    let tempLang = languageList.find((l) => l.id == this.$store.getters.langId);
    if (!tempLang) {
      this.onClickLanguageTab(0);
    }

    // this.getToken();
    this.inputUsername = this.$store.getters.username;
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    username() {
      return this.$store.getters.username;
    },
    selectedLanguage() {
      let selectedLanguage = languageList.find(
        (l) => l.id == this.$store.getters.langId
      );
      if (selectedLanguage) {
        return selectedLanguage;
      } else {
        return languageList[0];
      }
    },
    selectedLanguageId() {
      return this.$store.getters.langId;
    },
  },
  data() {
    return {
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
      //input parameter
      inputCurrent: '',
      inputNew: '',
      inputConfirm: '',
      inputUsername: '',
      // validation flag
      validateFlag1: false,
      validateFlag2: false,
      validateFlag3: false,
      validateFlag4: false,
      validateFlag5: false,
      //lang
      isLanguageDropdownOpen: false,

      languageList: languageList,
      currentTime: '',
    };
  },
  methods: {
    updateCurrentTime() {
      const today = new Date();
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const dateTime = date + ' ' + time;
      let tempDate = new Date(dateTime);
      let tempDateGmt = new Date();
      let gmt = Math.abs(tempDateGmt.getTimezoneOffset() / 60);
      tempDate =
        moment().format('MM/DD/yyyy hh:mm:ss A') +
        ' (GMT+' +
        gmt.toString() +
        ')';
      this.currentTime = tempDate;
    },
    onClickLanguageTab(index) {
      const tempLangId = this.languageList[index].id;
      this.$store.dispatch('setLangId', tempLangId);
    },
    onClickLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    async onClickSave() {
      this.formSubmitted = true;
      // if (this.inputNew != this.inputConfirm) {
      //   this.toggleDangerAlert(t('PasswordMustMatch'));
      //   return;
      // }
      const oldPassword = CustomEncrypt.encrypt(this.inputCurrent).toString();
      const newPassword = CustomEncrypt.encrypt(this.inputNew).toString();
      let json = {
        username: this.$store.getters.username,
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      this.isLoading = true;
      let response;
      try {
        response = await CustomHttp.post('/api/v1/member/update-pw', json);
      } catch (error) {
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace('Error:', '').trim();
        tempError = t(tempError);
        this.toggleDangerAlert(tempError.toString());
        return;
      }

      this.formSubmitted = false;
      this.isLoading = false;
      const responseData = response.data;
      if (responseData.error == null) {
        this.$store.dispatch('setReqChgPw', false);
        this.$store.dispatch('getSystemMessage');
        this.$store.dispatch('setUserLoginState', true);
        this.toggleSuccessAlert(t('Passwordupdatedsuccessfully'));
        this.validateFlag1 = false;
        this.validateFlag2 = false;
        this.validateFlag3 = false;
        this.validateFlag4 = false;
        this.validateFlag5 = false;
        this.inputCurrent = '';
        this.inputConfirm = '';
        this.inputNew = '';
        this.$router.replace('/');
      } else {
        this.toggleDangerAlert(responseData.error.message);
      }
    },
    onChangeNewPassword() {
      this.validateFlag1 = false;
      this.validateFlag2 = false;
      this.validateFlag3 = false;
      this.validateFlag4 = false;
      this.validateFlag5 = false;
      if (this.inputNew.length >= 8 && this.inputNew.length <= 15) {
        this.validateFlag1 = true;
      }
      // eslint-disable-next-line
      // const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      //format.test(this.inputNew) &&
      if (/^(?=.*[A-Za-z])(?=.*\d)/.test(this.inputNew)) {
        this.validateFlag2 = true;
      }
      const username = this.$store.getters.username;
      // const nickname = this.$store.getters.nickname;
      if (
        !this.inputNew.toLowerCase().includes(username.toLowerCase()) &&
        !this.inputNew.toLowerCase().includes(username.toLowerCase())
      ) {
        this.validateFlag3 = true;
      }

      if (this.inputNew == this.inputConfirm) {
        this.validateFlag4 = true;
      }
      if (this.inputCurrent != this.inputNew) {
        this.validateFlag5 = true;
      }
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1900);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1900);
    },
    onClickLogout() {
      this.$store.dispatch('userLogout');
      this.$router.replace('/');
    },
  },
};
</script>
<style scoped>
.header-logo {
  width: 232px;
  object-fit: contain;
  position: relative;
  padding-top: 2px;
  z-index: 1;
}
.logo-glow {
  position: absolute;
  left: -40px;
  top: -10px;
  z-index: 0;
  mix-blend-mode: color-dodge;
}

.lamp{
    height: 650px;
    width: auto;
    position: absolute;
    left: -5vw;
}
  .force-password-header{
    color: #c500ff;
    text-shadow: 0 0 10px #c500ff;
    -webkit-text-stroke: 1px #c500ff;
    text-transform: uppercase;
    margin-left: 0.25em;
  }
.backdrop{
  padding-top: 2.5em;
  background: url(../../../assets/images/desktop-bg.jpg) 0 0 / cover;
}
.info-text {
  padding: 6px 0px 6px 16px;
  background-image: linear-gradient(to left, rgba(221, 221, 221, 0), #ddd);
  font-size: 12px;
  text-align: left;
  color: #6b6555;
  width: 273px;
  border-radius: 5px 0 0 0; /* top left, top right, bottom right, bottom left */
  /* font-family: SegoeUI; */
}

.profile-label {
  margin-left: 0.25em;
  font-size: 14px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0.5em;
}

.profile-info {
  text-align: left;
  font-size: 14px;
  color: #000;
  font-weight: 700;
}

.right-card-container{
  padding: 7px;
  border-radius: 35px;
  border: solid 2px #6fecff;
  margin-top: 2em;
}
.right-card {
  border-radius: 5px;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: transparent;
  padding: 20px 25px;
  border-radius: 25px;
  border: solid 2px #6fecff;
  min-height: 440px;
  overflow-y: auto;
}

.btn-save {
  border: none;
  font-weight: bold;
  color: black;
  border-radius: 5px !important;
  background-image: linear-gradient(to bottom, #00edff, #22a4dd);
}

.btn-save:disabled {
  border: none;
  font-weight: bold;
  color: black;
  border-radius: 5px !important;
  opacity: 0.5;
}

.form-control {
  border-radius: 7px;
  border: solid 1px #fff;
  background: rgba(0, 67, 72, 0.45);
  font-weight: bold;
}
.password-field{

}
.card {
  border: 0px !important;
}

input:placeholder-shown {
  color: #fff;
}

.password-info {
  /* font-family: Helvetica; */
  font-size: smaller;
  text-align: left;
  color: #e9a0ff;
  font-weight: bold;
}

.text-muted {
  opacity: 0.95;
  color: #c3c9ce !important;
}

.card {
  border: 0px;
}

.bg-light-grey {
  background-color: #f7f7f7;
  /* background-color: #fdfdfd; */
}
.header-logo {
  width: 215px;
  object-fit: contain;
}

.footer-text {
  font-size: 80%;
  font-weight: 400;
}

.text-dark-grey {
  color: rgb(94, 92, 92);
}

.password-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
  text-align: left;
}

.password-text {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
}

.bg-light-gold-gradient {
  background-image: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
  border-radius: 5px;
}

.container-min-height {
  min-height: 85vh;
}

.validate-wrap {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1.5em;
  color: #929298;
}

.fa-check-circle {
  font-size: 13px !important;
}

.form-control[data-v-d79fbe0a] {
  font-size: 14px;
}

.border-none {
  border: 0px !important;
  background-color: transparent;
  color: white;
}

/* desktop */
@media (min-width: 769px) {
  .dropdown-menu {
    top: 2rem !important;
    left: auto !important;
    right: 0;
    background-color: #21201c;
    max-width: 230px;
    height: 120px;
  }
}

/* mobile */

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: inherit;
}

.dropdown-item {
  color: #fff !important;
}

@media (max-width: 769px) {
  .force-password-header{
    margin-top: 1em;
  }
  .right-card-container{
    border: none;
  }
  .right-card{
    border: none;
  }
  .dropdown-menu {
    top: 100% !important;
    left: auto !important;
    right: 0;
    background-color: #21201c;
    max-width: 200px;
    height: 120px;
  }
  .mobile{
    background: none;
  }
  .text-success {
    color: #28a745 !important;
  }
  .text-muted {
    color: #6c757d !important;
  }
  .validate-wrap{
    padding-left: 1em;
  }

}

.current-time-text {
  position: relative;
  top: 1px;
  letter-spacing: 0.03rem;
  margin: 0px 6px;
  display: inline-block;
  color: #ffffff;
  line-height: 1.36;
  opacity: 0.6;
  font-size: 11px !important;
  position: relative;
}
.font-11 {
  font-size: 11px !important;
}
</style>
