<template>
  <div class="container">
    <loading-spinner v-if="isLoading"></loading-spinner>
    <div class="row">
      <div
        class="login-wrapper img-backdrop"
        :style="'background:url(' + backgroundImage + ') no-repeat 0 0 / cover'"
      >
        <!----language select bar--->
        <transition name="slideFromRight">
          <div class="language-wrapper" v-if="showLanguageSidebar">
            <div class="langForm col-12">
              <div
                v-for="(language, index) in languageList"
                :key="language.code"
                class="row"
              >
                <div class="float-left">
                  <span class="langSel" @click="onSelectLanguage(index)">
                    <img :src="language.icn" width="35" />
                    <div class="langFlagText">{{ language.name }}</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!---end language select bar--->
        <div class="">
          <div class="close-right">
            <div class="close-right-img" @click="toggleClose()">
              <img src="../../../assets/icons/close.png" width="32" />
            </div>
          </div>
          <div class="img-big-ub-logo">
            <img src="../../../assets/images/logo-sm.png" />
          </div>
          <div class="LoginForm">
            <div class="login-input-container full-center">
              <img class="login-backdrop" src="../../../assets/images/btn-username.png"/>
              <input
                name="inputUserName"
                type="text"
                class="login-input"
                maxlength="50"
                v-on:keyup.enter="focusInputPassword()"
                :placeholder="$t('UserName2')"
                v-model.trim="inputUsername"
                v-on:input="onChangeLogin()"
              />
            </div>
            <div class="login-input-container full-center">
              <img class="login-backdrop" src="../../../assets/images/btn-password.png"/>
              <input
                name="password"
                type="password"
                class="login-input"
                ref="inputPassword"
                maxlength="16"
                :placeholder="$t('Password2')"
                v-model.trim="inputPassword"
                v-on:keyup.enter="onClickLogin"
                v-on:input="onChangeLogin()"
              />
            
            </div>
            <div class="login_error container">{{ errMessage }} &nbsp;</div>
            <router-link to="/agreement">
              <div class="login-input-container full-center">
                <img src="../../../assets/images/btn-login.png" class="btnlogin-backdrop"/>
                <a
                  id="btnSignIn"
                  class="btnlogin cursor-pointer"
                  @click="onClickLogin"
                >
                  {{ $t('Login2') }}
                </a>
              </div>


            </router-link>
          </div>
            <div class="f-control2">
              <div
                style="display: inline-block"
                class="cursor-pointer"
                @click="toggleLanguageSidebar()"
              >
                <div class="LangText">
                  {{ selectedLang.name }}
                </div>
                <div class="arrowdown"></div>
              </div>
            </div>
          <div class="copyright">Copyright © 2022 All rights reserved.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import languageList from './../../../assets/script/languageList';
import i18n from './../../../i18n';
const { t } = i18n.global;

//import xxx from "./../../../assets/images/login-backdrop.png";

export default {
  name: 'Login',
  emits: ['toggle-close'],
  props: ['backgroundImage'],
  created() {
    this.selectedLang = languageList.find(
      (l) => l.id == this.$store.getters.langId
    );
    if (this.selectedLang == undefined) {
      this.selectedLang = 0;
    }
  },
  data() {
    return {
      inputUsername: '',
      inputPassword: '',
      showLanguageSidebar: false,
      languageList: languageList,
      selectedLang: null,
      isLoading: false,
      errMessage: '',
    };
  },
  methods: {
    focusInputPassword() {
      this.$refs.inputPassword.focus();
    },
    onChangeLogin() {
      this.errMessage = '';
    },
    toggleClose() {
      this.$emit('toggle-close');
    },
    toggleLanguageSidebar() {
      this.showLanguageSidebar = true;
    },
    onSelectLanguage(index) {
      this.errMessage = '';
      this.selectedLang = this.languageList[index];
      this.showLanguageSidebar = false;
      const tempLangId = this.languageList[index].id;
      this.$store.dispatch('setLangId', tempLangId);
    },
    async onClickLogin() {
      if (this.inputUsername == '' || this.inputPassword == '') {
        this.errMessage = t('InvalidUsernameOrPassword');
        return;
      }

      this.errMessage = '';
      this.isLoading = true;
      const json = {
        username: this.inputUsername,
        password: this.inputPassword,
      };
      try {
        await this.$store.dispatch('userLogin', json);
        //  this.isLoading = false;
        //   this.$i18n.locale = this.$store.getters.langId.toString();
        // this.$router.push("/agreement");
        // this.$router.push("agreement");
        // this.$router.push({ path: `/agreement` });
        window.location.href = '/agreement';
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace('Error:', '').trim();
        tempError = t(tempError);
        // if (this.$store.getters.langId != 16) {
        //   tempError = t("InvalidUsernameOrPassword");
        // }
        this.errMessage = t(tempError);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-backdrop {
  /* background: url("../../../assets/images/login-backdrop.png") no-repeat; */
  width: 100%;
  background-position: bottom;
  flex: 1 1 auto;
  background-size: contain;
}

.container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
</style>
