<template>
  <div class="card-body">
    <p class="card-text">
      V-Sport là một trong những thương hiệu cá cược qua mạng hàng đầu trên thế
      giới.
    </p>
    <p class="card-text">
      V-Sport Sportsbook cung cấp hơn 500 sự kiện thể thao mỗi tuần, bao gồm tất
      cả các giải bóng đá chính và các sự kiện thể thao quốc tế. V-Sport cũng
      cung cấp thông tin cập nhật bóng đá trực tiếp, xác nhận thắng tức thì,
      thanh toán nhanh chóng, truy cập dễ dàng và cá cược qua mạng nhanh chóng.
      <br />
      Sòng Bài V-Sport là một dịch vụ giải trí tổng hợp cung cấp hàng loại các
      lọai chơi bài và Người Chia Bài Trực Tiếp cho Nhiều Người Chơi hợp tác với
      Hệ Thống Chơi Bài qua mạng của Úc đáp ứng từng yêu cầu của người chơi.
    </p>
    <p class="card-text">
      Trong Trò Chơi V-Sport, bạn có thể chọn chơi các trò chơi độc quyền như thẻ
      cào, keno, xì phé và Rulét, cho phép bạn chơi không giới hạn và không cần
      phải tải về hay lắp đặt chương trình nào.
      <br />
      V-Sport sử dụng công nghệ tiên tiến nhất để mang lại một môi trường cá cược
      bảo mật và hiệu quả nhất. Tại V-Sport, chúng tôi cam kết cung cấp cho khách
      hàng một dịch vụ cá cược hoàn hảo. Với đa dạng sản phẩm và dịch vụ, V-Sport
      đáp ứng yêu cầu của tất cả khách hàng chơi cá cược thể thao từ người chơi
      không thường xuyên cho đến khách hàng VIP và Nhiều Nhà Cái Quốc Tế.
    </p>
    <p class="card-text">
      Tất cả những điều này trên phạm vi rộng nhất của thể thao, sự kiện và thị trường, trong sự thoải mái tại nhà riêng của bạn hoặc bất cứ nơi nào trên thế giới. Khi đặt cược với V-Sport, bạn có thể cảm thấy tự tin khi biết rằng bạn đang giao dịch với một nhà cái thể thao trực tuyến uy tín có ban quản lý có nhiều thập kỷ kinh nghiệm trong ngành công nghiệp trò chơi.
      <br />
      Bộ Phận Hỗ Trợ V-Sport của chúng tôi luôn luôn sẵn sàng trợ giúp trong bất
      cứ tình huống nào, bao gồm thắc mắc về tài khoản, gởi và rút tiền, hoặc
      trợ giúp chung về cá cược.
    </p>

    <p class="card-text">
      Hãy cá cược với V-Sport, nhà cái bạn có thể tin tưởng.
    </p>
    <br><br><br>
  </div>
</template>

<style scoped>
.card-text {
  font-size: 16px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  letter-spacing: 0.2px;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
}
</style>
