<template>
  <div class="card left-card">
      <div class="card-body">
        <div class="col-12 p-0">
          <div class="row pb-4" v-for="d in dataList" :key="d.seq">
              <div class="icn-round">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
            <div class="col-11 pl-4">
              <div class="row">
                <span class="title"> {{ d.title }}</span>
              </div>
              <div class="row">
                <span class="desc" v-html="d.desc"> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  mounted() {
    this.setDataList();
  },
  data() {
    return {
      fullList: [
        {
          langId: '0', //english
          item: [
            {
              title: 'Best Prices',
              desc:
                'V-Sport offers the most competitively priced odds among the major sportsbooks worldwide.',
            },
            {
              title: 'Fastest Payout',
              desc:
                'Payment effected within 24 hours of receipt of your funds withdrawal request.',
            },
            {
              title: 'Widest Selections Of Events',
              desc:
                'From over 500 sporting events every week in Sportsbook, an extensive range of casino games in Casino, a wide variety of international horse racing events in Racebook and exclusive games in Games, V-Sport is a 1-Stop Shop offering a complete suite of gaming products. Bet on your favorite sport, team or selection with us.',
            },
            {
              title: 'Access Anytime Anywhere',
              desc:
                'With V-Sport WAP and iPhone service, you can bet whilst sitting in the comfort of the stadium while enjoying the game.',
            },
            {
              title: 'Easy Payment Methods',
              desc:
                'Through our tie-ups with various payment solution providers such as Moneybookers and NETELLER, settlement of accounts has never been easier.',
            },
            {
              title: 'Wide Browser Compatibility',
              desc:
                'V-Sport supports a wide array of popular browsers like Microsoft Internet Explorer, Mozilla Firefox, Apple Safari and Opera. There is no need to change your favorite browser because of us.',
            },
          ],
        },
        {
          langId: '2', //thai
          item: [
            {
              title: 'ราคาดีที่สุด',
              desc:
                'V-Sport นำเสนอราคาออดซ์ (ราคาต่อรอง) ที่ดีที่สุดในกลุ่มผู้นำสปอร์ตบุ๊คทั่วโลก',
            },
            {
              title: 'จ่ายเงินเร็วที่สุด',
              desc:
                'การจ่ายเงินจะใช้เวลาภายใน 24 ชั่วโมงหลังจากได้รับคำร้องขอถอนเงินจากท่าน อ่านคู่มือและเรียนรู้วิธีการส่งคำร้องขอถอนเงิน: เมื่อต้องการถอนเงินจากบัญชีควรทำอย่างไร?',
            },
            {
              title: 'มีเกมส์การแข่งขันให้เลือกหลากหลายที่สุด',
              desc:
                'V-Sport เป็นศูนย์รับวางพนันที่มีผลิตภัณฑ์แบบครบวงจรที่เดียวเบ็ดเสร็จ เพราะเราให้บริการเกมส์การแข่งขันกีฬามากกว่า 500 เกมส์ทุกสัปดาห์ในสปอร์ตบุ๊ค นอกจากนี้ยังมีเกมส์คาสิโน, รายการม้าแข่งนานาชาติในเรซบุ๊ค และเกมส์อื่นๆอีกมากมาย เริ่มต้นวางพนันกีฬา ทีมหรือเกมส์โปรดของท่านกับเราได้แล้ววันนี้ <br/> เรียนรู้เพิ่มเติมเกี่ยวกับ V-Sport สปอร์ตบุ๊ค และV-Sport Games',
            },
            {
              title: 'เข้าระบบได้ทุกที่ทุกเวลา',
              desc:
                'ด้วยบริการ V-Sport ผ่าน WAP (เว็บไซต์บนโทรศัพท์มือถือ) และบริการเว็บไซต์ V-Sport ผ่านโทรศัพท์มือถือ ท่านสามารถวางพนันได้ แม้ขณะที่กำลังดูการแข่งขันอยู่ในสนามกีฬา เรียนรู้เพิ่มเติมเกี่ยวกับ บริการ V-Sport ผ่าน WAP มือถือ และ บริการเว็บไซต์ V-Sport ผ่านมือถือ',
            },
            {
              title: 'วิธีการจ่ายเงินอย่างง่ายๆ',
              desc:
                'ด้วยระบบการชำระเงินที่ผูกกับผู้ให้บริการในการจ่ายเงินชั้นนำอย่าง Skrill (Moneybookers) และ NETELLER การลงทะเบียนเริ่มต้นใช้บริการเป็นไปได้อย่างง่ายดาย',
            },
            {
              title: 'ใช้ได้กับหลายบราวเซอร์',
              desc:
                'V-Sport สามารถใช้ได้กับบราวเซอร์ที่มีชื่อเสียงต่างๆ อาทิเช่น Microsoft Internet Explorer, Mozilla Firefox, Apple Safari และ Opera ท่านไม่จำเป็นต้องเปลี่ยนบราวเซอร์เมื่อใช้ V-Sport <br/> เรียนรู้เว็บเบราว์เซอร์ที่ V-Sport รองรับ: ฉันจะใช้เว็บเบราว์เซอร์ที่มีอยู่เข้าเว็บไซต์ V-Sport ได้หรือไม่?',
            },
          ],
        },
        {
          langId: '3', //vn
          item: [
            {
              title: 'Giá Tốt Nhất',
              desc:
                'V-Sport cung cấp tỷ lệ cược giá cạnh tranh nhất trong các nhà cái thể thao trên thế giới.',
            },
            {
              title: 'Thanh Toán Nhanh Nhất',
              desc:
                'Thanh toán có hiệu lực trong vòng 24giờ sau khi nhận yêu cầu rút tiền của bạn. Hãy đọc tài liệu hướng dẫn để hiểu làm sao bạn có thể gởi yêu cầu rút tiền: Làm sao tôi rút tiền từ tài khoản của tôi?Thanh toán có hiệu lực trong vòng 24giờ sau khi nhận yêu cầu rút tiền của bạn',
            },
            {
              title: 'Nhiều Sự Kiện Phong Phú Nhất',
              desc:
                'Hơn 500 sự kiện thể thao mỗi tuần trong Sportsbook , đa dạng các môn chơi bài trong Sòng Bài và các trò chơi độc quyền trong Các Trò Chơi, V-Sport là Một Nhà Cái Tổng Hợp cung cấp đầy đủ các sản phẩm các cược. Hãy đặt cược vào đội, môn thể thao bạn thích nhất với chúng tôi.',
            },
            {
              title: 'Truy Cập Mọi Lúc Mọi Nơi',
              desc:
                'Với V-Sport WAP và dịch vụ iPhone, bạn có thể cá cược ngay khi bạn đang ngồi thưởng thức trận đấu tại sân vận động',
            },
            {
              title: 'Phương Thức Thanh Tóan Đơn Giản',
              desc:
                'Với nhiều nhà cung cấp giải pháp thanh toán khác nhau như Skrill (Moneybookers) và NETELLER, thanh tóan chưa bao giờ đơn giản hơn thế. <br/>Tìm hiểu thêm về phương thức thanh toán bạn có thể sử dụng để gởi tiền vào tài khỏan V-Sport của bạn: Tôi cần phải sử dụng cái gì để gởi tiền vào tài khoản của tôi?',
            },
            {
              title: 'Nhiều Công Cụ Tìm Kiếm',
              desc:
                'V-Sport hỗ trợ các phương tiện tìm kiếm phổ biến như Microsoft Internet Explorer, Mozilla FireFox, Apple Safari và Opera. Vì vậy bạn không cần phải thay đổi công cụ tìm kiếm mà bạn yêu thích.',
            },
          ],
        },
        {
          langId: '4', //english
          item: [
          {
              title: 'Bei Bora',
              desc:
                'V-Sport inatoa uwezekano wa bei ya ushindani zaidi kati ya vitabu vikuu vya michezo duniani kote.',
            },
            {
              title: 'Malipo ya Haraka Zaidi',
              desc:
                'Malipo yametekelezwa ndani ya saa 24 baada ya kupokea ombi lako la kutoa pesa.',
            },
            {
              title: 'Chaguzi pana Zaidi za Matukio',
              desc:
                'Kutoka kwa zaidi ya matukio 500 ya michezo kila wiki katika Kitabu cha Michezo, aina mbalimbali za michezo ya kasino katika Kasino, aina mbalimbali za matukio ya kimataifa ya mbio za farasi katika Kitabu cha Mbio na michezo ya kipekee katika Michezo, V-Sport ni Duka la 1-Stop linalotoa seti kamili ya bidhaa za michezo ya kubahatisha. Dau kwenye mchezo, timu au uteuzi unaoupenda zaidi nasi.',
            },
            {
              title: 'Fikia Wakati Wowote Mahali Popote',
              desc:
                'Kwa huduma ya V-Sport WAP na iPhone, unaweza kuweka dau ukiwa umeketi katika starehe ya uwanja huku ukifurahia mchezo.',
            },
            {
              title: 'Njia Rahisi za Malipo',
              desc:
                'Kupitia maingiliano yetu na watoa huduma mbalimbali za malipo kama vile Moneybookers na NETELLER, utatuzi wa akaunti haujawahi kuwa rahisi.',
            },
            {
              title: 'Upatanifu wa Kivinjari Kina',
              desc:
                'V-Sport inasaidia safu nyingi za vivinjari maarufu kama Microsoft Internet Explorer, Mozilla Firefox, Apple Safari na Opera. Hakuna haja ya kubadilisha kivinjari chako unachokipenda kwa sababu yetu.'
            },
          ],
        },
      ],
      dataList: null,
    };
  },
  methods: {
    setDataList() {
      const dataList = this.fullList.find(
        (x) => x.langId.toString() == this.$store.getters.langId.toString()
      );
      if (dataList == undefined) {
        this.dataList = this.fullList[0].item;
      } else {
        this.dataList = dataList.item;
      }
    },
  },
  computed: {
    langId() {
      return this.$store.getters.langId;
    },
  },
  watch: {
    // eslint-disable-next-line
    langId(newCount, oldCount) {
      this.setDataList();
    },
  },
};
</script>

<style scoped>
.card {
  border: 0px;
  position: relative;
}
.card-body {
  padding: 1em 2em;
}
.left-card {
  border-radius: 5px;
  /* box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important; */
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #fff;
}

.card-text {
  font-size: 12px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  color: #36342f;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.1rem;
  opacity: 0.85;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
  color: #fff;
}

.icn-round {
  font-weight: bold;
  text-align: left;
  color: #16c60c;
  white-space: nowrap;
}

.title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-align: left;
  color: #e9a0ff;
  font-weight: 600;
}
.desc {
  letter-spacing: 0.18px;
  text-align: left;
}
</style>
