<template>
  <div class="body-wrap">
    <div class="container1">
      <div class="main-img"></div>
      <div class="centered">{{ $t('WHYCHOOSEUS') }}</div>
    </div>

    <!-- Small Thumbnail -->
    <div class="content-wrapper" v-if="langId == '0'">
      <dl class="faq">
        <dt>Best Prices</dt>
        <dd>
          VSPORT offers the most competitively priced odds among the major
          sportsbooks worldwide.
        </dd>
        <dt>Fastest Payout</dt>
        <dd>
          Payment effected within 24 hours of receipt of your funds withdrawal
          request.
        </dd>
        <dt>Widest Selections Of Events</dt>
        <dd>
          From over 500 sporting events every week in Sportsbook, an extensive
          range of casino games in Casino, a wide variety of international horse
          racing events in Racebook and exclusive games in Games, VSPORT is a
          1-Stop Shop offering a complete suite of gaming products. Bet on your
          favorite sport, team or selection with us.
        </dd>
        <dt>Access Anytime Anywhere</dt>
        <dd>
          With VSPORT WAP and iPhone service, you can bet whilst sitting in the
          comfort of the stadium while enjoying the game.
        </dd>
        <dt>Easy Payment Methods</dt>
        <dd>
          Through our tie-ups with various payment solution providers such as
          Moneybookers and NETELLER, settlement of accounts has never been
          easier.
        </dd>
        <dt>Wide Browser Compatibility</dt>
        <dd>
          VSPORT supports a wide array of popular browsers like Microsoft
          Internet Explorer, Mozilla Firefox, Apple Safari and Opera. There is
          no need to change your favorite browser because of us.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '1'">
      <dl class="faq">
        <dt>Best Prices</dt>
        <dd>
          VSPORT offers the most competitively priced odds among the major
          sportsbooks worldwide.
        </dd>
        <dt>Fastest Payout</dt>
        <dd>
          Payment effected within 24 hours of receipt of your funds withdrawal
          request.
        </dd>
        <dt>Widest Selections Of Events</dt>
        <dd>
          From over 500 sporting events every week in Sportsbook, an extensive
          range of casino games in Casino, a wide variety of international horse
          racing events in Racebook and exclusive games in Games, VSPORT is a
          1-Stop Shop offering a complete suite of gaming products. Bet on your
          favorite sport, team or selection with us.
        </dd>
        <dt>Access Anytime Anywhere</dt>
        <dd>
          With VSPORT WAP and iPhone service, you can bet whilst sitting in the
          comfort of the stadium while enjoying the game.
        </dd>
        <dt>Easy Payment Methods</dt>
        <dd>
          Through our tie-ups with various payment solution providers such as
          Moneybookers and NETELLER, settlement of accounts has never been
          easier.
        </dd>
        <dt>Wide Browser Compatibility</dt>
        <dd>
          VSPORT supports a wide array of popular browsers like Microsoft
          Internet Explorer, Mozilla Firefox, Apple Safari and Opera. There is
          no need to change your favorite browser because of us.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '2'">
      <dl class="faq">
        <dt>ราคาดีที่สุด</dt>
        <dd>
          VSPORT นำเสนอราคาออดซ์ (ราคาต่อรอง)
          ที่ดีที่สุดในกลุ่มผู้นำสปอร์ตบุ๊คทั่วโลก
        </dd>

        <dt>จ่ายเงินเร็วที่สุด</dt>
        <dd>
          การจ่ายเงินจะใช้เวลาภายใน 24
          ชั่วโมงหลังจากได้รับคำร้องขอถอนเงินจากท่าน
          อ่านคู่มือและเรียนรู้วิธีการส่งคำร้องขอถอนเงิน:
          เมื่อต้องการถอนเงินจากบัญชีควรทำอย่างไร?
        </dd>

        <dt>มีเกมส์การแข่งขันให้เลือกหลากหลายที่สุด</dt>
        <dd>
          VSPORT เป็นศูนย์รับวางพนันที่มีผลิตภัณฑ์แบบครบวงจรที่เดียวเบ็ดเสร็จ
          เพราะเราให้บริการเกมส์การแข่งขันกีฬามากกว่า 500
          เกมส์ทุกสัปดาห์ในสปอร์ตบุ๊ค นอกจากนี้ยังมีเกมส์คาสิโน,
          รายการม้าแข่งนานาชาติในเรซบุ๊ค และเกมส์อื่นๆอีกมากมาย
          เริ่มต้นวางพนันกีฬา ทีมหรือเกมส์โปรดของท่านกับเราได้แล้ววันนี้<br />

          เรียนรู้เพิ่มเติมเกี่ยวกับ VSPORT สปอร์ตบุ๊ค และVSPORT Games
        </dd>

        <dt>เข้าระบบได้ทุกที่ทุกเวลา</dt>
        <dd>
          ด้วยบริการ VSPORT ผ่าน WAP (เว็บไซต์บนโทรศัพท์มือถือ)
          และบริการเว็บไซต์ VSPORT ผ่านโทรศัพท์มือถือ ท่านสามารถวางพนันได้
          แม้ขณะที่กำลังดูการแข่งขันอยู่ในสนามกีฬา เรียนรู้เพิ่มเติมเกี่ยวกับ
          บริการ VSPORT ผ่าน WAP มือถือ และ บริการเว็บไซต์ VSPORT ผ่านมือถือ
        </dd>

        <dt>วิธีการจ่ายเงินอย่างง่ายๆ</dt>
        <dd>
          ด้วยระบบการชำระเงินที่ผูกกับผู้ให้บริการในการจ่ายเงินชั้นนำอย่าง
          Skrill (Moneybookers) และ NETELLER
          การลงทะเบียนเริ่มต้นใช้บริการเป็นไปได้อย่างง่ายดาย
        </dd>

        <dt>ใช้ได้กับหลายบราวเซอร์</dt>
        <dd>
          VSPORT สามารถใช้ได้กับบราวเซอร์ที่มีชื่อเสียงต่างๆ อาทิเช่น Microsoft
          Internet Explorer, Mozilla Firefox, Apple Safari และ Opera
          ท่านไม่จำเป็นต้องเปลี่ยนบราวเซอร์เมื่อใช้ VSPORT<br />

          เรียนรู้เว็บเบราว์เซอร์ที่ VSPORT รองรับ:
          ฉันจะใช้เว็บเบราว์เซอร์ที่มีอยู่เข้าเว็บไซต์ VSPORT ได้หรือไม่?
        </dd>
      </dl>
    </div>

    <div class="content-wrapper" v-if="langId == '3'">
      <dl class="faq">
        <dt>Giá Tốt Nhất</dt>
        <dd>
          VSPORT cung cấp tỷ lệ cược giá cạnh tranh nhất trong các nhà cái thể
          thao trên thế giới.
        </dd>

        <dt>Thanh Toán Nhanh Nhất</dt>
        <dd>
          Thanh toán có hiệu lực trong vòng 24giờ sau khi nhận yêu cầu rút tiền
          của bạn. Hãy đọc tài liệu hướng dẫn để hiểu làm sao bạn có thể gởi yêu
          cầu rút tiền: Làm sao tôi rút tiền từ tài khoản của tôi?Thanh toán có
          hiệu lực trong vòng 24giờ sau khi nhận yêu cầu rút tiền của bạn
        </dd>

        <dt>Nhiều Sự Kiện Phong Phú Nhất</dt>
        <dd>
          Hơn 500 sự kiện thể thao mỗi tuần trong Sportsbook , đa dạng các môn
          chơi bài trong Sòng Bài và các trò chơi độc quyền trong Các Trò Chơi,
          VSPORT là Một Nhà Cái Tổng Hợp cung cấp đầy đủ các sản phẩm các cược.
          Hãy đặt cược vào đội, môn thể thao bạn thích nhất với chúng tôi.
        </dd>

        <dt>Truy Cập Mọi Lúc Mọi Nơi</dt>
        <dd>
          Với VSPORT WAP và dịch vụ iPhone, bạn có thể cá cược ngay khi bạn đang
          ngồi thưởng thức trận đấu tại sân vận động.
        </dd>

        <dt>Phương Thức Thanh Tóan Đơn Giản</dt>
        <dd>
          Với nhiều nhà cung cấp giải pháp thanh toán khác nhau như Skrill
          (Moneybookers) và NETELLER, thanh tóan chưa bao giờ đơn giản hơn thế.

          <br />Tìm hiểu thêm về phương thức thanh toán bạn có thể sử dụng để
          gởi tiền vào tài khỏan VSPORT của bạn: Tôi cần phải sử dụng cái gì để
          gởi tiền vào tài khoản của tôi?
        </dd>

        <dt>Nhiều Công Cụ Tìm Kiếm</dt>
        <dd>
          VSPORT hỗ trợ các phương tiện tìm kiếm phổ biến như Microsoft Internet
          Explorer, Mozilla FireFox, Apple Safari và Opera. Vì vậy bạn không cần
          phải thay đổi công cụ tìm kiếm mà bạn yêu thích.
        </dd>
      </dl>
    </div>
    <div class="content-wrapper" v-if="langId == '4'">
      <dl class="faq">
        <dt>Bei Bora</dt>
         <dd>
           VSPORT inatoa uwezekano wa bei ya ushindani zaidi kati ya kuu
           vitabu vya michezo duniani kote.
         </dd>
         <dt>Malipo ya Haraka Zaidi</dt>
         <dd>
           Malipo yametekelezwa ndani ya saa 24 baada ya kupokea uondoaji wako wa pesa
           ombi.
         </dd>
         <dt>Chaguzi pana Zaidi za Matukio</dt>
         <dd>
           Kutoka zaidi ya matukio 500 ya michezo kila wiki katika Sportsbook, pana
           anuwai ya michezo ya kasino huko Kasino, aina nyingi za farasi wa kimataifa
           matukio ya mbio katika Kitabu cha mbio na michezo ya kipekee katika Michezo, VSPORT ni
           1-Stop Shop inayotoa safu kamili ya bidhaa za michezo ya kubahatisha. Bet kwenye yako
           mchezo unaopenda, timu au uteuzi na sisi.
         </dd>
         <dt>Fikia Wakati Wowote Mahali Popote</dt>
         <dd>
           Ukiwa na huduma ya VSPORT WAP na iPhone, unaweza kuweka dau ukiwa umeketi
           faraja ya uwanja wakati wakifurahia mchezo.
         </dd>
         <dt>Njia Rahisi za Malipo</dt>
         <dd>
           Kupitia mahusiano yetu na watoa huduma mbalimbali za malipo kama vile
           Moneybookers na NETELLER, utatuzi wa akaunti haujawahi
           rahisi zaidi.
         </dd>
         <dt>Upatanifu wa Kivinjari Kina</dt>
         <dd>
           VSPORT inasaidia safu nyingi za vivinjari maarufu kama Microsoft
           Internet Explorer, Mozilla Firefox, Apple Safari na Opera. Kuna
           hakuna haja ya kubadilisha kivinjari chako unachopenda kwa sababu yetu.
         </dd>
      </dl>
    </div>
  </div>
</template>

<script>
//Vue export
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  font-family: 'Montserrat';
  /* height: 823px; */
}

* {
  padding: 0px;
  margin: 0px;
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: #907339;
  margin-top: 0px;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/why.jpg');
  background-size: cover;
  background-position: center;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  margin: 0 auto;
  font-size: 15px;
  color: white;
  max-width: 1200px;
  text-align: justify;
  padding: 30px;
  border-top: 1px solid #cbd8e7;
  border-bottom: 1px solid #cbd8e7;
  overflow-y: auto;
  max-height: 600px;
}

.faq {
  counter-reset: my-badass-counter;
}

.faq dt {
  color: white;
  position: relative;
  font-size: 18px;
  padding: 4px 0 5px 0;
  text-align: left;
}

.faq dt:before {
  color: white;
  content: counter(my-badass-counter);
  counter-increment: my-badass-counter;
  position: absolute;
  left: 0;
  top: 0;
  font: bold 50px/1 Sans-Serif;
}

.faq dd {
  margin: 0 0 30px 0;
}

.faq dt,
.faq dd {
  padding-left: 55px;
}
</style>
