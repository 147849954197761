<template>
  <div class="body-wrap">
    <form
      method="post"
      action="./BettingRulesWLSmart.aspx?lang=EN-US"
      id="form1"
    >
      <div class="aspNetHidden">
        <input type="hidden" name="__VIEWSTATE" id="__VIEWSTATE" value="/" />
      </div>

      <div class="aspNetHidden">
        <input
          type="hidden"
          name="__VIEWSTATEGENERATOR"
          id="__VIEWSTATEGENERATOR"
          value="8D8B8727"
        />
      </div>
      <div class="container1">
        <div class="main-img"></div>
        <div class="centered">{{ $t('BettingRules') }}</div>
      </div>

      <!-- Small Thumbnail -->
      <div class="content-wrapper">
        <EnRules v-if="langId == '0' || langId == '4'" />
        <CnRules v-if="langId == '1'" />
        <ThRules v-if="langId == '2'" />
        <VnRules v-if="langId == '3'" />
      </div>
    </form>
  </div>
</template>

<script>
import EnRules from './Rules/EnRules.vue';
import ThRules from './Rules/ThRules.vue';
import VnRules from './Rules/VnRules.vue';
import CnRules from './Rules/CnRules.vue';
//Vue export
export default {
  components: { EnRules, ThRules, VnRules, CnRules },
  data() {
    return {};
  },
  methods: {},
  computed: {
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped>
.body-wrap {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  width: 100%;
  font-family: 'Montserrat';
  /* height: 823px; */
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin-top: 0px;
}

h2 {
  font-weight: 200;
  color: white !important;
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-weight: 200;
  font-size: 14px;
  font-weight: mormal;
}

h4 {
  font-weight: 200;
  font-size: 14px;
  font-weight: mormal;
}

h5 {
  font-weight: 200;
  font-size: 14px;
  font-weight: mormal;
}

.button span {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  background-color: white;
  border-radius: 5px;
  line-height: 20px;
  width: 105px;
  height: 20px;
  display: block;
  text-align: center;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}

.button:hover span {
  color: #000;
}

.container1 {
  text-align: center;
  max-width: 1200px;
  height: 200px;
  position: relative;
  text-align: center;
  color: white;
  margin: 0 auto;
  background-color: #1c3452;
  padding: 0px;
}

.centered {
  font-size: 2em;
  text-shadow: 2px 2px #000;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  color: white;
  font-size: 15px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: justify;
  padding: 10px;
  border-top: 1px solid #2c1901;
  border-bottom: 1px solid #2c1901;
  overflow-y: auto;
  max-height: 600px;
}

.main-img {
  max-width: 1200px;
  height: 200px;
  background-image: url('../../../../assets/images/rules.jpg');
  background-size: cover;
  background-position: center;
}
b,
strong {
  font-weight: 700 !important;
}
</style>
