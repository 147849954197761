<template>
  <div id="home-body">
    <div class="glow-backdrop"></div>
    <div class="row mx-0 pt-5">
      <desktop-header></desktop-header>
      <!--- page content --->
      <div class="col-12 px-0">
        <div class="container clear-both px-0 container-min-height">
          <div class="row px-0">
            <!--- page header --->
            <div class="col-3 d-none d-lg-block">
              <img class="message-back" src="../../../../assets/images/img-lamp.png"/>
            </div>
            <!--- end page header --->
            <!----card section --->
            <div class="col-lg-9 col-md-12 mt-4 right-card-container">
              <div class="right-card-container2">
              <div class="right-card">
                <h2 class="neon text-uppercase">{{ $t('Message') }}</h2>
                <!-----page content ------>
                <template v-if="showProgress">
                  <div class="col-lg-12 p-3">
                    <div class="card left-card py-5">
                      <progress-spinner></progress-spinner>
                    </div>
                  </div>
                </template>

                <template v-if="!showProgress">
                  <div
                    class="col-12 px-0"
                    v-for="(item, index) in messageList"
                    :key="item.date"
                  >
                    <div class="d-flex align-items-center">
                      <div
                        class="date-title float-left d-flex align-items-center"
                      >
                        <!-- <span v-if="langId != '0'">
                          {{ $t(item.dayName) }} &nbsp;
                        </span> -->
                        {{ item.displayDate }}

                        ({{ $t(item.dayName) }})
                      </div>
                    </div>
                    <!-- nested item ---->

                    <div
                      v-for="msg in messageList[index].list"
                      :key="msg.date"
                    >
                      <div class="msg-card p-3">
                        <div class="row">
                          <div class="col-flex msg-text pl-0">
                            {{ getTimebyDate(msg.date) }}
                          </div>
                          <div
                            class="col msg-text"
                            v-html="msg.message"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="!showProgress && messageList.length == 0">
                  <div class="d-flex align-items-center">
                      {{ $t('No message yet.') }}
                  </div>
                </template>
                <!---end page content ---->
              </div>
              </div>
            </div>

            <!---end card section -->
          </div>
        </div>
      </div>

      <!----end page comtent --->
      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>

    <!--globa  element component  --->
    <loading-spinner v-if="isLoading"></loading-spinner>
    <!-- <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition> -->
  </div>
</template>

<script>
import moment from 'moment';
// import CustomHttp from '../../../CustomHttp';
export default {
  components: {},
  created() {
    this.getSystemMessage();
  },
  data() {
    return {
      showProgress: false,
      isLoading: false,
      messageList: [],
    };
  },
  methods: {
    getTimebyDate(date) {
      const tempDate = new Date(date);
      return moment(tempDate.getTime())
        .format('hh:mm A')
        .toString();
    },
    async getSystemMessage() {
      this.showProgress = true;
      await this.$store.dispatch('getSystemMessage');
      let systemMsg = [...this.$store.getters.systemMessageList];
      if (systemMsg.length == 0) {
        // no record
        this.messageList = [];
        this.showProgress = false;
        return;
      }
      systemMsg = systemMsg.map((item) => {
        return {
          date: new Date(item.date),
          message: item.msg,
        };
      });

      //dummy item for testing
      const today = new Date();
      //   systemMsg.push({
      //     date: new Date(),
      //     message: 'today news',
      //   });
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      //   systemMsg.push({
      //     date: yesterday,
      //     message: 'yesterday news',
      //   });
      //end push dummy item section

      // generate unique date array
      let uniqueDate = systemMsg.filter(
        (date, i, self) =>
          self.findIndex(
            (d) => d.date.toDateString() === date.date.toDateString()
          ) === i
      );
      // date sorting
      uniqueDate.sort((a, b) => new Date(b.date) - new Date(a.date));
      //process messageList to the format ease to loop for display
      let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      this.messageList = uniqueDate.map((item) => {
        const tempDate = new Date(item.date);
        return {
          date: tempDate,
          dayName: weekday[tempDate.getDay()],
          list: [],
        };
      });
      // add item get from API to the processed array
      uniqueDate.forEach((uItem, uIndex) => {
        systemMsg.forEach((sItem, sIndex) => {
          if (sItem.date.toDateString() == uItem.date.toDateString()) {
            this.messageList[uIndex].list.push(systemMsg[sIndex]);
          } //end if
        }); //end foreach
      }); //end foreach

      // make today date become today string
      this.messageList.forEach((item, index) => {
        if (moment(item.date).isSameOrAfter(tomorrow, 'day')) {
          this.messageList[index].displayDate = 'Upcoming';
        } else if (moment(item.date).isSameOrAfter(today, 'day')) {
          this.messageList[index].displayDate = 'Today';
        } else if (moment(item.date).isSameOrAfter(yesterday, 'day')) {
          this.messageList[index].displayDate = 'Yesterday';
        } else {
          this.messageList[index].displayDate = moment(
            item.date.getTime()
          ).format('DD-MM-YYYY');
        }
      }); //end foreach
      this.showProgress = false;
    },
  },
  computed: {
    systemMessageList() {
      return this.$store.getters.systemMessageList;
    },
    systemMessage() {
      let message;
      message = this.$store.getters.systemMessage;
      return message.replace(/(<([^>]+)>)/gi, '');
      // return message;
    },
    langId() {
      return this.$store.getters.langId.toString();
    },
  },
  watch: {
    // eslint-disable-next-line
    systemMessage(newCount, oldCount) {
      // Our fancy notification (2).
      if(newCount.replace(/[\n\r\s\t]+/g, '') != oldCount.replace(/[\n\r\s\t]+/g, ''))
      {
       this.isLoading = true;
       window.location.reload();
      }
    },
  },
};
</script>

<style scoped>
  .message-back{
    height: 500px;
    position: absolute;
    left: -5vw;
  }
.bg-light-grey {
  background-color: #f7f7f7;
}

.bg-light-gold-gradient {
  border-radius: 5px;
  background: linear-gradient(to left, rgba(241, 215, 152, 0), #f5b50a);
}

.menu-text {
  margin-top: 4px;
  font-size: 11px;
  white-space: nowrap;
}

.account-text {
  font-weight: 600;
  opacity: 0.9;
  color: #36342f;
  padding-top: 3px;
  padding-bottom: 3px;
  /* font-family: SegoeUI; */
  font-size: 16px;
}
.account-btn {
  text-align: center;
  flex-wrap: wrap;
  padding: 7px 10px;
}

.left-card {
  border-radius: 5px;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background: transparent;
}

.img-avatar {
  object-fit: contain;
  width: 60px;
}

.img-separator {
  object-fit: contain;
  width: 100%;
}
.username-text {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.21px;
  text-align: center;
  color: #f5b50a;
}

.currency-text {
  font-size: 12px;
  text-align: center;
  color: #a1a1a1;
}

.credit-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.account-tab-active {
  background-image: linear-gradient(to bottom, #36342f, #5c4812);
}

.account-tab {
  background-color: #fff;
}

/* .account-tab-active:hover,
.account-tab:hover {
  opacity: 1;
} */

.tab-label-active {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.tab-label {
  color: #908d84;
  font-size: 14px;
  font-weight: 600;
}

.img-tab {
  width: 22px;
  height: 22px;
}

.tab-border {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.date-title {
  width: 100%;
  height: 32px;
  font-size: 16px;
  letter-spacing: 0.18px;
  text-align: left;
  color: #e9a0ff;

}


.bg-light {
  background-color: rgb(248, 248, 248) !important;
}

.msg-text {
  letter-spacing: 0.18px;
  text-align: left;
  color: white;
  min-width: 80px;
}

.msg-card {
  padding: 1px 0.7px 14.5px 0.8px;
  background: transparent;
}
#home-body{
  background: url('../../../../assets/images/desktop-bg.jpg') 0 0 / cover;
}
.right-card-container{
  padding: 7px;
  border-radius: 35px;
  border: solid 2px #6fecff;
}
.right-card-container2{
  border-radius: 25px;
  border: solid 2px #6fecff;
  }
.right-card {
  border-radius: spx;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: transparent;
  padding: 25px 30px;
  min-height: 440px;
  overflow-y: auto;
  max-height: 500px;
  margin-right: 20px;
} 
.right-card::-webkit-scrollbar {
  width: 8px;
}
.right-card::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 0px;
  background: blue;
  background: linear-gradient(#4a4a4a, #4a4a4a) no-repeat center/2px 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.right-card::-webkit-scrollbar-thumb {
  width: 3px;
  height: 77px;
  border-radius: 10px;
  background-color: #4a4a4a;
}
.right-card::-webkit-scrollbar-thumb:window-inactive {
  width: 3px;
  height: 77px;
  border-radius: 10px;
  background-color: #4a4a4a;
}
.container-min-height{
  min-height: 85vh;
}
</style>
