<template>
  <span
    class="preload-img-span"
    v-for="p in preloadArr"
    :key="p.src"
    :style="'background:url(' + p.src + ') no-repeat; background-size: 0;'"
  >
  </span>
  <div id="home-body">
    <div class="row mx-0 pt-5" style="overflow: hidden;">
      <desktop-header ref="desktopHeader"></desktop-header>
      <!----promo banner---->
      <!--
      <div class="clear-both col-md-12 px-0">
        <div class="container px-0 py-3">
          <div class="col-12 px-0">
            <div class="row">
              <div
                class="col-md-4 py-1 px-2 banner-hover"
                v-for="banner in banners"
                :key="banner.imgUrl"
              >
                <img
                  class="d-block w-100 content-header-banner cursor-pointer"
                  :src="banner.imgUrl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  -->
      <!-- banner -->
      <div class="container banner-container">
        <img class="banner-glow-back" src="./../../assets/images/img-glow.png"/>
        <img class="banner-back" src="./../../assets/images/banner-back-main.png"/>
        <img class="banner-left" src="./../../assets/images/btn-left.png" data-target="#desktopBanner" data-slide="prev"/>
        <img class="banner-right" src="./../../assets/images/btn-right.png" data-target="#desktopBanner" data-slide="next"/>
        <div
          id="desktopBanner"
          data-interval="3500"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              class="carousel-item"
              v-for="(banner, idx) in desktopBanners"
              :key="idx"
              :class="{ active: idx == 0 }"

            >
              <img
                :src="getLangBanner(banner)"
                alt="banner"
                class="d-block content-header-banner"
                @click="onClickBanner(banner)"
              />
            </div>
          </div>
        </div>
      </div>
      <!----system message ---->
      <div
        class="clear-both mt-5 col-md-12 px-0"
        v-if="isLoggedIn && systemMessage != ''"
      >
        <div class="container">
          <router-link to="/message">
            <div class="announcements col-center">
                <img src="./../../assets/icons/icn-speaker.png" class="img-annoucement" />
                <div class="an-mask">
                    <div class="ml-4 small announcement-scroll">{{ systemMessage }} </div>
                </div>
            </div>
          </router-link>
        </div>
      </div>
      <!----page content--->
      <div class="py-4 col-12 px-0">
        <div class="container clear-both">
          <div class="row px-0">
              <img class="mx-auto" width="582" src="../../assets/images/popular1.png" v-if="langId == 1"/>
              <img class="mx-auto" width="582" src="../../assets/images/popular.png" v-else/>
            <!---Menu card--->
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3 col-md-5 mt-0 mt-lg-3">
                  <div class="game-card-lg menu-hover" @click="goToSportsbook2">
                    <img
                      src="../../assets/images/img-sportsbook-d.png"
                      alt="Game img"
                      class="card-img-lg"
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-md-7 pl-2 pr-0 row padding-tablet">
                    <div
                      class="col-lg-4 col-md-6 mt-0 mt-lg-3 game-card-wrap pl-4 pr-0 menu-hover"
                      v-for="(game, index) in games"
                      :key="game.name"
                      @click="onClickGameIcon(index)"
                    >
                      <div class="game-card">
                        <img
                          class="card-img-md"
                          :src="getLangImg(game)"
                          alt="game img"
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>

            <!----second menu card--->
            <!-- <div class="col-12 mt-4">
              <div class="row">
                <div class="col-12 py-0">
                  <div
                    class="menu-btn d-flex align-items-center col-5 bg-gold-gradient py-0"
                  >
                    <img
                      class="float-left menu-img-lg"
                      src="./../../assets/icons/menu/menu-jackpot.png"
                    />
                    <div class="menu-text pl-1 text-white pt-2">
                      <h6>
                        <strong>GRAND JACKPOT</strong>
                        FOR YOU
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6 mt-3 pr-0">
                  <div class="col-12 pl-0">
                    <div class="game-card menu-hover">
                      <img
                        class="card-img-mini"
                        :src="habaGame.img"
                        alt="game img"
                      />
                      <div class="game-footer game-footer-card col-12 py-3">
                        <div class="row">
                          <div class="col-10 pl-4">
                            <div class="row">
                              <div class="float-left text-dark pt-1 col-12">
                                <strong> {{ habaGame.name }}</strong>
                              </div>
                              <div class="float-left text-dark pt-1 col-12">
                                {{ habaGame.name2 }}
                              </div>
                            </div>
                          </div>

                          <div class="col-2 pt-2">
                            <img
                              class="icon-play cursor-pointer"
                              src="../../assets/icons/icn-gold-play.png"
                              alt="play icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-lg-9 col-sm-6 px-0">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-lg-2 col-md-4 mt-3 game-card-wrap pl-2 pr-1 menu-hover"
                        v-for="game in miniGames"
                        :key="game.name"
                      >
                        <div class="game-card mt-1">
                          <img
                            class="card-img-sm"
                            :src="game.img"
                            alt="game img"
                          />
                          <div class="game-footer game-footer-mini col-12">
                            <span class="float-left text-dark pt-1">
                              <strong>{{ game.name }}</strong>
                            </span>
                            <span class="float-right">
                              <img
                                class="icon-play-outline cursor-pointer"
                                src="../../assets/icons/icn-play-outline.png"
                                alt="play icon"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!--Bottom card-->

            <section class="container main-promo-container">
              <img class="main-back" src="../../assets/images/main-border.png"/>
              <img class="main-top-header" src="../../assets/images/main-top-header1.png" v-if="langId == 1"/>
              <img class="main-top-header" src="../../assets/images/main-top-header.png" v-else/>

              <div class="full-center mt-5" style="align-items: start;">
                <div>
                  <div class="main-promogame-container">
                    <img class="main-promogame" src="../../assets/images/main-casino.png" />
                  </div>
                  <h2 class="main-label">{{$t('main_livecasino')}}</h2>
                  <p class="main-text">{{$t('main_livecasino_text')}}</p>
                  <img @click="bannerRoute('/live-casino')" class="main-playnow" v-if="langId == 1" src="../../assets/images/main-playnow1.png" />
                  <img @click="bannerRoute('/live-casino')" class="main-playnow" v-else src="../../assets/images/main-playnow.png" />
                </div>
                <div>
                  <div class="main-promogame-container">
                    <img class="main-promogame" src="../../assets/images/main-slots.png" />
                  </div>
                  <h2 class="main-label">{{$t('main_slots')}}</h2>
                  <p class="main-text">{{$t('main_slots_text')}}</p>
                  <img @click="bannerRoute('/slot')" class="main-playnow" v-if="langId == 1" src="../../assets/images/main-playnow1.png" />
                  <img @click="bannerRoute('/slot')" class="main-playnow" v-else src="../../assets/images/main-playnow.png" />
                </div>
                <div>
                  <div class="main-promogame-container">
                    <img class="main-promogame" src="../../assets/images/main-fishing.png" />
                  </div>
                  <h2 class="main-label">{{$t('main_fishing')}}</h2>
                  <p class="main-text">{{$t('main_fishing_text')}}</p>
                  <img @click="bannerRoute('/fishing')" class="main-playnow" v-if="langId == 1" src="../../assets/images/main-playnow1.png" />
                  <img @click="bannerRoute('/fishing')" class="main-playnow" v-else src="../../assets/images/main-playnow.png" />
                </div>
              </div>
            </section>

            <!-- 

             <div class="col-lg-12 mb-3 mt-3">
              <div class="row">
                <template v-for="(game, index) in gamesBanner" :key="game.name">
                  <div class="col-lg-4 col-md-6 mt-5 tablet-card">
                    <div
                      class="catalog-card menu-hover"
                      @click="onClickGameBanner(index)"
                    >
                      <img
                        class="catalog-img"
                        :src="game.imgUrl"
                        alt="Card image"
                      />
                      <div class="card-img-overlay">
                        <div
                          class="col-12 pb-1 game-lg-title font-weight-700"
                          :class="game.textClass"
                        >
                          {{ $t(game.title) }}
                        </div>
                        <div class="col-12 pb-1 pt-1">
                          <p class="text-white pb-0 mb-0 game-desc">
                            {{ $t(game.desc) }}
                          </p>
                          <span
                            class="text-light-grey game-desc2"
                            v-html="$t(game.desc2)"
                          >
                          </span>
                        </div>
                        <div class="col-12">
                          <button
                            class="btn btn-game-lg text-white"
                            :class="game.btnClass"
                          >
                            <strong>
                              <small> {{ $t('PLAYNOW') }}</small>
                            </strong>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            -->

          </div>
        </div>
      </div>

      <!-- Footer-->
      <desktop-footer></desktop-footer>
    </div>

    <!--- login modal--->
    <login-modal
      ref="loginModal"
      :errMessage="loginPanelErr"
      @toggle-danger="toggleDangerAlert"
      @set-err-messsage="setErrMessage"
    ></login-modal>
    <!---game  modal --->
    <game-modal ref="gameModal"></game-modal>
    <!---end game  modal --->

    <loading-spinner v-if="isLoading"></loading-spinner>
    <transition name="fadeIn">
      <div v-if="showSuccessAlert">
        <success-alert :message="successMessage"></success-alert>
      </div>
    </transition>
    <transition name="fadeIn">
      <div v-if="showErrorAlert">
        <danger-alert :message="errorMessage"></danger-alert>
      </div>
    </transition>
  </div>
</template>

<script>
// import i18n from "./../../../i18n";
// const { t } = i18n.global;
import desktopImport from '../../assets/script/desktop.js';
import desktopB from '../../assets/script/desktopBanner.js';
import CustomHttp from '../../CustomHttp';
import hotGameMenu from '../../assets/script/catalog/hot';

// import bannerImport from "../../assets/script/gameBanner";
// import desktopPreload from "../../assets/script/desktopPreload";
import loginBackgroundSrc from '../../assets/images/icn-login-panel.png';
// import $ from 'jquery';

// import arr from '../../assets/icons/arrow-down.png';
//Vue export
export default {
  beforeMount() {
    //banner of game page
    this.preloadArr = [];
    // this.preloadArr = [...bannerImport.banners];

    this.preloadArr.push({ desc: '', src: loginBackgroundSrc });

    this.preloadArr = [
      ...this.preloadArr,
      // ...desktopPreload.img.items,
      // ...desktopPreload.img2.items,
    ];

    //this.preloadArr = bannerImport.banners;
  },
  async mounted() {
    this.isLoading = false;
    this.onLoadValidate();
    setTimeout(()=>{
      eval("$('#desktopBanner').carousel({interval: 3500});");
    },400);
    setTimeout(()=>{
      //fixing weird carousel issue with safari
      eval(`
        $('#desktopBanner').carousel(0);
        $('.carousel-dot').removeClass('active');
        $('.carousel-dot:first').addClass('active');
        `);
    },500)
    console.log('LG:' + this.$store.getters.isLoggedIn);
    if (this.$store.getters.isLoggedIn) {
      //add livechat after loading
      const lang =[
        'en-us',
        'zh-cn',
        'th',
        'en-us',
      ];
      const locale = lang[this.$store.getters.langId];
      const accid = this.$store.getters.username;

      let restoreId = "";
      console.log('Initializing LC');
      if(sessionStorage.getItem("restoreId") != null && sessionStorage.getItem("restoreId")){
        restoreId = sessionStorage.getItem("restoreId");
        console.log('LC:' + restoreId);
      }else{
        const startFC = async()=>{
          let response_res = await CustomHttp.post("api/v1/member/get-live-chat-restoreid", { username: accid })
          if(response_res.data.data == null){
            console.log('No LCuser');
          }else{
            if(response_res.data.data.restoreId.length > accid.length){
              sessionStorage.setItem("restoreId", response_res.data.data.restoreId);
              restoreId = response_res.data.data.restoreId;
              console.log('LCuser' + restoreId);
            }else{
              console.log('LCuser resid incorrect:' + restoreId)
            }
          }
        }
        startFC();
      }

      const initFreshChat = async function initFreshChat() {
        console.log('Init FC')
          await window.fcWidget.init({
          //token: "0c51cce0-de42-4487-8aea-9ccce031c713",
          token: "0c51cce0-de42-4487-8aea-9ccce031c713",
          host: "https://wchat.freshchat.com",
          locale: locale,
          externalId: accid,
          firstName: accid,
          lastName: "",
          restoreId: restoreId,
          config: {
            headerProperty: {
              hideChatButton: false
            }
          }
          });
          
          await window.fcWidget.user.get(function (resp) {
              var status = resp && resp.status;
              if (status !== 200) {
                  window.fcWidget.user.setProperties({
                      firstName: accid,
                      lastName: ""
                  });
                  //when start chatting
                  window.fcWidget.on('user:created', async (resp) => {
                        var status = resp && resp.status,
                            data = resp && resp.data;
                        if (status === 200) {
                            if (data.restoreId) {
                //insert into redis BEGIN
                    let json = { username: accid };
                    let response = await CustomHttp.post("api/v1/member/get-live-chat-restoreid", json);

                    if(response.data.data == null){
                      let json = { username: accid, restoreId : data.restoreId  };
                      let response = await CustomHttp.post("api/v1/member/add-live-chat-restoreid", json);

                      if(response.success == true){
                        sessionStorage.setItem("restoreId", data.data.restoreId);
                      }
                    }else{
                      sessionStorage.setItem("restoreId", data.data.restoreId);
                    }
                //insert into redis END
                            }
                        }
                  });
              }
          });
          }
        const initialize = function initialize(i, t) { 
          console.log('Starting LC');
          var e; i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e)) 
        } 
        const initiateCall = function initiateCall() {
          initialize(document, "Freshchat-js-sdk"); 
        }
        initiateCall();
        
       let el = document.getElementById('fc_frame');
       if(el) el.style.display = 'block';
    }

  },
  data() {
    return {
      inputUsername: '',
      inputPassword: '',
      showLoginModal: false,
      selectedMenuIndex: 0,
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
      loginPanelErr: '',
      isDropdownOpen: false,
      desktopBanners : desktopB.banner,
      banners: [
        { imgUrl: desktopImport.bannerUrl1 },
        { imgUrl: desktopImport.bannerUrl2 },
        { imgUrl: desktopImport.bannerUrl3 },
      ],
      menuIcons: [
        {
          name: 'HOT',
          isActive: false,
          iconUrl: desktopImport.icnHot,
          iconUrlActive: desktopImport.icnHotActive,
        },
        {
          name: 'SPORTSBOOK',
          isActive: false,
          iconUrl: desktopImport.sb,
          iconUrlActive: desktopImport.sbActive,
        },
        {
          name: 'LIVE CASINO',
          isActive: false,
          iconUrl: desktopImport.lc,
          iconUrlActive: desktopImport.lcActive,
        },
        {
          name: 'SLOT',
          isActive: false,
          iconUrl: desktopImport.slot,
          iconUrlActive: desktopImport.slotActive,
        },
        {
          name: 'ITP SLOT',
          isActive: false,
          iconUrl: desktopImport.itp,
          iconUrlActive: desktopImport.itpActive,
        },
        {
          name: 'CARDS',
          isActive: false,
          iconUrl: desktopImport.cards,
          iconUrlActive: desktopImport.cardsActive,
        },
        {
          name: 'FISHING',
          isActive: false,
          iconUrl: desktopImport.fishing,
          iconUrlActive: desktopImport.fishingActive,
        },
        {
          name: 'LOTTO',
          isActive: false,
          iconUrl: desktopImport.lotto,
          iconUrlActive: desktopImport.lottoActive,
        },
        {
          name: 'eSPORT',
          isActive: false,
          iconUrl: desktopImport.esport,
          iconUrlActive: desktopImport.esportActive,
        },
      ],
      sportsbookGame: {
        name: 'Sportsbook',
        img: [
          { langId: 0, src: desktopImport.gameSportsbook },
          {
            langId: 2,
            src: desktopImport.gameSportsbookTh,
          },
        ],
      },
      habaGame: {
        name: 'HABANERO SLOT',
        name2: 'ITP',
        img: desktopImport.miniHabanero,
      },
      gamesBanner: [
        {
          title: 'FISHING',
          desc: 'GRAB BIG BONUS',
          desc2: 'IN THIS INTENSIVE FISH & HUNT!',
          imgUrl: desktopImport.gameLgFishing,
          textClass: 'text-blue',
          btnClass: 'bg-blue-gradient',
          url: '/fishing',
        },
        {
          title: 'LIVECASINO',
          desc: 'ALL YOU NEED',
          desc2: 'BLACK JACK | ROULETTE | POKER',
          imgUrl: desktopImport.gameLgCasino,
          textClass: 'text-red',
          btnClass: 'bg-red-gradient',
          url: '/live-casino',
        },
        {
          title: 'CARDS',
          desc: 'EXCLUSIVE BONUS',
          desc2: 'AWAITING YOU',
          imgUrl: desktopImport.gameLgCard,
          textClass: 'text-orange',
          btnClass: 'bg-orange-gradient',
          url: '/cards',
        },
      ],
      miniGames: [
        {
          name: 'Fa Chai',
          img: desktopImport.miniFachai,
        },
        {
          name: 'PTS SLOT',
          img: desktopImport.miniPtS,
        },
        {
          name: 'SPADE GAMING',
          img: desktopImport.miniSpade,
        },
        {
          name: 'JOKER',
          img: desktopImport.miniJoker,
        },
        {
          name: 'JILI',
          img: desktopImport.miniJili,
        },
        {
          name: 'RED TIGER',
          img: desktopImport.miniRedTiger,
        },
        {
          name: "PLAY'N GO SLOT",
          img: desktopImport.miniPlayAndGo,
        },
        {
          name: 'NEXTSPIN SLOT',
          img: desktopImport.miniNextSpin,
        },
        {
          name: 'KING MAKER',
          img: desktopImport.miniKing,
        },
        {
          name: 'CQ9 SLOT',
          img: desktopImport.miniCQ9,
        },
        {
          name: 'AE GAMING SLOT',
          img: desktopImport.miniAEG,
        },
        {
          name: 'GIOCO PLUS SLOT',
          img: desktopImport.miniGioco,
        },
      ],
      games: hotGameMenu.items,
      preloadArr: null,
    };
  },
  methods: {
    bannerRoute(url){
      this.$router.replace(url)
    },
    onClickGameBanner(i) {
      const bannerUrl = this.gamesBanner[i].url;
      this.$router.replace(bannerUrl);
    },
    async onLoadValidate() {
      const loginUser = this.$route.query.username;
      const loginSession = this.$route.query.sessionId;
      if (!loginUser || !loginSession) {
        return;
      }
      const json = {
        username: loginUser,
        sessionId: loginSession,
      };
      this.isLoading = true;
      try {
        await this.$store.dispatch('userValidate', json);
        this.isLoading = false;
        this.$router.replace(window.location.origin);
      } catch (error) {
        this.isLoading = false;
        this.toggleDangerAlert(error);
        this.$router.replace(window.location.origin);
      }
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    onClickGameIcon(i) {
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.$refs.desktopHeader.onClickGameIcon(i);
      } else {
        const selectedGame = this.games[i];
        this.$store.dispatch('setSelectedGame', selectedGame);

        this.$refs.desktopHeader.toOpenGameModal();

        // this.$refs.gameModal.showModal();
        // this.$refs.gameModal.getGameDetails();
      }
    },
    setErrMessage(errMessage) {
      this.loginPanelErr = errMessage.toString();
    },
    async goToSportsbook2() {
      this.$refs.desktopHeader.goToSportsbook2();
    },
    getLangImg(obj) {
      let imgSrc = obj.img[0].src;
      let tempSrc = obj.img.find((i) => i.langId == this.$store.getters.langId);
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    getLangBanner(obj) {
      let imgSrc = obj.imgUrl[0].src;
      let tempSrc = obj.imgUrl.find(
        (i) => i.langId == this.$store.getters.langId
      );
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
    onClickBanner(obj) {
      
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.$refs.desktopHeader.onClickGameIcon(null);
      } else {
        const selectedGame = obj.game;
        // console.log('selectedGame');
        // console.log(selectedGame);
        if(obj.game.name =='SPORTSBOOK'){
          this.$refs.desktopHeader.goToSportsbook2(true);
        }else{
          this.$store.dispatch('setSelectedGame', selectedGame);

          this.$refs.desktopHeader.toOpenGameModal();
        }
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    systemMessage() {
      let message;
      message = this.$store.getters.systemMessage;
      return message.replace(/(<([^>]+)>)/gi, '');
      // return message;
    },
    langId() {
      return this.$store.getters.langId;
    },
    isTimeout() {
      return this.$store.getters.isTimeout;
    },
  },
  watch: {
    // eslint-disable-next-line
    // langId(newCount, oldCount) {
    // },
    // eslint-disable-next-line
    systemMessage(newCount, oldCount) {
      
      if(newCount.replace(/[\n\r\s\t]+/g, '') != oldCount.replace(/[\n\r\s\t]+/g, ''))
      {
       this.isLoading = true;
       window.location.reload();
      }

      // this.isLoading = true;
      // Our fancy notification (2).
      //window.location.reload();
      // console.log(this.systemMessage);
      // this.$i18n.locale = this.$store.getters.langId.toString();
    },
    /* eslint-disable */
    // isTimeout(newValue, oldValue) {
    //   if (newValue) {
    //     this.toggleDangerAlert(t("Session timed out. Please login again."));
    //     this.logUserOut();
    //   }
    // },
    /* eslint-enable */
  },
};
</script>

<style scoped lang="css" src="../../assets/css/desktop.css"></style>
