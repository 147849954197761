import gameImport from './../game';
import liveCasino from './live.js';
import slot from './slot.js';
import svpower from './../../images/game/fishing/img-vpower-s.png';

var gameMenu = {
  name: 'Hot Games',
  items: [
    // cards.items.find((x) => x.code === '80'),

    liveCasino.items.find((x) => x.code === '39-153'),
    liveCasino.items.find((x) => x.code === '39-101'),
    {
      name: 'VPOWER',
      provider: 'UFA Fishing',
      icon: gameImport.find((x) => x.code.toString() === '72').icon,
      code: '72',
      img: [{ langId: 0, src: svpower }],
      img2: [{ langId: 0, src: svpower }],
    },
    slot.items.find((x) => x.code === '35'),
    slot.items.find((x) => x.name.toUpperCase() === 'PEGASUS'),
    
    slot.items.find((x) => x.code === '72-101'),
  ],
};

export default gameMenu;
