<template>
  <div class="card-body">
    <h1 class="cclip neon">TUNAKUTAMBULISHA V-SPORT</h1>
    <p class="card-text">
      V-Sport ni mojawapo ya chapa zinazoongoza duniani za michezo ya kubahatisha mtandaoni.
    </p>
    <p class="card-text">
      Kitabu cha Michezo cha V-Sport hutoa zaidi ya matukio 500 ya michezo kila wiki, pamoja na habari nyingi za ligi kuu kuu za soka na matukio ya kimataifa ya michezo. V-Sport pia hutoa masasisho ya moja kwa moja ya soka, uthibitisho wa ushindi mara moja, malipo ya haraka, ufikiaji rahisi na uwekaji dau wa haraka mtandaoni unapatikana kila saa.
      <br><br>
      Kasino ya V-Sport ni tovuti ya burudani ya kituo kimoja inayotoa anuwai ya michezo ya kasino na Muuzaji wa Moja kwa Moja wa Wachezaji wengi iliyokadiriwa sana iliyotengenezwa kwa kushirikiana na Mifumo ya Michezo ya Kubahatisha ya Australia ili kukidhi mahitaji ya kila mchezaji.
      <br><br>
      Katika Michezo ya V-Sport, unaweza kuchagua kucheza michezo ya kipekee kama vile kadi za mwanzo, keno, poker na roulette, yote ikiwa na michezo isiyo na kikomo na hakuna upakuaji au usakinishaji unaohitajika.
      <br><br>
      V-Sport hutumia teknolojia za hivi punde kutoa mazingira ya wachezaji wa haraka na salama. Katika V-Sport, tumejitolea kuwapa wateja wetu uzoefu bora wa michezo ya kubahatisha. Pamoja na uteuzi wetu mpana wa bidhaa na huduma, V-Sport inahudumia waweka dau wote wa michezo kuanzia wapiga dau wa kawaida hadi wachezaji wa VIP na Waweka fedha wa Kimataifa.
    </p>
    <p class="card-text">
      Haya yote kwenye anuwai kubwa ya michezo, hafla na soko, katika starehe ya nyumba yako mwenyewe au mahali popote ulimwenguni. Unapoweka kamari ukitumia V-Sport, unaweza kujiamini ukijua kuwa unashughulikia kitabu cha michezo mtandaoni kinachotambulika ambacho usimamizi wake una uzoefu wa miongo mingi katika tasnia ya michezo ya kubahatisha.
      <br />
      Timu yetu ya kirafiki ya Usaidizi wa V-Sport inapatikana kila mara ili kusaidia kwa njia yoyote iwezekanavyo, ikijumuisha maswali ya akaunti, kuweka au kutoa pesa, au usaidizi wa jumla wa kamari.
    </p>

    <p class="card-text">
      Bet na V-Sport, Kitabu cha Michezo unachoweza kuamini.
    </p>
    <br><br><br>
  </div>
</template>

<style scoped>
.card-text {
  font-size: 16px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  letter-spacing: 0.2px;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
}
</style>
