<template>
  <loading-spinner v-if="isLoading"></loading-spinner>
  <transition name="fadeIn">
    <div v-if="showSuccessAlert">
      <success-alert :message="successMessage"></success-alert>
    </div>
  </transition>
  <transition name="fadeIn">
    <div v-if="showErrorAlert">
      <danger-alert :message="errorMessage"></danger-alert>
    </div>
  </transition>
  <!--  Header-->
  <nav
    class="navbar navbar-expand-lg navbar-dark fixed-top d-flex justify-content-center row col-12 gx-4"
    id="mainNav"
  >
    <div class="container px-0">
          <div
            class="col-5"
          >
            <div class="pull-left position-relative">
              <img
                class="header-logo cursor-pointer"
                alt="logo"
                src="../../../assets/images/logo.png"
                @click="onClickMenuIcon(0)"
              />
              <img class="logo-glow" src="../../../assets/images/logo-glow.png"/>
              <!-- "
              <button
                class="btn btn-outline-gold btn-sm ml-3 px-2 py-2"
                @click="goToSportsbook"
                v-if="isLoggedIn"
              > 
                <img
                  src="../../../assets/icons/icn-backold.png"
                  class="img-responsive icn-back-old"
                  alt="back to old"
                  width="14"
                  height="14"
                />
                {{ $t('Back to Old Design') }}
              </button>
              -->
            </div>
          </div>
          <div
            class="col-7 side-header"
          >
              <div class="row" style="justify-content: end;">
                <div class="col-12 px-0 small">
                  <div class="float-right">
                    <span class="mr-3 current-time-text float-left">
                      {{ currentTime }}
                    </span>
                    <div class="cursor-pointer float-left px-0">
                      <!-- <img
                            src="./../../../assets/icons/lang/icn-flag-en.png"
                            width="20"
                          /> -->

                      <!----language section --->
                      <div
                        class="cursor-pointer"
                        id="languageDropdownBtn"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="onClickLanguageDropdown"
                      >
                      <img
                        :src="selectedLanguage.icn"
                        class="img-responsive"
                        alt="Responsive image"
                        width="18"
                      />
                        <span class="lang-text uppercase">
                          {{ selectedLanguage.name }}
                        </span>
                        <span v-show="!isLanguageDropdownOpen">
                          <i class="fas fa-caret-down icon-white"></i>
                        </span>

                        <span v-show="isLanguageDropdownOpen">
                          <i class="fas fa-caret-up icon-white"></i>
                        </span>
                      </div>
                      <div
                        id="language-dropdown"
                        class="dropdown-menu lang-dropdown"
                        aria-labelledby="languageDropdownBtn"
                      >
                        <!----lanuage tab  ---->
                        <template
                          v-for="(lang, index) in languageList"
                          :key="lang.id"
                        >
                          <a
                            class="dropdown-item lang-dropdown-item"
                            href="#"
                            @click="onClickLanguageTab(index)"
                          >
                            <img
                              :src="lang.icn"
                              class="img-responsive"
                              alt="Responsive image"
                              width="18"
                            />
                            <span class="lang-dropdown-text text-uppercase"
                              >{{ lang.name }}
                            </span>

                            <span
                              class="text-success float-right"
                              v-if="selectedLanguageId == lang.id"
                            >
                              <i class="fa fa-check"> </i>
                            </span>
                          </a>
                        </template>
                        <!---- end lanuage tab  ---->
                      </div>
                      <!----endlanguage section --->
                    </div>
                  </div>
                </div>
                <template v-if="isLoggedIn">
                  <!---deposit and withdrawal button section --->
                  <div class="col-12 px-0 col-center small mt-2 justify-content-end">
                      <span
                        class="cursor-pointer mx-2 hover-teal"
                        @click="onClickCashOut"
                        style="white-space: nowrap;"
                      >
                        {{ $t('CashOut') }}
                      </span>
                      &nbsp;
                      <router-link to="/statement">
                        <span class="cursor-pointer ml-1 mr-2 hover-teal">
                          {{ $t('Statement') }}
                        </span>
                      </router-link>
                      <button
                        v-if="!suspend && enablePaymentGateway"
                        class="btn btn-sm btn-success btn-deposit mx-1"
                        @click="onClickDeposit"
                      >
                        <i class="fas fa-plus-circle"></i>
                        <span class="position-top-1 px-1">
                          {{ $t('Btn_Deposit') }}
                        </span>
                      </button>
                      <button
                        v-if="!suspend && enablePaymentGateway"
                        class="btn btn-sm btn-danger btn-withdrawal mr-0 ml-1"
                        @click="onClickWithdrawal"
                      >
                        <i class="fas fa-minus-circle"></i>
                        <span class="position-top-1 px-1">
                          {{ $t('Btn_Withdraw') }}
                        </span>
                      </button>

                      <div class="vertical-divider mx-2">&nbsp;</div>
                      <!----currency area--->
                      <span class="text-white" style="white-space: nowrap;">
                        <span v-if="!isLoadingBalance">
                          {{ currency }}
                          {{ $filters.currency(totalRemainingCreditLimit) }}
                        </span>
                      </span>
                      <span v-if="!isLoadingBalance">
                        <img
                          src="../../../assets/icons/icn-refresh.png"
                          class="img-responsive mx-2 cursor-pointer"
                          alt="Responsive image"
                          width="21"
                          height="21"
                          @click="onClickRefreshBalance"
                        />
                      </span>
                      <span v-if="isLoadingBalance" class="ml-5">
                        <i
                          class="fas fa-spinner fa-pulse currency-spinner ml-5 mr-2"
                        ></i>
                      </span>

                      <!-- <button type="button" class="btn btn-refresh">
                            <i class="fas fa-sync-alt"></i>
                          </button> -->
                      <div class="vertical-divider mr-2 ml-0">&nbsp;</div>
                      <span
                        class="cursor-pointer d-flex align-items-center"
                        id="userProfileMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        @click="onClickDropdown"
                      >
                        <span> {{ username }} </span>
                        <img
                          src="../../../assets/icons/icn-profile.png"
                          class="img-responsive mx-1"
                          alt="Responsive image"
                          width="21"
                          height="21"
                          ref="submitBtn"
                        />
                        <div class="d-flex">
                          <span v-show="!isDropdownOpen">
                            <i class="fas fa-caret-down ml-1"></i>
                          </span>

                          <span v-show="isDropdownOpen">
                            <i class="fas fa-caret-up ml-1"></i>
                          </span>
                        </div>
                      </span>

                      <div
                        id="profile-dropdown"
                        class="dropdown-menu user-dropdown"
                        aria-labelledby="userProfileMenuButton"
                      >
                        <router-link to="/account">
                          <a class="dropdown-item" href="#">
                            <img
                              src="../../../assets/icons/menu-myaccount.png"
                              class="img-responsive"
                              alt="Responsive image"
                              width="18"
                            />

                            <span class="dropdown-text">
                              {{ $t('MyAccount') }}
                            </span>
                          </a>
                        </router-link>
                        
                        <router-link to="/message">
                          <a class="dropdown-item" href="#">
                            <img
                              src="../../../assets/icons/icn-announcement.png"
                              class="img-responsive"
                              alt="Responsive image"
                              width="18"
                            />

                            <span class="dropdown-text">
                              {{ $t('Message') }}
                            </span>
                          </a>
                        </router-link>
                        <router-link to="/credit-history">
                          <a class="dropdown-item" href="#">
                            <img
                              src="../../../assets/icons/menu-history.png"
                              class="img-responsive"
                              alt="Responsive image"
                              width="18"
                            />
                            <span class="dropdown-text">
                              {{ $t('History') }}
                            </span>
                          </a>
                        </router-link>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="onClickLogout"
                        >
                          <img
                            src="../../../assets/icons/menu-logout.png"
                            class="img-responsive"
                            alt="Responsive image"
                            width="18"
                          />
                          <span class="dropdown-text">
                            {{ $t('Logout') }}
                          </span>
                        </a>
                      </div>
                  </div>
                </template>
                <template v-if="!isLoggedIn">
                  <div class="col-12 px-0 col-center mt-2 pt-1 justify-content-end">
                    <div class="desktop-field-container">
                    <img class="desktop-field-top" src="../../../assets/images/btn-field-desktop.png"/>
                     <input
                      type="text"
                      class="desktop-login-field"
                      :placeholder="$t('Username')"
                      aria-label="Username"
                      v-model.trim="inputUsername"
                      maxlength="50"
                      />
                    </div>
                    <div class="desktop-field-container">
                    <img class="desktop-field-top" src="../../../assets/images/btn-field-desktop.png"/>
                      <input
                        type="password"
                        class="desktop-login-field"
                        :placeholder="$t('Password')"
                        aria-label="Password"
                        @keyup.enter="onClickLogin()"
                        v-model.trim="inputPassword"
                        maxlength="16"
                      />
                    </div>
                    <div class="desktop-field-login-container pointer" @click="onClickLogin">
                    <img class="desktop-field-login" src="../../../assets/images/btn-login-desktop.png"/>
                    <span class="desktop-login-btn">{{ $t('Login') }}</span>
                    </div>
                  </div>
                </template>
              </div>
          </div>
    </div>
  </nav>

  <!----top menu---->
  <section class="col-12" @mouseleave="onLeaveMenu()">
    <div class="container" style="margin-top:4em">
          <div
            class="col-spaced menu-container"
          >
            <div
              class="menu-wrap"
              v-for="(item, index) in menuIcons"
              :key="item.message"
              
              @click="onClickMenuIcon(index)"
            >
            <!-- @mouseover="onOverMenu(index)" -->
              <!-- <div class="menu-btn col-center"> -->
                <img
                  class="menu-icn"
                  :class="item.isActive ? 'menu-active' : '' "
                  :src="
                    item.isActive ? item.iconUrlActive : item.iconUrl
                  "
                />
                <div
                  class="menu-text pl-2"
                >
                  {{ $t(item.name) }}
                </div>
            </div>
          </div>
    </div>

    <!----custom game menu  ---->
    <div v-if="isMenuHover" @mouseleave="onLeaveMenu()">
      <div class="p-0">
        <div class="dropdown-menu dropdown-wrap">
          <div class="container">
            <div class="clear-both py-0 col-12">
              <div class="row px-0 py-0">
                <div class="col-lg-12 py-2">
                  <div class="row px-0 d-flex align-items-center">
                    <div
                      class="col-2"
                      v-for="(item, index) in hoveredMenuItem"
                      :key="item.message"
                      @click="onClickGameIcon(index)"
                    >
                      <div class="cursor-pointer">
                        <img
                          class="game-circle"
                          v-bind:src="getLangImg2(item)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--- enter lobby loading pop out -->
    <transition name="fadeIn">
      <template v-if="isWalletTransfer">
        <TransferModal @set-isWalletTransfer="setIsWalletTransfer" />
      </template>
    </transition>

    <!--end custom-->
    <!--- login modal--->
    <login-modal
      ref="loginModal"
      :errMessage="loginPanelErr"
      @toggle-danger="toggleDangerAlert"
      @set-err-messsage="setErrMessage"
    ></login-modal>
    <!---game  modal --->
    <game-modal ref="gameModal"></game-modal>
    <!---end game  modal --->
  </section>
</template>

<script>
import moment from 'moment';
import desktopImport from '../../../assets/script/desktop.js';
import CustomHttp from '../../../CustomHttp';
import CustomEncrypt from '../../../CustomEncrypt';
import gameLiveCasino from '../../../assets/script/catalog/live.js';
import gameSlot from '../../../assets/script/catalog/slot.js';
import gameItp from '../../../assets/script/catalog/itp.js';
import gameCards from '../../../assets/script/catalog/cards.js';
import gameFishing from '../../../assets/script/catalog/fish.js';
import gameLotto from '../../../assets/script/catalog/lotto.js';
import gameESports from '../../../assets/script/catalog/eports.js';
import gameCockFt from '../../../assets/script/catalog/cockft.js';

import languageList from '../../../assets/script/languageList';
import i18n from './../../../i18n';
const { t } = i18n.global;
import GameImport from '../../../assets/script/game';
const registerUrl = process.env.VUE_APP_USER_REGISTER_URL;
import CustomStorage from '../../../CustomStorage.js';
const gameUrl = process.env.VUE_APP_GAME_URL;
const gameUrl2 = process.env.VUE_APP_GAME_URL2;
// import { useRouter } from 'vue-router';
//import $ from 'jquery';
// import arr from '../../../assets/icons/arrow-down.png';
//Vue export
import TransferModal from './../elements/TransferModal.vue';
export default {
  components: { TransferModal },
  mounted() {
    this.updateCurrentTime();
    // this.onClickMenuIcon(0);
    this.computeHighlightLink();
    this.isLoading = false;
    setInterval(() => {
      this.updateCurrentTime();
    }, 1000);

    //check default language and fallback to english
    let tempLang = languageList.find((l) => l.id == this.$store.getters.langId);
    if (!tempLang) {
      this.onClickLanguageTab(0);
    }
  },
  data() {
    return {
      inputUsername: '',
      inputPassword: '',
      showLoginModal: false,
      selectedGame: null,
      isLoading: false,
      isLoadingBalance: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      successMessage: '',
      errorMessage: '',
      loginPanelErr: '',
      isDropdownOpen: false,
      isLanguageDropdownOpen: false,
      currentTime: '',
      isMenuHover: false,
      languageList: languageList,
       menuIcons: [
        {
          name: 'HOT',
          isActive: false,
          iconUrl: desktopImport.icnHot,
          iconUrlActive: desktopImport.icnHotActive,
        },
        {
          name: 'SPORTSBOOK',
          isActive: false,
          iconUrl: desktopImport.sb,
          iconUrlActive: desktopImport.sbActive,
        },
        {
          name: 'LIVECASINO',
          isActive: false,
          iconUrl: desktopImport.lc,
          iconUrlActive: desktopImport.lcActive,
        },
        {
          name: 'SLOT',
          isActive: false,
          iconUrl: desktopImport.slot,
          iconUrlActive: desktopImport.slotActive,
        },
        {
          name: 'FISHING',
          isActive: false,
          iconUrl: desktopImport.fishing,
          iconUrlActive: desktopImport.fishingActive,
        },
        {
          name: 'CARDS',
          isActive: false,
          iconUrl: desktopImport.cards,
          iconUrlActive: desktopImport.cardsActive,
        },
        {
          name: 'COCKFT',
          isActive: false,
          iconUrl: desktopImport.cockft,
          iconUrlActive: desktopImport.cockftActive,
        },
      ],
      hoveredMenuItem: [],
    };
  },
  created() {
    //listen to initial game tab message
    window.addEventListener(
      'storage',
      (event) => {
      if (event.key == 'event-transferGameProvider') {
          let tempProvider = localStorage.getItem('event-transferGameProvider');
          console.log(tempProvider);
          this.$store.dispatch('setTransferGameProvider', tempProvider);
          //localStorage.removeItem('event-transferGameProvider');
        }
        // if (event.key == 'event-isWalletTransfer') {
        //   let tempIsTransfer = localStorage.getItem('event-isWalletTransfer');
        //   this.$store.dispatch('setIsWalletTransfer', tempIsTransfer == 'true');
        //   console.log(this.isWalletTransfer);
        //   localStorage.removeItem('event-isWalletTransfer');
        // }
        if (event.key == 'event-setTransferError') {
          let tempTransferError = localStorage.getItem(
            'event-setTransferError'
          );
          this.toggleDangerAlert(t(tempTransferError));
          this.$store.dispatch('setIsWalletTransfer', false);
          localStorage.removeItem('event-setTransferError');
        }
      },
      false
    );
  },
  methods: {
    setIsWalletTransfer(flag) {
      this.$store.dispatch('setIsWalletTransfer', flag);
      //user click close on transfer modal
      localStorage.removeItem('event-closeTransferModal');
      if (flag == false) {
        console.log(flag);
        localStorage.setItem('event-closeTransferModal', true);
      }
    },
    async withdrawAllFromGame() {
      this.$store.dispatch('setTransferGameProvider', '');
      this.$store.dispatch('setIsWalletTransfer', true);
      // url 1
      let gameImport = JSON.parse(JSON.stringify(GameImport));
      gameImport = gameImport.filter((x) => x.gameUrl == gameUrl);
      // gameImport = gameImport.filter((x) => x.code != providerId);
      let idListString = '';
      gameImport.forEach((item, index) => {
        if (gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });
      //get balance from all game provider
      let response = [];
      if (!this.isWalletTransfer) {
        return;
      }
      let startTime = new Date();
      try {
        response = await CustomHttp.getAllGame(idListString, gameUrl, 10);
      } catch (err) {
        this.toggleDangerAlert(t('Please try again'));
        this.$store.dispatch('setIsWalletTransfer', false);
        return;
      }
      //url 2
      gameImport = JSON.parse(JSON.stringify(GameImport));
      gameImport = gameImport.filter((x) => x.gameUrl == gameUrl2);
      // gameImport = gameImport.filter((x) => x.code != providerId);
      idListString = '';
      gameImport.forEach((item, index) => {
        if (gameImport.length == index + 1) {
          idListString += item.code;
        } else {
          idListString += item.code + ',';
        }
      });
      //get balance from all game provider
      let response2 = [];
      if (!this.isWalletTransfer) {
        return;
      }
      let endDate = new Date();
      let seconds = (endDate.getTime() - startTime.getTime()) / 1000;
      seconds = 5 - seconds;
      startTime = new Date();
      console.log('seconds');
      console.log(seconds);
      try {
        if (seconds < 0) {
          throw new Error('Please try again.');
        }
        response2 = await CustomHttp.getAllGame(
          idListString,
          gameUrl2,
          seconds
        );
      } catch (err) {
        this.toggleDangerAlert(t('Please try again.'));
        this.$store.dispatch('setIsWalletTransfer', false);
        return;
      }
      //handle login in other site message
      if (response.data.ErrText != '' || response2.data.ErrText != '') {
        this.toggleDangerAlert(t(response.data.ErrText));
        this.$store.dispatch('setIsWalletTransfer', false);
        return;
      }
      let responseData = [];
      let gameImportUrl = GameImport.filter((x) => x.gameUrl == gameUrl);
      let gameImportUrl2 = GameImport.filter((x) => x.gameUrl == gameUrl2);
      //handle api return null
      let gameList = response.data.game_list;
      gameList = gameList.filter((x) => x != null);
      gameImportUrl.forEach((item) => {
        const tempRecord = gameList.find((l) => l.GameId == item.code);
        responseData.push(tempRecord);
      });
      //handle api return null
      let gameList2 = response2.data.game_list;
      gameList2 = gameList2.filter((x) => x != null);
      gameImportUrl2.forEach((item) => {
        const tempRecord = gameList2.find((l) => l.GameId == item.code);
        responseData.push(tempRecord);
      });
      let gameProviderWithBalance = [];
      console.log(responseData);
      responseData.forEach((item) => {
        if (item.GameBalance != 0) {
          gameProviderWithBalance.push({
            GameId: item.GameId,
            GameBalance: item.GameBalance,
          });
        }
      });
      //start withdraw
      endDate = new Date();
      seconds = (endDate.getTime() - startTime.getTime()) / 1000;
      seconds = 5 - seconds;
      startTime = new Date();
      console.log('seconds');
      console.log(seconds);
      if (gameProviderWithBalance.length >= 1) {
        console.log('enter withdraw');
        for (let i = 0; i < gameProviderWithBalance.length; i++) {
          let gameProviderName = GameImport.find(
            (g) => g.code == gameProviderWithBalance[i].GameId
          ).name;
          this.$store.dispatch('setTransferGameProvider', gameProviderName);
          if (!this.isWalletTransfer) {
            return;
          }
          //console.log('withdraw from');
          // console.log(gameProviderWithBalance[i].GameId);

          try {
            if (seconds < 0) {
              throw new Error('Please try again.');
            }
            await CustomHttp.withDrawFromGame(
              gameProviderWithBalance[i].GameId,
              gameProviderWithBalance[i].GameBalance,
              seconds
            );
            endDate = new Date();
            seconds = (endDate.getTime() - startTime.getTime()) / 1000;
            seconds = 5 - seconds;
            startTime = new Date();
            console.log('seconds after withdraw');
            console.log(seconds);
            return seconds;
          } catch (err) {
            this.toggleDangerAlert(t('Please try again.'));
            this.$store.dispatch('setIsWalletTransfer', false);
            return;
          }
          //handle possible error
          //   if (response.errtext[0] == '') {
          //   }
        }
      } else {
        return seconds;
      }
    },
    async processWalletTransfer(gameCode) {
      if (this.isIOS() == true) {
        localStorage.setItem('IOS-transfer', JSON.stringify(gameCode));
        window.open('/initializing');
        return;
      }

      let remainingTime = await this.withdrawAllFromGame();
      console.log('remainingTime : ' + remainingTime);
      //check for game status
      try {
        if (remainingTime < 0) {
          throw new Error('Please try again.');
        }
        console.log('gameCode');
        console.log(gameCode);
        await CustomHttp.getOneGame(gameCode, remainingTime);
      } catch (err) {
        console.log('error' + err.message);
        let tempMessage = err.message;
        tempMessage = tempMessage.replace('Error:', '');
        this.toggleDangerAlert(t(tempMessage));
        this.$store.dispatch('setIsWalletTransfer', false);
      }
      // get latest balance
      if (!this.isWalletTransfer) {
        return;
      }

      let creditResult = await CustomHttp.post('/api/v1/member/get', {
        username: this.$store.getters.username,
        withTotalRemainingCreditLimit: true,
      });
      let totalRemainingCreditLimit =
        creditResult.data.data.totalRemainingCreditLimit;
      //deposit latest balance to selected game
      // start game
      this.$store.dispatch('setTransferGameProvider', '');
      CustomStorage.setItem('gameCode', gameCode);
      CustomStorage.setItem('tempAmount', totalRemainingCreditLimit);
      window.open('/initializing', '_blank');
      //window.open('/initializing');
      //this.$store.dispatch('setOpenNewTab', true);

      this.$store.dispatch('setIsWalletTransfer', false);
    },
    onClickRegister() {
      window.open(registerUrl, '_blank');
    },
    onClickLanguageTab(index) {
      const tempLangId = this.languageList[index].id;
      this.$store.dispatch('setLangId', tempLangId);
    },
    isIpadOS() {
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      );
    },
    isIOS() {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        this.isIpadOS()
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobileOrTablet() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        this.isIpadOS()
      ) {
        return true;
      } else {
        return false;
      }
    },
    onOverMenu(index) {
      ///control close isMenuHover dropdown

      if (this.$store.getters.selectedHeaderIndex == index) {
        this.isMenuHover = false;
        return;
      }
      this.menuIcons = this.menuIcons.map((tempItem) => {
        tempItem.isActive = false;
        return tempItem;
      });
      this.hoveredMenuItem = null;
      if (this.menuIcons[index].name.toUpperCase() == 'LIVECASINO') {
        this.hoveredMenuItem = gameLiveCasino.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'SLOT') {
        this.hoveredMenuItem = gameSlot.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'ITP') {
        this.hoveredMenuItem = gameItp.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'CARDS') {
        this.hoveredMenuItem = gameCards.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'FISHING') {
        this.hoveredMenuItem = gameFishing.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'LOTTO') {
        this.hoveredMenuItem = gameLotto.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'ESPORT') {
        this.hoveredMenuItem = gameESports.items;
      }
      if (this.menuIcons[index].name.toUpperCase() == 'COCKFT') {
        this.hoveredMenuItem = gameCockFt.items;
      }

      this.isMenuHover = false;
      if (this.hoveredMenuItem) {
        this.isMenuHover = true;
      }
      this.menuIcons[index].isActive = true;
      this.computeHighlightLink();

      if (this.isMobileOrTablet()) {
        this.onClickMenuIcon(index);
      }
    },
    onLeaveMenu() {
      this.isMenuHover = false;
      this.menuIcons = this.menuIcons.map((tempItem) => {
        tempItem.isActive = false;
        return tempItem;
      });
      this.computeHighlightLink();
    },
    onClickDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    onClickLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    onClickMenuIcon(index) {
      // this.menuIcons[index].isActive = !this.menuIcons[index].isActive;
      //
      //unselect other item
      // if (this.menuIcons[index].isActive) {
      //   this.menuIcons.forEach((item, i) => {
      //     if (index != i) {
      //       this.menuIcons[i].isActive = false;
      //     }
      //   });
      // }
      //
      // seleted which card group to show
      this.$store.dispatch('setSelectedHeaderIndex', index);
      if (this.menuIcons[index].name.toUpperCase() == 'HOT') {
        this.$router.replace('/');
      } else if (this.menuIcons[index].name.toUpperCase() == 'SPORTSBOOK') {
        this.$router.replace('/sportsbook');
      } else if (this.menuIcons[index].name.toUpperCase() == 'SLOT') {
        this.$router.replace('/slot');
      } else if (this.menuIcons[index].name.toUpperCase() == 'LIVECASINO') {
        this.$router.replace('/live-casino');
      } else if (this.menuIcons[index].name.toUpperCase() == 'ITP') {
        this.$router.replace('/itp');
      } else if (this.menuIcons[index].name.toUpperCase() == 'CARDS') {
        this.$router.replace('/cards');
      } else if (this.menuIcons[index].name.toUpperCase() == 'FISHING') {
        this.$router.replace('/fishing');
      } else if (this.menuIcons[index].name.toUpperCase() == 'LOTTO') {
        this.$router.replace('/lotto');
      } else if (this.menuIcons[index].name.toUpperCase() == 'ESPORT') {
        this.$router.replace('/esports');
      } else if (this.menuIcons[index].name.toUpperCase() == 'COCKFT') {
        this.$router.replace('/cockft');
      }
      this.computeHighlightLink();
    },
    computeHighlightLink() {
      let currentPath = this.$router.currentRoute._value.path;
      let tempIndex = null;
      if (currentPath == '/') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'HOT'
        );
      } else if (currentPath == '/sportsbook') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'SPORTSBOOK'
        );
      } else if (currentPath == '/live-casino') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'LIVECASINO'
        );
      } else if (currentPath == '/slot') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'SLOT'
        );
      } else if (currentPath == '/itp') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'ITP'
        );
      } else if (currentPath == '/cards') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'CARDS'
        );
      } else if (currentPath == '/fishing') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'FISHING'
        );
      } else if (currentPath == '/lotto') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'LOTTO'
        );
      } else if (currentPath == '/esports') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'ESPORT'
        );
      } else if (currentPath == '/cockft') {
        tempIndex = this.menuIcons.findIndex(
          (x) => x.name.toUpperCase() == 'COCKFT'
        );
      }

      //highlight some route
      if (tempIndex != null) {
        this.menuIcons[tempIndex].isActive = true;
      }
      // else {
      //   this.menuIcons = this.menuIcons.map((tempItem) => {
      //     tempItem.isActive = false;
      //     return tempItem;
      //   });
      // }
    },
    async onClickCashOut() {
      let w = 780;
      let h = 820;
      let url = '/cash-out';
      let title = 'Cash Out';
      // Fixes dual-screen position                             Most browsers      Firefox
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      const top = (height - h) / 2 / systemZoom + dualScreenTop;
      const newWindow = window.open(
        url,
        title,
        `
      scrollbars=yes,
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      resizable=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
      );

      if (window.focus) newWindow.focus();
      // this.$router.replace('/cash-out');
      // this.isLoading = true;
    },
    updateCurrentTime() {
      const today = new Date();
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const dateTime = date + ' ' + time;
      let tempDate = new Date(dateTime);
      let tempDateGmt = new Date();
      let gmt = Math.abs(tempDateGmt.getTimezoneOffset() / 60);
      tempDate =
        moment().format('DD/MM/yyyy hh:mm:ss A') +
        ' (GMT+' +
        gmt.toString() +
        ')';
      this.currentTime = tempDate;
    },
    goToPayment(method) {
      let today = new Date();
      today.setHours(today.getHours() + 8);
      let currentUnixTime = moment(today).unix();
      let token = CustomEncrypt.SignAndHash(currentUnixTime);
      let routeData = this.$router.resolve({
        path: 'ValidationLogin',
        query: {
          username: this.$store.getters.username,
          timestamp: currentUnixTime,
          token: token,
          method: method,
          lang: CustomEncrypt.getPaymentLanguage(),
          landingPage: window.location.origin,
        },
      });
      window.open(CustomEncrypt.getPaymentUrl() + routeData.href, '_blank');
    },
    onClickDeposit() {
      this.goToPayment('D');
    },
    onClickWithdrawal() {
      this.goToPayment('W');
    },
    async onClickRefreshBalance() {
      this.isLoadingBalance = true;
      try {
        await this.$store.dispatch('getMemberBalance');
        this.isLoadingBalance = false;
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoadingBalance = false;
        this.toggleDangerAlert(error);
      }
    },
    async onClickLogin() {
      if (this.inputUsername == '' || this.inputPassword == '') {
        this.toggleDangerAlert(t('InvalidUsernameOrPassword'));
        return;
      }
      this.submitLogin(this.inputUsername, this.inputPassword);
    },
    async submitLogin(userName, userPassword) {
      this.isLoading = true;
      const json = {
        username: userName,
        password: userPassword,
      };
      try {
        await this.$store.dispatch('userLogin', json);
        this.isLoading = false;
        //  this.$i18n.locale = this.$store.getters.langId.toString();

        await this.$store.dispatch('getSession');
        await this.$store.dispatch("setInitSid", this.$store.getters.session);
        console.log(this.$store.getters.initSid);

        this.$router.replace('/agreement');
        // this.toggleSuccessAlert('You Have Successfully Logged in');
      } catch (error) {
        this.isLoading = false;
        let tempError = error.message;
        tempError = tempError.replace('Error:', '').trim();
        tempError = t(tempError);
        // if (this.$store.getters.langId != 16) {
        //   tempError = t("InvalidUsernameOrPassword");
        // }
        this.toggleDangerAlert(tempError.toString());
      }
    },
    async onClickLogout() {
      this.isLoading = true;
      await this.$store.dispatch('userLogoutWithApi');
      this.$router.replace('/');
      this.isLoading = false;
    },
    toggleSuccessAlert(message) {
      this.successMessage = message;
      this.showSuccessAlert = true;
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 1500);
    },
    toggleDangerAlert(message) {
      this.errorMessage = message;
      this.showErrorAlert = true;
      setTimeout(() => {
        this.showErrorAlert = false;
      }, 1500);
    },
    closeLoginModal() {
      this.showLoginModal = false;
      // $('#loginModal').modal('hide');
    },
    openLoginModal() {
      // $('#loginModal').modal('show');
      this.loginPanelErr = '';
      this.$refs.showLoginBtn.click();
    },
    onClickGameIcon(i) {
      if (!this.$store.getters.isLoggedIn) {
        //not logged in
        this.loginPanelErr = '';
        this.$refs.loginModal.showModal();
      } else {
        const selectedGame = this.hoveredMenuItem[i];
        this.$store.dispatch('setSelectedGame', selectedGame);

        if (this.autoTransferWallet == true) {
          //prevent modal show
          const gameCode = this.$store.getters.selectedGame.code;
          this.processWalletTransfer(gameCode);
          this.onLeaveMenu();
        } else {
          this.$refs.gameModal.showModal();
          this.$refs.gameModal.getGameDetails();
        }
      }
    },
    toOpenGameModal() {
      if (this.autoTransferWallet == true) {
        //prevent modal show
        const gameCode = this.$store.getters.selectedGame.code;
        this.processWalletTransfer(gameCode);
      } else {
        
        this.$refs.gameModal.showModal();
        this.$refs.gameModal.getGameDetails();
      }
    },
    setErrMessage(errMessage) {
      this.loginPanelErr = errMessage.toString();
    },
    async goToSportsbook() {
      if (!this.$store.getters.isLoggedIn) {
        //no logged in
        this.loginPanelErr = '';
        this.$refs.loginModal.showModal();
        //  window.open(process.env.VUE_APP_MEMBER_SITE, '_blank');
      } else {
        if (this.autoTransferWallet == true) {
          await this.withdrawAllFromGame();
          this.$store.dispatch('setTransferGameProvider', '');

          this.$store.dispatch('setIsWalletTransfer', false);
        }
        this.isLoading = true;
        // const isMaintenance = await this.isSportsbookMaintenance();
        // if (isMaintenance) {
        //   this.isLoading = false;
        //   this.toggleDangerAlert(t("Sportsbook is under maintenance"));
        //   return;
        // }
        const json = {
          username: this.$store.getters.username,
          langCode: this.languageList.find(
            (l) => l.id == this.$store.getters.langId
          ).code,
          logout_url: window.location.origin,
        };
        try {
          const response = await CustomHttp.post(
            'api/v1/member/login-old-site',
            json
          );
          const memberUrl =
            process.env.VUE_APP_MEMBER_SITE + response.data.data.url;
          window.location.href = memberUrl;
          //window.open(memberUrl, '_blank');
        } catch (err) {
          this.isLoading = false;
        }
      }
    },
    async goToSportsbook2(wc = false) {
      if (!this.$store.getters.isLoggedIn) {
        //no logged in
        this.loginPanelErr = '';
        this.$refs.loginModal.showModal();
        //  window.open(process.env.VUE_APP_MEMBER_SITE, '_blank');
      } else {
        if (this.autoTransferWallet == true) {
          await this.withdrawAllFromGame();
          this.$store.dispatch('setTransferGameProvider', '');
          this.$store.dispatch('setIsWalletTransfer', false);
        }
        const json = {
          username: this.$store.getters.username,
          langCode: this.languageList.find(
            (l) => l.id == this.$store.getters.langId
          ).code,
          logout_url: window.location.origin,
        };
        if(wc == true){
          localStorage.setItem('sb-wc', '1');
        }
        localStorage.setItem('sb-memberUrl', JSON.stringify(json));
        window.open('/initializing');
      }
    },
    getLangImg2(obj) {
      let imgSrc = obj.img2[0].src;
      let tempSrc = obj.img2.find(
        (i) => i.langId == this.$store.getters.langId
      );
      if (tempSrc) {
        imgSrc = tempSrc.src;
      }
      return imgSrc;
    },
  },
  computed: {
    enablePaymentGateway() {
      return this.$store.getters.enablePaymentGateway;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
      //  return true;
    },
    suspend() {
      return this.$store.getters.suspend;
    },
    username() {
      return this.$store.getters.username;
    },
    currency() {
      return this.$store.getters.currency;
    },
    totalRemainingCreditLimit() {
      // const current = new Date();
      return this.$store.getters.totalRemainingCreditLimit;
    },
    selectedLanguage() {
      let selectedLanguage = languageList.find(
        (l) => l.id == this.$store.getters.langId
      );
      if (selectedLanguage) {
        return selectedLanguage;
      } else {
        return languageList[0];
      }
    },
    selectedLanguageId() {
      return this.$store.getters.langId;
    },
    autoTransferWallet() {
      return this.$store.getters.autoTransferWallet;
    },
    transferError() {
      return this.$store.getters.transferError;
    },
    isWalletTransfer() {
      return this.$store.getters.isWalletTransfer;
    },
  },
  watch: {
    transferError(newValue) {
      if (newValue != '') {
        this.toggleDangerAlert(t(newValue));
        this.$store.dispatch('setTransferError', '');
      }
    },
  },
};
</script>

<style scoped lang="css" src="../../../assets/css/desktop.css"></style>
