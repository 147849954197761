<template>
  <div class="card-body">
    <h1 class="cclip neon">INTRODUCING V-SPORT</h1>
    <p class="card-text">
      V-Sport is one of the world’s leading online gaming brands.
    </p>
    <p class="card-text">
      V-Sport Sportsbook offers over 500 sporting events every week, with extensive coverage of all major soccer leagues and international sporting events. V-Sport also provides live soccer updates, immediate winnings confirmation, rapid payouts, easy access and fast online betting available around the clock.
      <br><br>
      V-Sport Casino is a one-stop entertainment site offering an extensive range of casino games and the highly-rated Multi Player Live Dealer developed in conjunction with the online Gaming Systems of Australia to satisfy every player’s needs.
      <br><br>
      In V-Sport Games, you can choose to play exclusive games such as scratch cards, keno, poker and roulette, all with unlimited plays and no download or installation required.
      <br><br>
      V-Sport uses the latest technologies to deliver a fast and secure player environment. At V-Sport, we are committed to providing our customers with an outstanding gaming experience. With our wide selections of products and services, V-Sport caters for all sports bettors ranging from the casual punter through to VIP players and International Bookmakers.
    </p>
    <p class="card-text">
      All of this on the widest range of sports, events and markets, in the comfort of your own home or anywhere in the world. When betting with V-Sport, you can feel confident knowing that you are dealing with a reputable online sportsbook whose management has many decades of experience in the gaming industry.
      <br />
      Our friendly V-Sport Support Team is always available to assist in any way possible, including account enquiries, depositing or withdrawing of funds, or general betting assistance.
    </p>

    <p class="card-text">
      Bet with V-Sport, the Sportsbook you can trust. 
    </p>
    <br><br><br>
  </div>
</template>

<style scoped>
.card-text {
  font-size: 16px;
  /* letter-spacing: 0.18px; */
  text-align: left;
  letter-spacing: 0.2px;
}

.card-img-caption {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-caption .card-img-top {
  z-index: 0;
}

.card-img-caption .card-text {
  text-align: center;
  width: 100%;
  margin: 10% 0;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  font-size: 18px;
  letter-spacing: 0.27px;
}
</style>
