import gameImport from './../game';

import spadefish from './../../images/game2/fishing/img-spadegaming-fish.png';
import jilifish from './../../images/game2/fishing/img-jili-fish.png';
import fachaifish from './../../images/game2/fishing/img-fachai-fish.png';
import play8fish from './../../images/game2/fishing/img-play8-fish.png';
import sfachaifish from './../../images/game2/fishing/img-fachai-fish-s.png';
import sjilifish from './../../images/game2/fishing/img-jili-fish-s.png';
import sspadefish from './../../images/game2/fishing/img-spadegaming-fish-s.png';
import splay8fish from './../../images/game2/fishing/img-play8-fish-s.png';
import vpower from './../../images/game2/fishing/img-vpower-d.png';
import svpower from './../../images/game2/fishing/img-vpower-s.png';

var gameMenu = {
  name: 'FISHING',
  items: [
   {
     name: 'VPOWER',
     provider: 'UFA Fishing',
     isFullWidth: true,
     icon: gameImport.find((x) => x.code.toString() === '72').icon,
     code: '72',
     img: [{ langId: 0, src: vpower }],
     img2: [{ langId: 0, src: svpower }],
     isNew: false,
     labelName: '',
     parentName: '',
   },
    {
      name: 'SPADE GAMING FISHING',
      isFullWidth: true,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-302',
      img: [{ langId: 0, src: spadefish }],
      img2: [{ langId: 0, src: sspadefish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'JILI FISHING',
      isFullWidth: true,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-305',
      img: [{ langId: 0, src: jilifish }],
      img2: [{ langId: 0, src: sjilifish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'FA CHAI FISHING',
      isFullWidth: true,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-304',
      img: [{ langId: 0, src: fachaifish }],
      img2: [{ langId: 0, src: sfachaifish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
    {
      name: 'PLAY8 FISHING',
      isFullWidth: true,
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-307',
      img: [{ langId: 0, src: play8fish }],
      img2: [{ langId: 0, src: splay8fish }],
      isNew: false,
      labelName: '',
      parentName: '',
    },
  ],
};

export default gameMenu;
