import axios from 'axios';
const dynamicUrl = process.env.VUE_APP_DYNAMIC_URL;
// const returnUrl = process.env.VUE_APP_RETURN_URL;
const ufaUrl = process.env.VUE_APP_UFA_URL;
import store from './store/index';
import xml2js from 'xml2js';
import PreferenceImport from './assets/script/preference';
import httpError from './assets/script/httpError';
import CustomStorage from './CustomStorage';
import CustomEncrypt from './CustomEncrypt';
import gameImport from './assets/script/game';
// import i18n from './i18n';
// const { t } = i18n.global;
export default {
  async get(url) {
    return axios({
      method: 'post',
      url: dynamicUrl + url,
      baseURL: '/',
      data: {
        Username: 'apiUser',
        Password: 'apiPassword',
      },
    })
      .then((response) => {
        // console.log(response);
        return response;
      })
      .catch((error) => {
        //  console.log(error.response);

        return error;
      });
  },
  async post(url, jsonData) {
    let header;
    if (store.getters.token) {
      header = {
        'x-api-key': store.getters.token,
        'Content-Type': 'application/json',
      };
    }
    //  console.log('http header');
    //  console.log(i18n.tc('AboutUs'));
    return axios({
      method: 'post',
      headers: header,
      url: dynamicUrl + url,
      baseURL: '/',
      data: jsonData,
    })
      .then((response) => {
        if (response.data.error) {
          //no record return empty array
          if (response.data.error.code.toString() == '-25') {
            return response;
          }
          if (response.data.error.code.toString() == '-40') {
            return response;
          }
          let tempError = httpError.find(
            (e) => e.code == response.data.error.code
          ).message;
          //  tempError = i18n.tc(tempError);
          throw new Error(tempError);
        } else {
          return response;
        }
      })
      .catch((error) => {
        if (!error.response) {
          let tempMessage = error.toString().replace('Error:', '');
          if (tempMessage.trim() == 'SystemMaintenance') {
            CustomStorage.setItem('maintenance', 'true');
            window.location = '/maintenance';
            throw new Error('SystemMaintenance');
          }
          throw new Error(error);
        }
        if (error.response.status == 401) {
          store.dispatch('setTimeoutFlag', true);
        }
        return error;
      });
  },
  async postUfa(url, jsonData) {
    let header;

    header = {
      'x-api-key': CustomEncrypt.getUfaApiKey(),
      'Content-Type': 'application/json',
    };

    //  console.log('http header');
    //  console.log(i18n.tc('AboutUs'));
    return axios({
      method: 'post',
      headers: header,
      url: ufaUrl + url,
      baseURL: '/',
      data: jsonData,
      //  timeout: 1000 * 5, // Wait for 5 seconds
    })
      .then((response) => {
        if (response.data.error) {
          //no record return empty array
          if (response.data.error.code.toString() == '-25') {
            return response;
          }
          let tempError = httpError.find(
            (e) => e.code == response.data.error.code
          ).message;
          //  tempError = i18n.tc(tempError);
          throw new Error(tempError);
        } else {
          return response;
        }
      })
      .catch((error) => {
        // if (error.response.status == 404) {
        //   console.log('404');
        // }
        return error;
      });
  },
  async logoutUser(jsonData) {
    let header;
    const url = 'api/v1/member/logout';
    if (store.getters.token) {
      header = {
        'x-api-key': store.getters.token,
        'Content-Type': 'application/json',
      };
    }
    //  console.log('http header');
    //  console.log(i18n.tc('AboutUs'));
    return axios({
      method: 'post',
      headers: header,
      url: dynamicUrl + url,
      baseURL: '/',
      data: jsonData,
      //  timeout: 1000 * 5, // Wait for 5 seconds
    })
      .then((response) => {
        if (response.data.error) {
          //no record return empty array
          if (response.data.error.code.toString() == '-25') {
            return response;
          }
          let tempError = httpError.find(
            (e) => e.code == response.data.error.code
          ).message;
          //  tempError = i18n.tc(tempError);
          throw new Error(tempError);
        } else {
          return response;
        }
      })
      .catch((error) => {
        // console.log('error');
        if (!error.response) {
          throw new Error(error);
        }
        return error;
      });
  },
  async getMemberBalanceMobileLanding(jsonData) {
    let header;
    const url = 'api/v1/member/get';
    if (store.getters.token) {
      header = {
        'x-api-key': store.getters.token,
        'Content-Type': 'application/json',
      };
    }
    //  console.log('http header');
    //  console.log(i18n.tc('AboutUs'));
    return axios({
      method: 'post',
      headers: header,
      url: dynamicUrl + url,
      baseURL: '/',
      data: jsonData,
      //  timeout: 1000 * 5, // Wait for 5 seconds
    })
      .then((response) => {
        if (response.data.error) {
          //no record return empty array
          if (response.data.error.code.toString() == '-25') {
            return response;
          }
          let tempError = httpError.find(
            (e) => e.code == response.data.error.code
          ).message;
          //  tempError = i18n.tc(tempError);
          throw new Error(tempError);
        } else {
          return response;
        }
      })
      .catch((error) => {
        if (!error.response) {
          let tempMessage = error.toString().replace('Error:', '');
          if (tempMessage.trim() == 'SystemMaintenance') {
            CustomStorage.setItem('maintenance', 'true');
            window.location = '/maintenance';
            throw new Error('SystemMaintenance');
          }
          throw new Error(error);
        }
        // if (error.response.status == 401) {
        //   store.dispatch('setTimeoutFlag', true);
        // }
        return error;
      });
  },
  //extend api
  async extendSessionAPI() {
    let header;

    if (store.getters.token) {
      header = {
        'x-api-key': store.getters.token,
        'Content-Type': 'application/json',
      };
    }
    // console.log('http header');
    const jsonData = {
      username: store.getters.username,
    };
    const url = 'api/v1/member/extend-session';
    return (
      axios({
        method: 'post',
        headers: header,
        url: dynamicUrl + url,
        baseURL: '/',
        data: jsonData,
      })
        // eslint-disable-next-line
        .then((response) => {
          // return response;
        })
        // eslint-disable-next-line
        .catch((error) => {})
    );
  },
  //extend api
  async extendSessionAPI2() {
    let header;

    if (store.getters.token) {
      header = {
        'x-api-key': store.getters.token,
        'Content-Type': 'application/json',
      };
    }

    await store.dispatch('getSession');
    console.log(store.getters.initSid + ":" + store.getters.session + ":" + (store.getters.initSid != store.getters.session));
    if(store.getters.initSid != store.getters.session){
      store.dispatch('setTimeoutFlag', true);
      return;
    }

    const jsonData = {
      username: store.getters.username,
    };
    const url = 'api/v1/member/extend-session';
    return axios({
      method: 'post',
      headers: header,
      url: dynamicUrl + url,
      baseURL: '/',
      data: jsonData,
    })
      .then((response) => {
        if (response.data.error) {
          //no record return empty array
          if (response.data.error.code.toString() == '-25') {
            return response;
          }
          let tempError = httpError.find(
            (e) => e.code == response.data.error.code
          ).message;
          //  tempError = i18n.tc(tempError);
          throw new Error(tempError);
        } else {
          return response;
        }
      })
      .catch((error) => {
        if (!error.response) {
          throw new Error(error);
        }
        if (error.response.status == 401) {
          store.dispatch('setTimeoutFlag', true);
        }
        return error;
      });
  },
  //cash out func
  async getAllGame(gameListString, gameUrl, timeoutSeconds = 10) {
    await store.dispatch('getSession');
    let header;
    // if (store.getters.token) {
    //   header = {
    //     'x-api-key': store.getters.token,
    //     'Content-Type': 'application/json',
    //   };
    // }
    const action = 'checkwdall';
    const accid = store.getters.accId;
    const home = '5';
    const sid = store.getters.session;
    // await this.extendSessionAPI();
    return axios({
      method: 'post',
      headers: header,
      timeout: 1000 * timeoutSeconds, // Wait for timeoutSeconds seconds
      url:
        gameUrl +
        `ApiMbWD.aspx?action=${action}&home=${home}&accid=${accid}&gameids=${gameListString}&sid=${sid}`,
      baseURL: '/',
    })
      .then((response) => {
        // console.log('getAllGame');
        //console.log(response);
        return response;
      })
      .catch((error) => {
        if (!error.response) {
          throw new Error(error);
        }
        if (error.response.status == 401) {
          store.dispatch('setTimeoutFlag', true);
        }
        return error;
      });
  }, //end get all game
  async getOneGame(gameId, timeoutSeconds = 10, baseURl = null) {
    let providerId = gameId.split('-');
    let gameUrl = baseURl;
    if (baseURl == null) {
      gameUrl = gameImport.find((x) => x.code.toString() === providerId[0])
        .gameUrl;
    }
    await store.dispatch('getSession');
    let header;
    // if (store.getters.token) {
    //   header = {
    //     'x-api-key': store.getters.token,
    //     'Content-Type': 'application/json',
    //   };
    // }
    const action = 'check';
    const accid = store.getters.accId;
    const home = '5';
    const sid = store.getters.session;
    const auto = 'false';
    const ct = store.getters.compType;
    let returnResult;
    // await this.extendSessionAPI();
    return axios({
      method: 'post',
      headers: header,
      timeout: 1000 * timeoutSeconds, // Wait for timeoutSeconds seconds
      url:
        gameUrl +
        `api.aspx?action=${action}&auto=${auto}&ct=${ct}&home=${home}&accid=${accid}&sid=${sid}&game=${gameId}`,
      baseURL: '/',
    })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            returnResult = err;
            //Do something
          } else {
            // result.response.result[0].credit[0]
            // returns a json array
            returnResult = result.response.result[0];

            //check error
            let errTest = result.response.errtext[0];
            if (errTest != '') {
              throw new Error(errTest);
            }
          }
        });
        return returnResult;
      })
      .catch((error) => {
        //  console.log(error.message);
        // if (error.response.status == 401) {
        //   store.dispatch('setTimeoutFlag', true);
        // }
        throw new Error(error);
      });
  }, //end get one game
  async getOneGame2(gameId, baseURl = null) {
    let providerId = gameId.split('-');
    let gameUrl = baseURl;

    if (baseURl == null) {
      gameUrl = gameImport.find((x) => x.code.toString() === providerId[0])
        .gameUrl;
    }
    // await store.dispatch('getSession');
    let header;
    // if (store.getters.token) {
    //   header = {
    //     'x-api-key': store.getters.token,
    //     'Content-Type': 'application/json',
    //   };
    // }
    const action = 'check';
    const accid = store.getters.accId;
    const home = '5';
    const sid = store.getters.session;
    const auto = 'false';
    const ct = store.getters.compType;
    let returnResult;
    // await this.extendSessionAPI();
    return axios({
      method: 'post',
      headers: header,
      url:
        gameUrl +
        `api.aspx?action=${action}&auto=${auto}&ct=${ct}&home=${home}&accid=${accid}&sid=${sid}&game=${gameId}`,
      baseURL: '/',
    })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            returnResult = err;
            //Do something
          } else {
            // result.response.result[0].credit[0]
            // returns a json array
            returnResult = result.response.result[0];

            //check error
            let errTest = result.response.errtext[0];
            if (errTest != '') {
              throw new Error(errTest);
            }
          }
        });
        return returnResult;
      })
      .catch((error) => {
        //  console.log(error.message);
        // if (error.response.status == 401) {
        //   store.dispatch('setTimeoutFlag', true);
        // }
        throw new Error(error);
      });
  }, //end get one game
  async withDrawFromGame(gameId, amount, timeoutSeconds = 10, baseURl = null) {
    let providerId;
    let gameUrl = baseURl;
    console.log('timeoutSeconds');
    console.log(timeoutSeconds);
    if (baseURl == null) {
      gameId = gameId.toString();
      if (gameId.includes('-')) {
        providerId = gameId.split('-');
        providerId = providerId[0];
      } else {
        providerId = gameId;
      }
      gameUrl = gameImport.find((x) => x.code.toString() === providerId)
        .gameUrl;
    }
    await store.dispatch('getSession');
    let header;
    // if (store.getters.token) {
    //   header = {
    //     'x-api-key': store.getters.token,
    //     'Content-Type': 'application/json',
    //   };
    // }
    const action = 'withdraw';
    const accid = store.getters.accId;
    const home = '5';
    const sid = store.getters.session;
    const ct = store.getters.compType;
    const amt = amount;
    let returnResult;
    // await this.extendSessionAPI();
    return axios({
      method: 'post',
      headers: header,
      timeout: 1000 * timeoutSeconds, // Wait for timeoutSeconds secondseconds
      url:
        gameUrl +
        `api.aspx?action=${action}&amt=${amt}&ct=${ct}&home=${home}&accid=${accid}&sid=${sid}&game=${gameId}`,
      baseURL: '/',
    })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            //Do something
          } else {
            //  console.log('withDrawFromGame');
            //  console.log(response.data);
            // result.response.result[0].credit[0]
            // returns a json array
            returnResult = result.response;
          }
        });
        return returnResult;
      })
      .catch((error) => {
        if (!error.response) {
          throw new Error(error);
        }
        if (error.response.status == 401) {
          store.dispatch('setTimeoutFlag', true);
        }
        return error;
      });
  }, //end withdraw function
  async depositToGame(gameId, amount) {
    let providerId = gameId.split('-');
    let gameUrl = gameImport.find((x) => x.code.toString() === providerId[0])
      .gameUrl;
    await store.dispatch('getSession');
    let header;
    // if (store.getters.token) {
    //   header = {
    //     'x-api-key': store.getters.token,
    //     'Content-Type': 'application/json',
    //   };
    // }
    const action = 'deposit';
    const accid = store.getters.accId;
    const home = '5';
    const sid = store.getters.session;
    const ct = store.getters.compType;
    const amt = amount;
    let returnResult;
    // await this.extendSessionAPI();
    return axios({
      method: 'post',
      headers: header,
      url:
        gameUrl +
        `api.aspx?action=${action}&amt=${amt}&ct=${ct}&home=${home}&accid=${accid}&sid=${sid}&game=${gameId}`,
      baseURL: '/',
    })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            //Do something
          } else {
            //console.log('withDrawFromGame');
            // console.log(response.data);
            returnResult = result.response;
          }
        });
        return returnResult;
      })
      .catch((error) => {
        if (!error.response) {
          throw new Error(error);
        }
        if (error.response.status == 401) {
          store.dispatch('setTimeoutFlag', true);
        }
        return error;
      });
  }, //end withdraw function
  async loginGame(gameId, loginAction = 'login') {
    // await store.dispatch('getSession');

    let providerId = gameId.split('-');
    let gameUrl = gameImport.find((x) => x.code.toString() === providerId[0])
      .gameUrl;
    let header;
    // if (store.getters.token) {
    //   header = {
    //     'x-api-key': store.getters.token,
    //     'Content-Type': 'application/json',
    //   };
    // }
    const action = loginAction;
    const accid = store.getters.accId;
    const home = '5';
    const sid = store.getters.session;
    const ct = store.getters.compType;
    const langID = store.getters.langId;
    const lang = PreferenceImport.Language.find((l) => l.value == langID)
      .gameLang;
    let returnResult;
    // await this.extendSessionAPI();
    return axios({
      method: 'post',
      headers: header,
      url:
        gameUrl +
        `api.aspx?action=${action}&lang=${lang}&ct=${ct}&home=${home}&accid=${accid}&sid=${sid}&game=${gameId}`,
      baseURL: '/',
    })
      .then((response) => {
        xml2js.parseString(response.data, (err, result) => {
          if (err) {
            //Do something
          } else {
            // console.log('withDrawFromGame');
            // console.log(response.data);
            returnResult = result.response;
          }
        });
        return returnResult;
      })
      .catch((error) => {
        if (!error.response) {
          throw new Error(error);
        }
        if (error.response.status == 401) {
          store.dispatch('setTimeoutFlag', true);
        }
        return error;
      });
  }, //end login game
};
