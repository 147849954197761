<template>
    <div class="col-12 bg-maintenance">
      <div class="row p-0 d-flex h-100">
        <div class="col-lg-6 col-sm-12 p-0">
          <div class="maintenance-body">
            <img
              class="img-responsive"
              src="./../../../assets/images/maintenance.png"
              alt="Card image"
            />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-center">
          <div class="col-12 p-0 mt-5">
            <template v-for="m in maintenanceList" :key="m.icon">
              <div class="align-self-center col-center maintenance-text" >
                <div class="padding-time">
                  <table>
                    <tr>
                      <td style="width: 22%; min-width: 60px">
                        <img class="img-flag" :src="m.icon" />
                      </td>
                      <td style="width: 78%; min-width: 220px">
                        <div class="maintenance-title">
                          <span v-html="m.title"></span>
                          <template v-if="m.title2 != ''">
                            <br />
                            {{ m.title2 }}
                          </template>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>
                        <div
                          v-if="maintenanceRemark != ''"
                          class="row maintenance-time mt-2 pl-2"
                        >
                          {{ maintenanceRemark }}&nbsp;
                          <span class="maintenance-gmt">{{ timeGmt }}</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CustomHttp from "../../../CustomHttp";
import thFlag from "./../../../assets/icons/maintenance/th.png";
import ukFlag from "./../../../assets/icons/maintenance/uk.png";
import cnFlag from "./../../../assets/icons/maintenance/cn.png";
// import CustomStorage from "../../../CustomStorage";
// import CustomEncrypt from '../../../CustomEncrypt';

//Vue export
export default {
  mounted() {
    setInterval(() => {
      this.getSystemMaintenanceStatus();
    }, 300000);

    // const checkFlag = CustomStorage.getItem("maintenance");
    // if (checkFlag != undefined) {
    //   CustomStorage.removeItem("maintenance");
    //   this.getSystemMaintenanceStatus();
    // }
    // this.getUserCashOutDetails();
    // if (this.maintenanceRemark == "") {
    //   this.getSystemMaintenanceStatus;
    // }
  },
  methods: {
    async getSystemMaintenanceStatus() {
      this.isLoading = true;
      const response = await CustomHttp.post("api/v1/system/get-maintenance-status");
      const isMaintenance = response.data.data.isMaintenance;
      if (isMaintenance) {
        this.$store.dispatch("userLogout");
        const remark = response.data.data.remark;

        this.$store.dispatch("setMaintenanceRemark", remark);
        // this.$router.replace("/maintenance");
      } else {
        this.$router.replace("/");
      }
    },
  },
  data() {
    return {
      isLoading: false,
      maintenanceList: [
        {
          icon: ukFlag,
          title: "We're down for maintenance.",
          title2: "Be Right back.",
        },
        { icon: cnFlag, title: "网站维护中，服务恢复时间。", title2: "" },
        {
          icon: thFlag,
          title: "เราลงสําหรับการบํารุงรักษา",
          title2: "เดี๋ยวกลับมา.",
        },
      ],
    };
  },
  computed: {
    maintenanceRemark() {
      return this.$store.getters.maintenanceRemark;
      //return "08:00 PM";
    },
    timeGmt() {
      if (this.$store.getters.maintenanceRemark != "") {
        return "(GMT+8)";
        //  &#40;GMT+8&#41;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.img-responsive {
  text-align: center;
  width: 60%;
  margin-top: 15%;
  margin-left: 40%;
}
.maintenance-body{
  text-align: center;
}
.bg-maintenance {
  background:  url(./../../../assets/images/maintenance-bg.jpg) #0b0c0d 0 0 / cover;
  min-height: 100vh;
}

.padding-time {
  padding-bottom: 2rem !important;
  padding-top: 1rem !important;
}
.maintenance-text{
  margin-left: 15%;
}
@media (max-width: 769px) {
  .img-responsive {
    width: 80%;
    margin-left: 0;
  }
  .bg-maintenance {
    background:  url(./../../../assets/images/maintenance-bg-mb.jpg) #0b0c0d 0 0 / cover;
    min-height: 100vh;
  }
  .maintenance-text{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

}

/* desktop */
@media (min-width: 769px) {
  .img-flag {
    width: 42px;
    height: 42px;
  }

  .maintenance-time {
    font-size: 28px;
    font-weight: 600;
    line-height: 0.83;
    text-align: left;
    color: #e4c847;
  }

  .maintenance-gmt {
    font-size: 14px;
    align-self: flex-end;
    padding-bottom: 1px;
  }
}

/* mobile */
@media (max-width: 769px) {
  /* .padding-time {
    padding-left: 1.5rem;
  } */

  .img-flag {
    width: 28px;
    height: 28px;
  }

  .maintenance-time {
    font-size: 18px;
    font-weight: 600;
    line-height: 0.83;
    text-align: left;
    color: #e4c847;
  }

  .maintenance-gmt {
    font-size: 14px;
    align-self: flex-end;
    padding-bottom: 1px;
  }

  /* .padding-time {
    padding-left: 10%;
  } */
}

.maintenance-title {
  width: 250px;
  white-space: nowrap;
  word-wrap: break-word;
}
</style>
