<template>
  <body style="background: rgb(244, 244, 244);">
    <form method="post" action="./Agreement8.aspx" id="form1" class="aboveBackground">
      <header class="header">
        <img
          alt=""
          src="../../../../assets/images/logo.png"
          width="280"
          height="85"
        />
      </header>

      <section class="col-center">
        <template v-if="userLangId == '2'">
          <ThTerm />
        </template>
        <template v-else-if="userLangId == '3'">
          <VnTerm />
        </template>
        <template v-else>
          <EnTerm />
        </template>
      </section>

      <footer class="footer-mb">
        <div>
          <a
            id="btnAgree"
            class="btn-dt CssBtnAgree-dt"
            href="JavaScript:void(0);"
            @click="onClickAccept"
          >
            {{ $t('AcceptBtn') }}
          </a>
        </div>
        <div>
          <a
            id="btnNotAgree"
            class="btn-dt CssBtnNotAgree-dt"
            href="JavaScript:void(0);"
            @click="onClickReject"
          >
            {{ $t('RejectBtn') }}
          </a>
        </div>
      </footer>
    </form>
  </body>
</template>

<script scoped>
import EnTerm from './EnTerm.vue';
import ThTerm from './ThTerm.vue';
import VnTerm from './VnTerm.vue';
//Vue export
export default {
  components: {
    EnTerm,
    ThTerm,
    VnTerm,
  },
  emits: ['click-accept', 'click-reject'],
  methods: {
    async onClickAccept() {
      this.$emit('click-accept');
    },
    onClickReject() {
      this.$emit('click-reject');
    },
  },
  computed: {
    userLangId() {
      return this.$store.getters.langId.toString();
    },
  },
};
</script>
<style scoped lang="css" src="../../../../assets/css/agreement.css"></style>
<style scoped>
.btn-dt {
  padding: 0.8em 2.4em;
  border-radius: 30px;
  text-align: center;
  font-family: 'Verdana';
  min-width: 65px;
  font-size: 12px;
  display: inline-block;
}

.CssBtnAgree-dt {
  cursor: pointer;
  color: black;
  margin-right: 1.5em;
  background-image: linear-gradient(to bottom, #00edff, #23a4dd);
}

.CssBtnNotAgree-dt {
  cursor: pointer;
  color: #0fcdf0;
  border: solid 1px #0fcdf0;;
}

@media (max-width: 700px) {
  table {
    background: #f4f4f4;
    width: 100%;
    height: 100%;
  }
  .body-wrap > div {
    height: 100% !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }
}

table {
  background: #f4f4f4;
  width: 100%;
  /* height: 100%; */
}
.body-wrap > div {
  height: 100% !important;
  overflow: auto !important;
}

.body-wrap {
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding-bottom: 80px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  display: block;
  overflow: scroll;
}

section {
  flex: 1;
  display: flex;
}
.header {
  background: black;
  text-align: center;
  padding: 1em;
}
.col-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CssBtnAgree,
.CssBtnNotAgree {
  text-align: center;
  margin-right: 1.5em;
}

.agree-btn-container {
  /* margin: 1em 1em 1em 1em;  */
  /* Top, Right, Bottom, Left */
}

.agree-btn-top {
  margin-bottom: 1em;
  margin-top: 1em;
}
.agree-btn-bottom {
  /* margin-top: 0.5em !important;
  margin-bottom: 0em !important; */
  padding-bottom: 1.5rem !important;
  /* padding-top: 4rem !important; */
}

@media (max-height: 720px) {
  .agree-btn-bottom {
    /* padding-top: 6vh !important; */
    padding-top: 0vh !important;
  }
}

@media (min-height: 720px) {
  .agree-btn-bottom {
    /* padding-top: calc(10vh - 8px) !important; */
    margin-top: calc(9.5vh - 8px) !important;
  }
}

@media (min-height: 1000px) {
  .agree-btn-bottom {
    padding-top: calc(57vh - 8px) !important;
  }
}

.footer-mb {
  position: fixed;
  background: #040d2a;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-mb {
  padding: 1em 2.5em;
  border-radius: 30px;
  text-align: center;
  font-family: 'Verdana';
  min-width: 65px;
}
@media (max-width: 340px) {
  .btn-mb {
    padding: 1em 1.5em;
  }
}
.CssBtnAgree_mb {
  cursor: pointer;
  color: white;
  margin-right: 1.5em;
  box-shadow: 0 4px 4px 0 rgba(136, 108, 44, 0.45);
  background-image: linear-gradient(to bottom, #ebdca6, #8a6f36 59%, #bc9f5b);
}
.CssBtnNotAgree_mb {
  cursor: pointer;
  color: #bfbfbf;
  border: solid 1px #bfbfbf;
}
#termsFrame {
  width: 767px;
  height: 465px;
}
.d-none {
  display: none !important;
}

h1 {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.strong {
  font-weight: bold !important;
}

/* a:hover {
  color: inherit;
} */

.iframe-class {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: inset;
  border-color: initial;
  border-image: initial;
}

.CssBtnAgree {
  background: url(../../../../assets/icons/s28.jpg) no-repeat 0px 0px;
  background-position: 0px 0px;
  width: 78px;
  height: 20px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  line-height: 20px;
}
.CssBtnNotAgree {
  background: url(../../../../assets/icons/s12.jpg) no-repeat 0px 0px;
  background-position: 0px 0px;
  width: 78px;
  height: 20px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #000;
  text-decoration: none;
  line-height: 20px;
}

.iframe-table {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 500px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  -webkit-border-horizontal-spacing: 10px;
  -webkit-border-vertical-spacing: 10px;
  /* default style */
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

.iframe-table tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.iframe-table td {
  display: table-cell;
  vertical-align: inherit;
  padding: 20px;
}

.header-logo {
  width: 213px;
  height: 64px;
  object-fit: contain;
}

#agreement-wraper {
  height: 100%;
  display: block;
}

p {
  /* margin: 10px 0 !important; */
  padding: 0;
}

/* desktop */
@media (min-width: 769px) {
  @media (max-height: 660px) {
    .table-wrapper {
      height: 63vh;
    }
  }

  @media (max-height: 600px) {
    .table-wrapper {
      height: 60vh !important;
    }
  }

  @media (max-height: 550px) {
    .table-wrapper {
      height: 57vh !important;
    }
  }

  @media (max-height: 520px) {
    .table-wrapper {
      height: 55vh !important;
    }
  }

  @media (max-height: 490px) {
    .table-wrapper {
      height: 52vh !important;
    }
  }

  @media (max-height: 740px) {
    .table-wrapper {
      height: 74vh;
    }
  }
  @media (max-height: 600px) {
    .table-wrapper {
      height: 70vh;
    }
  }

  @media (max-height: 590px) {
    .table-wrapper {
      height: 68vh;
    }
  }

  @media (max-height: 530px) {
    .table-wrapper {
      height: 67vh;
    }
  }

  @media (max-height: 480px) {
    .table-wrapper {
      height: 64vh;
    }
  }
}

@media (max-width: 7400px) {
  /* @media (min-height: 800px) {
    .table-wrapper {
      height: 72vh;
    }
  } */

  @media (max-height: 740px) {
    .table-wrapper {
      height: 64vh;
    }
  }

  @media (max-height: 600px) {
    .table-wrapper {
      height: 70vh;
    }
  }
  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 21px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 10px rgb(238, 238, 238); */
    -webkit-border-radius: 10px;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 10px rgb(238, 238, 238);
    background-clip: content-box;
    border-top: 8px solid #ffffff;
    border-right: 8px solid #ffffff;
    border-left: 8px solid #ffffff;
    border-bottom: 8px solid #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 10px;
    height: 77px;
    border-radius: 30px;
    background-color: #fed974;
    background-clip: content-box;
    border-top: 8px solid transparent;
    border-right: 8px solid #ffffff;
    border-left: 8px solid #ffffff;
    border-bottom: 8px solid transparent;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #fed974;
  }

  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .iframe-table td {
    line-height: 115%;
  }

  .bottom-container {
    width: 767px;
    margin: 0 auto;
    padding-bottom: 15px;
    border-top: 1px solid #d4d4d4;
    padding-top: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
  }
}

/* mobile */
@media (max-width: 769px) {
  /* Track */
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 10px rgb(238, 238, 238); */
    -webkit-border-radius: 10px;
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 10px rgb(238, 238, 238);
    background-clip: content-box;
    border-top: 8px solid #f4f4f4;
    border-right: 8px solid #f4f4f4;
    border-left: 8px solid #f4f4f4;
    border-bottom: 8px solid #f4f4f4;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 10px;
    height: 77px;
    border-radius: 30px;
    background-color: #fed974;
    background-clip: content-box;
    border-top: 8px solid transparent;
    border-right: 8px solid #f4f4f4;
    border-left: 8px solid #f4f4f4;
    border-bottom: 8px solid transparent;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #fed974;
  }

  .header img {
    width: 200px;
    height: auto;
  }
  #termsFrame {
    width: 100%;
    height: calc(100vh - 70px);
  }
  .iframe-table td {
    line-height: 140%;
  }

  .btn-dt {
    padding: 1em 2.5em;
  }

  .border-secondary {
    border: 0px;
  }
}
.table-wrapper {
  overflow-x: hidden !important;
  overflow-y: scroll;
  display: block;
}

.clear-both {
  clear: both;
}

.content-body {
  background-color: #f7f7f7;
  min-height: 100% !important;
  display: block !important;
}

#Header {
  width: 100%;
}
#Content {
  height: 100% !important;
  min-height: 82vh !important;
  width: 100% !important;
  display: block !important;
  overflow-x: hidden !important;
}

.card-body {
  min-height: 35vh !important;
  width: 100% !important;
  display: block !important;
  overflow: auto;
  padding: 0rem !important;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.card-header {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.border-secondary {
  border-color: #b5b5b5 !important;
}

.card-footer {
  background-color: #fff !important;
  padding: 0.9rem 1.25rem;
}

.table-wrapper {
  font-family: Arial, Helvetica, sans-serif;
  height: 64.5vh;
  font-size: 12px;
  display: block;
  overflow: scroll;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

/* mobile */
@media (max-width: 769px) {
  .card-body {
    padding: 0px !important;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }

  .table-wrapper {
    height: 79vh;
  }
  .card-footer {
    padding: 1rem 1.25rem;
  }
  .btn-dt {
    padding: 1.4em 4.25em;
  }
  .CssBtnAgree-dt {
    margin-right: 2em;
  }

  @media (max-height: 740px) {
    .table-wrapper {
      height: 74vh;
    }
  }
  @media (max-height: 600px) {
    .table-wrapper {
      height: 70vh;
    }
  }

  @media (max-height: 590px) {
    .table-wrapper {
      height: 68vh;
    }
  }

  @media (max-height: 530px) {
    .table-wrapper {
      height: 67vh;
    }
  }

  @media (max-height: 480px) {
    .table-wrapper {
      height: 64vh;
    }
  }
}

@media (max-width: 7400px) {
  @media (max-height: 660px) {
    .table-wrapper {
      height: 69vh;
    }
  }
  @media (max-height: 600px) {
    .table-wrapper {
      height: 70vh;
    }
  }
}

@media (max-width: 400px) {
  @media (max-height: 740px) {
    .table-wrapper {
      height: 74vh;
    }
  }
}

@media (max-width: 320px) {
  @media (max-height: 740px) {
    .table-wrapper {
      height: 69vh;
    }
  }
}

@media (min-width: 1100px) {
  @media (max-height: 1400px) {
    .table-wrapper {
      height: 64vh;
    }
  }
}

@media (max-width: 1100px) {
  @media (max-height: 760px) {
    .table-wrapper {
      height: 76vh;
    }
  }
}

@media (max-width: 1100px) {
  @media (max-height: 650px) {
    .table-wrapper {
      height: 65vh;
    }
  }
}

@media (max-width: 560px) {
  @media (max-height: 830px) {
    .table-wrapper {
      height: 79vh;
    }
  }
}

@media (max-width: 420px) {
  @media (max-height: 740px) {
    .table-wrapper {
      height: 76vh;
    }
  }
}

@media (max-width: 560px) {
  @media (max-height: 730px) {
    .table-wrapper {
      height: 73vh;
    }
  }
}

@media (max-width: 700px) {
  table {
    background: #f4f4f4;
    width: 100%;
    height: 100%;
  }
  body > div {
    height: 100% !important;
    overflow: auto !important;
  }
}
</style>
