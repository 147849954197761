import gameImport from './../game';

import sa from './../../images/game/live/img-sagaming-d.png';
import sexyba from './../../images/game/live/img-sexybaccarat-d.png';
//import ebet from './../../images/game/live/img-ebet-d.png';
//import venus from './../../images/game/live/img-venuscasino-d.png';
import big from './../../images/game/live/img-biggaming-d.png';
//import pgs from './../../images/game/live/img-pgs-d.png';
import hotroad from './../../images/game/live/img-hotrod-d.png';

//import worldent from './../../images/game/live/img-nanyang-d.png';
//import pp from './../../images/game/live/v-sport-lc-pplive-s.png';

var gameMenu = {
  name: 'LIVE CASINO',
  items: [
    // {
    //   name: 'PEGASUS',
    //   isFullWidth: false,
    //   provider: 'pegasus',
    //   icon: gameImport.find((x) => x.code.toString() === '80').icon,
    //   code: '80-202',
    //   img: [{ langId: 0, src: pgs }],
    //   img2: [{ langId: 0, src: pgs }],
    // },
    {
      name: 'SA GAMING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '31').icon,
      code: '31',
      img: [{ langId: 0, src: sa }],
      img2: [{ langId: 0, src: sa }],
    },
    {
      name: 'AE SEXY',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-101',
      img: [{ langId: 0, src: sexyba }],
      img2: [{ langId: 0, src: sexyba }],
    },
    // {
    //   name: 'eBET',
    //   provider: 'ebet',
    //   icon: gameImport.find((x) => x.code.toString() === '64').icon,
    //   code: '64',
    //   img: [{ langId: 0, src: ebet }],
    //   img2: [{ langId: 0, src: ebet }],
    // },
    // {
    //   name: 'VENUS CASINO',
    //   provider: 'game hall',
    //   icon: gameImport.find((x) => x.code.toString() === '39').icon,
    //   code: '39-102',
    //   img: [{ langId: 0, src: venus }],
    //   img2: [{ langId: 0, src: venus }],
    // },
    {
      name: 'BIG GAMING',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-108',
      img: [{ langId: 0, src: big }],
      img2: [{ langId: 0, src: big }],
    },
    // {
    //   name: 'WORLD. ENT',
    //   provider: 'game hall',
    //   icon: gameImport.find((x) => x.code.toString() === '81').icon,
    //   code: '81',
    //   img: [{ langId: 0, src: worldent }],
    //   img2: [{ langId: 0, src: worldent }],
    // },
    //{
    //  name: 'PRAGMATIC PLAY',
    //  provider: 'game hall',
    //  icon: gameImport.find((x) => x.code.toString() === '39').icon,
    //  code: '39-132',
    //  img: [{ langId: 0, src: pp }],
    //  img2: [{ langId: 0, src: pp }],
    //},
    {
      name: 'Hot Road Gaming',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-153',
      img: [{ langId: 0, src: hotroad }],
      img2: [{ langId: 0, src: hotroad }],
    },
  ],
};

export default gameMenu;
