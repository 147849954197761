import gameImport from './../game';


import sb from './../../images/game/sportsbook/img-sportsbook2-d.png';
import parley from './../../images/game/sportsbook/img-parley-d.png';
import basketball from './../../images/game/sportsbook/img-basketball-d.png';
import tennis from './../../images/game/sportsbook/img-tennis-d.png';
import badminton from './../../images/game/sportsbook/img-badminton-d.png';
import baseball from './../../images/game/sportsbook/img-baseball-d.png';
// import evo from './../../images/game/sportsbook/v-sport-evo-racing-s-min.png';

var gameMenu = {
  name: 'SPORTSBOOK',
  items: [
    {
      name: 'Sportsbook',
      target: 'sportsbook',
      provider: '',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '1',
      img: [
        { langId: 0, src: sb },
        { langId: 2, src: sb },
      ],
    },
    {
      name: 'Parley',
      target: 'sportsbook',
      provider: '',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '2',
      img: [
        { langId: 0, src: parley },
        { langId: 2, src: parley },
      ],
    },
    {
      name: 'Basketball',
      target: 'sportsbook',
      provider: '',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '3',
      img: [
        { langId: 0, src: basketball },
        { langId: 2, src: basketball },
      ],
    },
    {
      name: 'Tennis',
      target: 'sportsbook',
      provider: '',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '4',
      img: [
        { langId: 0, src: tennis },
        { langId: 2, src: tennis },
      ],
    },
    {
      name: 'Badminton',
      target: 'sportsbook',
      provider: '',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '5',
      img: [
        { langId: 0, src: badminton },
        { langId: 2, src: badminton },
      ],
    },
    {
      name: 'Baseball',
      target: 'sportsbook',
      provider: '',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '6',
      img: [
        { langId: 0, src: baseball },
        { langId: 2, src: baseball },
      ],
    },
    // {
    //   name: 'Evo Racing',
    //   provider: 'evolution',
    //   icon: gameImport.find((x) => x.code.toString() === '98').icon,
    //   code: '98',
    //   img: [
    //     { langId: 0, src: evo },
    //     { langId: 2, src: evo },
    //   ],
    //   img2: [{ langId: 0, src: evo }],
    // },
  ],
};

export default gameMenu;
