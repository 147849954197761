import gameImport from './../game';


import thaibo from './../../images/game/cards/thaiho.png';
import sicbo from './../../images/game/cards/sicbo.png';
import sthaibo from './../../images/game/cards/img-thaihilo-s.png';
import ssicbo from './../../images/game/cards/img-sicbo-s.png';

var gameMenu = {
  name: 'CARDS',
  items: [
    //child
    {
      name: 'THAI LI HO',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: thaibo }],
      img2: [{ langId: 0, src: sthaibo }],
    },
    {
      name: 'SIC BO',
      provider: 'game hall',
      icon: gameImport.find((x) => x.code.toString() === '39').icon,
      code: '39-204',
      img: [{ langId: 0, src: sicbo }],
      img2: [{ langId: 0, src: ssicbo }],
      isNew: false,
      labelName: '',
      parentName: '',
    }
  ],
};

export default gameMenu;
